import { useForm, Controller, useWatch } from "react-hook-form"
import classNames from "classnames/bind"
import { useEffect, useRef, useState, useContext } from "react"
import Sortable from "sortablejs"
import io from "socket.io-client"
import { IoImages } from "react-icons/io5"
import { MdOutlineCancel } from "react-icons/md"
import { IoChatboxEllipsesOutline } from "react-icons/io5"

import {
  handleFormatPriceInput,
  handleFormatPrice,
  TextLimit,
  deleteAccentedText,
} from "../../handle-reuses/reuses"
import styles from "./FormRealEstate.module.scss"
import {
  handleAddDataMarketSimple,
  handleSuggestStreetEstate,
} from "../../handle-reuses/Market-simple/Method/Market-simple"
import AppContext from "../../handle-reuses/Contexts/CreateContexts"
import { handleNLP } from "../../handle-reuses/reuses/suptech/nlp"
import MyEditer from "../CkEditer/MyEdit"
import CustomEditor from "../CkEditer/CustomEditer"
import ModalReact from "../Modal/ModalReact"
import { WalletMain } from "../../pages/pc/Component/Wallet"

const cx = classNames.bind(styles)
const infoOther = {
  info: `
  <p>Thông tin chính</p>
  <ul>
  <li>Đường ngõ: </li>
  <li>Diện tích MB: </li>
  <li>Mặt tiền: </li>
  <li>Số tầng: </li>
  <li>Hướng nhà/Ban công: </li>
  </ul>`,
}

const optiondonvi = [
  { id: 1, donvi: "Đêm", type: "cho thuê" },
  { id: 2, donvi: "Ngày", type: "cho thuê" },
  { id: 3, donvi: "Tháng", type: "cho thuê" },
  { id: 4, donvi: "Năm", type: "cho thuê" },
  { id: 5, donvi: "Thỏa thuận", type: "cho thuê" },
]

function FormRealEstateFast({result}) {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm()
  const [files, setFiles] = useState([])
  const [objectFile, setObjectFile] = useState([])
  const [formatPrice, setFormatPrice] = useState(0)
  const [electricity, setElectricity] = useState(0)
  const [water, setWater] = useState(0)
  const [serviceOther, setServiceOther] = useState(0)
  const [deposit, setDeposit] = useState(0)
  const { dataUser, dataBalance, socketio } = useContext(AppContext)
  const updateObjectFile = useRef([])

  // suggest cate.
  const [optionCate, setOptionCate] = useState([])
  const [optionType, setOptionType] = useState([])
  useEffect(() => {
    // Suggest cate
    socketio.emit("client-data-cate", {iden: process.env.REACT_APP_IDENTIFICATION})
    socketio.on("server-data-cate", (data) => {
      setOptionCate(data)
    })
    // Suggest type.
    socketio.emit("client-data-type", {iden: process.env.REACT_APP_IDENTIFICATION})
    socketio.on("server-data-type", (data) => {
      setOptionType(data)
    })
    return () => {
      socketio.off("client-data-cate")
      socketio.off("client-data-type")
      socketio.off("server-data-cate")
      socketio.off("search-data-type")
    }
  }, [])

  // format price.
  const handleFormatPrice_ = (e) => {
    const formatPrice_ = e.target.value.replace(/[,. ]/g, "")
    if (isNaN(formatPrice_)) {
      alert("Bạn cần nhập dạng số.")
    } else {
      const FP = handleFormatPriceInput(e.target.value)
      setFormatPrice(FP)
    }
  }

  // format giá điện
  const handleFormatElectricity = (e) => {
    const formatPrice_ = e.target.value.replace(/[,. ]/g, "")
    if (isNaN(formatPrice_)) {
      alert("Bạn cần nhập dạng số.")
    } else {
      const FP = handleFormatPriceInput(e.target.value)
      setElectricity(FP)
    }
  }
  // Formta giá nước
  const handleFormatWater = (e) => {
    const formatPrice_ = e.target.value.replace(/[,. ]/g, "")
    if (isNaN(formatPrice_)) {
      alert("Bạn cần nhập dạng số.")
    } else {
      const FP = handleFormatPriceInput(e.target.value)
      setWater(FP)
    }
  }
  // format giá dịch vụ
  const handleFormatServiceOther = (e) => {
    const formatPrice_ = e.target.value.replace(/[,. ]/g, "")
    if (isNaN(formatPrice_)) {
      alert("Bạn cần nhập dạng số.")
    } else {
      const FP = handleFormatPriceInput(e.target.value)
      setServiceOther(FP)
    }
  }
  // format tiền đặt cọc
  const handleFormatDeposit = (e) => {
    const formatPrice_ = e.target.value.replace(/[,. ]/g, "")
    if (isNaN(formatPrice_)) {
      alert("Bạn cần nhập dạng số.")
    } else {
      const FP = handleFormatPriceInput(e.target.value)
      setDeposit(FP)
    }
  }

  // LInk đên thẻ input khi click button.
  const linkRef = useRef()
  const handleAddImages = () => {
    linkRef.current.click()
  }
  // Xử lý hiển thị xem trước hình ảnh
  const handleFileImages = (e) => {
    const file = e.target.files;
     
     
    if (file.length > 0) {
      let checkLengthFile =
        Number(file.length) + Number(updateObjectFile.current.length)
      if (checkLengthFile > 6) {
        alert(
          "Tối đa chỉ được 6 ảnh, hãy lựa chọn những ảnh bạn cho là đẹp nhất để truyền thông.",
        )
      } else {
        updateObjectFile.current = [...updateObjectFile.current, ...file]
        // Hiển thị giao diện người dùng
        const newFile = Array.from(file).map((file_) => {
          return URL.createObjectURL(file_)
        })
        setFiles((fileOld) => [...fileOld, ...newFile])
        // Cập nhật lên CSDL

        setObjectFile((old) => [...old, ...file])
      }
    }
  }
  useEffect(() => {
    // Quản lý sắp xếp ảnh
    new Sortable(document.querySelector(".imageContainer"), {
      animation: 250, // Thời gian chuyển động khi sắp xếp (milliseconds)
      // Các tùy chọn khác...
      onEnd: (evt) => {
        // Xử lý sự kiện khi kết thúc việc sắp xếp
        const items = evt.from.children
        const newImages = []
        for (let i = 0; i < items.length; i++) {
          newImages.push(items[i].getAttribute("data-image").replace(/\D/g, ""))
        }
        // Thực hiện cập nhật danh sách sắp xếp hình ảnh
        // console.log('New image order:', newImages);
        // console.log([...file])
        let sortList = []
        for (let i = 0; i < [...updateObjectFile.current].length; i++) {
          sortList.push(updateObjectFile.current[newImages[i]])
        }
        setObjectFile(sortList)
      },
    })
  }, [])

  // Delete images.
  const handleDeleteImages = (index) => {
    const updateImage = [...files];
    URL.revokeObjectURL(updateImage[index])
    updateImage.splice(index, 1)
    setFiles(updateImage)
    // xóa ảnh khỏi form file (cập nhật lại form file).
    const formatArr = [...updateObjectFile.current]
    formatArr.splice(index, 1)
    updateObjectFile.current = formatArr
    setObjectFile(updateObjectFile.current)
  }

  // Xử lý mô tả bằng AI.
  const requestValue = useWatch({
    control,
    name: "title",
  })
  const city_ = useWatch({
    control,
    name: "city",
  })
  const streets = useWatch({
    control,
    name: "address",
  })
  const districts_ = useWatch({
    control,
    name: "districts",
  })
  const [textInput, setTextInput] = useState("")
  const [textOut, setTextOut] = useState("")
  const indexRef = useRef(0)

  // get discription
  const handleContentAI = () => {
    // Đầu vào phải khác rỗng
    if (city_ !== "" && streets !== "" && districts_ !== "") {
      // Ý định người dùng (trường hợp 1)
      const intent = handleNLP(requestValue)
      // lấy ý định từ yêu cầu: tuyến đường, quận huyện, thành phố (trường hợp 2)
      const object = {
        streets: streets,
        districts: districts_,
        city: city_,
      }
      const checkMethod = {
        method: "POST",
        headers: {
          "content-type": "application/json",
          identification: process.env.REACT_APP_IDENTIFICATION,
        },
        body: JSON.stringify(object),
      }
      const fetchData = async () => {
        const response = await fetch(
          process.env.REACT_APP_APIGetMarketContentAi,
          checkMethod,
        )
        const dataRes = await response.json()
        if (dataRes.length > 0) {
          const indexRandom = Math.floor(Math.random() * dataRes.length)
          const result = dataRes[indexRandom]
          const text = `<p>${result.content_location}</p> <p>${result.content_advantage}</p><p>${result.content_contact}</p>`
          setTextInput(text)
        } else {
          const text = `Tôi rất xin lỗi vì chưa thể đáp yêu cầu của bạn vào lúc này, nhưng tôi hứa sẽ bổ sung dữ liệu trong tương lai.`
          // setTextInput(text)
          alert(text)
        }
      }
      // === Thêm yêu cầu vào thống kê cho quản trị biết đã có người dùng yêu cầu này. ===
      const intent__ = streets + " " + districts_ + " " + city_
      const params = {
        title: intent__,
        identification: process.env.REACT_APP_IDENTIFICATION,
      }
      socketio.emit("client-check-content-ai-statistical", params)
      socketio.on("server-check-content-ai-statistical", (data) => {
        if (data.length > 0) {
          let object = {
            params: {
              id: data[0].id,
              quantity: Number(data[0].quantity) + 1,
            },
            iden: process.env.REACT_APP_IDENTIFICATION,
          }
          socketio.emit("client-edit-content-ai-statistical", object)
        } else {
          let object = {
            params: {
              title: intent__,
              quantity: 0,
              source: "Lấy từ form thêm mới sản phẩm",
            },
            iden: process.env.REACT_APP_IDENTIFICATION,
          }
          socketio.emit("client-create-content-ai-statistical", object)
        }
      })
      // === * Thêm yêu cầu vào thống kê cho quản trị biết đã có người dùng yêu cầu này. ===

      // Không cho người dùng click khi content AI đang chạy.
      if (indexRef.current === 0) {
        fetchData()
      }
    } else {
      alert("Bạn chưa điền đẩy đủ thông tin bắt buộc.")
    }
  }
  // Tạo hiệu ứng
  const typeWriter = () => {
    const textOut_ = textInput.slice(0, indexRef.current)
    setTextOut(textOut_)
    indexRef.current = indexRef.current + 1

    if (indexRef.current <= textInput.length) {
      document
        .getElementById("submitProduct")
        .setAttribute("style", "display:none")
      setTimeout(typeWriter, 3)
    } else {
      // Reset về ban đầu.
      indexRef.current = 0
      document
        .getElementById("submitProduct")
        .setAttribute("style", "display:block")
    }
  }
  // Quản lý hiệu ứng
  useEffect(() => {
    if (textInput.length > 0) {
      typeWriter()
    }
  }, [textInput])

  // suggest đơn vị cạnh giá sản phẩm
  const [optionDonviSP, setOptionDonviSP] = useState([])
  // Thông tin dịch vụ.
  const [serviceInfo, setServiceInfo] = useState("none")
  const handleEventCate = (e) => {
    const checkCate = e.target.value
    if (
      checkCate.toLowerCase().includes("cho thuê căn hộ") ||
      checkCate.toLowerCase().includes("cho thuê chung cư") ||
      checkCate.toLowerCase().includes("cho thuê nhà riêng") ||
      checkCate.toLowerCase().includes("cho thuê phòng trọ") ||
      checkCate.toLowerCase().includes("cho thuê mặt bằng")
    ) {
      // Chỉ hiển thị cho thuê nhà
      setServiceInfo("block")
      setOptionDonviSP(optiondonvi)
    } else if (
      checkCate.toLowerCase() === "cho thuê đất nền" ||
      checkCate.toLowerCase() === "cho thuê kho bãi, nhà xưởng"
      // checkCate.toLowerCase() === "cho thuê mặt bằng"
    ) {
      setServiceInfo("none")
      setOptionDonviSP([
        { id: 3, donvi: "Tháng", type: "cho thuê" },
        { id: 4, donvi: "Năm", type: "cho thuê" },
      ])
    } else {
      // Ẩn form nếu là bán
      setServiceInfo("none")
      setOptionDonviSP([{ id: 1, donvi: "Thỏa thuận", type: "bán" }])
    }
  }

  // Quản lý đơn vị.
  const [disPrice, setDisPrice] = useState(false)
  const handleDonviPrice = (e) => {
    const dvPr = e.target.value
    if (dvPr === "Thỏa thuận") {
      setDisPrice(true)
    } else {
      setDisPrice(false)
    }
  }

  // Gợi ý tuyến đường, quận huyện, thành phố.
  const [dataStreet, setDataStreet] = useState([])
  const [street, setStreet] = useState("")
  const hideRef = useRef()
  // Street
  const handleGetValueStreet = (e) => {
    const getData = async () => {
      setStreet(e.target.value)
      const arrStr = await handleSuggestStreetEstate(e.target.value)
      if (arrStr) {
        // Loại bỏ giá trị không tồn tại trong mảng.
        const filterArrStr = arrStr.filter((item) => {
          return item.address !== "0"
        })
        // Tạo một đối tượng dùng để theo dõi các giá trị duy nhất của thuộc tính
        const uniqueValues = []
        // Lọc mảng để chỉ giữ lại các phần tử có giá trị duy nhất của thuộc tính
        const uniqueArr = filterArrStr.filter((item) => {
          if (!uniqueValues[item.address]) {
            uniqueValues[item.address] = true
            return true
          }
        })
        setDataStreet(uniqueArr)
      }
    }
    getData()
  }
  const handleOptionStreet = (option) => {
    setStreet(option)
    setDataStreet([])
  }
  useEffect(() => {
    const handleHideSuggest = (e) => {
      if (hideRef.current && !hideRef.current.contains(e.target)) {
        setDataStreet([])
      }
    }
    window.addEventListener("click", handleHideSuggest)
    return () => {
      window.removeEventListener("click", handleHideSuggest)
    }
  }, [])

  // Districts.
  const [datadistricts, setDataDistricts] = useState([])
  const [districts, setDistricts] = useState("")
  const disRef = useRef()
  const handleGetValueDistricts = (e) => {
    const getData = async () => {
      setDistricts(e.target.value)
      const arrStr = await handleSuggestStreetEstate(e.target.value)
      if (arrStr) {
        // Loại bỏ giá trị không tồn tại trong mảng.
        const filterArrStr = arrStr.filter((item) => {
          return item.districts !== "0"
        })
        // Tạo một đối tượng dùng để theo dõi các giá trị duy nhất của thuộc tính
        const uniqueValues = []
        // Lọc mảng để chỉ giữ lại các phần tử có giá trị duy nhất của thuộc tính
        const uniqueArr = filterArrStr.filter((item) => {
          if (!uniqueValues[item.districts]) {
            uniqueValues[item.districts] = true
            return true
          }
        })
        setDataDistricts(uniqueArr)
      }
    }
    getData()
  }
  const handleOptionDistricts = (option) => {
    setDistricts(option)
    setDataDistricts([])
  }
  useEffect(() => {
    const handleHideSuggest = (e) => {
      if (disRef.current && !disRef.current.contains(e.target)) {
        setDataDistricts([])
      }
    }
    if (datadistricts.length > 0) {
      window.addEventListener("click", handleHideSuggest)
    }
    return () => {
      window.removeEventListener("click", handleHideSuggest)
    }
  }, [])

  // Thành phố.
  const [dataCity, setDataCity] = useState([])
  const [city, setCity] = useState("")
  const cityRef = useRef()
  // Street
  const handleGetValueCity = (e) => {
    const getData = async () => {
      setCity(e.target.value)
      const arrStr = await handleSuggestStreetEstate(e.target.value)
      if (arrStr) {
        // Loại bỏ giá trị không tồn tại trong mảng.
        const filterArrStr = arrStr.filter((item) => {
          return item.city !== "0"
        })
        // Tạo một đối tượng dùng để theo dõi các giá trị duy nhất của thuộc tính
        const uniqueValues = []
        // Lọc mảng để chỉ giữ lại các phần tử có giá trị duy nhất của thuộc tính
        const uniqueArr = filterArrStr.filter((item) => {
          if (!uniqueValues[item.city]) {
            uniqueValues[item.city] = true
            return true
          }
        })
        setDataCity(uniqueArr)
      }
    }
    getData()
  }
  const handleOptionCity = (option) => {
    setCity(option)
    setDataCity([])
  }
  useEffect(() => {
    const handleHideSuggest = (e) => {
      if (cityRef.current && !cityRef.current.contains(e.target)) {
        setDataCity([])
      }
    }
    window.addEventListener("click", handleHideSuggest)
    return () => {
      window.removeEventListener("click", handleHideSuggest)
    }
  }, [])

  // Giá trị từ MyEdit (discription).
  const [resultDis, setResultDis] = useState("")
  const handleValueDiscription = (value) => {
    setResultDis(value)
  }

  // Format trường dtmb.
  const [formatDtmb, setFormatDtmb] = useState("")
  const handleFormatDtmb = (e) => {
    let formatDt = e.target.value;
    if (!isFinite(formatDt)) {
      alert("Bạn cần nhập dạng số.")
    }
    setFormatDtmb(formatDt.replace(/[^0-9.]/g, ""))
  }
  // format trường mattien.
  const [formatMattien, setFormatMattien] = useState("")
  const handleFormatMattien = (e) => {
    let formatMattien = e.target.value
    if (!isFinite(formatMattien)) {
      alert("Bạn cần nhập dạng số.")
    }
    setFormatMattien(formatMattien.replace(/[^0-9.]/g, ""))
  }
  // Format số tầng.
  const [formatSotang, setFormatSotang] = useState("")
  const handleFormatSotang = (e) => {
    let formatSotang = e.target.value
    if (!isFinite(formatSotang)) {
      alert("Bạn cần nhập dạng số.")
    }
    setFormatSotang(formatSotang.replace(/[^0-9.]/g, ""))
  }
  // format đường ngõ.
  const [formatDuongngo, setFormatDuongngo] = useState("")
  const handleFormatDuongngo = (e) => {
    let formatDuongngo = e.target.value
    if (!isFinite(formatDuongngo)) {
      alert("Bạn cần nhập dạng số.")
    }
    setFormatDuongngo(formatDuongngo.replace(/[^0-9.]/g, ""))
  }
  // format số điện thoại.
  const [formatPhone, setFormatPhone] = useState("")
  const handleFormatPhone = (e) => {
    let formatPhone = e.target.value
    let format_ = formatPhone.replace(/[.]/g, "")
    if (!isFinite(format_)) {
      alert("Bạn cần nhập dạng số.")
    }
    if (formatPhone.length <= 12) {
      setFormatPhone(formatPhone.replace(/[^0-9.]/g, ""))
    }
  }


  // ẩn bảng đăng tin nếu đăng tin thành công.
  const checkoffSocket = useRef(false)
  const hidenModalPostEstate = (status_) => {
    if (status_) {
      // Ẩn bảng đăng tin
      result(status_)
      if (status_) {
        alert("Bạn đã đăng tin thành công")
        checkoffSocket.current = true
      }
      // Xóa file khỏi bộ nhớ nếu submit xong.
      URL.revokeObjectURL(files)
      // Xóa list images
      setFiles([])
      updateObjectFile.current = []
      // xóa trường format price.
      setFormatPrice(0)
      // Xóa nội dung.
      setTextOut("")
    }
  }


  // Nút submit
  const onSubmit = (data) => {
    delete data.listImages
    data.short_keywords = data.cate + " " + data.form_cate + " " + data.address
    data.discription = resultDis === "" ? textOut : resultDis
    let formatString = data.address.toLowerCase()
    data.alias_address = deleteAccentedText(formatString).replace(
      /[ /]/g,
      "-",
    ).replace(/[,.]/g, "").replace(/[%]/g, "")

    const formatObjectFile = { ...objectFile, length: objectFile.length }
    setTimeout(() => {
      // Kiểm tra số dư tài khoản
      socketio.emit("client-check-user-pay", dataUser[0].id)
      socketio.on("server-check-user-pay", (dataPay) => {
        // Kiểm tra số lượng ảnh trước khi thêm mới
        if (formatObjectFile.length <= 6) {
          handleAddDataMarketSimple(
            data,
            formatObjectFile,
            dataUser,
            dataPay,
            "addEstate",
            hidenModalPostEstate,
          )
        } else {
          alert("Số lượng ảnh vượt quá 6 ảnh.")
        }
      })
    }, 300);

    //tự động Thêm mới seo cate nếu dữ liệu chưa tồn tại (cho mục trang tìm kiếm để tạo seo).
    let formatAliasSeo = (
      data.cate +
      " đường " +
      data.address +
      " " +
      data.city
    ).toLowerCase()
    const paramsSeoCate = {
      title: formatAliasSeo,
      alias: deleteAccentedText(formatAliasSeo).replace(/[., /]/g, "-"),
      description: formatAliasSeo + ", " + deleteAccentedText(formatAliasSeo),
      keywords:
        formatAliasSeo +
        ", " +
        data.cate +
        " mặt đường " +
        data.address +
        ", " +
        data.cate +
        " trong ngõ " +
        data.address +
        ", " +
        data.short_keywords +
        ", " +
        data.cate +
        " đường " +
        data.address +
        " " +
        data.districts +
        ", " +
        deleteAccentedText(formatAliasSeo),
    }
    socketio.emit("client-create-data-seo-cate", paramsSeoCate)
  }
  
  useEffect(() => {
    return () => {
      socketio.off("client-check-user-pay")
      socketio.off("server-check-user-pay")
      socketio.off("client-create-data-seo-cate")
    }
  }, [checkoffSocket.current])

  // Modal pay his.
  const [modalPayHis, setModalPayHis] = useState(false)
  const handleModalPayHis = () => {
    setModalPayHis(true)
  }
  const handleCloseModalPayHis = () => {
    setModalPayHis(false)
  }

  return (
    <div className="p-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row gy-3 pb-3">
          <div className="col-xl-12 col-md-12 position-relative">
            <label className="form-label mb-1 text-muted">
              Thành phố <sup className="text-danger">*</sup>
            </label>
            <Controller
              name="city"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <>
                  <input
                    value={city}
                    className="form-control"
                    placeholder="Tên thành phố"
                    onChange={(e) => {
                      onChange(e)
                      handleGetValueCity(e)
                    }}
                  />
                  {errors.city && (
                    <span className={cx("text-danger", "fs-12")}>
                      *Bạn cần chọn thành phố.
                    </span>
                  )}
                  <div
                    className={cx(
                      "suggestList",
                      dataCity.length > 0 ? "active" : "",
                    )}
                    ref={cityRef}
                  >
                    <ul>
                      {dataCity.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onChange(item.city)
                            handleOptionCity(item.city)
                          }}
                        >
                          {item.city}
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            />
            {/* <select
              {...register("city", { required: true })}
              className="form-control"
            >
              <option value="">Chọn thành phố</option>
              {optioncity.map((option) => {
                return (
                  <option key={option.id} value={option.city}>
                    {option.city}
                  </option>
                )
              })}
            </select>
            {errors.cate && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn chưa chọn thành phố
              </span>
            )} */}
          </div>
          <div className="col-xl-6 col-md-12 position-relative">
            <label className="form-label mb-1 text-muted">
              Tên đường/Dự án <sup className="text-danger">*</sup>
            </label>

            <Controller
              name="address"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <>
                  <input
                    value={street}
                    className="form-control"
                    placeholder="Tên đường hoặc tên dự án"
                    onChange={(e) => {
                      onChange(e)
                      handleGetValueStreet(e)
                    }}
                  />
                  {errors.address && (
                    <span className={cx("text-danger", "fs-12")}>
                      *Bạn cần điền tên đường hoặc tên dự án
                    </span>
                  )}
                  <div
                    className={cx(
                      "suggestList",
                      dataStreet.length > 0 ? "active" : "",
                    )}
                    ref={hideRef}
                  >
                    <ul>
                      {dataStreet.length > 0 &&
                        dataStreet.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              onChange(item.address)
                              handleOptionStreet(item.address)
                            }}
                          >
                            <TextLimit text={item.address} number={26} />
                          </li>
                        ))}
                    </ul>
                  </div>
                </>
              )}
            />
          </div>
          <div className="col-xl-6 col-md-12 position-relative">
            <label className="form-label mb-1 text-muted">
              Quận/Huyện <sup className="text-danger">*</sup>
            </label>
            <Controller
              name="districts"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <>
                  <input
                    value={districts}
                    className="form-control"
                    placeholder="Tên quận hoặc huyện"
                    onChange={(e) => {
                      onChange(e)
                      handleGetValueDistricts(e)
                    }}
                  />
                  {errors.districts && (
                    <span className={cx("text-danger", "fs-12")}>
                      *Bạn cần điền tên quận, huyện
                    </span>
                  )}
                  <div
                    className={cx(
                      "suggestList",
                      datadistricts.length > 0 ? "active" : "",
                    )}
                    ref={disRef}
                  >
                    <ul>
                      {datadistricts.map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => {
                              onChange(item.districts)
                              handleOptionDistricts(item.districts)
                            }}
                          >
                            {item.districts}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </>
              )}
            />
          </div>
          <div className="col-xl-6 col-md-12">
            <label className="form-label mb-1 text-muted">
              Danh mục <sup className="text-danger">*</sup>
            </label>
            <select
              {...register("cate", { required: true })}
              className="form-control"
              onChange={handleEventCate}
            >
              <option value="">Chọn danh mục</option>
              {optionCate.map((option) => {
                return (
                  <option key={option.id} value={option.title}>
                    {option.title}
                  </option>
                )
              })}
            </select>
            {errors.cate && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn chưa chọn danh mục
              </span>
            )}
            <div className={cx("suggestCity")} id="suggestCate"></div>
          </div>
          <div className="col-xl-6 col-md-12">
            <label className="form-label mb-1 text-muted">
              Hình thức <sup className="text-danger">*</sup>
            </label>
            <select
              {...register("form_cate", { required: true })}
              className="form-control"
            >
              <option value="">Chọn hình thức</option>
              {optionType.map((option) => {
                return (
                  <option key={option.id} value={option.title}>
                    {option.title}
                  </option>
                )
              })}
            </select>
            {errors.form_cate && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn chưa chọn hình thức
              </span>
            )}
            <div className={cx("suggestCity")} id="suggestFormCate"></div>
          </div>
          <div className="col-xl-12">
            <label className="form-label mb-1 text-muted">
              Tiêu đề <sup className="text-danger">*</sup>
            </label>
            <input
              {...register("title", { required: true })}
              className="form-control"
              placeholder="Cần cho thuê nhà đường trần nguyên hãn, lê chân, hải phòng"
            />
            {errors.title && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn cần tạo tiêu đề
              </span>
            )}
            <div className={cx("suggestCity")} id="suggestCity"></div>
          </div>
          {/* Mô tả */}
          <div className="col-xl-12">
            <label
              htmlFor="discription"
              className="form-label mb-1 d-flex justify-content-between"
            >
              <span className="text-muted">
                Mô tả<sup className="text-danger">*</sup>
              </span>
              <button
                className="border-0 rounded-1 p-1 px-3"
                type="button"
                onClick={handleContentAI}
              >
                Mô tả AI <IoChatboxEllipsesOutline className="ms-1" />
              </button>
            </label>
            {/* <MyEditer result={handleValueDiscription} valueinput={textOut} /> */}
            <CustomEditor
              resultText={handleValueDiscription}
              valueInput={textOut}
            />
            {/* <textarea
              className="col-12"
              rows={9}
              style={{ border: "1px solid #eff1f9", padding: "9px", overflow: "auto" }}
            ></textarea> */}
            {/* <Controller
              name="discription"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  
                  <ReactQuill
                    theme="snow"
                    value={textOut}
                    onChange={(value) => {
                      setTextOut(value)
                      field.onChange(value)
                    }}
                  />
                  {errors.discription && (
                    <span className={cx("text-danger", "fs-12")}>
                      Bạn chưa có mô tả cho sản phẩm.
                    </span>
                  )}
                </>
              )}
            /> */}
          </div>
          {/* các trường nhập thông tin sản phẩm */}
          <div className="col-xl-6 col-md-12">
            <label className="form-label mb-1 text-muted">
              DT mặt bằng<sup className="text-danger">*</sup>
            </label>
            <input
              value={formatDtmb}
              {...register("dtmb", { required: true })}
              className="form-control"
              placeholder="Ví dụ: 68.8"
              onChange={handleFormatDtmb}
            />
            {errors.dtmb && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn chưa diện tích
              </span>
            )}
          </div>
          <div className="col-xl-6 col-md-12">
            <label className="form-label mb-1 text-muted">
              Mặt tiền<sup className="text-danger">*</sup>
            </label>
            <input
              value={formatMattien}
              {...register("mattien", { required: true })}
              className="form-control"
              placeholder="Ví dụ: 8.5"
              onChange={handleFormatMattien}
            />
            {errors.mattien && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn chưa mặt tiền
              </span>
            )}
          </div>
          <div className="col-xl-6 col-12 col-md-12">
            <label className="form-label mb-1 text-muted">Số tầng</label>
            <input
              value={formatSotang}
              {...register("sotang")}
              className="form-control"
              placeholder="Ví dụ: 8"
              onChange={handleFormatSotang}
            />
          </div>
          <div className="col-xl-6 col-12 col-md-12">
            <label className="form-label mb-1 text-muted">Đường/ngõ</label>
            <input
              value={formatDuongngo}
              {...register("duongngo")}
              className="form-control"
              placeholder="Ví dụ: 4.5"
              onChange={handleFormatDuongngo}
            />
          </div>
          <div className="col-xl-6 col-md-12">
            <label className="form-label mb-1 text-muted">
              Hướng<sup className="text-danger">*</sup>
            </label>
            <select
              {...register("direction", { required: true })}
              className="form-control"
            >
              <option value="">Chọn hướng</option>
              <option value="Đông">Đông</option>
              <option value="Tây">Tây</option>
              <option value="Nam">Nam</option>
              <option value="Bắc">Bắc</option>
              <option value="Đông nam">Đông nam</option>
              <option value="Đông bắc">Đông bắc</option>
              <option value="Tây nam">Tây nam</option>
              <option value="Tây bắc">Tây bắc</option>
              <option value="Liên hệ">Liên hệ</option>
            </select>
            {errors.direction && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn chưa hướng sản phẩm
              </span>
            )}
          </div>
          <div className="col-xl-6 col-12 col-md-12">
            <label className="form-label mb-1 text-muted">Pháp lý</label>
            <select {...register("phaply")} className="form-control">
              <option value="Liên hệ">Chọn giấy tờ</option>
              <option value="Sổ hồng">Sổ hồng</option>
              <option value="Sổ đỏ">Sổ đỏ</option>
              <option value="Trích đo">Trích đo</option>
              <option value="Viết tay">Viết tay</option>
              <option value="Chủ đầu tư">Chủ đầu tư</option>
              <option value="Hợp lệ">Hợp lệ</option>
              <option value="Loại khác">Loại khác</option>
            </select>
          </div>

          {/* các trường khác */}
          <div className="col-xl-6 col-md-12">
            <label className="form-label mb-1 text-muted col-12 d-flex align-items-center justify-content-between">
              <span>
                Giá sản phẩm<sup className="text-danger">*</sup>
              </span>
              <span>
                {formatPrice &&
                  handleFormatPrice(Number(formatPrice.replace(/[.,]/g, "")))}
              </span>
            </label>
            <input
              {...register("price", { required: true })}
              className="form-control"
              placeholder="Ví dụ: 200,000,000"
              value={formatPrice}
              onChange={handleFormatPrice_}
              disabled={disPrice}
            />
            {errors.price && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn chưa nhập giá
              </span>
            )}
          </div>
          <div className="col-xl-6 col-md-12">
            <label className="form-label mb-1 text-muted">Đơn vị</label>
            <select
              {...register("donvi")}
              className="form-control"
              onChange={handleDonviPrice}
            >
              <option value="">Chọn đơn vị</option>
              {optionDonviSP.map((option) => {
                return (
                  <option key={option.id} value={option.donvi}>
                    {option.donvi}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="col-xl-12">
            <span className="text-danger fs-14">
              Lưu ý: Số lượng ảnh tối đa là 6 ảnh.
            </span>
            <div className={cx("border-1", "p-3 rounded-3")}>
              <div
                className={cx(
                  "d-flex justify-content-between align-items-center",
                )}
              >
                <span>Thêm ảnh vào bài viết</span>
                <div className="position-relative">
                  <input
                    type="file"
                    {...register("listImages")}
                    multiple
                    className={cx("file-input")}
                    onChange={handleFileImages}
                    style={{ display: "none" }}
                    ref={linkRef}
                  />
                  <label
                    className={cx("option-button")}
                    onClick={handleAddImages}
                  >
                    <IoImages />
                  </label>
                </div>
              </div>
              <ul className="row mt-3 imageContainer">
                {files.map((value, index) => {
                  return (
                    <li
                      key={index}
                      className="col-4 listImage p-1"
                      data-image={"img" + index}
                    >
                      <img
                        src={value}
                        height="100"
                        width="100%"
                        className={cx("rounded-3")}
                        alt=""
                      />
                      <button
                        type="button"
                        className={cx("deleteButtonImage")}
                        onClick={() => {
                          handleDeleteImages(index)
                        }}
                      >
                        <MdOutlineCancel className="fs-21" />
                      </button>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="col-xl-12">
            <label className="form-label mb-1 text-muted">Link view360</label>
            <textarea
              {...register("view360")}
              placeholder="Lấy link tại view360.vn"
              className="form-control"
              rows={6}
            />
          </div>
        </div>
        <div
          className="px-0 py-3 border-top border-block-start-dashed"
          style={{ display: serviceInfo }}
        >
          <div className="col-xl-12 col-md-12">
            <label className="form-label mb-1">Thông tin dịch vụ</label>
          </div>

          <div className="row mb-3 mt-3">
            <label className="col-sm-6 col-form-label">Điều hòa</label>
            <div className="col-sm-6">
              <div className="input-group">
                <Controller
                  name="dieuhoa"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      {/* Checkbox 1 */}
                      <label className="form-check-label me-3">
                        <input
                          type="checkbox"
                          value="1"
                          checked={field.value === "1"}
                          onChange={() => field.onChange("1")}
                          className="me-1 rounded-5 form-checked-dark"
                        />
                        Có
                      </label>

                      {/* Checkbox 2 */}
                      <label>
                        <input
                          type="checkbox"
                          value="0"
                          checked={field.value === "0"}
                          onChange={() => field.onChange("0")}
                          className="me-1"
                        />
                        Không
                      </label>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3 mt-3">
            <label className="col-sm-6 col-form-label">Bình nóng lạnh</label>
            <div className="col-sm-6">
              <div className="input-group">
                <Controller
                  name="binhnonglanh"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      {/* Checkbox 1 */}
                      <label className="form-check-label me-3">
                        <input
                          type="checkbox"
                          value="1"
                          checked={field.value === "1"}
                          onChange={() => field.onChange("1")}
                          className="me-1 rounded-5 form-checked-dark"
                        />
                        Có
                      </label>

                      {/* Checkbox 2 */}
                      <label>
                        <input
                          type="checkbox"
                          value="0"
                          checked={field.value === "0"}
                          onChange={() => field.onChange("0")}
                          className="me-1"
                        />
                        Không
                      </label>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3 mt-3">
            <label className="col-sm-6 col-form-label">Đơn giá tiền điện</label>
            <div className="col-sm-6">
              <div className="input-group">
                <input
                  type="text"
                  {...register("electricity_money")}
                  value={electricity}
                  onChange={handleFormatElectricity}
                  className="form-control"
                  placeholder="Nhập số tiền"
                />
                <div
                  className="input-group-text fs-11"
                  style={{ width: "35%" }}
                >
                  / Số điện
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-6 col-form-label">Đơn giá tiền nước</label>
            <div className="col-sm-6">
              <div className="input-group">
                <input
                  type="text"
                  {...register("water_money")}
                  value={water}
                  onChange={handleFormatWater}
                  className="form-control"
                  placeholder="Nhập số tiền"
                />
                <div
                  className="input-group-text fs-11"
                  style={{ width: "35%" }}
                >
                  / Khối
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-6 col-form-label">
              Đơn giá dịch vụ khác
            </label>
            <div className="col-sm-6">
              <div className="input-group">
                <input
                  type="text"
                  {...register("service_price")}
                  value={serviceOther}
                  onChange={handleFormatServiceOther}
                  className="form-control"
                  placeholder="Nhập số tiền"
                />
                <div
                  className="input-group-text fs-11"
                  style={{ width: "35%" }}
                >
                  / Tháng
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mt-3">
            <label className="col-sm-6 col-form-label">
              Thời gian thuê tối thiểu
            </label>
            <div className="col-sm-6">
              <div className="input-group">
                <input
                  type="text"
                  {...register("contract_time")}
                  className="form-control"
                  placeholder="Nhập số tháng"
                />
                <div
                  className="input-group-text fs-11"
                  style={{ width: "35%" }}
                >
                  / Tháng
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-6 col-form-label">Tiền đặt cọc</label>
            <div className="col-sm-6">
              <div className="input-group">
                <input
                  type="text"
                  {...register("deposit")}
                  value={deposit}
                  onChange={handleFormatDeposit}
                  className="form-control"
                  placeholder="Nhập số tiền"
                />
                <div
                  className="input-group-text fs-11"
                  style={{ width: "35%" }}
                >
                  vnđ
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mt-3">
            <label className="col-sm-6 col-form-label">
              Phương thức thanh toán
            </label>
            <div className="col-sm-6">
              <div className="input-group">
                <Controller
                  name="payment_methods"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      {/* Checkbox 1 */}
                      <label className="form-check-label me-3">
                        <input
                          type="checkbox"
                          value="1"
                          checked={field.value === "1"}
                          onChange={() => field.onChange("1")}
                          className="me-1 rounded-5 form-checked-dark"
                        />
                        Đêm
                      </label>

                      {/* Checkbox 1 */}
                      <label className="form-check-label me-3">
                        <input
                          type="checkbox"
                          value="2"
                          checked={field.value === "2"}
                          onChange={() => field.onChange("2")}
                          className="me-1 rounded-5 form-checked-dark"
                        />
                        Ngày
                      </label>

                      {/* Checkbox 2 */}
                      <label className="me-3">
                        <input
                          type="checkbox"
                          value="3"
                          checked={field.value === "3"}
                          onChange={() => field.onChange("3")}
                          className="me-1"
                        />
                        Quý
                      </label>

                      {/* Checkbox 3 */}
                      <label>
                        <input
                          type="checkbox"
                          value="4"
                          checked={field.value === "4"}
                          onChange={() => field.onChange("4")}
                          className="me-1"
                        />
                        Năm
                      </label>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          {/* <div className="row mb-3">
            <label className="col-sm-6 col-form-label">
              Thanh toán định kỳ
            </label>
            <div className="col-sm-6">
              <div className="input-group">
                <input
                  type="text"
                  {...register("periodic_payments")}
                  className="form-control"
                  placeholder="Nhập số tháng"
                />
                <div
                  className="input-group-text fs-11"
                  style={{ width: "35%" }}
                >
                  / Tháng
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* info customer chỉ danh cho admin */}
        {dataUser[0].roleid === 1 ||
        dataUser[0].roleid === 2 ||
        dataUser[0].reputation === 1 ? (
          <div className="row gy-3 pb-3">
            <div className="col-xl-6 col-12">
              <label className="form-label mb-1 text-muted">Tên liên hệ</label>
              <input
                {...register("customer_name")}
                className="form-control"
                placeholder="Tên khách hàng"
              />
            </div>
            <div className="col-xl-6 col-12">
              <label className="form-label mb-1 text-muted">
                Số điện thoại
              </label>
              <input
                value={formatPhone}
                {...register("customer_phone")}
                className="form-control"
                placeholder="Số điện thoại"
                onChange={handleFormatPhone}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {/* *info customer chỉ danh cho admin */}
        <div className="fs-12 text-danger mb-3 text-center">
          <span className="me-1">Lưu ý: Số tiền tối thiểu để quảng cáo là 50,000đ/tháng.</span>
          <button
            type="button"
            className="border-0 rounded-5 text-info fw-5 px-3"
            onClick={handleModalPayHis}
          >
            Nạp tiền
          </button>
        </div>
        <div className="px-4 d-flex py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
          <button
            className="btn btn-primary-light m-1 fs-18 fw-6"
            type="submit"
            id="submitProduct"
          >
            Đăng tin
          </button>
        </div>
      </form>
      {/* Modal pay his */}
      <ModalReact
        modalTitle="Ví của bạn"
        showModal={modalPayHis}
        handleClose={handleCloseModalPayHis}
        theme={<WalletMain />}
      />
    </div>
  )
}

export default FormRealEstateFast

