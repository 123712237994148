import React, { useEffect, useRef, useState } from "react"


const CustomEditor = ({resultText, valueInput}) => {
  const [text, setText] = useState("")
  const editorRef = useRef(null)

  const handlePaste = (event) => {
    event.preventDefault()

    // Lấy nội dung clipboard
    const clipboardData = event.clipboardData || window.clipboardData
      const pastedData = clipboardData.getData("text/plain"); // lấy văn bản thuần túy.
      //const pastedDataHTML = clipboardData.getData("text/html"); // lấy cả định dạng được dán vào;

    // Định dạng nội dung để thêm thẻ <p>
      const formattedData = formatTextToHtml(pastedData);

    // Chèn nội dung đã định dạng vào vị trí con trỏ
      insertHtmlAtCaret(formattedData)
      setText(formattedData)
      resultText(formattedData)
  }

  const formatTextToHtml = (text) => {
    // Thêm thẻ <p> xung quanh đoạn văn bản và thay thế dấu xuống dòng bằng thẻ <p>
    return text
      .split("\n")
      .map((line) => `<p>${line}</p>`)
      .join("")
  }

  const insertHtmlAtCaret = (html) => {
    const sel = window.getSelection()
    if (sel.rangeCount) {
      let range = sel.getRangeAt(0)
      range.deleteContents()

      // Tạo một div tạm thời để chứa HTML định dạng
      const div = document.createElement("div")
      div.innerHTML = html

      // Tạo DocumentFragment để lưu trữ các nút
      const frag = document.createDocumentFragment()
      let node, lastNode

      // Di chuyển các nút con từ div tạm thời vào DocumentFragment
      while ((node = div.firstChild)) {
        lastNode = frag.appendChild(node)
      }

      // Chèn DocumentFragment vào vị trí con trỏ
      range.insertNode(frag)

      // Di chuyển con trỏ đến cuối của nội dung đã chèn
      if (lastNode) {
        range = range.cloneRange()
        range.setStartAfter(lastNode)
        range.collapse(true)
        sel.removeAllRanges()
        sel.addRange(range)
      }
    }
  }
//   Thay đổi nội dung
    const handleInput = (e) => {
      setText(editorRef.current.innerHTML)
      resultText(editorRef.current.innerHTML)
  }
    
    // Nội dung tự động.
    useEffect(() => {
        if (valueInput.length > 0) {
            editorRef.current.innerHTML = valueInput
            const formatText = valueInput;
            setText(formatText)
            resultText(formatText)
        }
    }, [valueInput])


  return (
    <div className="product-description-editor">
      <div
        ref={editorRef}
        contentEditable
        onPaste={handlePaste}
        onInput={handleInput}
        style={{
          border: "1px solid rgb(240 242 249)",
          padding: "10px",
          minHeight: "200px",
          overflow: "auto",
        }}
        className="rounded-1 fs-16"
      ></div>
    </div>
  )
}



export default CustomEditor
