
import { useEffect, useLayoutEffect, useState, useRef, memo } from "react"
import classNames from "classnames/bind"
import moment from "moment"
import Tippy from "@tippyjs/react/headless"
import Tippy1 from "@tippyjs/react"
import { PiTimer } from "react-icons/pi"
import { GrTransaction } from "react-icons/gr"
import { IoTrashOutline } from "react-icons/io5"

import ListBooking from "./ListBooking"
import styles from "../../Dashboard.module.scss"
import { SliderImageList } from "../../../../../components/Slider"
import {
  TextLimit,
  handleFormatNumberUnit,
  handleFormatPrice,
  handleLimitTitle,
} from "../../../../../handle-reuses/reuses"
import ModalReact from "../../../../../components/Modal/ModalReact"
import FormEditRealEstateFast from "../../../../../components/Form/FormEditRealEstateFast"
import TimeExtension from "./TimeExtension"
import DetailProduct from "./DetailProduct"
import { handleMethodEditData } from "../../../../../handle-reuses/reuses/method"
import { images } from "../../../../../assets/images"
import { TbRefresh } from "react-icons/tb"
import { FiEdit } from "react-icons/fi"
import { BsDot } from "react-icons/bs"
import CheckPCMobile from "../../../../../handle-reuses/reuses/check.pc.mobile"

const cx = classNames.bind(styles)

function ListProduct({ data, dataBooking, socketio, result, effectLoading, statusSearch }) {
  // giới hạn title
  useLayoutEffect(() => {
    handleLimitTitle("product-title", 30)
  }, [])

  // Xử lý edit data.
  const [modalEdit, setModalEdit] = useState(false)
  const [idPro, setIdPro] = useState(0)
  const handleModalEditPro = (id_) => {
    setModalEdit(true)
    setIdPro(id_) // truyền id cho form
  }
  const handleClose = () => {
    setModalEdit(false)
    setIdPro(0) // trả về giá trị ban đầu
  }
  // Xác nhận edit thành công.
  const handleResultEditEstateSucess = (status_) => {
    if (status_) {
      setModalEdit(false) // tắt modal
      result(true) // xác nhận sự thay đổi.
      setIdPro(0)
    }
  }

  // handle transaction.
  const handleModalEditTransactionPro = (id_) => {
    socketio.emit("client-transaction-estate", id_)
  }
  useEffect(() => {
    socketio.on("server-transaction-estate", (dataEdit) => {
      const params = {
        iden: process.env.REACT_APP_IDENTIFICATION,
        object: {
          id: dataEdit[0].id,
          transaction: dataEdit[0].transaction === 0 ? 1 : 0,
        },
      };
      
      if (parseInt(dataEdit[0].transaction) === 0) {
        const checkTransactionSuccess = window.confirm(
          "Sản phẩm này sẽ không được truyền thông, và số tiền quảng cáo sẽ không được hoàn lại, khi bạn xác nhận đã giao dịch thành công?",
        )
        if (checkTransactionSuccess) {
          socketio.emit("client-edit-transaction-estate", params)
          alert("Xác nhận giao dịch thành công.")
          result(true) // xác nhận sự thay đổi
        }
      } else if (parseInt(dataEdit[0].transaction) === 1) {
        const checkTransaction = window.confirm(
          "Sản phẩm của bạn sẽ tiếp tục được truyền thông nếu thời gian quảng cáo vẫn còn hạn.",
        )
        if (checkTransaction) {
          socketio.emit("client-edit-transaction-estate", params)
          result(true) // xác nhận sự thay đổi
        }
      }
    })
    return () => {
      socketio.off("client-transaction-estate")
      socketio.off("server-transaction-estate")
      socketio.off("client-edit-transaction-estate")
    }
  }, [])

  // Làm mới sản phẩm.
  const checkSimpleId = useRef(0);
  const handleRefreshProduct = (dataEstate, author, admoneyOld) => {
    checkSimpleId.current = dataEstate.id
    // Kiểm tra nếu sản phẩm hết hạn sẽ không thể làm mới.
    let endtime_ = new Date(dataEstate.endtime).getTime()
    let currentTime = new Date().getTime()

    const params = {
      iden: process.env.REACT_APP_IDENTIFICATION,
      userid: author
    }
    
    // Kiểm tra nếu tin chưa được quảng cáo sẽ không được làm mới.
    if (dataEstate.status === 1) {
      if (endtime_ > currentTime) {
        // Cho phép làm mới tin.
        let checkRefresh = window.confirm(
          "Mỗi lần làm mới sẽ trừ (-1,000đ/lượt), và sản phẩm sẽ lên đầu trang chủ. Bạn muốn làm mới chứ ?",
        )
        if (checkRefresh) {
          // Kiểm tra thanh toán trước.
          socketio.emit("client-edit-balance-refresh-user-pay", params)
          socketio.on("server-edit-balance-refresh-user-pay", (data_) => {
            if (data_ === "success") {
              const paramsUpdateTime = {
                iden: process.env.REACT_APP_IDENTIFICATION,
                object: {
                  id: dataEstate.id,
                  update_time: moment().format("YYYY-MM-DD HH:mm:ss"),
                  admoney: Number(admoneyOld) + 1000,
                },
              }
              socketio.emit("client-refresh-estate", paramsUpdateTime)
              alert("Sản phẩm đã được làm mới.")
              result(true) // xác nhận sự thay đổi khi làm mới
            } else {
              alert("Tài khoản của bạn không đủ để làm mới.")
            }
          })
        }
      } else {
        // Không cho phép làm mới
        alert(
          "Tin đã hết hạn, không thể làm mới. Hãy gia hạn để sản phẩm tiếp tục được truyền thông.",
        )
      }
    } else {
      // Không cho phép làm mới.
      alert("Tin chưa được quảng cáo (truyền thông) sẽ không thể làm mới")
    }
    
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-balance-refresh-user-pay")
      socketio.off("server-edit-balance-refresh-user-pay")
      socketio.off("client-refresh-estate")
    }
  }, [checkSimpleId.current])
  // Delete estate(xóa sản phẩm)
  const handleDeleteEstate = (simpleid) => {
    const paramsDelete = {
      iden: process.env.REACT_APP_IDENTIFICATION,
      object: {
        id: simpleid,
        delete_simple: 1
      }
    }
    let checkDelete = window.confirm("Bạn muốn xóa sản phẩm này?")
    if (checkDelete) {
      socketio.emit("client-edit-delete-simple", paramsDelete)
      result(true) // xác nhận sự thay đổi khi xóa để cập nhật lại giao diện người dùng
    }
  }

  useEffect(() => {
    return () => {
        socketio.off("client-edit-balance-refresh-user-pay")
        socketio.off("server-edit-balance-refresh-user-pay")
        socketio.off("client-refresh-estate")
        socketio.off("client-edit-delete-simple")
      }
  }, [checkSimpleId.current])

  // Modal detail real estate.
  const [dataDetail, setDataDetail] = useState([])
  const dataImagesDetail = useRef([])
  const [modalDetail, setModalDetail] = useState(false)
  const handleModalDetailEstate = (id) => {
    const dataFilter = data.filter((item) => {
      return item.id === id
    })
    let listImages = []
    if (data[0].images) {
      listImages = data[0].images.split(",")
    }
    setDataDetail(dataFilter)
    dataImagesDetail.current = listImages
    setModalDetail(true)
  }
  const handleCloseDetail = () => {
    setModalDetail(false)
  };

  if (!data || data.length === 0) {
    return <div className="text-center mt-5 fs-18">Chưa có sản phẩm</div>
  }
  if (data[0].id === null) {
    return <div className="text-center mt-5 fs-18">Không có sản phẩm nào phù hợp.</div>
  }
 
  return (
    <div className={cx("row mb-3")}>
      {data.map((item) => {
        // Danh sách images
        let filterImages = []
        if (item.images) {
          filterImages = item.images.split(",")
        };

        const filterDataBooking = dataBooking.filter((row) => {
          return row.simpleid === item.id
        })
        // Format time.
        let endDate = moment(item.endtime)
        let currentDate = moment()
        let diffDate = endDate.diff(currentDate, "days")
        return (
          <div
            className={cx(
              "col-xxl-3",
              "col-xl-3",
              "col-lg-6",
              "col-md-6",
              "col-sm-12",
              "mb-5",
            )}
            key={item.id}
          >
            <div className={cx("custom-card", "product-card", "border-1")}>
              <div>
                {/* Hiển thị list images */}
                {filterImages.length > 0 ? (
                  <SliderImageList data={filterImages} />
                ) : (
                  <img
                    src={images.noImagePro}
                    className="d-block w-100"
                    alt="..."
                    height={283}
                    style={{ borderRadius: "15px" }}
                  />
                )}
                {/* loading images */}
                {/* show images */}

                {/* Icon ad */}
                {item.status === 1 && diffDate > 0 ? (
                  <div
                    className="position-absolute"
                    style={{ left: "9px", top: "6px" }}
                  >
                    <span className="me-2 fs-16">
                      <span className="badge bg-success rounded-5">
                        Quảng cáo
                      </span>
                      <span className="badge bg-danger op-9 ms-2 rounded-5">
                        -
                        {Number(item.admoney)
                          .toLocaleString()
                          .replace(/[.]/g, ",")}
                        đ
                      </span>
                    </span>
                    <span
                      className="fs-16 mt-2 op-9"
                      // style={{
                      //   backgroundColor: "#ffffff8a",
                      //   padding: "0 6px",
                      //   borderRadius: "21px",
                      // }}
                    >
                      {/* <PiTimer /> */}
                      <span className="badge bg-warning rounded-3">
                        {moment(item.endtime).diff(moment(), "days")} ngày nữa
                      </span>
                    </span>
                    {/* Tình trạng giao dịch */}
                    <span className="op-9 mt-2 fs-16 ms-2">
                      {item.transaction === 1 ? (
                        <span className="badge bg-danger rounded-3">
                          Đã giao dịch
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                ) : (
                  <span
                    className="badge bg-danger position-absolute op-8 rounded-5 fs-14"
                    style={{ left: "9px", top: "12px" }}
                  >
                    {item.status === 1 ? "Hết hạn" : ""}
                  </span>
                )}

                {/* Hiển thị thông tin */}
                <div className="mt-2">
                  <div
                    className="cursor-pointer"
                    onClick={() => handleModalDetailEstate(item.id)}
                  >
                    <p className="product-name fw-semibold mb-0 d-flex align-items-center justify-content-between">
                      <span className="product-title fs-16 limitText">
                        {item.address}, {item.districts}, {item.city}
                      </span>
                    </p>
                    <p className="product-description text-muted fs-15 fw-4 mb-1 ">
                      <span className="text-success me-1 fs-15 fw-5 fontSize-1">
                        <TextLimit text={item.cate} number={15} />
                      </span>
                      <TextLimit text={item.title} number={45} />
                    </p>
                  </div>
                </div>
                <p className="fs-14 mb-0 d-flex align-items-center mb-2">
                  {/* Giá sản phẩm */}
                  <span>
                    <span className="fw-6 me-1 text-h fs-16">
                      {item.donvi !== "Thỏa thuận"
                        ? handleFormatPrice(Number(item.price))
                        : ""}
                    </span>
                    {item.donvi.toLowerCase() === "thỏa thuận"
                      ? item.donvi
                      : item.donvi
                      ? " / " + item.donvi
                      : ""}
                  </span>
                  <BsDot className="text-muted" />
                  <span className="text-muted fs-15 fontSize-1">
                    {moment(item.addtime)
                      .locale("vi")
                      .fromNow()
                      .replace("một", "1")}
                  </span>
                </p>
                {/* Thống kê lượt xem, tương tác, tư vấn */}
                <div className="mt-2 mb-2 border-top border-block-start-dashed"></div>
                <div className="mb-1 fs-16 d-flex align-items-center justify-content-between">
                  <div></div>
                  <CheckPCMobile>
                    {(isMobile) =>
                      isMobile ? (
                        <ComponentStatisticalMobile
                          item={item}
                          socketio={socketio}
                          filterDataBooking={filterDataBooking}
                          data={data}
                        />
                      ) : (
                        <ComponentStatisticalPC
                          item={item}
                          socketio={socketio}
                          filterDataBooking={filterDataBooking}
                          data={data}
                        />
                      )
                    }
                  </CheckPCMobile>
                </div>
                {/* Chức năng edit, xác nhận, gia hạn... */}
                <div className="mt-2 mb-2 border-top border-block-start-dashed"></div>
                <div
                  className={cx(
                    "d-flex gap-3 mt-2 justify-content-end align-items-center",
                  )}
                >
                  {/* Button làm mới sản phẩm */}
                  <Tippy1 content="Làm mới">
                    <button
                      className="border-0 fs-18 p-2 rounded-5 d-flex align-items-center gap-1 text-muted fw-5"
                      onClick={() =>
                        handleRefreshProduct(item, item.userid, item.admoney)
                      }
                    >
                      <TbRefresh />
                    </button>
                  </Tippy1>
                  {/* Sửa thông tin */}
                  <Tippy1 content="Sửa sản phẩm">
                    <button
                      className={cx(
                        "rounded-5 border-0 fs-18 p-2 d-flex align-items-center gap-1 text-muted fw-5",
                        "edit",
                      )}
                      onClick={() => handleModalEditPro(item.id)}
                    >
                      <FiEdit />
                    </button>
                  </Tippy1>
                  {/* Điều kiện hiển thị nút gia hạn */}
                  {item.status === 1 && diffDate > 0 ? (
                    ""
                  ) : (
                    <Tippy
                      interactive
                      delay={[0, 0]}
                      placement="top-start"
                      trigger="click"
                      render={() => (
                        <div
                          style={{
                            background: "white",
                            padding: "0 9px",
                            color: "black",
                            borderRadius: "9px",
                            border: "1px solid #f6bf5e",
                          }}
                        >
                          <TimeExtension
                            dataEstate={item}
                            id={item.id}
                            admoneyOld={item.admoney}
                            status={item.status}
                            result={result}
                          />
                        </div>
                      )}
                    >
                      <button
                        className={cx(
                          "rounded-5 border-0 fs-18 p-2 d-flex align-items-center gap-1 text-muted fw-5",
                          "moretime",
                        )}
                      >
                        <PiTimer />
                      </button>
                    </Tippy>
                  )}
                  {/* kiểm tra giao dịch */}
                  <Tippy1 content="Chuyển đổi trạng thái giao dịch">
                    <button
                      className={cx(
                        "rounded-5 border-0 fs-18 p-2 d-flex align-items-center gap-1 text-muted fw-5",
                        "transaction",
                      )}
                      onClick={() => handleModalEditTransactionPro(item.id)}
                    >
                      <GrTransaction />
                    </button>
                  </Tippy1>

                  {/* Delete */}
                  <Tippy1 content="Xóa sản phẩm">
                    <button
                      className={cx(
                        "rounded-5 border-0 fs-18 p-2 d-flex align-items-center gap-1 text-muted fw-5",
                        "delete",
                      )}
                      onClick={() => handleDeleteEstate(item.id)}
                    >
                      <IoTrashOutline />
                    </button>
                  </Tippy1>
                </div>
                <div className="mt-2 mb-2 border-top border-block-start-dashed"></div>
              </div>
            </div>
          </div>
        )
      })}
      {/* Hiệu ứng loading */}
      {statusSearch === "" &&
        (!effectLoading ? (
          <div className="text-center fs-16">Đang tải...</div>
        ) : (
          <div className="text-center fs-16">Bạn đã xem hết rồi.</div>
        ))}
  
      {/* Modal edit product */}
      {idPro ? (
        <ModalReact
          id="editProductFast"
          size="modal-fade"
          theme={
            <FormEditRealEstateFast
              idPro={idPro}
              result={handleResultEditEstateSucess}
            />
          }
          modalTitle="Sửa thông tin sản phẩm"
          showModal={modalEdit}
          handleClose={handleClose}
        />
      ) : (
        ""
      )}
      {/* Modal detail real estate */}
      {modalDetail ? (
        <ModalReact
          id="detailRealEstate"
          theme={
            <DetailProduct data={dataDetail} dataImages={dataImagesDetail.current} />
          }
          modalTitle="Chi tiết sản phẩm"
          showModal={modalDetail}
          handleClose={handleCloseDetail}
        />
      ) : (
        ""
      )}
    </div>
  )
}

export default memo(ListProduct)


// components thông kê trên mobile.
function ComponentStatisticalMobile({item, socketio, filterDataBooking, data}) {
  return (
    <div className="float-end fs-16 text-h d-flex gap-2 op-6">
      <span className="d-flex align-items-center gap-1">
        <CountViewEstate
          countview={item.countview}
          idpro={item.id}
          socketio={socketio}
        /> lượt xem
      </span>
      <span className="d-flex align-items-center gap-1">
        <CountClickPhoneEstate
          countclick={item.countclick}
          idpro={item.id}
          socketio={socketio}
        /> lượt tương tác
      </span>
      <Tippy
        interactive
        delay={[200, 0]}
        placement="top-start"
        trigger="click"
        render={() => (
          <ListBooking dataBooking={filterDataBooking} dataPro={data} />
        )}
      >
        <span className="d-flex align-items-center">
          {Number(filterDataBooking.length).toLocaleString()} tư vấn
        </span>
      </Tippy>
    </div>
  )
}



// Component thống kê trên PC.
function ComponentStatisticalPC({item, socketio, filterDataBooking, data}) {
  return (
    <div className="float-end text-h d-flex gap-2 op-6">
      <span className="d-flex align-items-center fs-14">
        {/* <IoEyeOutline className="me-1" />{" "} */}
        {/* {handleFormatNumberUnit(item.countview)} */}
        <CountViewEstate
          countview={item.countview}
          idpro={item.id}
          socketio={socketio}
        />
        <span className="ms-1">lượt xem</span>
      </span>
      <span className="d-flex align-items-center fs-14">
        {/* <TbPasswordMobilePhone className="me-1" />{" "} */}
        <CountClickPhoneEstate
          countclick={item.countclick}
          idpro={item.id}
          socketio={socketio}
        />
        <span className="ms-1">tương tác</span>
      </span>
      <Tippy
        interactive
        delay={[200, 0]}
        placement="top-start"
        trigger="click"
        render={() => (
          <ListBooking dataBooking={filterDataBooking} dataPro={data} />
        )}
      >
        <span className="d-flex align-items-center fs-14">
          {/* <IoCalendarOutline className="me-1" />{" "} */}
          {handleFormatNumberUnit(Number(
            filterDataBooking.length,
          ))}{" "}
          tư vấn
        </span>
      </Tippy>
    </div>
  )
}

// count view.
function CountViewEstate({ countview, idpro, socketio }) {
  const [updateView, setUpdateView] = useState(countview)

  useEffect(() => {
    socketio.on("server-click-views", (data) => {
      const filter = data.find((item) => {
        return item.id === Number(idpro)
      })
      if (filter) {
        setUpdateView(filter.countview)
      }
    })
    return () => {
      socketio.off("server-click-views")
    }
  }, [])
  return (
    <span>
      {handleFormatNumberUnit(Number(updateView))}
    </span>
  )
}


// count click phone.
function CountClickPhoneEstate({ countclick, idpro, socketio }) {
  const [updateClickPhone, setUpdateClickPhone] = useState(countclick)


  useEffect(() => {
    
    socketio.on("server-click-phone", (dataUpdate) => {
      const filter = dataUpdate.find((item) => {
        return item.id === Number(idpro)
      })
      if (filter) {
        setUpdateClickPhone(filter.countclick)
      }
    })
    return () => {
      socketio.off("server-click-phone")
    }
  }, [])
  return (
    <span>
      {handleFormatNumberUnit(Number(updateClickPhone))}
    </span>
  )
}


