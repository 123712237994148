
import { useContext } from "react";
import { useForm } from "react-hook-form";
import AppContext from "../../handle-reuses/Contexts/CreateContexts";

/** Component này tái sử dụng được nó trả về 1 đối tượng của form*/
function FormSample({ result, buttonText }) {
  const {dataUser} = useContext(AppContext)
  const { register, handleSubmit, formState: { errors }, reset } = useForm()

  const onSubmit = (data) => {
    if (dataUser.length > 0) {
      data.author = dataUser[0].id
    };
    result(data, reset)
    }
    return (
      <div className="p-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row gy-3 pb-3">
            <div className="col-xl-12">
              <label className="form-label mb-1 fs-16 text-muted">
                Tiêu đề <sup className="text-danger">*</sup>
              </label>
              <input
                {...register("title", { required: true })}
                className="form-control fs-15"
                placeholder="Ví dụ: Giải quyết giúp tôi vấn đề sau..."
              />
              {errors.title && (
                <span className="text-danger fs-14">*Bạn cần tạo tiêu đề</span>
              )}
            </div>
            <div className="col-xl-12">
              <label
                htmlFor="discription"
                className="form-label mb-1 fs-16 text-muted"
              >
                Nội dung <sup className="text-danger">*</sup>
              </label>
              <textarea
                {...register("discription", { required: true })}
                className="form-control fs-15"
                placeholder="Nội dung yêu cầu..."
                rows={6}
              />
              {errors.discription && (
                <span className="text-danger fs-14">
                  *Bạn chưa nhập nội dung yêu cầu
                </span>
              )}
            </div>
            <div className="col-xl-6 col-12">
              <label className="form-label mb-1 fs-16 text-muted">
                Họ tên <sup className="text-danger">*</sup>
              </label>
              <input
                defaultValue={dataUser.length > 0 ? dataUser[0].hoten : ""}
                {...register("fullname", { required: true })}
                className="form-control fs-15"
                placeholder="Hoàng Đại A"
              />
              {errors.fullname && (
                <span className="text-danger fs-14">
                  *Bạn chưa nhập tên liên hệ
                </span>
              )}
            </div>
            <div className="col-xl-6 col-12">
              <label className="form-label mb-1 fs-16 text-muted">
                Số điện thoại <sup className="text-danger">*</sup>
              </label>
              <input
                defaultValue={
                  dataUser.length > 0 ? dataUser[0].sodienthoai : ""
                }
                {...register("fullphone", { required: true })}
                className="form-control fs-15"
                placeholder="0866..."
              />
              {errors.fullphone && (
                <span className="text-danger fs-14">
                  *Bạn chưa nhập số điện thoại liên hệ
                </span>
              )}
            </div>
          </div>
          <div className="px-4 d-flex py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
            <button
              className="btn btn-primary-light m-1 fw-5 fs-16"
              type="submit"
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    )
}

export default FormSample;