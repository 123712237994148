import { useContext, useState, useRef, useEffect } from "react"
import Tippy from "@tippyjs/react/headless"
import classNames from "classnames/bind"
import { IoNotificationsOutline } from "react-icons/io5"
import { BsDot } from "react-icons/bs"
import { Link } from "react-router-dom"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"



function IconNotifiMobile({ classIcon, className }) {
  const { dataUser, socketio } = useContext(AppContext)
  const [dataNotifi, setDataNotifi] = useState([])

  useEffect(() => {
    if (dataUser.length > 0) {
      // Nhận dữ liệu ban đầu nạp tiền thành công.
      const paramsPayHis = {
        iden: process.env.REACT_APP_IDENTIFICATION,
        userid: dataUser[0].id,
      }
      socketio.emit("client-list-notifi-pay-his-success", paramsPayHis)
    }

    return () => {
      socketio.off("client-list-notifi-pay-his-success")
    }
  }, [])

  // Quản lý nhận thông báo từ admin đã duyệt.
  useEffect(() => {
    // thanh toán.
    if (dataUser.length > 0) {
      socketio.on("server-list-notifi-pay-his-success", (dataNotifi) => {
        let filterUser = dataNotifi.filter((item) => {
          return item.userid === dataUser[0].id
        })
        if (filterUser.length > 0) {
          setDataNotifi(dataNotifi)
        }
      })
    }

    return () => {
      socketio.off("server-list-notifi-pay-his-success")
    }
  }, [])

  // Ẩn thông báo khi bấm vào chuông thông báo.
  const checkChangeNotifi = useRef()
  const handleWatchedPayHis = () => {
    // Danh sách nạp tiền thành công.
    const dataEditWatchedPayHis = [...dataNotifi]
    checkChangeNotifi.current = dataEditWatchedPayHis // đánh dấu sự thay đổi để tắt socketio

    for (let i = 0; i < dataEditWatchedPayHis.length; i++) {
      const paramsEditWatched = {
        iden: process.env.REACT_APP_IDENTIFICATION,
        object: {
          id: dataEditWatchedPayHis[i].id,
          watched: 1,
        },
      }
      socketio.emit("client-edit-watched-pay-his", paramsEditWatched)
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-watched-pay-his")
    }
  }, [checkChangeNotifi.current])

  return (
    <button
      className="border-0 bg-transparent position-relative p-0"
      onClick={handleWatchedPayHis}
    >
      <IoNotificationsOutline className={classIcon} />
      {dataNotifi.length > 0 && (
        <span
          className="badge bg-danger rounded-pill header-icon-badge pulse pulse-danger"
          style={{ top: "0px", right: "0px", border: "2px solid white" }}
        >
          {Number(dataNotifi.length)}
        </span>
      )}
    </button>
  )
}
export default IconNotifiMobile
