import classNames from "classnames/bind"
import { FaHeart } from "react-icons/fa6"
import { BsDot } from "react-icons/bs"
import { Link } from "react-router-dom"
import { useEffect, useState, useRef, useContext } from "react"

import styles from "../Mobile.module.scss"
import SearchHorizontal from "../../../components/Search/SearchHorizontal"
import { images } from "../../../assets/images"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import {
  handleFormatPrice,
  deleteAccentedText,
} from "../../../handle-reuses/reuses"
import MenuFooter from "../MenuFooter/MenuFooter"

const cx = classNames.bind(styles)

function ProductLike() {
  const [valueSearch, setValueSearch] = useState()
  const handleResultSearch = (textInput) => {
    setValueSearch(textInput)
  }
  return (
    <div className={cx("wrapper_dash", "p-3 py-0")}>
      <div className={cx("")}>
        {/* Search */}
        <div className={cx("mb-3")}>
          <SearchHorizontal
            result={handleResultSearch}
            placeholder="Tên, số điện thoại, tên đường phố"
            className="rounded-3"
          />
        </div>
        {/* Content */}
        <div>
          <ListProductLike valueSearch={valueSearch} />
        </div>
      </div>
      {/* Footer */}
      <MenuFooter />
    </div>
  )
}

export default ProductLike

// List product like.
function ListProductLike({ valueSearch }) {
  const { dataUser, socketio } = useContext(AppContext)
  const [dataProLike, setDataProLike] = useState([])
  const arrSavedId = useRef([])
  const dataProRef = useRef([])

  useEffect(() => {
    // Get data product saved.
    socketio.emit("client-saved", dataUser[0].id)
    return () => {
      socketio.off("client-saved")
    }
  }, [])

  useEffect(() => {
    socketio.on("server-saved", function (data) {
      const filterData = data.filter((item) => {
        return item.status === 1
      })
      let arrSimpleid = [],
        arrSaved = []
      for (let i = 0; i < filterData.length; i++) {
        arrSimpleid.push(filterData[i].simpleid)
        socketio.emit("client-detail-product", filterData[i].simpleid)
        arrSaved.push({
          id: filterData[i].id,
          simpleid: filterData[i].simpleid,
        })
      };
      arrSavedId.current = [...arrSaved]
    })
    socketio.on("server-detail-product", (data) => {
      setDataProLike((old) => [...old, ...data])
      // Dùng cho update pro.
      dataProRef.current.push(data[0])
    })
    return () => {
      socketio.off("server-saved")
      socketio.off("client-detail-product")
      socketio.off("server-detail-product")
    }
  }, [])

  // Kiểm tra hủy thích.
  const handleCheckCancelLike = (status_, simpleid, arrSaved) => {
    if (status_) {
      const updatePro = dataProLike.filter((item) => item.id !== simpleid);
      const arrSavedIdUpdate = arrSaved.filter((item) => item.simpleid !== simpleid);
      setDataProLike(updatePro)
      arrSavedId.current = [...arrSavedIdUpdate]
      // Gán mảng tổng mới (dùng cho tìm kiếm)
      dataProRef.current = updatePro
    }
  }

  // Search data pro.
  useEffect(() => {
    if (valueSearch !== "" && dataProRef.current.length > 0) {
      const dataSearchPro = dataProRef.current.filter((item) => {
        return (
          item.hoten.toLowerCase().includes(valueSearch.toLowerCase()) ||
          item.address.toLowerCase().includes(valueSearch.toLowerCase()) ||
          item.phone
            .replace(/[., ]/g, "")
            .includes(valueSearch.replace(/[., ]/g, ""))
        )
      })
      setDataProLike(dataSearchPro)
    }
  }, [valueSearch])

  return (
    <ListItemProLike
      data={dataProLike}
      arrSaved={arrSavedId.current}
      result={handleCheckCancelLike}
    />
  )
}

// List item product like.
function ListItemProLike({ data, arrSaved, result }) {
  const { socketio } = useContext(AppContext)

  const noImagePro = (
    <img
      src={images.noImagePro}
      alt=""
      width={65}
      height={65}
      className={cx("me-3")}
    />
  )
  // Xử lý edit saved.
  const handleEditSaved = (savedId, simpleId) => {
    let checkCancel = window.confirm("Bạn muốn hủy trạng thái đã lưu ?")
    if (checkCancel) {
      // Xử lý edit simple saved.
      const params = {
        id: savedId,
        status: 0
      }
      socketio.emit("client-edit-status-saved", params)
      result(true, simpleId, arrSaved)
    }
  }

  // count view khi chạm vào phần tử.
  const handleCountview = (simpleId, countViewOld) => {
    // Tăng view khi click.
    const params = {
      id: simpleId,
      countview: Number(countViewOld) + 1,
    }
    socketio.emit("client-click-views", params)
  }
  // Mở detail.
  const handleOpenDetail = (simpleId, countViewOld) => {
    // Tăng view khi click.
    const params = {
      id: simpleId,
      countview: Number(countViewOld) + 1,
    }
    socketio.emit("client-click-views", params)
  }

  // Hiển thị danh sách
  return data.map((item) => {
    // filter id saved.
    let savedId = arrSaved.find((saved) => {
      return saved.simpleid === item.id
    });
    // console.log(savedId)
    // List images.
    let listImages = []
    if (item.images) {
      listImages = item.images.split(",")
    }
    // format url detail.
    const formatCateUrl = deleteAccentedText(item.cate).toLowerCase()
    const cate = formatCateUrl.replace(/[ ]/g, "-")
    const formatAddress = deleteAccentedText(item.address).toLowerCase()
    const address = formatAddress.replace(/[ ]/g, "-")

    return (
      <div
        className={cx("bg-itemPro", "mb-3")}
        key={item.id}
        onTouchStart={() => handleCountview(item.id, item.countview)}
      >
        <div className={cx("d-flex", "itemProLike")}>
          {listImages.length > 0 ? (
            <img
              src={listImages[0]}
              alt=""
              width={65}
              height={65}
              className={cx("me-3")}
            />
          ) : (
            noImagePro
          )}
          <div className={cx("col-9 d-flex justify-content-between")}>
            <Link
              to={
                "/" + cate + "-" + address + "/" + item.alias + "-pr" + item.id
              }
              onClick={() => handleOpenDetail(item.id, item.countview)}
            >
              <h6 className={cx("mb-1")}>{item.address}</h6>
              <div className={cx("d-flex justify-content-between")}>
                <div style={{ color: "#0289ff" }}>
                  <span>{item.cate}</span>
                  <BsDot />
                  <span className={cx("fw-5")}>
                    {Number(item.price) > 0
                      ? handleFormatPrice(Number(item.price))
                      : "Liên hệ"}
                  </span>
                </div>
              </div>
              <p className="fs-14 mb-0 text-muted">
                <span>{item.districts}</span>
                <BsDot />
                <span>{item.city}</span>
                {/* <TextLimit text={item.title} number="30" /> */}
              </p>
            </Link>
            <button
              className={cx("border-0 rounded-5 bg-transparent")}
              style={{ color: "#ff6174" }}
              onClick={() => handleEditSaved(savedId.id, item.id)}
            >
              <FaHeart className="fs-18" />
            </button>
          </div>
        </div>
      </div>
    )
  })
}
