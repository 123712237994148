import { imageLogo } from "../../assets/images";
// Các footer phụ
import FooterSimple from "./FooterSimple";
import moment from "moment";
// Footer chính
function Footer() {
    return (
      <>
        <section className="section landing-footer ">
          <div className="container mt-5">
            <div className="row">
              <div className="col-xl-4 mb-3">
                <div className="px-4">
                  <p className="fw-semibold mb-2">
                    <a href="index.html">
                      <img src={imageLogo.logo} alt="" width={150} />
                    </a>
                  </p>
                  <p className="mb-2 op-6 fw-normal fs-16">
                    Trang bất động sản view 360 là hệ sinh thái của View360 nơi
                    bạn có thể khám phá và tìm hiểu về các căn nhà, căn hộ, và
                    bất động sản khác trên khắp cả nước một cách dễ dàng và
                    thuận tiện nhất.
                  </p>
                  {/* <p className="mb-0 op-8 fw-normal fs-16">
                    Ghi rõ nguồn gốc Batdongsan.view360.vn khi phát lại thông
                    tin từ website này.
                  </p> */}
                </div>
              </div>
              <div className="col-xl-2 mb-3">
                <div className="px-4">
                  <h6 className="fw-semibold">WEBSITE</h6>
                  <ul className="list-unstyled op-8 fw-normal landing-footer-list fs-16">
                    <li>
                      <a href="https://view360.com.vn" className="">
                        View360.com.vn
                      </a>
                    </li>
                    <li>
                      <a href="https://view360.vn" className="">
                        View360.vn
                      </a>
                    </li>
                    <li>
                      <a href="https://batdongsan.view360.vn" className="">
                        Batdongsan.view360.vn
                      </a>
                    </li>
                    {/* <li>
                      <a href={"tel: 0934222740"} className="">
                        Hieu Hoang
                      </a>
                    </li> */}
                    {/* <li>
                      <a
                        href="https://www.youtube.com/@view360vn/videos"
                        className=""
                      >
                        Youtube.com
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 mb-3">
                <div className="px-4">
                  <h6 className="fw-semibold">MẠNG XÃ HỘI</h6>
                  <ul className="list-unstyled op-8 fw-normal landing-footer-list fs-16">
                    <li>
                      <a
                        href="https://www.youtube.com/@view360vn/videos"
                        className=""
                      >
                        Youtube
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/Thucteao3d"
                        className=""
                      >
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a href="#" className="">
                        TikTok
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 mb-3">
                <div className="px-4">
                  <h6 className="fw-semibold">LIÊN HỆ</h6>
                  <ul className="list-unstyled fw-normal landing-footer-list fs-16">
                    <li>
                      <a href="#" className="op-8">
                        <i className="ri-home-4-line me-1 align-middle"></i>{" "}
                        Nguyễn Công Mỹ, Kiến An, TP. Hải Phòng
                      </a>
                    </li>
                    <li>
                      <a href="#" className="op-8">
                        <i className="ri-mail-line me-1 align-middle"></i>{" "}
                        hotro.view360@gmail.com
                      </a>
                    </li>
                    <li>
                      <a href="#" className="op-8">
                        <i className="ri-phone-line me-1 align-middle"></i>{" "}
                        +(84)0378 052 118
                      </a>
                    </li>
                    {/* <li>
                      <a href="#" className=" op-8">
                        <i className="ri-printer-line me-1 align-middle"></i>{" "}
                        +(123) 1293 123
                      </a>
                    </li> */}
                    {/* <li className="mt-3">
                      <p className="mb-2 fw-semibold op-8">FOLLOW US ON :</p>
                      <div className="mb-0">
                        <div className="btn-list">
                          <button className="btn btn-sm btn-icon btn-primary-light btn-wave waves-effect waves-light">
                            <i className="ri-facebook-line fw-bold"></i>
                          </button>
                          <button className="btn btn-sm btn-icon btn-secondary-light btn-wave waves-effect waves-light">
                            <i className="ri-twitter-line fw-bold"></i>
                          </button>
                          <button className="btn btn-sm btn-icon btn-warning-light btn-wave waves-effect waves-light">
                            <i className="ri-instagram-line fw-bold"></i>
                          </button>
                          <button className="btn btn-sm btn-icon btn-success-light btn-wave waves-effect waves-light">
                            <i className="ri-github-line fw-bold"></i>
                          </button>
                          <button className="btn btn-sm btn-icon btn-danger-light btn-wave waves-effect waves-light">
                            <i className="ri-youtube-line fw-bold"></i>
                          </button>
                        </div>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End:: Section-11 --> */}

        <div className="text-center landing-main-footer py-3" style={{marginBottom: "6rem"}}>
          <span className="text-muted fs-15">
            {" "}
            Copyright © <span id="year">{moment().format("YYYY")}</span>{" "}
            <a href="#" className="fw-semibold" style={{ color: "#ff5f5f" }}>
              <u className="text-decoration-none">Batdongsan.view360.vn</u>
            </a>
            . Thiết kế & ý tưởng <span className="fa fa-heart text-danger"></span> bởi{" "}
            <a
              href="tel: 0934222740"
              className="fw-semibold"
              style={{ color: "#ff5f5f" }}
            >
              <u className="text-decoration-none">Hieu Hoang</u>
            </a>{" "}
          </span>
        </div>
      </>
    )
}

export default Footer;


export { FooterSimple }

