
import io from "socket.io-client"
import { useState, useEffect, useRef } from "react"
import FormSample from "../../../../components/Form/FormSample"
import { handleMethodCreateData } from "../../../../handle-reuses/reuses/method"
function FormSupport() {
  // Khởi tạo socket.
  const socket = useRef()
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
    return () => {
      socket.current.disconnect()
    }
  }, [])

  const handleGetObjectInput = (object, reset) => {
    // Xử lý thêm mới.
    handleMethodCreateData(
      object,
      () => {
        alert(
          "Cảm ơn bạn đã đóng góp ý kiến, chúng tôi sẽ xử lý yêu cầu của bạn sớm nhất có thể.",
        )
        reset()
        // Thống báo cho admin biết có người gửi Support.
        socket.current.emit("client-send-support-notifi", {
          iden: process.env.REACT_APP_IDENTIFICATION,
        })
      },
      process.env.REACT_APP_APICreateDataSupport,
    )
  }
  return <FormSample result={handleGetObjectInput} buttonText="Gửi yêu cầu" />
}

export default FormSupport
