import { useContext, useEffect, useState } from "react"
import classNames from "classnames/bind"
import { AiOutlineLike, AiOutlineLink, AiFillLike } from "react-icons/ai"
import { IoMailOutline } from "react-icons/io5"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import { copyToClipboard, handleFormatNumberUnit } from "../../../handle-reuses/reuses"
import styles from "../Mobile.module.scss"
import { MdCancel } from "react-icons/md"

const cx = classNames.bind(styles)

function LikeCopyEstate({ dataEstate, totalLike, likeDislike, path }) { 
  const { dataUser, socketio } = useContext(AppContext)
    // Xửu lý thêm mới simple like.
    const handleAddSimpleLike = (simpleid) => {
      if (dataUser.length > 0) {
        // Kiểm tra dữ liệu có tồn tại trước đó hay không.
        const checkParams = {
          iden: process.env.REACT_APP_IDENTIFICATION,
          simpleid: simpleid,
          object: {
            simpleid: simpleid,
            userid: dataUser[0].id,
          },
        }
        socketio.emit("client-check-data-simple-like", checkParams)
      } else {
        alert("Bạn cần đăng nhập trước đã.")
      }
    }
  useEffect(() => {
    return () => {
      socketio.off("client-check-data-simple-like");
    }
  }, [totalLike])
  
  // Xử lý sao chép đường dẫn.
  const handleCopyPath = (path_) => {
    copyToClipboard(path_)
  }
  // Xửu lý hiển thị bảng tư vấn.
  const [activeBooking, setActiveBooking] = useState("none");
  const handleShowActiveBooking = () => {
    setActiveBooking("block")
  }
  const handleCloseActiveBooking = () => {
    setActiveBooking("none")
  }

    return (
      <div className="mt-2">
        <div className="d-flex justify-content-between fs-16 op-6">
          <span className="">
            {handleFormatNumberUnit(Number(totalLike))} lượt thích
          </span>
          <div>
            <span className="me-2">
              {handleFormatNumberUnit(Number(dataEstate.countview))} lượt xem
            </span>
            <span>
              {handleFormatNumberUnit(Number(dataEstate.countclick))} lượt tương
              tác
            </span>
          </div>
        </div>
        <div className="mt-2 mb-2 border-top border-block-start-dashed"></div>
        {/* Icon */}
        <div className="d-flex justify-content-between">
          <button
            className={
              likeDislike
                ? likeDislike.quantity === 1
                  ? "border-0 bg-transparent d-flex align-items-center fw-5 text-danger"
                  : "border-0 bg-transparent d-flex align-items-center fw-5 text-muted"
                : "border-0 bg-transparent d-flex align-items-center fw-5 text-muted"
            }
            onClick={() =>
              handleAddSimpleLike(dataEstate.id ? dataEstate.id : 0)
            }
          >
            {likeDislike ? (
              likeDislike.quantity === 0 ? (
                <AiOutlineLike className="fs-18 me-1" />
              ) : (
                <AiFillLike className="fs-18 me-1" />
              )
            ) : (
              <AiOutlineLike className="fs-18 me-1" />
            )}
            Thích
          </button>
          <button
            className="border-0 bg-transparent d-flex align-items-center text-muted fw-5"
            onClick={() => handleCopyPath(path)}
          >
            <AiOutlineLink className="fs-18 me-1" />
            Sao chép
          </button>
          <button
            className="border-0 bg-transparent d-flex align-items-center text-muted fw-5"
            onClick={handleShowActiveBooking}
          >
            <IoMailOutline className="fs-18 me-1" />
            Tư vấn
          </button>
        </div>
        <div className="mt-2 mb-2 border-top border-block-start-dashed"></div>
        {/* Bảng booking */}
        {activeBooking === "block" && (
          <div style={{ display: activeBooking ? "block" : "none" }}>
            <div
              className="mt-3 text-center position-relative rounded-3"
              style={{ background: "#eeeeee6b", padding: "12px" }}
            >
              <span>Gửi yêu cầu tư vấn</span>
              <button
                className="border-0 bg-transparent p-0"
                style={{ position: "absolute", right: "0", top: "-12px" }}
                onClick={handleCloseActiveBooking}
              >
                <MdCancel className="fs-21" />
              </button>
              <BookingEstate dataEstate={dataEstate} />
            </div>
          </div>
        )}
      </div>
    )
}
export default LikeCopyEstate


// Booking
function BookingEstate({ dataEstate }) {
  const { dataUser, socketio } = useContext(AppContext)

  const [object, setObject] = useState({
    fullname: dataUser.length > 0 ? dataUser[0].hoten : "",
    fullphone: dataUser.length > 0 ? dataUser[0].sodienthoai : "",
    discription: "",
  })
  const [checkName, setCheckName] = useState(true)
  const [checkPhone, setCheckPhone] = useState(true)
  const [checkDis, setCheckDis] = useState(true)

  useEffect(() => {
    socketio.on("server-add-booking", (data) => {
      // console.log(data)
    })
    return () => {
      socketio.off("server-add-booking")
    }
  }, [])

  const arrOption = [
    { id: 1, title: "Tôi cần tư vấn sản phẩm này !" },
    { id: 2, title: "Dòng sản phẩm tương tự như này ?" },
    { id: 3, title: "Tôi cần tư vấn các sản phẩm đường " + dataEstate.address + ", " + dataEstate.districts + ", " + dataEstate.city },
  ]
  const handleFullName = (e) => {
    setCheckName(true)
    setObject({
      fullname: e.target.value,
      fullphone: object.fullphone,
      discription: object.discription,
    })
  }
  const handleFullPhone = (e) => {
    setCheckPhone(true)
    setObject({
      fullname: object.fullname,
      fullphone: e.target.value,
      discription: object.discription,
    })
  }
  const handleDiscription = (e) => {
    setCheckDis(true)
    setObject({
      fullname: object.fullname,
      fullphone: object.fullphone,
      discription: e.target.value,
    })
  }

  const handleOptionContent = (key) => {
    const findOption = arrOption.find((item) => {
      return item.id === key
    })
    setCheckDis(true)
    setObject({
      fullname: object.fullname,
      fullphone: object.fullphone,
      discription: findOption.title,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Kiểm tra trường bắt buộc.
    if (object.fullname === "") {
      setCheckName(false)
    }
    if (object.fullphone === "") {
      setCheckPhone(false)
    }
    if (object.discription === "") {
      setCheckDis(false)
    }
    if (
      object.fullname !== "" &&
      object.fullphone !== "" &&
      object.discription !== ""
    ) {
      // Gửi yêu cầu đến server.
      object.simpleid = dataEstate.id
      object.author_simple = dataEstate.userid
      object.name_estate = dataEstate.title
      if (dataUser.length > 0) {
        object.email = dataUser[0].email
      } else {
        object.email = ""
      }
      
      socketio.emit("client-add-booking", object)
      alert("Cảm ơn bạn, yêu cầu đã được gửi. Tôi sẽ liên hệ sớm nhất có thể")
      setTimeout(() => {
        setObject({
          fullname: object.fullname,
          fullphone: object.fullphone,
          discription: "",
        })
        
      }, 100)
    }
  }

  return (
    // <div className={cx("fs-17")}>Gửi yêu cầu liên hệ</div>
    <form onSubmit={handleSubmit}>
      <div className="col-xl-12 col-md-12">
        <div className="form-floating mb-1 mt-2">
          <input
            type="text"
            value={object.fullname}
            className="form-control rounded-3 fs-16 op-8"
            placeholder="Họ tên"
            onChange={handleFullName}
          />
          <label className="fs-16">
            Họ tên
            <sup className="text-danger">*</sup>
          </label>
          {!checkName && (
            <span className={cx("text-danger", "fs-14")}>
              *Hãy cho tôi biết tên của bạn
            </span>
          )}
        </div>
      </div>
      <div className="col-xl-12 col-md-12 mt-3">
        <div className="form-floating mb-1">
          <input
            type="text"
            value={object.fullphone}
            className="form-control rounded-3 fs-16 op-8"
            placeholder="Số điện thoại"
            onChange={handleFullPhone}
          />
          <label className="fs-16">
            Số điện thoai <sup className="text-danger">*</sup>
          </label>
          {!checkPhone && (
            <span className={cx("text-danger", "fs-14")}>
              *Hãy để lại số điện thoại của bạn
            </span>
          )}
        </div>
      </div>
      <div className="col-xl-12 col-md-12 mt-3">
        <div className={cx("mb-3 fs-16")}>
          <button
            className={cx("border-0 rounded-5 px-3 py-1 mb-2 btn-outline-dark")}
            onClick={() => handleOptionContent(1)}
            type="button"
          >
            Tôi cần tư vấn sản phẩm này !
          </button>
          <button
            type="button"
            className={cx("border-0 rounded-5 px-3 py-1 mb-2 btn-outline-dark")}
            onClick={() => handleOptionContent(2)}
          >
            Dòng sản phẩm tương tự như này ?
          </button>
          <button
            type="button"
            className={cx("border-0 rounded-5 px-3 py-1 btn-outline-dark")}
            onClick={() => handleOptionContent(3)}
          >
            Các sản phẩm {dataEstate.address}
          </button>
        </div>
        <div className="mb-1">
          <textarea
            type="text"
            value={object.discription}
            className="form-control rounded-3 fs-16"
            placeholder="Nội dung *"
            rows={3}
            onChange={handleDiscription}
          />
          {!checkDis && (
            <span className={cx("text-danger", "fs-14")}>
              *Hãy để lại lời nhắn cho chủ sở hữu
            </span>
          )}
        </div>
      </div>
      <div className="px-4 d-flex mt-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
        <button
          className="btn btn-danger-light mt-3 op-9 fw-5 fs-16"
          type="submit"
          id="submitProduct"
        >
          Gửi yêu cầu
        </button>
      </div>
    </form>
  )
}