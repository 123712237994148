import io from "socket.io-client"
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames/bind"
import { HiOutlinePhone } from "react-icons/hi"
import { IoMailOutline } from "react-icons/io5"
import { TbMapPin } from "react-icons/tb"
import { LiaUserEditSolid } from "react-icons/lia"
import { PiMonitor } from "react-icons/pi"

import styles from "./ProfileDefault.module.scss"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import ModalReact from "../../../components/Modal/ModalReact";
import ModalEditInfoUser from "./Modal-edit-user/ModalEditInfoUser";
import { handleMethodEditData } from "../../../handle-reuses/reuses/method";

const cx = classNames.bind(styles);

function ProfilePC() {
  const { dataUser, socketio } = useContext(AppContext)
  const [balance, setBalance] = useState([])
  const [dataUserNew, setDataUserNew] = useState([])
  const [modalEditInfo, setModalEditInfo] = useState(false)
  const [editPasswordModal, setEditPasswordModal] = useState(false)

  // Get thông tin số dư.
  useEffect(() => {
    socketio.emit("client-check-user-pay", dataUser[0].id)
    // Nhận data.
    socketio.on("server-check-user-pay", (data) => {
      setBalance([...data])
    })
    return () => {
      socketio.off("client-check-user-pay")
      socketio.off("server-check-user-pay")
    }
  }, [])

  // Hiển thị form edit user.
  const handleModalEditInfoUser = () => {
    setModalEditInfo(true)
  }
  const handleCloseModaleEditInfoUser = () => setModalEditInfo(false)
  // Xác nhận edit info success.
  const checkEditInfoSuccess = (status_) => {
    if (status_) {
      socketio.emit("client-user-id", dataUser[0].id)
    }
  }

  // Xử lý đổi mật khẩu.
  const userId = useRef(0)
  const handleEditPassword = () => {
    setEditPasswordModal(true)
    userId.current = dataUser[0].id
  }
  const handleCloseEditPasswordModal = () => {
    setEditPasswordModal(false)
    userId.current = 0
  }
  // Xác nhận edit password success.
  const handleEditPasswordSuccess = (status_) => {
    if (status_) {
      setEditPasswordModal(false)
      userId.current = 0
    }
  }

  // Lấy dữ liệu mới nhất của user (khi edit info nó có thể tự động cập nhật thông qua socket)
  useEffect(() => {
    setDataUserNew([...dataUser])
    return () => {
      socketio.off("client-user-id")
    }
  }, [dataUser])

  if (balance.length === 0) {
    return <div className="text-center mt-3">Chưa tồn tại số tài khoản.</div>
  }

  return (
    <div className="col-xxl-12 col-xl-12 col-12">
      <div className="overflow-hidden rounded-3">
        <div className="card-body p-0">
          <div className="d-sm-flex align-items-top p-4 border-bottom border-block-end-dashed main-profile-cover text-center">
            <div>
              <span className={cx("avatar avatar-xxl avatar-rounded me-3")}>
                <img
                  src={
                    "https://batdongsan.view360.vn/" +
                    dataUserNew[0].avatar +
                    `?t=${new Date().getTime()}`
                  }
                  alt=""
                />
              </span>
            </div>
            <div className="flex-fill main-profile-info">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="fw-semibold mb-1 text-fixed-white">
                  {dataUserNew[0].hoten}
                </h6>
                {/* <button className="btn btn-light btn-wave">
                    <i className="ri-add-line me-1 align-middle d-inline-block"></i>
                    Follow
                  </button> */}
              </div>
              <p className="mb-1 text-fixed-white op-7 d-flex align-items-center fs-6">
                <span className="me-2">Tổng nạp:</span>
                <span>
                  {Number(balance[0].total_deposit)
                    .toLocaleString()
                    .replace(/[.]/g, ",")}
                  đ
                </span>
              </p>
              <p className="fs-12 text-fixed-white mb-4 op-5">
                {/* <span className="me-3">
                    <i className="ri-building-line me-1 align-middle"></i>
                    Georgia
                  </span> */}
                <span className="d-flex align-items-center fs-16">
                  <span className="me-2">Số dư:</span>
                  <span>
                    {Number(balance[0].total_balance)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                    đ
                  </span>
                </span>
              </p>
              <div className="d-flex mb-0">
                <div className="me-4">
                  <button
                    className="fs-16 text-fixed-white text-shadow mb-0 d-flex border-0 bg-transparent"
                    onClick={handleModalEditInfoUser}
                  >
                    <LiaUserEditSolid className="me-2 fs-21" />
                    Sửa thông tin
                  </button>
                  <p className="mb-0 fs-11 op-5 text-fixed-white">
                    Thông tin cá nhân
                  </p>
                </div>
                <div className="me-4">
                  <Link to="/san-pham">
                    <p className="fs-16 text-fixed-white text-shadow mb-0 d-flex">
                      <PiMonitor className="me-2 fs-21" />
                      Kho sản phẩm
                    </p>
                    <p className="mb-0 fs-11 op-5 text-fixed-white">
                      Sản phẩm của bạn
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="p-4 border-bottom border-block-end-dashed">
            <p className="fs-15 mb-2 me-4 fw-semibold">Thông tin liên hệ :</p>
            <div className="text-muted">
              <p className="mb-2 d-flex align-items-center">
                <IoMailOutline className="fs-16 me-2" />
                <span className="fs-16">{dataUserNew[0].email}</span>
              </p>
              <p className="mb-2">
                <span className="d-flex align-items-center">
                  <HiOutlinePhone className="fs-16 me-2" />{" "}
                  <span className="fs-16">{dataUserNew[0].sodienthoai}</span>
                </span>
              </p>
              <p className="mb-0 d-flex align-items-center">
                <TbMapPin className="fs-16 me-2" />
                <span className="fs-16">
                  {dataUserNew[0].area === ""
                    ? "Không xác định"
                    : dataUserNew[0].area}
                </span>
              </p>
            </div>
            {/* Đổi mật khẩu */}
            {/* <p className="fs-15 mb-2 mt-3 me-4 fw-semibold">Đổi mật khẩu</p> */}
            <div className="text-center mt-3">
              <button
                className="border-0 rounded-3"
                onClick={handleEditPassword}
              >
                Đổi mật khẩu
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* modalEditInfo */}
      {modalEditInfo && (
        <ModalReact
          modalTitle="Sửa thông tin"
          showModal={modalEditInfo}
          handleClose={handleCloseModaleEditInfoUser}
          theme={
            <ModalEditInfoUser
              dataUser={dataUserNew}
              result={checkEditInfoSuccess}
            />
          }
        />
      )}
      {/* Modal edit password */}
      {editPasswordModal && (
        <ModalReact
          modalTitle="Đổi mật khẩu"
          showModal={editPasswordModal}
          handleClose={handleCloseEditPasswordModal}
          theme={
            <FormEditPassword
              userId={userId.current}
              result={handleEditPasswordSuccess}
            />
          }
        />
      )}
    </div>
  )
}

export default ProfilePC


// Form edit password.
function FormEditPassword({ userId, result }) {
    const [newPass, setNewPass] = useState("");

    const handleGetValuePass = (e) => {
        setNewPass(e.target.value)
    }

    const handleValuePassword = (e) => {
        e.preventDefault()
        const object = {
                id: userId,
                matkhau: newPass
            }
        // Xử lý edit.
        if (newPass.length >= 6) {
            handleMethodEditData(
              object,
              () => {
                  alert("Bạn đã đổi mật khẩu thành công.")
                  setNewPass("")
                  //   Trả về người dùng sự thay đổi khi edit success.
                  result(true)
              },
              process.env.REACT_APP_APIEditPasswordMarketUser,
            )
        } else {
            alert("Mật khẩu cần có độ dài ít nhất 6 ký tự.")
        }
    }
    return (
      <form onSubmit={handleValuePassword}>
        <div className="col-xl-12">
          <label htmlFor="signup-lastname" className="form-label text-default">
            Mật khẩu mới của bạn <sup className="text-danger">*</sup>
          </label>
          <input
            type="text"
            className="form-control form-control-lg"
            placeholder="Mật khẩu mới"
            value={newPass}
            onChange={handleGetValuePass}
          />
        </div>
        <div className="col-xl-12 d-grid mt-3">
          <button type="submit" className="btn btn-lg btn-primary">
            Lưu lại
          </button>
        </div>
      </form>
    )
}


