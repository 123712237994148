import classNames from "classnames/bind"
import { useEffect, useState, useContext, useRef } from "react"
import { Link, useLocation, useParams, useNavigate } from "react-router-dom"

import { FiHome } from "react-icons/fi"
import { TfiHeadphoneAlt } from "react-icons/tfi"
import { SlCompass } from "react-icons/sl"
import { HiOutlineSquare3Stack3D } from "react-icons/hi2"
import { IoAddCircleOutline } from "react-icons/io5"
import { BsBoxArrowUp } from "react-icons/bs"
import { AiOutlineNotification } from "react-icons/ai"

import styles from "../Mobile.module.scss"
import FormSupport from "../../pc/Component/Support/FormSupport"
import ModalReact from "../../../components/Modal/ModalReact"
import { HandleResult } from "../../../components/Phongthuy/Phongthuy"
import FormRealEstateFast from "../../../components/Form/FromRealEstateFast"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import { NotifiBooking } from "../../pc/Component/Notification"

const cx = classNames.bind(styles)
// const listMenu = [
//   { id: 1, icon: <FiHome className="fs-21" />, title: "Trang chủ", path: "/" },
//   { id: 2, icon: <TfiHeadphoneAlt className="fs-21" />, title: "Hỗ trợ", path: "/" },
//   {
//     id: 3,
//     icon: <HiOutlineSquare3Stack3D className="fs-21" />,
//     title: "Quản lý",
//     path: "/dashboard"
//   },
//   {
//     id: 4,
//     icon: <VscHeart className="fs-21" />,
//       title: "Đã thích",
//     path: "/"
//   },
//   {
//     id: 5,
//     icon: <SlCompass className="fs-21" />,
//       title: "Phong thủy",
//     path: "/"
//   },
// ]
function MenuFooter() {
  const { dataUser } = useContext(AppContext)
  
    // Get path.
    const location = useLocation()
    const currentPath = location.pathname;
    const {slug} = useParams()
    const [activeMenu, setActiveMenu] = useState(currentPath === "/" ? "home" : "")
    const handleActiveMenu = (key) => {
        setActiveMenu(key)
    }
    useEffect(() => {
        if (slug) {
            setActiveMenu("")
        }
    }, [])

    // Đăng tin.
  const [modalAddProduct, setModalAddProduct] = useState(false)
    const handleAddProduct = () => {
      if (dataUser.length > 0) {
          setModalAddProduct(true)
      }
    }
    const handleCloseAddProduct = () => setModalAddProduct(false)

    // Modal support.
    const [modalSupport, setModalSupport] = useState(false)
    const handleModalSupport = () => {
        setModalSupport(true)
    }
    const handleCloseModalSupport = () => setModalSupport(false)

    // Modal phong thuy.
    const [showHidePT, setShowHidePT] = useState(false);
    const handleModalPhongthuy = () => {
        setShowHidePT(true)
    }
  const handleCloseModalPT = () => setShowHidePT(false)
  
  // Xác nhận khi đăng tin thành công.
  const handleCheckAddSuccess = (status_)=>{
    if (status_) {
      setModalAddProduct(false)
    }
  }

  // Hướng dẫn sử dụng web app.
  const buttonWebApp = useRef(true)
  const [modalWebApp, setModalWebApp] = useState(false)
  const handleShowModalWebApp = () => {
    setModalWebApp(true)
    buttonWebApp.current = false
  }
  const handleCloseModalWebApp = () => {
    setModalWebApp(false)
  }
  useEffect(() => {
    setTimeout(() => {
      buttonWebApp.current = false
    }, 5000);
  }, [buttonWebApp.current])
     
    return (
      <>
        <div className={cx("menu_footer", "justify-content-between")}>
          {buttonWebApp.current && (
            <div className={cx("webApp")}>
              <button
                className="rounded-5 p-2"
                onClick={handleShowModalWebApp}
              >
                <AiOutlineNotification className="fs-25"/>
              </button>
            </div>
          )}
          <Link
            to="/"
            onClick={() => handleActiveMenu("home")}
            className={cx(activeMenu === "home" ? "activeMenu" : "")}
          >
            <div
              className={cx(
                "d-flex justify-content-center flex-direction-column align-items-center",
              )}
            >
              <FiHome className="fs-21" />
              <div className="mt-1">Trang chủ</div>
            </div>
          </Link>
          <Link
            to="#"
            onClick={handleModalSupport}
            className={cx(activeMenu === "hotro" ? "activeMenu" : "")}
          >
            <div
              className={cx(
                "d-flex justify-content-center flex-direction-column align-items-center",
              )}
              onClick={() => handleActiveMenu("hotro")}
            >
              <TfiHeadphoneAlt className="fs-21" />
              <div className="mt-1">Hỗ trợ</div>
            </div>
          </Link>
          <Link
            to={dataUser.length === 0 ? "/login" : "#"}
            onClick={() => handleActiveMenu("dangtin")}
            className={cx(activeMenu === "dangtin" ? "activeMenu" : "")}
          >
            <div
              className={cx(
                "d-flex justify-content-center flex-direction-column align-items-center",
              )}
              onClick={handleAddProduct}
            >
              <IoAddCircleOutline className="fs-25" />
              <div className="mt-0">Đăng tin</div>
            </div>
          </Link>
          <Link
            to="/dashboard"
            className={cx(currentPath === "/dashboard" ? "activeMenu" : "")}
          >
            <div
              className={cx(
                "d-flex justify-content-center flex-direction-column align-items-center",
                "position-relative",
              )}
            >
              <HiOutlineSquare3Stack3D className="fs-21" />
              <div className="mt-1">Quản lý</div>
              <NotifiBooking />
            </div>
          </Link>
          {/* <Link
            to="/"
            className={cx(currentPath === "/saved" ? "activeMenu" : "")}
          >
            <div
              className={cx(
                "d-flex justify-content-center flex-direction-column align-items-center",
              )}
            >
              <VscHeart className="fs-21" />
              <div className="mt-1">Đã thích</div>
            </div>
          </Link> */}
          <Link
            to="#"
            onClick={() => handleActiveMenu("phongthuy")}
            className={cx(activeMenu === "phongthuy" ? "activeMenu" : "")}
          >
            <div
              className={cx(
                "d-flex justify-content-center flex-direction-column align-items-center",
              )}
              onClick={handleModalPhongthuy}
            >
              <SlCompass className="fs-21" />
              <div className="mt-1">Hướng</div>
            </div>
          </Link>
        </div>
        {/* Modal Add Product */}
        <ModalReact
          // size="modal-lg"
          showModal={modalAddProduct}
          handleClose={handleCloseAddProduct}
          theme={<FormRealEstateFast result={handleCheckAddSuccess} />}
          modalTitle="Đăng tin"
        />
        {/* Modal support */}
        <ModalReact
          modalTitle="Hỗ trợ giải đáp 0378.052.118"
          showModal={modalSupport}
          handleClose={handleCloseModalSupport}
          theme={<FormSupport />}
        />
        {/* Modal Phong thủy*/}
        <ModalReact
          modalTitle="Bạn hợp với hướng ?"
          showModal={showHidePT}
          handleClose={handleCloseModalPT}
          theme={<HandleResult />}
        />
        {/* Modal web app */}
        <ModalReact
          modalTitle="Hướng dẫn sử dụng WEB APP"
          showModal={modalWebApp}
          handleClose={handleCloseModalWebApp}
          theme={<InfoModalWebApp />}
        />
      </>
    )
}

export default MenuFooter


// Hướng dẫn sử dụng web app
function InfoModalWebApp() {
  return (
    <div>
      {/* Trên iphone */}
      <div>
        <h6>A. Trên iphone</h6>
        <div>
          <p>
            <b className="op-7">Bước 1:</b> chọn biểu tượng <BsBoxArrowUp />
          </p>
          <p>
            <b className="op-7">Bước 2:</b> kéo xuống chọn "Thêm vào MH chính"
          </p>
        </div>
      </div>
      <div className="text-center mt-3 mb-3 border-top border-block-start-dashed"></div>
      <div>
        <h6>B. Trên Android</h6>
        <div>
          <p>
            <b className="op-7">Bước 1:</b> chọn biểu tượng <BsBoxArrowUp />{" "}
            trên thanh công cụ đường dẫn
          </p>
          <p>
            <b className="op-7">Bước 2:</b> kéo xuống chọn "Thêm vào MH chính"
          </p>
        </div>
      </div>
      <div className="text-center mt-3 mb-3 border-top border-block-start-dashed"></div>
      <span className="">
        <h6>Ưu điểm</h6>
        Việc sử dụng web app sẽ không tốn dung lượng điện thoại, nhưng mang lại trải nghiệm như đang sử dụng app di động.
      </span>
    </div>
  )
}