import { useState, useLayoutEffect, useContext } from "react"
import { Dropdown } from "react-bootstrap"
import {
  HiOutlineUserCircle,
  HiOutlineEnvelope,
  HiOutlinePhone,
} from "react-icons/hi2"

import { LoadingSpinner } from "../../../../../components/Loading"
import { handleFormatPrice } from "../../../../../handle-reuses/reuses"
import { handleMethodEditData } from "../../../../../handle-reuses/reuses/method"
import AppContext from "../../../../../handle-reuses/Contexts/CreateContexts"

function StatusBooking({ id, dataPro, result }) {
  const { dataUser, socketio } = useContext(AppContext)

  const [data, setData] = useState([])
  const [dataEs, setDataEs] = useState([])
  const [loading, setLoading] = useState(true)
  const [checkStatus, setCheckStatus] = useState(false)

  const fetchData = async () => {
    const response = await fetch(
      process.env.REACT_APP_APIGetForUserBooking + `?only1=${id}`,
    )
    const dataRes = await response.json()
    if (dataRes.length > 0) {
      setData(...dataRes)
      const filterES = dataPro.find((item) => item.id === dataRes[0].simpleid)
      if (filterES) {
        setDataEs(filterES)
      }
    }
    setLoading(false)
    setCheckStatus(false)
  }

  useLayoutEffect(() => {
    fetchData()
    // Hiển thị discription.
    if (document.getElementById("discription")) {
      document.getElementById("discription").innerHTML = dataEs.discription
    }
  }, [checkStatus])

  // Xử lý chuyển đổi trạng thái.
  const handleStatus = (key) => {
    setCheckStatus(true)
    const object = {
      id: data.id,
      status: key,
    }
    handleMethodEditData(
      object,
      () => {
        alert("Chuyển trạng thái thành công.")
        // Cập nhật thông báo với socket.
        socketio.emit("client-dataBooking", dataUser[0].id)
        result(true) // xác nhận đã chuyển đổi trạng thái
      },
      process.env.REACT_APP_APIEditBooking,
    )
  }

  if (loading) {
    return <LoadingSpinner />
  }
  if (data.length === 0 && dataEs.length === 0) {
    return <div>Chưa có thông tin</div>
  }

  return (
    <div>
      <div className="p-4 border-bottom border-block-end-dashed">
        <p className="fs-15 mb-2 me-4 fw-semibold">Thông tin khách yêu cầu :</p>
        <div className="op-8">
          <p className="mb-0 d-flex align-items-start">
            <HiOutlineUserCircle className="me-2 fs-19" />
            <span className="fs-15">{data.fullname}</span>
          </p>
          <p className="mb-2 d-flex align-items-start mt-1">
            <HiOutlineEnvelope className="me-2 fs-19" />{" "}
            <span className="fs-15">
              {data.email ? data.email : "Chưa có email"}
            </span>
          </p>
          <p className="mb-2 d-flex align-items-start mt-1">
            <HiOutlinePhone className="me-2 fs-19" />{" "}
            <span className="fs-15">{data.fullphone}</span>
          </p>
          <div className="d-flex justify-content-end">
            <Dropdown>
              {data.status === 0 ? (
                <Dropdown.Toggle
                  variant="warning border-1" // class mặc định
                  id="dropdown-basic"
                  bsPrefix="custom-dropdown-toggle" // đặt tên cho class fix loại bỏ class dropdown-toggle
                >
                  Chờ xử lý
                </Dropdown.Toggle>
              ) : (
                ""
              )}
              {data.status === 1 ? (
                <Dropdown.Toggle
                  variant="secondary border-1" // class mặc định
                  id="dropdown-basic"
                  bsPrefix="custom-dropdown-toggle" // đặt tên cho class fix loại bỏ class dropdown-toggle
                >
                  Đang xử lý
                </Dropdown.Toggle>
              ) : (
                ""
              )}
              {data.status === 2 ? (
                <Dropdown.Toggle
                  variant="success border-1" // class mặc định
                  id="dropdown-basic"
                  bsPrefix="custom-dropdown-toggle" // đặt tên cho class fix loại bỏ class dropdown-toggle
                >
                  Đã xử lý
                </Dropdown.Toggle>
              ) : (
                ""
              )}

              <Dropdown.Menu>
                {data.status !== 0 ? (
                  <li
                    className="dropdown-item d-flex fs-15"
                    onClick={() => handleStatus(0)}
                  >
                    Chờ xử lý
                  </li>
                ) : (
                  ""
                )}
                {data.status !== 1 ? (
                  <li
                    className="dropdown-item d-flex fs-15"
                    onClick={() => handleStatus(1)}
                  >
                    Đang xử lý
                  </li>
                ) : (
                  ""
                )}
                {data.status !== 2 ? (
                  <li
                    className="dropdown-item d-flex fs-15"
                    onClick={() => handleStatus(2)}
                  >
                    Đã xử lý
                  </li>
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className="p-4 border-bottom border-block-end-dashed">
        <div className="mb-4">
          <p className="fs-15 mb-2 fw-semibold">Yêu cầu :</p>
          <p className="fs-15 mb-0 fs-16">{data.discription}</p>
          <div className="mb-0 mt-3">
            <span className="fw-6">Yêu cầu được khách gửi từ sản phẩm</span>
            <p className="mb-1 fs-15 op-8 d-flex align-items-start">
              {/* <CiLocationOn className="me-2 fs-21" /> */}
              {dataEs.title}
            </p>
            <p className="mb-1 fs-15 op-8 d-flex align-items-start">
              {/* <GiPriceTag className="me-2 fs-19" /> */}
              <span className="fs-16">
                {handleFormatPrice(dataEs.price)}{" "}
                {dataEs.donvi === "Thỏa thuận"
                  ? dataEs.donvi
                  : " / " + dataEs.donvi}
              </span>
            </p>
            <div className="mb-1 fs-15 op-8 d-flex align-items-start mt-3">
              <div id="discription"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatusBooking
