import { useEffect, useRef, useState } from "react"
import AppContext from "./CreateContexts"
import Loading from "../../components/Loading"
import io from "socket.io-client"
import { setMaDinhdanh } from "../reuses"

function ContextData({ children }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const socket = useRef()
  const [getDataUser, setGetDataUser] = useState([])
  const [accountBalance, setAccountBalance] = useState([])
  const [loading, setLoading] = useState(true)
  const checkLocal = localStorage.getItem("object")

  let user
  if (checkLocal) {
    user = JSON.parse(localStorage.getItem("object"))
  }

  const phone = user ? user.p : ""
  const getToken = user ? user.t : ""
  // khi get data từ sever cần gửi kèm token
  const token = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken}`,
    },
  }

  // Kiểm tra kết nối mạng
  useEffect(() => {
    const handleOnline = () => setIsOnline(true)
    const handleOffline = () => setIsOnline(false)

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [isOnline])

  // Get data user
  const fetchData = async () => {
    try {
      if (phone !== "") {
        const response = await fetch(
          process.env.REACT_APP_APIGetDataSearchUser + `?keyword=${phone}`,
        )
        const dataRes = await response.json();
        if (dataRes.length > 0) {
          socket.current.emit("client-check-user-pay", dataRes[0].id)
          socket.current.on("server-check-user-pay", (data) => {
            setAccountBalance(data)
          })
          
        }
        setGetDataUser(dataRes)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  // Cập nhật dữ liệu user nếu edit.
  useEffect(() => {
    if (getDataUser.length > 0) {
      socket.current.on("server-user-id", (dataUserUpdate) => {
        setGetDataUser([...dataUserUpdate])
      })
      return () => {
        socket.current.off("server-user-id")
      }
    }
  }, [getDataUser])
  // Dùng để gọi dữ liệu
  useEffect(() => {
    // Kiểm tra kết nối internet
    if (isOnline) {
      fetchData()
    }
  }, [isOnline])

  // khởi tạo socket
  useEffect(() => {
    if (isOnline) {
      socket.current = io(process.env.REACT_APP_SERVER)
      let traffic // dùng để thống kê lượt truy cập
      if (getDataUser.length > 0) {
        socket.current.emit("client-connect-app", getDataUser[0].id)
        // socket.current = io(process.env.REACT_APP_SERVER, {
        //   query: {
        //     userId: getDataUser[0].id,
        //   },
        // })
        traffic = "userid-" + getDataUser[0].id
      } else {
        // socket.current = io(process.env.REACT_APP_SERVER)
        // kiểm tra xem đã có mã định danh chưa.
        const checkDinhdanh = localStorage.getItem("dd")
        if (checkDinhdanh) {
          traffic = checkDinhdanh
        } else {
          // Nếu chưa có, tạo mã định danh mới
          const newVisitorID = setMaDinhdanh()
          // Lưu vào localStorage
          localStorage.setItem("dd", newVisitorID)
          traffic = newVisitorID
        }
      }

      // Thống kê lưu lượng truy cập
      if (traffic) {
        socket.current.emit("client-connect-traffic", {
          check: true,
          dinhdanh: traffic,
        })
      }
    }
    return () => {
      socket.current.disconnect()
      socket.current.off("client-connect-traffic")
      socket.current.off("client-connect-app")
      socket.current.off("client-check-user-pay")
      socket.current.off("server-check-user-pay")
    }
  }, [isOnline])

  // Kiểm tra đường truyền internet
  if (!isOnline) {
    return (
      <div className="text-center mt-5 fs-18">
        Đường truyền mạng không ổn định...
      </div>
    )
  }
  // Kiểm tra xem loading data thành công chưa
  if (loading) {
    return <Loading />
  }
  return (
    <AppContext.Provider
      value={{
        dataUser: getDataUser,
        dataBalance: accountBalance,
        connect: isOnline,
        socketio: socket.current,
        token: token,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default ContextData
