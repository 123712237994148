import { useState, useEffect, useContext, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import classNames from "classnames/bind"
import { IoIosArrowBack } from "react-icons/io"


import styles from "../Mobile.module.scss"
import { images } from "../../../assets/images"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import moment from "moment"
import { BsDot } from "react-icons/bs"
import { IoWalletOutline } from "react-icons/io5"

const cx = classNames.bind(styles)


// Trang thông báo
function NotificationMobile() {
  const { socketio, dataUser } = useContext(AppContext)
  const limit = 9
  const page = useRef(1)
  const [checkChange, setCheckChange] = useState()
  // Sử dụng điều hướng với navigate
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }

  const [dataNotifiPayHis, setDataNotifiPayHis] = useState([])
  // Get data.
  const handleGetDataPayHis = (limit_, page_) => {
    // Nhận dữ liệu ban đầu nạp tiền thành công.
    const paramsPayHis = {
      iden: process.env.REACT_APP_IDENTIFICATION,
      object: {
        userid: dataUser[0].id,
        quantity: limit_,
        offset: (page_ - 1) * limit_,
      },
    }
    socketio.emit("client-data-for-user-payhis", paramsPayHis)
  }
  useEffect(() => {
    if (dataUser.length > 0) {
      handleGetDataPayHis(limit, page.current)
    }

    return () => {
      socketio.off("client-data-for-user-payhis")
    }
  }, [])

  // Quản lý nhận thông báo từ admin đã duyệt.
  useEffect(() => {
    // thanh toán.
    if (dataUser.length > 0) {
      socketio.on("server-data-for-user-payhis", (dataNotifi) => {
        let filterUser = dataNotifi.filter((item) => {
          return item.userid === dataUser[0].id
        })
        
        if (filterUser.length > 0) {
          setDataNotifiPayHis((old) => [...old, ...dataNotifi])
        } else {
          setCheckChange(dataNotifi)
        }
      })
    }

    return () => {
      socketio.off("server-data-for-user-payhis")
    }
  }, [])

  // Loadmore notification.
  const handleLoadMoreNotifi = () => {
    page.current = page.current + 1;
    handleGetDataPayHis(limit, page.current)
    
  }
  useEffect(() => {
    return () => {
      socketio.off("client-data-for-user-payhis")
    }
  }, [checkChange])

  return (
    <div className={cx("mobile_wrapper")}>
      {/* App Header */}
      <div className={cx("appHeader")}>
        <div className={cx("wrapper_header")}>
          <div className={cx("left", "d-flex align-items-center")}>
            <Link
              to="#"
              className={cx("headerButton", "goBack")}
              onClick={goBack}
            >
              <IoIosArrowBack className="me-1 fs-15" />{" "}
              <span className="fs-14">QUAY LẠI</span>
            </Link>
          </div>
          <div className={cx("pageTitle")}>Thông báo</div>
        </div>
      </div>
      <div style={{ height: "56px" }} className="mb-3"></div>
      {/* *App Header */}

      {/* List notification */}
      {Number(dataNotifiPayHis.length) > 0 ? (
        <ListNotification
          dataNotifiPayHis={dataNotifiPayHis}
          result={handleLoadMoreNotifi}
          loadmoreData={checkChange}
        />
      ) : (
        <div className="text-center fs-15" >
          Bạn chưa có thông báo.
        </div>
      )}
    </div>
  )
}

export default NotificationMobile

// List notification.
function ListNotification({ dataNotifiPayHis, result, loadmoreData}) {
  return (
    <div className={cx("appCapsule")}>
      <ul>
        {/* Danh sách nạp tiền thành công */}
        {dataNotifiPayHis.map((item, index) => {
          return (
            <li className="dropdown-item mb-3" key={"pay" + index}>
              <div className="d-flex align-items-start gap-3">
                <div className="bg-primary-transparent p-2 rounded-5">
                  <IoWalletOutline className="fs-29" />
                  {/* <span className="avatar avatar-md bg-primary-transparent avatar-rounded">
                    <img
                      src={item.avatar ? "/" + item.avatar : images.user}
                      alt=""
                    />
                  </span> */}
                </div>
                <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                  <div>
                    {/* <p className="mb-0 fw-semibold">
                      {item.title}{" "}
                      {item.status === 1 ? " đã được phê duyệt" : " đã bị hủy"}
                    </p> */}
                    <span className="fw-5 fs-18 header-notification-text">
                      Bạn vừa được nhận
                    </span>
                    <p className="mb-0">
                      <span className="text-success fw-5">
                        {Number(item.deposit)
                          .toLocaleString()
                          .replace(/[,]/g, ",")}
                        đ
                      </span>
                      <span className="text-muted">
                        <BsDot />
                        {moment(item.update_time)
                          .locale("vi")
                          .fromNow()
                          .replace("một", "1")}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
      <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
      <div className="text-center">
        {dataNotifiPayHis.length > 9 &&
          (loadmoreData.length > 0 ? (
            <button className="border-0 rounded-2 px-3" onClick={result}>
              Thông báo trước đó
            </button>
          ) : (
            <span>Bạn đã xem hết rồi.</span>
          ))}
      </div>
    </div>
  )
}
