import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import PageSearchPC from "./PageSearchPC"
import PageSearchMobile from "../../mobile/PageSeach"
import { Helmet } from "react-helmet"

function PageSearch() {
  return (
    <>
      <Helmet>
        <title>Bất động sản view360</title>
        <meta property="og:title" content="Bất động sản view360"></meta>
        <meta
          property="og:description"
          content="Kênh thông tin bất động sản như bán nhà, bán đất, cho thuê căn hộ..."
        ></meta>
        <meta property="og:image" content="%PUBLIC_URL%/logo-512.png"></meta>
        <meta property="og:image:width" content="500"></meta>
        <meta property="og:image:height" content="500"></meta>
        <meta property="og:type" content="website"></meta>
      </Helmet>
      <CheckPCMobile>
        {(isMobile) => (isMobile ? <PageSearchMobile /> : <PageSearchPC />)}
      </CheckPCMobile>
    </>
  )
}
export default PageSearch
