import { useEffect, useState, useContext, useRef } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import moment from "moment"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import classNames from "classnames/bind"
import { SlHeart } from "react-icons/sl"
import { FaHeart } from "react-icons/fa6"

import {
  TextLimit,
  handleLimitTitle,
  handleFormatPrice,
  deleteAccentedText,
  handleFormatNumberUnit,
} from "../../../handle-reuses/reuses"
import { SliderImageList } from "../../../components/Slider"
import { images } from "../../../assets/images"
import styles from "./PageHome.module.scss"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import {
  handleMethodCreateData,
  handleMethodEditData,
} from "../../../handle-reuses/reuses/method"
import io from "socket.io-client"

const cx = classNames.bind(styles)

function ListItemRealEstate({ dataEstate }) {
  const { dataUser, socketio } = useContext(AppContext)


  useEffect(() => {
    // Giới hạn address.
    // handleLimitTitle("titleAddress", 30)
    // Giới hạn discription.
    handleLimitTitle("limitDiscription", 68)
  }, [])

  // Xử lý lưu lại sản phẩm cho khách hàng.
  const [dataSaved, setDataSaved] = useState([])
  const simpleSavedId = useRef()
  const handleEventSaved = (simpleId) => {
    if (dataUser.length > 0) {
      // kiểm tra xem tồn tại đã từng lưu chưa.
      const params = { simpleid: simpleId, userid: dataUser[0].id }
      socketio.emit("client-simple-saved", params)
      simpleSavedId.current = simpleId
    } else {
      alert("Bạn cần đăng nhập trước khi lưu")
    }
  }
  useEffect(() => {
    socketio.on("server-simple-saved", (dataSaved_) => {
      handleCreateEditSaved(
        dataSaved_,
        simpleSavedId.current,
        dataUser,
        handleUpdateDataSaved,
      )
    })
    return () => {
      socketio.off("server-simple-saved")
    }
  }, [])

  // Xác định user đã lưu sản phẩm hay chưa.
  const handleUpdateDataSaved = () => {
    socketio.emit("client-saved", dataUser[0].id)
    socketio.on("server-saved", (dataSimpleSaved) => {
      setDataSaved(dataSimpleSaved)
    })
  }
  useEffect(() => {
    // Chạy lần đầu
    if (dataUser.length > 0) {
      handleUpdateDataSaved()
    }
    return () => {
      socketio.off("client-saved")
      socketio.off("server-saved")
    }
  }, [])


  // count view khi hover chuột phải.
  const checkEvent = useRef(0)
  const idSimpleOld = useRef(0)
  const handleCountview = (simpleId) => {
    checkEvent.current = simpleId
    socketio.emit("client-detail-product", simpleId)
    
  }
  useEffect(() => {
    // Tăng view khi click.
    socketio.on("server-detail-product", (dataUpdate) => {
      if (checkEvent.current !== 0) {
        const params = {
          id: dataUpdate[0].id,
          countview: Number(dataUpdate[0].countview) + 1,
        }
        if (checkEvent.current !== idSimpleOld.current) {
          socketio.emit("client-click-views", params)
        }
        // Xác nhận id này vừa mới tăng view rồi.
        idSimpleOld.current = checkEvent.current
      }
    })

    return () => {
      socketio.off("client-detail-product")
      socketio.off("server-detail-product")
      socketio.off("client-click-views")
    }
  }, [checkEvent.current])

  return (
    <>
      {/* Thêm thuộc tính vào <head> */}
      <Helmet>
        <title>Bất động sản view360</title>
        <meta
          name="description"
          content="Bất động sản view360 là trang website chuyên về bất động sản toàn quốc, cung cấp giải pháp chuyển đổi số như cho thuê chung cư, cho thuê căn hộ, bán nhà mặt phố hay bán nhà trong ngõ"
        />
        <meta
          name="keywords"
          content="Bất động sản, bất động sản view360, nhà đất,bán nhà phố,bán nhà trong ngõ, bán nhà dự án, bán chung cư, bán căn hộ, bán dự án hoàng huy, bán dự án vinhome, thuê nhà trọ, thuê phòng trọ sinh viên "
        />
      </Helmet>
      {/* Loading */}
      {(!dataEstate) && <div className="text-center mt-5 fs-18">Đang tải...</div>}
      {dataEstate.length === 0 && <div className="text-center mt-5 fs-18">Chưa có sản phẩm.</div>}
      {/* Nội dung page home */}
      {dataEstate.map((item, index) => {
        let listImages = []
        if (item.images !== null) {
          listImages = item.images.split(",")
        }
        let filterSaved = dataSaved
        if (filterSaved.length > 0) {
          filterSaved = dataSaved.filter((item_) => {
            return item_.simpleid === item.id
          })
        }
        // format url detail.
        const formatCateUrl = deleteAccentedText(item.cate).toLowerCase()
        const cate = formatCateUrl.replace(/[ ]/g, "-")
        const formatAddress = deleteAccentedText(item.address).toLowerCase()
        const address = formatAddress.replace(/[ ]/g, "-")
        // Giới hạn title.
        let limitTitle = item.address + " " + item.districts + " " + item.city

        return (
          <div
            className="col-xl-3 col-sm-6 col-md-6"
            key={item.id + "-" + index}
            // onTouchStart={() => handleCountview(item.id)}
            onMouseEnter={() => handleCountview(item.id)}
          >
            <div className="card border-0">
              <div style={{ borderRadius: "15px" }} className="fh">
                <div className="position-absolute zIndex-9 col-12 p-3">
                  <div className="d-flex justify-content-between">
                    <div className="fs-15 likePro fw-5 limitText">
                      {item.cate}
                    </div>
                    <button
                      className={cx("buttonSave")}
                      onClick={() => handleEventSaved(item.id)}
                    >
                      {filterSaved.length > 0 ? (
                        filterSaved[0].status === 1 ? (
                          <FaHeart className="fs-21" />
                        ) : (
                          <SlHeart className="fs-21" />
                        )
                      ) : (
                        <SlHeart className="fs-21" />
                      )}
                    </button>
                  </div>
                </div>
                {/* list image */}
                {listImages.length > 0 ? (
                  <SliderImageList data={listImages} />
                ) : (
                  <img
                    src={images.noImagePro}
                    className={cx("d-block w-100", "fh")}
                    alt="..."
                    // height={268}
                    style={{ borderRadius: "15px" }}
                  />
                )}
                {/* list image */}
              </div>
              <div className="card-body p-0 mt-3 mb-3">
                <Link
                  to={
                    "/" +
                    cate +
                    "-" +
                    address +
                    "/" +
                    item.alias +
                    "-pr" +
                    item.id
                  }
                >
                  <div className="d-flex justify-content-between">
                    <h6 className="card-title fw-6 mb-1 titleAddress limitText">
                      <TextLimit text={limitTitle} number={30} />
                    </h6>
                    {/* <div className="d-flex align-items-center">
                            <Star
                              style={{ width: "15px", height: "15px" }}
                              className="me-1"
                            />
                            689
                          </div> */}
                  </div>
                  <p className="card-text mb-1 op-6 limitDiscription fs-16 fontSize">
                    đã có {handleFormatNumberUnit(Number(item.countview))} lượt
                    tham khảo sản phẩm,{" "}
                    {item.countclick > 20 ? (
                      <span>
                        {handleFormatNumberUnit(Number(item.countclick))} lượt
                        tương tác
                      </span>
                    ) : (
                      <span>
                        cập nhật mới nhất{" "}
                        {moment(item.update_time).format("DD/MM/YYYY")}
                      </span>
                    )}
                  </p>
                  <p className="card-text mb-0">
                    <span className="fw-5 me-1 fs-16">
                      Giá:{" "}
                      {Number(item.price) > 0
                        ? handleFormatPrice(Number(item.price))
                        : "Liên hệ"}
                    </span>
                    <span>
                      {item.donvi === "Thỏa thuận" ||
                      item.donvi.toLowerCase() === ""
                        ? ""
                        : "/ " + item.donvi.toLowerCase()}
                    </span>
                  </p>
                </Link>
              </div>
            </div>
          </div>
        )
      })}
      <ToastContainer />
    </>
  )
}

export default ListItemRealEstate

// Xử lý create edit saved
function handleCreateEditSaved(
  dataSaved_,
  simpleId,
  dataUser,
  handleUpdateDataSaved,
) {
  if (dataSaved_.length === 0) {
    // thêm mới
    const object = {
      simpleid: simpleId,
      status: 1,
      author: dataUser[0].id,
    }
    handleMethodCreateData(
      object,
      () => {
        alert("Bạn đã lưu thành công. Vào trang quản lý để xem.")
        handleUpdateDataSaved()
      },
      process.env.REACT_APP_APICreateMarketSimpleSaved,
    )
  } else {
    // Hủy lưu
    let object, alertSeved
    if (dataSaved_[0].status === 0) {
      object = {
        id: dataSaved_[0].id,
        status: 1,
      }
      alertSeved = "Bạn đã lưu thành công."
      handleMethodEditData(
        object,
        () => {
          toast.success("Lưu thành công", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            progress: undefined,
            theme: "light",
          })
          handleUpdateDataSaved()
        },
        process.env.REACT_APP_APIEditStatusMarketSimpleSaved,
      )
    } else {
      object = {
        id: dataSaved_[0].id,
        status: 0,
      }
      alertSeved = "Bạn đã HỦY thành công."
      handleMethodEditData(
        object,
        () => {
          toast.error("Đã hủy", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            progress: undefined,
            theme: "light",
          })
          handleUpdateDataSaved()
        },
        process.env.REACT_APP_APIEditStatusMarketSimpleSaved,
      )
    }
  }
}
