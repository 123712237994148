import { useState } from "react"
import classNames from "classnames/bind"
import { MdCancel } from "react-icons/md"
import { TbMapPin } from "react-icons/tb"


import styles from "./SearchHeader.module.scss"
const cx = classNames.bind(styles)

function SearchTwoInput({ placeholder, placeholder1, className, result }) {
    const [valueText, setValueText] = useState({
        city: "",
        districts: ""
    })
  const [showButton, setShowButton] = useState(false)
    // City.
    const handleInputTextCity = (e) => {
      setValueText({
        city: e.target.value,
        districts: valueText.districts,
      })
      result({
        city: e.target.value,
        districts: valueText.districts,
      })
    }
    // Xóa tìm kiếm city.
  const handleClearInputTextCity = () => {
      setShowButton(false)
      setValueText({
        city: "",
        districts: valueText.districts,
      })
      result({
        city:"",
        districts: valueText.districts,
      })
    }

    // Districts
  const handleInputTextDistricts = (e) => {
      setValueText({
        city: valueText.city,
        districts: e.target.value,
      })
    // Hiển thị nút tìm kiếm
    if (e.target.value !== "" && valueText.city !== "") {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
    
  }
  // Xóa tìm kiếm Districts.
  const handleClearInputTextDistricts = () => {
    setShowButton(false)
    setValueText({
      city: valueText.city,
      districts: "",
    })
    result({
      city: valueText.city,
      districts: "",
    })
  }

  // Trả về kết quả cuối cùng tìm kiếm cho người dùng.
  // Dùng cho button
  const handleResultSearch = () => {
    result({
      city: valueText.city,
      districts: valueText.districts,
    })
  }
  // Dùng cho khi người dùng ấn enter.
  const handleKeyDownSearch = (e) => {
    if (e.key === "Enter") {
      if (valueText.city === "" && valueText.districts !== "") {
        alert("Bạn không thể để trống thành phố.")
      } else if (valueText.city === "" && valueText.districts === "") {
        alert("Bạn không thể để trống thành phố và quận, huyện.")
      } else if (valueText.city !== "" && valueText.districts === "") {
        alert("Bạn không thể để trống quận, huyện.")
      } else if (valueText.city !== "" && valueText.districts !== "") {
        result({
          city: valueText.city,
          districts: valueText.districts,
        })
      }
      
    }
  }

  return (
    <>
      <div
        className={cx(
          "d-flex col-12 gap-2 align-items-center rounded-5",
          "Search_Wrapper_horizontal",
        )}
        style={{ height: "39px" }}
      >
        {/* Chọn thành phố */}
        <div
          className={cx(
            "d-flex justify-content-center align-items-center col-5",
            className,
          )}
          style={{ height: "39px" }}
        >
          <div className={cx("SearchIcon")}>
            <TbMapPin className="fs-15" />
          </div>
          <input
            type="text"
            placeholder={placeholder1 ? placeholder1 : "Tìm kiếm..."}
            className={cx("col-12", "Search_Input")}
            value={valueText.city}
            onChange={handleInputTextCity}
          />
          <div className={cx("Search_ClearText")}>
            {valueText.city !== "" ? (
              <MdCancel onClick={handleClearInputTextCity} className="fs-21" />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={cx("vach")}></div>
        {/* Quận huyện */}
        <div
          className={cx(
            "d-flex justify-content-center align-items-center col-5",
            className,
          )}
          style={{ height: "39px" }}
        >
          {/* <div className={cx("SearchIcon")}>
          <FiSearch className="fs-15" />
        </div> */}
          <input
            type="text"
            placeholder={placeholder ? placeholder : "Tìm kiếm..."}
            className={cx("col-12", "Search_Input")}
            value={valueText.districts}
            onChange={handleInputTextDistricts}
            onKeyDown={handleKeyDownSearch}
          />
          <div className={cx("Search_ClearText")}>
            {valueText.districts !== "" ? (
              <MdCancel
                onClick={handleClearInputTextDistricts}
                className="fs-21"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* Hiển thị button search */}
      {showButton && (
        <div className="text-center fs-15 d-flex justify-content-center">
          <button
            type="button"
            className="bg-transparent rounded-5 ms-1 d-flex align-items-center justify-content-center"
            onClick={handleResultSearch}
            style={{ height: "39px", width: "39px", border: "2px solid #e8e8e8" }}
          >
            {/* <TbMapPin className="fs-21" />*/}
            Tìm
          </button>
        </div>
      )}
    </>
  )
}

export default SearchTwoInput