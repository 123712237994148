import { Link } from "react-router-dom"
import { useState, useContext, useEffect, useLayoutEffect } from "react"
import { Dropdown } from "react-bootstrap"
import {
  HiOutlineCalendarDays,
  HiOutlineCreditCard,
  HiOutlineCog,
  HiOutlineSquare3Stack3D,
} from "react-icons/hi2"
import { HiOutlineLogout } from "react-icons/hi"
import { HiOutlineUserCircle } from "react-icons/hi2"
import { IoPersonOutline } from "react-icons/io5"

import AppContext from "../../../../handle-reuses/Contexts/CreateContexts"
import ModalReact from "../../../../components/Modal/ModalReact"
import { WalletMain } from "../../../pc/Component/Wallet"
import ProfilePage from "../../../pc/Profile"
import { images } from "../../../../assets/images"

function InfoUserMobile({ height, width }) {
  const { dataUser } = useContext(AppContext)
  const [dataUserNew, setDateUserNew] = useState([])
  useEffect(() => {
    setDateUserNew([...dataUser])
  }, [dataUser])

  return (
    <div className="header-element">
      {/* <!-- Start::header-link|dropdown-toggle --> */}
      <div className="d-flex align-items-center">
        <div className="me-sm-2 me-0 position-relative">
          {dataUserNew.length > 0 ? (
            <HandleDisplayDropdown
              dataUser={dataUserNew}
              height={height}
              width={width}
            />
          ) : (
            <Link to="/login">
              <HiOutlineUserCircle className="fs-29" />
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default InfoUserMobile

// Xử lý điều kiện hiển thị
const HandleDisplayDropdown = ({ dataUser, height, width }) => {

  // Modal payment.
  const [showHideWallet, setShowHideWallet] = useState(false)
  const handleShowWallet = () => {
    setShowHideWallet(true)
  }
  const handleCloseWallet = () => setShowHideWallet(false)

  // Delete localStorage.
  const handleDeleteLocalStorage = () => {
    localStorage.setItem("object", "")
  }
  // Modal info profile.
  const [modalInfoProfile, setModalInfoProfile] = useState(false)
  const handleModalInfoProfile = () => {
    setModalInfoProfile(true)
  }
  const handleCloseModalInfoProfile = () => setModalInfoProfile(false)

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-line border-0 p-0" // class mặc định
          id="dropdown-basic"
          bsPrefix="custom-dropdown-toggle" // đặt tên cho class fix loại bỏ class dropdown-toggle
        >
          <img
            src={
              dataUser[0].avatar
                ? "https://batdongsan.view360.vn/" +
                  dataUser[0].avatar +
                  `?t=${new Date().getTime()}` // `?t=${new Date().getTime()}` để tránh việc google, safari lưu cache
                : images.user
            }
            width={width ? width : 32}
            height={height ? height : 32}
            className="rounded-5 border-1"
            alt={dataUser[0].fullname}
          />
        </Dropdown.Toggle>

        <Dropdown.Menu className="mt-2" style={{ minWidth: "200px" }}>
          <button
            className="dropdown-item d-flex align-items-center border-0 bg-transparent"
            onClick={handleModalInfoProfile}
          >
            <IoPersonOutline className="fs-18 me-2" />
            Thông tin cá nhân
          </button>
          <Link to="/dashboard" className="dropdown-item d-flex">
            <HiOutlineSquare3Stack3D className="fs-18 me-2" />
            Trang quản lý
          </Link>
          <Link to="/lich-hen" className="dropdown-item d-flex">
            <HiOutlineCalendarDays className="fs-18 me-2" />
            Lịch hẹn
            {/* <span className="badge bg-success-transparent ms-auto">25</span> */}
          </Link>
          <Link
            to=""
            className="dropdown-item d-flex"
            onClick={handleShowWallet}
          >
            <HiOutlineCreditCard className="fs-18 me-2" />
            Ví của bạn
          </Link>
          {/* <Link to="" className="dropdown-item d-flex">
              <HiOutlineInformationCircle className="fs-18 me-2" />
              Hỗ trợ
            </Link> */}
          {/* {dataUser[0].roleid === 1 || dataUser[0].roleid === 2 ? (
            <Link to="/setting" className="dropdown-item d-flex">
              <HiOutlineCog className="fs-18 me-2" />
              Cài đặt
            </Link>
          ) : (
            ""
          )} */}
          <Link
            to="/login"
            className="dropdown-item d-flex"
            onClick={handleDeleteLocalStorage}
          >
            <HiOutlineLogout className="fs-18 me-2" />
            Đăng xuất
          </Link>
        </Dropdown.Menu>
      </Dropdown>
      {/* Modal payment */}
      {showHideWallet && (
        <ModalReact
          modalTitle="Ví của bạn"
          showModal={showHideWallet}
          handleClose={handleCloseWallet}
          theme={<WalletMain />}
        />
      )}
      {/* Modal thông tin cá nhân */}
      {modalInfoProfile && (
        <ModalReact
          modalTitle="Thông tin"
          showModal={modalInfoProfile}
          handleClose={handleCloseModalInfoProfile}
          theme={<ProfilePage />}
        />
      )}
    </>
  )
}
