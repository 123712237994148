import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import { PiWarningCircleFill } from "react-icons/pi"

import "./Slider.scss"
import { useEffect, useRef, useState } from "react"
import { ImageModal } from "./Detail-Images"
import ModalReact from "../Modal/ModalReact"
import { images } from "../../assets/images"

function SliderImage({ data, styles, height }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  }
  // sắp xếp data.
  const dataSort = data.sort((a, b) => {
    return a.updatetime - b.updatetime
  })

  return (
    <div
      className="slider-container"
      style={{ height: height ? height + "px" : "286px" }}
    >
      <Slider {...settings}>
        {dataSort.map((value, index) => (
          <div key={value.id ? value.id : index} className="bg-transparent">
            <LazyLoadImage
              alt="Image 1"
              effect="blur"
              src={value.path ? value.path : "/" + value}
              className="d-block w-100"
              height={height ? height : 286}
              style={styles ? styles : { borderRadius: "15px" }}
            />
            {/* <img
              src={value.path ? value.path : "/" + value}
              className="d-block w-100"
              alt="..."
              height={height ? height : 286}
              style={styles ? styles : { borderRadius: "15px" }}
            /> */}
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default SliderImage

// Kiểm chỉ có đường dẫn.
function SliderImageList({ data, height, web }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  }

  // Chi tiết danh sách ảnh
  const [modalImages, setModalImages] = useState(false)
  
  const handleViewDetailImages = () => {
    setModalImages(true)
  }
  const handleCloseViewDetailImages = () => {
    setModalImages(false)
  }

  return (
    <div
      className="slider-container"
      // style={{ height: height ? height + "px" : "283px" }}
    >
      <Slider {...settings}>
        {data.map((value, index) => (
          <div key={index}>
            <LazyLoadImage
              alt="Image 1"
              effect="blur"
              src={web ? web + value : "/" + value}
              className="d-block w-100 fh"
              style={{
                borderRadius: "15px",
                maxHeight: height ? height + "px" : "",
                height: height ? height + "px" : "",
              }}
              onClick={handleViewDetailImages}
            />
          </div>
        ))}
      </Slider>
      {/* Modal list images */}
      {modalImages && (
        <ImageModal
          data={data}
          onClose={handleCloseViewDetailImages}
          web={web}
        />
      )}
    </div>
  )
}

export { SliderImageList }


// Mẫu data cho banner slide
const dataBanner = [
  {
    id: 1,
    title: "Không gian 3D",
    info: "View360.vn",
    info1: "Tài trợ",
    class: "bg-card",
    image: images.bannerPrView360,
    width: 250,
    link: `<iframe id="vt_iframe_8fe0093bb30d6f8c31474bd0764e6ac0" allow="accelerometer; camera; display-capture; fullscreen; geolocation; gyroscope; magnetometer; microphone; midi; xr-spatial-tracking;" width="100%" height="600px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://view360.vn/viewer/index.php?code=8fe0093bb30d6f8c31474bd0764e6ac0"></iframe>`,
    content: `Chào mừng bạn đến với View360 - nơi bạn khám phá thế giới từ mọi góc độ thông qua công nghệ 360 độ. Với sứ mệnh làm thay đổi cách chúng ta trải nghiệm thế giới xung quanh, View360 mang đến cho bạn một cách tiếp cận mới mẻ và tuyệt vời đối với nhiều lĩnh vực đời sống, từ bất động sản, du lịch, đến khu di tích và showroom.
<br><b>Bất động sản</b><br>
Bạn là một người đang tìm kiếm căn nhà hoặc căn hộ mơ ước? Với View360, bạn không chỉ xem những hình ảnh tĩnh của căn nhà, mà còn có cơ hội thực sự "đặt chân" vào không gian thông qua chế độ xem 360 độ. Từ phòng khách đến phòng ngủ, từ khu vực ngoài trời đến bếp, mọi góc độ và không gian đều được hiển thị rõ ràng và sinh động, giúp bạn đưa ra quyết định mua nhà một cách tự tin nhất.
<br><b>Du lịch</b><br>
Bạn đam mê du lịch và muốn khám phá điểm đến mới? View360 mang lại cho bạn trải nghiệm du lịch tuyệt vời mà không cần phải rời khỏi nhà. Từ các danh lam thắng cảnh đến những điểm đến nổi tiếng, bạn có thể thực sự "lạc mình" vào không gian đó thông qua hình ảnh 360 độ, giúp bạn lên kế hoạch cho hành trình tương lai một cách tỉ mỉ và chi tiết nhất.
<br><b>Showroom</b><br>
Ngoài ra, View360 còn áp dụng công nghệ xem nhà 360 độ vào nhiều lĩnh vực khác như khu di tích, showroom, và nhiều hơn nữa. Với khả năng tùy chỉnh linh hoạt và chất lượng hình ảnh sắc nét, chúng tôi cam kết mang lại trải nghiệm thú vị và đầy ấn tượng cho mọi người dùng. Chuyển đến view360.com.vn`,
  },
  {
    id: 2,
    title: "Chuyển đổi số",
    info: "Team Tech",
    info1: "Tài trợ",
    class: "bg-card",
    image: images.cds,
    width: 250,
    link: "#",
    content: `<b>Chuyển Đổi Số: Bước Đột Phá Cho Doanh Nghiệp Vừa Và Nhỏ</b>

<p>Bạn là chủ doanh nghiệp vừa và nhỏ? Bạn đang tìm kiếm cách để nâng cao hiệu quả hoạt động, tiết kiệm chi phí và mở rộng thị trường? Chuyển đổi số chính là giải pháp mà bạn đang tìm kiếm!</p>

<p><b>Tại sao Chuyển Đổi Số lại quan trọng?</b></p>
Tăng Cường Hiệu Quả: Tự động hóa quy trình giúp tiết kiệm thời gian, giảm thiểu sai sót và tăng năng suất lao động.</br>
Tiết Kiệm Chi Phí: Giảm chi phí vận hành, giảm thiểu lỗi và tăng cường khả năng dự báo, giúp doanh nghiệp hoạt động hiệu quả hơn với nguồn lực hiện có.</br>
Mở Rộng Thị Trường: Tiếp cận dễ dàng hơn với khách hàng mới thông qua các kênh kỹ thuật số và mạng xã hội, mở ra cơ hội kinh doanh toàn cầu.</br>
<p></p>
<p><b>Giải pháp Chuyển Đổi Số của chúng tôi bao gồm:</b></p>
Tư Vấn Chiến Lược Chuyển Đổi Số: Đánh giá hiện trạng doanh nghiệp, xây dựng lộ trình chuyển đổi số phù hợp với mục tiêu kinh doanh.</br>
Triển Khai Hệ Thống Quản Lý: Áp dụng các hệ thống CRM tiên tiến giúp quản lý hiệu quả tài nguyên, khách hàng và quy trình.</br>
<p></p>
<p><b>Lợi Ích Cụ Thể Khi Sử Dụng Dịch Vụ Chuyển Đổi Số Của Chúng Tôi:</b></p>
Tăng 30% Hiệu Suất Làm Việc: Nhờ vào hệ thống quản lý thông minh và tự động hóa.</br>
Tiết Kiệm 20% Chi Phí Vận Hành: Qua việc tối ưu hóa các quy trình và sử dụng tài nguyên một cách hiệu quả.</br>
<p></p>
<p><b>Đừng Để Doanh Nghiệp Của Bạn Bị Bỏ Lại Phía Sau!</b></p>
Liên hệ với chúng tôi ngay hôm nay để được tư vấn miễn phí và nhận lộ trình chuyển đổi số toàn diện cho doanh nghiệp của bạn. Cùng chúng tôi bước vào kỷ nguyên số và phát triển mạnh mẽ hơn bao giờ hết!

<p><b>Hotline: 0934.222.740</b></p>

<p>Chuyển đổi số không chỉ là xu hướng, mà là tương lai của doanh nghiệp. Hãy cùng chúng tôi tiến tới tương lai đó, bắt đầu từ hôm nay!</p>`,
  },
]
// Slide banner.
function SlideBanner() {
  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  }
  const data = dataBanner
  const [modalDonors, setModalDonors] = useState(false)
  const dataDonors = useRef([])
  const handleShowModalDonors = (donorsid) => {
    setModalDonors(true)
    dataDonors.current = data.filter((item)=>item.id === donorsid)
  }
  const handleCloseModalDonors = () => {
    setModalDonors(false)
    dataDonors.current = []
  }
  return (
    <div className="slider-container">
      <Slider {...settings}>
        {data.map((item) => {
          return (
            <button
              key={item.id}
              className="me-3 border-0 bg-transparent p-0"
              onClick={() => handleShowModalDonors(item.id)}
            >
              <div
                className={"box-gift-card " + item.class}
                style={{ width: item.width }}
              >
                <ul className="itemSlide">
                  <li>{item.title}</li>
                  <li className="text-success fw-6 text-left">{item.info}</li>
                  <li className="fs-11 text-muted text-left">
                    <PiWarningCircleFill className="me-1" />
                    {item.info1}
                  </li>
                </ul>

                <div className="img-gift">
                  <img src={item.image} alt="" width="100%" height="100%" />
                </div>
              </div>
            </button>
          )
        })}
      </Slider>
      {/* Modal quảng cáo */}
      {dataDonors.current.length > 0 && (
        <ModalReact
          modalTitle={"Nhà tài trợ " + dataDonors.current[0].info}
          showModal={modalDonors}
          handleClose={handleCloseModalDonors}
          theme={<InfoDonors dataDonors={dataDonors.current} />}
        />
      )}
    </div>
  )
}
export { SlideBanner }





// Custom next arrow component
const CustomNextArrow = (props) => {
  const { onClick } = props
  return (
    <div
      className="slick-next"
      style={{ right: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      Next
    </div>
  )
}

// Custom previous arrow component
const CustomPrevArrow = (props) => {
  const { onClick } = props
  return (
    <div
      className="slick-prev"
      style={{ left: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      Prev
    </div>
  )
}



// Thông tin nhà tài trợ.
function InfoDonors({ dataDonors }) {
  useEffect(() => {
    document.querySelector("#contentDonorsSlider").innerHTML = dataDonors[0].content
  }, [])
  return <div>
    <div><img src={dataDonors[0].image} alt="" width="100%" height={300} className="rounded-3" /></div>
    <div className="mt-3">
      <h5 className="text-center">Giới thiệu về {dataDonors[0].info}</h5>
      <p id="contentDonorsSlider">
      </p>
    </div>
  </div>
}