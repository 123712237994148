import io from "socket.io-client"
import { useState, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet"
import classNames from "classnames/bind"

import { HeaderHome2 } from "../../../components/Header"
import styles from "./detail.module.scss"
import DetailEstate from "./DetailEstate"
import Footer from "../../../components/Footer"
import LoadingSample from "../../../components/Loading/LoadingSample"
import ComponentHeader from "../../mobile/Component/Header/ComponentHeader" // dành cho mobile
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import MenuFooter from "../../mobile/MenuFooter/MenuFooter"
import DetailEstateMobile from "../../../components/DetailEstate"
import { images } from "../../../assets/images"

const cx = classNames.bind(styles)

function DetailPage() {
  const { slug } = useParams();
  const socket = useRef()
  const [dataEstate, setDataEstate] = useState([])
  const [loading, setLoading] = useState(true)
  // Get data theo parmas
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
    const stringUrl = slug.indexOf("pr");
    if (stringUrl !== -1) {
      const prId = slug.substring(stringUrl).replace(/\D/g, "");
      // Gửi yêu cầu lên server.
      socket.current.emit("client-detail-product", prId)
      setLoading(true)
    }
    return () => {
      socket.current.off("client-detail-product")
      socket.current.disconnect()
    }
  }, [slug])

  const imageAvatar = useRef([])
  useEffect(() => {
    socket.current.on("server-detail-product", (data) => {
      setDataEstate(data)
      let listImages = data[0].images
      if (listImages) {
        imageAvatar.current = listImages.split(",")
      } else {
        imageAvatar.current = [images.noImagePro]
      }
      setLoading(false)
    })
    return () => {
      socket.current.off("server-detail-product")
    }
  }, [slug])

  if (loading) {
    return <div className="text-center mt-3">Đang tải...</div>
  }

  return (
    <>
      {/* thêm vào thẻ <head> */}
      <Helmet>
        <title>{dataEstate[0].title}</title>
        <meta
          name="keywords"
          content={
            dataEstate[0].cate +
            ", " +
            dataEstate[0].address +
            ", " +
            dataEstate[0].short_keywords
          }
        />
        <meta
          property="og:url"
          content={"https://batdongsan.view360.vn/" + slug}
        />
        {/* Các thẻ meta để khi gửi đường dẫn nó sẽ nhận diện tiêu đề nội dung và hình ảnh */}
        <meta property="og:title" content={dataEstate[0].title} />
        <meta
          name="description"
          content={
            dataEstate[0].cate +
            " " +
            dataEstate[0].form_cate.toLowerCase() +
            " đường " +
            dataEstate[0].address.toLowerCase() +
            " " +
            dataEstate[0].districts.toLowerCase() +
            " " +
            dataEstate[0].city.toLowerCase()
          }
        />
        <meta
          property="og:description"
          content={
            dataEstate[0].cate +
            " " +
            dataEstate[0].form_cate.toLowerCase() +
            " đường " +
            dataEstate[0].address.toLowerCase() +
            " " +
            dataEstate[0].districts.toLowerCase() +
            " " +
            dataEstate[0].city.toLowerCase()
          }
        />
        <meta
          property="og:image"
          content={"https://batdongsan.view360.vn/" + imageAvatar.current[0]}
        />
        <meta property="og:type" content="article" />
      </Helmet>
      {/* Nội dung chi tiết */}
      <div className="page">
        {/* Header */}
        <CheckPCMobile>
          {(isMobile) => (isMobile ? <ComponentHeader /> : <HeaderHome2 />)}
        </CheckPCMobile>
        {/* Main */}
        <div className={cx("container")}>
          <div className={cx("main-sidebar-1")}>
            <CheckPCMobile>
              {(isMobile) =>
                isMobile ? (
                  <DetailEstateMobile dataEstate={dataEstate} />
                ) : (
                  <DetailEstate data={dataEstate} />
                )
              }
            </CheckPCMobile>
          </div>
          {/* Footer */}
          <Footer />
          {/* footer mobile */}
          <CheckPCMobile>
            {(isMobile) => isMobile && <MenuFooter />}
          </CheckPCMobile>
        </div>
      </div>
    </>
  )
}

export default DetailPage


