import { useState, useEffect, useRef, useContext } from "react"
import { useForm } from "react-hook-form"
import classNames from "classnames/bind"
import styles from "../Mobile.module.scss"
import Tippy from "@tippyjs/react/headless"
import { BsDot } from "react-icons/bs"
import { MdCancel } from "react-icons/md"
import { PiDotsThreeOutlineLight } from "react-icons/pi"
import { GoDotFill } from "react-icons/go"
import { Dropdown } from "react-bootstrap"
import moment from "moment"
import { Link } from "react-router-dom"

import ModalReact from "../../../components/Modal/ModalReact"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import { handleFormatPrice } from "../../../handle-reuses/reuses"
import { LoadingSpinner } from "../../../components/Loading"
import { ListImageTitle } from "../../../components/List-Item"
import FormEditCustomer from "../../pc/Dashboard/Component/Khachhang/FormEditCustomer"
import { handleAddNoteCustomer } from "../../pc/Dashboard/Component/Khachhang/Handle/Method"
import { handleMethodEditData } from "../../../handle-reuses/reuses/method"
import MenuFooter from "../MenuFooter/MenuFooter"

const cx = classNames.bind(styles)

const optionCate = [
  { id: 1, cate: "Mua nhà" },
  { id: 2, cate: "Mua đất" },
  { id: 3, cate: "Mua đầu tư" },
  { id: 4, cate: "Thuê nhà riêng" },
  { id: 5, cate: "Thuê phòng trọ" },
  { id: 6, cate: "Thuê căn hộ" },
  { id: 7, cate: "Thuê chung cư" },
  { id: 8, cate: "Thuê mặt bằng" },
]

function ListCustomer({ addSuccess }) {
  const { dataUser, socketio } = useContext(AppContext)
  const [dataCustomer, setDataCustomer] = useState([])
  const [loading, setLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const page = useRef(1)
  const limit = 10


  // fetch data,
  const fetchData = async (limit_, page_) => {
    const object = {
      only: dataUser[0].id,
      quantity: limit_,
      offset: (page_ - 1) * limit_,
    }
    const methodPost = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        identification: process.env.REACT_APP_IDENTIFICATION,
      },
      body: JSON.stringify(object),
    }
    const response = await fetch(
      process.env.REACT_APP_APIGetDataForCustomer,
      methodPost,
    )
    const dataRes = await response.json()
    if (dataRes.length > 0) {
      setDataCustomer((dataOld) => [...dataOld, ...dataRes])
    } else {
      // Xác nhận đã lấy hết dữ liệu
      setIsLoading(true)
    }
    // Xác nhận đã lấy xong dữ liệu
    setLoading(false)
  }
  // Gọi dữ liệu ban đầu.
  useEffect(() => {
    fetchData(limit, page)
  }, [])

  // Xử lý search data
  const handleGetValueSearch = (dataSearch) => {
    setDataCustomer(dataSearch)
  }
  // Xử lý edit success.
  const [editSuccess, setEditSuccess] = useState()
  const handleEditSuccess = async (result) => {
    setEditSuccess(true)
    if (result) {
      // Cập nhật lại dữ liệu hiển thị
      const dataRes = await handleUpdateDataCus(dataUser, limit, page)
      // Tạo hiêu ứng loading
      setTimeout(() => {
        setDataCustomer(dataRes)
        setEditSuccess(false)
      }, 500)
    }
  }
  // Xử lý add success.
  const [addSuccess_, setAddSuccess_] = useState()
  const handleUpdate = async () => {
    if (addSuccess) {
      setAddSuccess_(true)
      // Cập nhật lại dữ liệu hiển thị
      const dataRes = await handleUpdateDataCus(dataUser, limit, page)
      // Tạo hiêu ứng loading
      setTimeout(() => {
        setDataCustomer(dataRes)
        setAddSuccess_(false)
      }, 500)
    }
  }
  useEffect(() => {
    handleUpdate()
  }, [addSuccess])

  if (loading) {
    return <LoadingSpinner />
  }
  if (editSuccess || addSuccess_) {
    return (
      <div className="text-center" style={{ marginTop: "68px" }}>
        Đang cập nhật dữ liệu mới...
      </div>
    )
  }
  return (
    <div className={cx("wrapper_dash", "py-0")}>
      {/* Search */}
      <SearchCustomer result={handleGetValueSearch} />
      {/* Cate customer */}
      {/* <div className={cx("cateHorizontal")}>
          <ul className={cx("d-flex column-gap-9")} style={{ width: "400px" }}>
            <li className={cx("me-3")}>
              <button
                className={cx(
                  "btn btn-wave",
                  activeCate === "btn-iconBuy" ? activeCate : "btn-light",
                )}
                onClick={() => handleCateStatus(0, "btn-iconBuy")}
              >
                Mua nhà
              </button>
            </li>
            <li className={cx("me-3")}>
              <button
                className={cx(
                  "btn btn-wave",
                  activeCate === "btn-info" ? activeCate : "btn-light",
                )}
                onClick={() => handleCateStatus(1, "btn-info")}
              >
                Thuê nhà
              </button>
            </li>
            <li className={cx("me-3")}>
              <button
                className={cx(
                  "btn btn-wave",
                  activeCate === "btn-success" ? activeCate : "btn-light",
                )}
                onClick={() => handleCateStatus(2, "btn-success")}
              >
                Mua đất
              </button>
            </li>
            <li className={cx("me-3")}>
              <button
                className={cx(
                  "btn btn-wave",
                  activeCate === "btn-success" ? activeCate : "btn-light",
                )}
                onClick={() => handleCateStatus(2, "btn-success")}
              >
                Đầu tư
              </button>
            </li>
          </ul>
        </div> */}
      {/* List customer */}
      <ListCustomers dataCus={dataCustomer} result={handleEditSuccess} />
      {/* Footer */}
      <MenuFooter />
    </div>
  )
}

export default ListCustomer

// Xử  lý update data cus mỗi khi edit hoặc thêm mới.
async function handleUpdateDataCus(dataUser, limit, page) {
  const object = {
    only: dataUser[0].id,
    quantity: limit,
    offset: (page - 1) * limit,
  }
  const methodPost = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      identification: process.env.REACT_APP_IDENTIFICATION,
    },
    body: JSON.stringify(object),
  }
  const response = await fetch(
    process.env.REACT_APP_APIGetDataForCustomer,
    methodPost,
  )
  const dataRes = await response.json()

  return dataRes
}

// Search customer.
function SearchCustomer({ result }) {
  const { dataUser, socketio } = useContext(AppContext)

  const [object, setObject] = useState({
    only: dataUser[0].id,
    loaisanpham: "",
    text: "",
  })
  const handleValueCate = (e) => {
    setObject({
      only: dataUser[0].id,
      loaisanpham: e.target.value,
      text: object.text,
    })
    socketio.emit("client-search-customer", {
      only: dataUser[0].id,
      loaisanpham: e.target.value,
      text: object.text,
    })
  }
  const handleValueText = (e) => {
    setObject({
      only: dataUser[0].id,
      loaisanpham: object.loaisanpham,
      text: e.target.value,
    })
    socketio.emit("client-search-customer", {
      only: dataUser[0].id,
      loaisanpham: object.loaisanpham,
      text: e.target.value,
    })
  }
  // Xóa text search.
  const handleCancelText = () => {
    setObject({
      only: dataUser[0].id,
      loaisanpham: object.loaisanpham,
      text: "",
    })
    socketio.emit("client-search-customer", {
      only: dataUser[0].id,
      loaisanpham: object.loaisanpham,
      text: "",
    })
  }
  // Kết quả search.
  useEffect(() => {
    socketio.on("server-search-customer", function (data) {
      result(data)
    })
    return () => {
      socketio.off("server-search-customer")
    }
  }, [])
  return (
    <div className={cx("bg-cus-menu")}>
      <div className={cx("optionMenu")}>
        <div className="col-xl-12 d-flex gap-1">
          <div className="col-4">
            <select
              value={object.loaisanpham}
              className="form-control rounded-2 fs-14"
              onChange={handleValueCate}
              style={{ border: "1px solid #e6e6e6" }}
            >
              <option value="">Danh mục</option>
              {optionCate.map((option) => {
                return (
                  <option key={option.id} value={option.cate}>
                    {option.cate}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="col-8 position-relative">
            <input
              type="text"
              value={object.text}
              placeholder="Tên, số điện thoai, quận huyện, giá"
              className="form-control"
              onChange={handleValueText}
            />
            <button
              className={cx(
                "bg-transparent border-0 text-muted",
                "cancelSearch",
              )}
              style={{ display: object.text ? "block" : "none" }}
              onClick={handleCancelText}
            >
              <MdCancel className="fs-21" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

//List customer.
function ListCustomers({ dataCus, result }) {
  // Get data product cho sản phẩm phù hợp.
  const {socketio} = useContext(AppContext)
  const [dataPro, setDataPro] = useState([])
  useEffect(() => {
    // gọi dữ liệu.
    socketio.emit("client-fit-product", {
      identification: process.env.REACT_APP_IDENTIFICATION,
    })
    return () => {
      socketio.off("client-fit-product")
    }
  }, [])
  useEffect(() => {
    socketio.on("server-fit-product", function (data) {
      setDataPro(data)
    })
    return () => {
      socketio.off("server-fit-product")
    }
  }, [])
  // Xử lý thêm ghi chú
  const [activeModal, setActiveModal] = useState(false)
  const [idPro, setIdPro] = useState(0)
  const handleShowModal = (id_) => {
    setActiveModal(true)
    setIdPro(id_)
  }
  const handleClose = () => {
    setActiveModal(false)
    setIdPro(0)
  }

  // Xử lý edit data.
  const [activeEditModal, setActiveEditModal] = useState(false)
  const [dataEdit, setDataEdit] = useState([])
  const handleShowModalEdit = (id_) => {
    setActiveEditModal(true)
    const filterDataEdit = dataCus.filter((item) => {
      return item.id === id_
    })
    setDataEdit(filterDataEdit)
  }
  const handleEditClose = () => {
    setActiveEditModal(false)
    setDataEdit([])
  }
  // Check edit success.
  const handleEventSuccess = (result_) => {
    result(result_)
    //   Ẩn form edit.
    setActiveEditModal(false)
    setDataEdit([])
  }

  // Xử lý hiển thị danh sách sản phẩm phù hợp.
  const [showHideModal, setShowHideModal] = useState(false)
  const [dataListPro, setDataListPro] = useState([])

  const handleShowModalListPro = (dataListPro) => {
    setShowHideModal(true)
    setDataListPro(dataListPro)
  }
  const handleListProClose = () => {
    setShowHideModal(false)
    setDataListPro([])
  }

//  console.log(dataCus)


  return (
    <div className="mt-3 px-3">
      <ul>
        {dataCus.map((item) => {
          let loaisanpham = item.loaisanpham.toLowerCase()
          // filter data pro.
          let filterDataPro = handleFilterPro(
            dataPro,
            item.tuyenduong,
            item.quanhuyen,
            item.thanhpho,
            item.loaisanpham,
            item.giasanpham,
          )
          return (
            <li className="mb-4" key={item.id}>
              <div className="d-flex">
                {/* Icon */}
                <div className="me-3">
                  {loaisanpham.includes("mua") ? (
                    <div className={cx("iconBuy", "btn-iconBuy")}>MUA</div>
                  ) : (
                    <div className={cx("iconRent", "btn-iconRent")}>THUÊ</div>
                  )}
                </div>
                {/* Right */}
                <div className={cx("d-flex justify-content-between col-10")}>
                  {/* Info */}
                  <div>
                    <span className="fw-5">
                      {item.tenlienhe} - {item.sodienthoai}
                    </span>
                    <div>
                      <span className={cx("text-uppercase fs-12 fw-5")}>
                        {item.loaisanpham}
                      </span>{" "}
                      <BsDot />{" "}
                      <span className="text-muted fw-5 fs-14">
                        Khu vực {item.quanhuyen}
                      </span>{" "}
                    </div>
                    <div className="fs-14 text-muted">
                      <span>Phù hợp: {filterDataPro.length}</span>
                      <span>
                        <BsDot />
                        {handleFormatPrice(Number(item.giasanpham))}
                      </span>

                      {item.tinhtrang === 1 && (
                        <span className="text-success">
                          <BsDot />
                          Đã giao dịch
                        </span>
                      )}
                    </div>
                    <p className="mb-0 text-muted fs-15">{moment(item.addtime).format("DD/MM/YY")}</p>
                  </div>
                  {/* option */}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="btn btn-outline-light rounded-pill btn-wave border-0 p-1" // class mặc định
                      id="dropdown-basic-1"
                      bsPrefix="custom-dropdown-toggle"
                      className={cx("border-0 rounded-3")}
                      style={{ width: "35px", height: "35px" }}
                    >
                      <PiDotsThreeOutlineLight className="fs-21" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Link
                        to="/khach-hang"
                        className="dropdown-item fs-18"
                        onClick={() => handleShowModal(item.id)}
                      >
                        Ghi chú
                      </Link>
                      <Link
                        to="/khach-hang"
                        className="dropdown-item fs-18"
                        onClick={() => handleShowModalEdit(item.id)}
                      >
                        Sửa thông tin
                      </Link>
                      <Link
                        to="/khach-hang"
                        className="dropdown-item fs-18"
                        onClick={() => handleShowModalListPro(filterDataPro)}
                      >
                        Phù hợp
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
      {/* Modal add note*/}
      {idPro ? (
        <ModalReact
          modalTitle="Lịch trình chăm sóc"
          showModal={activeModal}
          handleClose={handleClose}
          theme={<CustomerDetailInfo id={idPro} data={dataCus} result={result}/>}
        />
      ) : (
        ""
      )}
      {/* Modal edit data */}
      {dataEdit.length > 0 ? (
        <ModalReact
          modalTitle="Sửa thông tin"
          showModal={activeEditModal}
          handleClose={handleEditClose}
          theme={
            <FormEditCustomer
              dataCusEdit={dataEdit}
              result={handleEventSuccess}
            />
          }
        />
      ) : (
        ""
      )}
      {/* Modal list product */}
      {showHideModal ? (
        <ModalReact
          modalTitle="Danh sách sản phẩm phù hợp"
          showModal={showHideModal}
          handleClose={handleListProClose}
          theme={<ListSuitableProduct data={dataListPro} />}
        />
      ) : (
        ""
      )}

      {/* {dataListPro.length > 0 ? (
        <ModalReact
          modalTitle="Danh sách sản phẩm phù hợp"
          showModal={showHideModal}
          handleClose={handleListProClose}
          theme={<ListSuitableProduct data={dataListPro} />}
        />
      ) : (
        ""
      )} */}
    </div>
  )
}

// Modal detail.
function CustomerDetailInfo({ id, data, result }) {
  const dataG = useContext(AppContext)
  const dataUser = dataG.dataUser
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const [showInput, setShowInput] = useState("none")
  const [dataNote, setDataNote] = useState([])
  const [loading, setLoading] = useState(true)

  // Filter data customer.
  const filterCus = data.find((item) => item.id === id)

  const fetchData = async () => {
    const response = await fetch(
      process.env.REACT_APP_APIGetDataMarketBuyerNote + `?buyerid=${id}`,
    )
    const dataRes = await response.json()
    const sortData = dataRes.sort((a, b) => {
      let min_ = new Date(a.addtime).getTime()
      let max_ = new Date(b.addtime).getTime()
      return max_ - min_
    })
    setTimeout(() => {
      setDataNote(sortData)
      setLoading(false)
    }, 100)
  }
  useEffect(() => {
    fetchData()
  }, [loading])
  // Bật trường input cho note
  const handleShowInput = () => {
    setShowInput("block")
  }
  // Check khi thêm mới hoàn tất.
  const handleCheckAddSuccess = () => {
    setLoading(true)
  }
  // Submit
  const onSubmit = (data) => {
    data.buyerid = id
    data.author = dataUser[0].id
    // Xử lý thêm mới
    handleAddNoteCustomer(data, reset, handleCheckAddSuccess)
    setShowInput("none")
  }

  // edit note.
  const [valueEdit, setValueEdit] = useState("")
  const [idEdit, setIdEdit] = useState(0)
  const handleGetValueEdit = (e, id_) => {
    setValueEdit(e.target.value)
    setIdEdit(id_)
  }
  const handleEventEditNote = (id_) => {
    // Xử lý sửa ghi chú.
    const objectEdit = {
      id: id_,
      note: valueEdit,
    }
    handleMethodEditData(
      objectEdit,
      () => {},
      process.env.REACT_APP_APIEditDataMarketBuyerNote,
    )
    setValueEdit("")
    setLoading(true)
  }

  // Xác nhận giao dịch (tình trạng).
  const handleCheckStatus = () => {
    const checkStatus = window.confirm("Khách hàng này đã chốt thành công?")
    if (checkStatus) {
      const object = {
        id: id,
        tinhtrang: 1,
      }
      handleMethodEditData(
        object,
        () => {
          alert("Đã chuyển trạng thái Giao Dịch Thành Công.")
          result(true)
        },
        process.env.REACT_APP_APIEditRowTinhtrangMarketBuyer,
      )
    }
  }

  return (
    <div className="p-1">
      <div className="d-flex mb-3">
        <button
          className="me-2 border-1 rounded-2"
          style={{ border: "1px solid rgba(178, 178, 178, 0.768627451)" }}
          onClick={handleShowInput}
        >
          Ghi chú mới
        </button>
        {(filterCus.tinhtrang === 0) ? (
          <button
            className="me-2 border-1 rounded-2"
            style={{ border: "1px solid rgba(178, 178, 178, 0.768627451)" }}
            onClick={handleCheckStatus}
          >
            Chuyển trạng thái
          </button>
        ) : (
          <button
            className="badge me-2 border-1 rounded-2 bg-success fs-12"
            style={{ border: "1px solid rgba(178, 178, 178, 0.768627451)" }}
          >
            G.Dịch thành công
          </button>
        )}
      </div>
      <div style={{ display: showInput }} className="mb-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2">
            <input
              type="text"
              {...register("note", { required: true })}
              className="form-control"
            />
            {errors.note && (
              <span className="text-danger fs-12">Bạn cần viết ghi chú.</span>
            )}
          </div>
          <div>
            <button className="badge bg-success border-0 p-2 fw-5" type="submit">
              Thêm mới
            </button>
          </div>
        </form>
      </div>

      <ul className="list-unstyled mb-0 crm-recent-activity">
        {loading ? <LoadingSpinner /> : ""}
        {!loading && dataNote.length === 0 ? "Chưa có ghi chú" : ""}
        {!loading &&
          dataNote.length > 0 &&
          dataNote.map((item) => {
            return (
              <li className="crm-recent-activity-content" key={item.id}>
                <div className="d-flex align-items-top">
                  <div className="me-3">
                    <span className="avatar avatar-xs bg-warning-transparent avatar-rounded">
                      <GoDotFill className="fs-15" />
                    </span>
                  </div>
                  <div className="crm-timeline-content">
                    <span className="fs-14">
                      {idEdit === item.id && valueEdit !== ""
                        ? valueEdit
                        : item.note}
                    </span>
                    <div className="d-block fs-12 text-muted me-2">
                      <span className="me-1">
                        {moment(item.addtime).locale("vi").startOf().fromNow()}
                      </span>
                    </div>
                  </div>
                  <div className="flex-fill text-end">
                    <span className="d-block text-muted fs-13">
                      {moment(item.addtime).format("DD/MM/YYYY HH:mm")}
                    </span>
                    <span
                      className="me-2 text-muted"
                      style={{ textTransform: "capitalize" }}
                    >
                      {moment(item.addtime).locale("vi").format("dddd")}
                    </span>
                    <Tippy
                      interactive
                      delay={[0, 0]}
                      // placement="top"
                      trigger="click"
                      render={() => {
                        return (
                          <div
                            className="p-3 rounded-3"
                            style={{
                              backgroundColor: "white",
                              borderColor: "var(--default-border)",
                              boxShadow:
                                "0 1rem 1.125rem rgba(169, 169, 169, 0.15)",
                            }}
                          >
                            <label className="fs-14 mb-1">Sửa ghi chú</label>
                            <textarea
                              type="text"
                              className="form-control"
                              rows={5}
                              defaultValue={item.note ? item.note : valueEdit}
                              onChange={(e) => {
                                handleGetValueEdit(e, item.id)
                              }}
                            />
                            <button
                              type="button"
                              className="mt-2 border-0 rounded-2 p-2 px-3"
                              onClick={() => handleEventEditNote(item.id)}
                            >
                              Lưu lại
                            </button>
                          </div>
                        )
                      }}
                    >
                      <button className="border-0 fs-12 rounded-5 px-3">
                        Sửa
                      </button>
                    </Tippy>
                  </div>
                </div>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

// Xử lý hiển thị danh sách sản phẩm phù hợp.
function ListSuitableProduct({ data }) {
  const [dataImagePro, setDataImagePro] = useState([])
  const [getImages, setGetImages] = useState(true)
  const fetchData = async (id_) => {
    const response = await fetch(
      process.env.REACT_APP_APIGetDataMarketSimpleImages + `?only=${id_}`,
    )
    const dataRes = await response.json()
    setDataImagePro((old) => [...old, ...dataRes])
  }

  useEffect(() => {
    let i = 0
    const handleGetImages = () => {
      if (i < data.length) {
        fetchData(data[i].id)
        i++
        handleGetImages()
      } else {
        setTimeout(() => {
          setGetImages(false)
        }, 100)
      }
    }
    handleGetImages()
  }, [])

  if (data.length === 0) {
    return <div className="text-center">Không có sản phẩm phù hợp</div>
  }

  return (
    <>
      {getImages ? (
        <LoadingSpinner />
      ) : (
        <ListImageTitle data={data} dataImages={dataImagePro} />
      )}
    </>
  )
}

// Xử lý filter data.
function handleFilterPro(
  dataPro,
  tuyenduong,
  quanhuyen,
  thanhpho,
  loaisanpham,
  giasanpham,
) {
  const filterData = dataPro.filter((item) => {
    let cate = ""
    if (item.cate.toLowerCase().includes("cho thuê nhà")) {
      cate = "thuê nhà riêng"
    } else if (item.cate.toLowerCase().includes("cho thuê căn hộ")) {
      cate = "thuê căn hộ"
    } else if (item.cate.toLowerCase().includes("cho thuê chung cư")) {
      cate = "thuê chung cư"
    } else if (item.cate.toLowerCase().includes("cho thuê phòng trọ")) {
      cate = "thuê phòng trọ"
    } else if (item.cate.toLowerCase().includes("cho thuê mặt bằng")) {
      cate = "thuê mặt bằng"
    } else if (
      item.cate.toLowerCase().includes("bán nhà") ||
      item.cate.toLowerCase().includes("bán căn hộ") ||
      item.cate.toLowerCase().includes("bán chung cư")
    ) {
      cate = "mua nhà"
    } else if (item.cate.toLowerCase().includes("bán đất")) {
      cate = "mua đất"
    }

    if (tuyenduong.length > 0) {
      return (
        item.address.toLowerCase().includes(tuyenduong.toLowerCase()) &&
        item.districts.toLowerCase().includes(quanhuyen.toLowerCase()) &&
        item.city.toLowerCase().includes(thanhpho.toLowerCase()) &&
        cate.includes(loaisanpham.toLowerCase()) &&
        Number(item.price) <= Number(giasanpham) + 50000000
      )
    } else {
      return (
        item.districts.toLowerCase().includes(quanhuyen.toLowerCase()) &&
        item.city.toLowerCase().includes(thanhpho.toLowerCase()) &&
        cate.includes(loaisanpham.toLowerCase()) &&
        Number(item.price) <= Number(giasanpham) + 50000000
      )
    }
  })

  return filterData
}
