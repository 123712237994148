import classNames from "classnames/bind"
import { useLayoutEffect, useState, memo, useContext } from "react"
import { CiCreditCard1 } from "react-icons/ci"
import { HiOutlineHomeModern, HiOutlineUsers } from "react-icons/hi2"
import { IoCalendarOutline } from "react-icons/io5"
import { SlEye } from "react-icons/sl"
import { PiUserCirclePlusLight } from "react-icons/pi"

import styles from "./CardHorizontal.module.scss"
import AppContext from "../../handle-reuses/Contexts/CreateContexts"
const cx = classNames.bind(styles)

function CardHorizontal({ dataPro, dataBooking, dataCustomer }) {
  const dataG = useContext(AppContext)
  const dataBalance = dataG.dataBalance
  // tỷ suất đã chi.
  const Dachi =
    dataBalance.length > 0
      ? Number(dataBalance[0].total_deposit) -
        Number(dataBalance[0].total_balance)
      : 0
  const tsDachi =
    dataBalance.length > 0
      ? ((Dachi / Number(dataBalance[0].total_deposit)) * 100).toFixed(1)
      : 0
  //  xử lý dataPro
  const [tsPro, setTsPro] = useState(0)
  const [tsProSuc, setTsProSuc] = useState(0)
  const [tsViewPro, setTsViewPro] = useState(0)
  const [tsClickPro, setTsClickPro] = useState(0)
  const [chuyendoi, setChuyendoi] = useState(0)

  const handleTotalView = (arr) => {
    let totalView = 0
    for (let i = 0; i < arr.length; i++) {
      totalView += arr[i].countview
    }
    return totalView
  }
  const handleTotalClick = (arr) => {
    let totalClick = 0
    for (let i = 0; i < arr.length; i++) {
      totalClick += arr[i].countclick
    }
    return totalClick
  }

  useLayoutEffect(() => {
    const conlai = dataPro.filter((item) => {
      return item.transaction === 0
    })
    const thanhcong = dataPro.filter((item) => {
      return item.transaction === 1
    })
    const totalPro = dataPro.length
    if (totalPro > 0) {
      // Tỷ suất
      const tyleConlai = (
        (Number(conlai.length) / Number(totalPro)) *
        100
      ).toFixed(1)
      const tyleThanhcong = (
        (Number(thanhcong.length) / Number(totalPro)) *
        100
      ).toFixed(1)
      // Tỷ suất view & click
      const tsView = handleTotalView(dataPro)
      const tsClick = handleTotalClick(dataPro)
      const tyleChuyendoi = ((tsClick / tsView) * 100).toFixed(1)

      setTsPro(tyleConlai)
      setTsProSuc(tyleThanhcong)
      setTsViewPro(tsView)
      setTsClickPro(tsClick)
      setChuyendoi(tyleChuyendoi)
    }
  }, [dataPro.length])

  // Xử lý dataBooking.
  const [tsBooking, setTsBooking] = useState(0)
  const [tsBookingSuc, setTsBookingSuc] = useState(0)
  useLayoutEffect(() => {
    const conlai = dataBooking.filter((item) => {
      return item.status === 0
    })
    const thanhcong = dataBooking.filter((item) => {
      return item.status === 2
    })
    const totalBooking = Number(dataBooking.length)
    if (totalBooking > 0) {
      const tyleConlai = ((Number(conlai.length) / totalBooking) * 100).toFixed(
        1,
      )
      const tyleThanhcong = (
        (Number(thanhcong.length) / totalBooking) *
        100
      ).toFixed(1)
      setTsBooking(tyleConlai)
      setTsBookingSuc(tyleThanhcong)
    }
  }, [dataBooking.length])

  // Xử lý data customer.
  const [totalCustomer, setTotalCustomer] = useState(0)
  const [tsCusRent, setTsCusRent] = useState(0)
  const [tsCusRentSuc, setTsCusRentSuc] = useState(0)
  const [totalCusBuyer, setTotalCusBuyer] = useState(0)
  const [tsCusBuyer, setTsCusBuyer] = useState(0)
  const [tsCusBuyerSuc, setTsCusBuyerSuc] = useState(0)

  useLayoutEffect(() => {
    const dataRent = dataCustomer.filter((item) => {
      return item.loaisanpham.includes("Cần thuê")
    })
    const dataBuyer = dataCustomer.filter((item) => {
      return item.loaisanpham !== "Cần thuê"
    })
    // Xử lý khách cần thuê
    const totalCusRent = Number(dataRent.length)
      .toLocaleString()
      .replace(/[.]/g, ",")
    const filterRent = dataRent.filter((item) => {
      return item.tinhtrang === 0
    })
    const filterRentSuc = dataRent.filter((item) => {
      return item.tinhtrang === 1
    })
    const tyleRent = (
      (Number(filterRent.length) / Number(dataRent.length)) *
      100
    ).toFixed(1)
    const tyleRentSuc = (
      (Number(filterRentSuc.length) / Number(dataRent.length)) *
      100
    ).toFixed(1)

    // Xử lý khách cần mua.
    const totalCusBuyer = Number(dataBuyer.length)
      .toLocaleString()
      .replace(/[.]/g, ",")
    const filterBuyer = dataBuyer.filter((item) => {
      return item.tinhtrang === 0
    })
    const filterBuyerSuc = dataBuyer.filter((item) => {
      return item.tinhtrang === 1
    })
    const tyleBuyer = (
      (Number(filterBuyer.length) / Number(dataBuyer.length)) *
      100
    ).toFixed(1)
    const tyleBuyerSuc = (
      (Number(filterBuyerSuc.length) / Number(dataBuyer.length)) *
      100
    ).toFixed(1)

    setTotalCustomer(totalCusRent)
    setTsCusRent(tyleRent)
    setTsCusRentSuc(tyleRentSuc)

    setTotalCusBuyer(totalCusBuyer)
    setTsCusBuyer(tyleBuyer)
    setTsCusBuyerSuc(tyleBuyerSuc)
  }, [dataCustomer.length])

  return (
    <div className="row">
      <div className="col-xxl-4 col-sm-6 col-xl-4">
        <div className="card custom-card rounded-4">
          <div className={cx("card-body", "border-1", "rounded-4")}>
            <div className="d-flex align-items-top justify-content-between mb-4">
              <div className="flex-fill d-flex align-items-top">
                <div className="me-2">
                  <span className="avatar avatar-md text-indigo border bg-light">
                    <CiCreditCard1 />
                  </span>
                </div>
                <div className="flex-fill">
                  <p className="fw-semibold fs-16 mb-0">Tài khoản</p>
                  <p className="mb-0 text-muted fs-15 op-7">Thống kê số dư</p>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-0">
              <p className="mb-0 fs-20 fw-semibold">
                {dataBalance.length > 0
                  ? Number(dataBalance[0].total_balance)
                      .toLocaleString()
                      .replace(/[.]/g, ",")
                  : 0}
                đ
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div className="flex-fill">
                <div className="progress progress-xs">
                  <div
                    className="progress-bar bg-indigo"
                    role="progressbar"
                    style={{ width: tsDachi + "%" }}
                    aria-valuenow="67"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="ms-3">
                <span className="fs-15 text-muted">{tsDachi}% Đã chi</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xxl-4 col-sm-6 col-xl-4">
        <div className="card custom-card rounded-4">
          <div className={cx("card-body", "border-1", "rounded-4")}>
            <div className="d-flex align-items-top justify-content-between mb-4">
              <div className="flex-fill d-flex align-items-top">
                <div className="me-2">
                  <span className="avatar avatar-md text-primary border bg-light">
                    <HiOutlineHomeModern />
                  </span>
                </div>
                <div className="flex-fill">
                  <p className="fw-semibold fs-16 mb-0">Tổng sản phẩm</p>
                  <p className="mb-0 text-muted fs-15 op-7">
                    Thống kê sản phẩm
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-0">
              <p className="mb-0 fs-20 fw-semibold">
                {Number(dataPro.length).toLocaleString().replace(/[.]/g, ",")}
              </p>
              <span className="text-muted ms-2 fs-15">
                <i className="ti ti-trending-up align-middle text-success me-1 d-inline-block"></i>
                {tsPro}%
              </span>
            </div>
            <div className="d-flex align-items-center">
              <div className="flex-fill">
                <div className="progress progress-xs">
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: tsProSuc + "%" }}
                    aria-valuenow="15"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="ms-3">
                <span className="fs-15 text-muted">{tsProSuc}% Thành công</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xxl-4 col-sm-6 col-xl-4">
        <div className="card custom-card rounded-4">
          <div className={cx("card-body", "border-1", "rounded-4")}>
            <div className="d-flex align-items-top justify-content-between mb-4">
              <div className="flex-fill d-flex align-items-top">
                <div className="me-2">
                  <span className="avatar avatar-md text-secondary border bg-light">
                    <IoCalendarOutline />
                  </span>
                </div>
                <div className="flex-fill">
                  <p className="fw-semibold fs-16 mb-0">Lịch hẹn</p>
                  <p className="mb-0 text-muted fs-15 op-7">
                    Theo dõi lịch hẹn
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-0">
              <p className="mb-0 fs-20 fw-semibold">
                {Number(dataBooking.length)
                  .toLocaleString()
                  .replace(/[.]/g, ",")}
              </p>
              <span className="text-muted ms-2 fs-15">
                <i className="ti ti-trending-up align-middle text-success me-1 d-inline-block"></i>
                {tsBooking}%
              </span>
            </div>
            <div className="d-flex align-items-center">
              <div className="flex-fill">
                <div className="progress progress-xs">
                  <div
                    className="progress-bar bg-secondary"
                    role="progressbar"
                    style={{ width: tsBookingSuc + "%" }}
                    aria-valuenow="55"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="ms-3">
                <span className="fs-15 text-muted">
                  {tsBookingSuc}% Thành công
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xxl-4 col-sm-6 col-xl-4">
        <div className="card custom-card rounded-4">
          <div className={cx("card-body", "border-1", "rounded-4")}>
            <div className="d-flex align-items-top justify-content-between mb-4">
              <div className="flex-fill d-flex align-items-top">
                <div className="me-2">
                  <span className="avatar avatar-md text-success border bg-light">
                    <SlEye />
                  </span>
                </div>
                <div className="flex-fill">
                  <p className="fw-semibold fs-16 mb-0">
                    Lượt xem / Chuyển đổi
                  </p>
                  <p className="mb-0 text-muted fs-15 op-7">
                    Thống kê lượt xem
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-0">
              <p className="mb-0 fs-20 fw-semibold">
                {tsViewPro} / {tsClickPro}
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div className="flex-fill">
                <div className="progress progress-xs">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: chuyendoi + "%" }}
                    aria-valuenow="24"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="ms-3">
                <span className="fs-15 text-muted">
                  {chuyendoi}% Chuyển đổi
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xxl-4 col-sm-6 col-xl-4">
        <div className="card custom-card rounded-4">
          <div className={cx("card-body", "border-1", "rounded-4")}>
            <div className="d-flex align-items-top justify-content-between mb-4">
              <div className="flex-fill d-flex align-items-top">
                <div className="me-2">
                  <span className="avatar avatar-md text-indigo border bg-light">
                    <HiOutlineUsers />
                  </span>
                </div>
                <div className="flex-fill">
                  <p className="fw-semibold fs-16 mb-0">Khách thuê</p>
                  <p className="mb-0 text-muted fs-15 op-7">
                    Thống kê khách thuê
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-0">
              <p className="mb-0 fs-20 fw-semibold">{totalCustomer}</p>
              <span className="text-muted ms-2 fs-15">
                <i className="ti ti-trending-down align-middle text-danger me-1 d-inline-block"></i>
                Chưa khai thác {tsCusRent > 0 ? tsCusRent : 0}%
              </span>
            </div>
            <div className="d-flex align-items-center">
              <div className="flex-fill">
                <div className="progress progress-xs">
                  <div
                    className="progress-bar bg-indigo"
                    role="progressbar"
                    style={{ width: tsCusRentSuc > 0 ? tsCusRentSuc : 0 + "%" }}
                    aria-valuenow="67"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="ms-3">
                <span className="fs-15 text-muted">
                  {tsCusRentSuc > 0 ? tsCusBuyerSuc : 0}% Khai thác
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xxl-4 col-sm-6 col-xl-4">
        <div className="card custom-card rounded-4">
          <div className={cx("card-body", "border-1", "rounded-4")}>
            <div className="d-flex align-items-top justify-content-between mb-4">
              <div className="flex-fill d-flex align-items-top">
                <div className="me-2">
                  <span className="avatar avatar-md text-indigo border bg-light">
                    <PiUserCirclePlusLight />
                  </span>
                </div>
                <div className="flex-fill">
                  <p className="fw-semibold fs-16 mb-0">Khách mua</p>
                  <p className="mb-0 text-muted fs-15 op-7">
                    Thống kê khách mua
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-0">
              <p className="mb-0 fs-20 fw-semibold">{totalCusBuyer}</p>
              <span className="text-muted ms-2 fs-15">
                <i className="ti ti-trending-down align-middle text-danger me-1 d-inline-block"></i>
                Chưa khai thác {tsCusBuyer > 0 ? tsCusBuyer : 0}%
              </span>
            </div>
            <div className="d-flex align-items-center">
              <div className="flex-fill">
                <div className="progress progress-xs">
                  <div
                    className="progress-bar bg-danger op-8"
                    role="progressbar"
                    style={{
                      width: tsCusBuyerSuc > 0 ? tsCusBuyerSuc : 0 + "%",
                    }}
                    aria-valuenow="67"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="ms-3">
                <span className="fs-15 text-muted">
                  {tsCusBuyerSuc > 0 ? tsCusBuyerSuc : 0}% Khai thác
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(CardHorizontal)
