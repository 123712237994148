import { useState, useContext, useEffect } from "react"
import classNames from "classnames/bind"
import moment from "moment"

import styles from "../../pc/Dashboard/Dashboard.module.scss"
import SearchSimple from "../../../components/Search/SearchSimple"
import ListProduct from "../../pc/Dashboard/Component/Sanpham/ListProduct"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import SearchHorizontal from "../../../components/Search/SearchHorizontal"

const cx = classNames.bind(styles)

function ListProducts({ data, dataBooking, socketio, result , effectLoading, checkSearch}) {
  const dataG = useContext(AppContext)
  const dataUser = dataG.dataUser
  const [activeTab, setActiveTab] = useState("home")
  const [dataEstates, setDataEstates] = useState(data)
  useEffect(() => {
    setDataEstates(data)
  }, [data])

  // Get value search.
  const handleGetValue = (valueText) => {
    // Nếu được tìm kiếm sẽ không cho scroll auto data
    checkSearch(valueText)
    
    if (valueText !== "") {
      const params = {
        iden: process.env.REACT_APP_IDENTIFICATION,
        object: {
          userid: dataUser[0].id,
          keyword: valueText,
        },
      }
      socketio.emit("client-search-dashboard-for-user", params)
      socketio.on("server-search-dashboard-for-user", (data_) => {
        setDataEstates(data_)
      })
    } else {
      // Trả về mảng ban đầu
      setDataEstates(data)
    }
  }

  // Quản lý dữ liệu search.
  useEffect(() => {
    return () => {
      socketio.off("server-search-dashboard-for-user")
      socketio.off("client-search-dashboard-for-user")
    }
  }, [])

  // Data không quảng cáo
  const dataNoAd = dataEstates.filter((item) => {
    return item.status === 0
  })
  // data hết hạn.
  const dataExpired = dataEstates.filter((item) => {
    let endDate = moment(item.endtime)
    let currentDate = moment()
    let diffTime = endDate.diff(currentDate, "days")
    return item.status === 1 && diffTime <= 0
  })
  // data quảng cáo.
  const dataAd = dataEstates.filter((item) => {
    let endDate = moment(item.endtime)
    let currentDate = moment()
    let diffTime = endDate.diff(currentDate, "days")
    return item.status === 1 && diffTime > 0
  })

  const handleTabSelect = (key) => {
    setActiveTab(key)
  }

  // Nhận về kết quả khi người dùng có thao tác thay đổi với list product.
  const handleResultListProduct = (status_) => {
    result(status_)
  }

  return (
    <div className="tab-style-2">
      <div className={cx("wrapper_search_cate")}>
        <div className="col-xl-4 col-12 d-flex justify-content-end mb-3">
          <SearchHorizontal result={handleGetValue} className="rounded-3" />
        </div>
        <div className={cx("cateProducts")}>
          <ul className="d-flex" style={{ width: "550px" }}>
            <li className="me-2">
              <button
                onClick={() => handleTabSelect("home")}
                className="btn btn-outline-dark fw-5"
              >
                Tất cả ({Number(data.length).toLocaleString()})
              </button>
            </li>

            <li className="me-2">
              <button
                onClick={() => handleTabSelect("hethan")}
                className="btn btn-outline-dark fw-5"
              >
                Hết hạn ({Number(dataExpired.length).toLocaleString()})
              </button>
            </li>
            <li className="me-2">
              <button
                onClick={() => handleTabSelect("quangcao")}
                className="btn btn-outline-dark fw-5"
              >
                Quảng cáo ({Number(dataAd.length).toLocaleString()})
              </button>
            </li>
            <li className="me-2">
              <button
                onClick={() => handleTabSelect("no-ad")}
                className="btn btn-outline-dark fw-5"
              >
                Không quảng cáo ({Number(dataNoAd.length).toLocaleString()})
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/* Nội dung của tab được hiển thị dựa trên activeTab */}
      <div className={cx("mt-3")}>
        {activeTab === "home" && (
          <ListProduct
            data={dataEstates}
            dataBooking={dataBooking}
            socketio={socketio}
            result={handleResultListProduct}
            effectLoading={effectLoading}
          />
        )}
        {activeTab === "no-ad" && (
          <ListProduct
            data={dataNoAd}
            dataBooking={dataBooking}
            socketio={socketio}
            result={handleResultListProduct}
            effectLoading={effectLoading}
          />
        )}
        {activeTab === "hethan" && (
          <ListProduct
            data={dataExpired}
            dataBooking={dataBooking}
            socketio={socketio}
            result={handleResultListProduct}
            effectLoading={effectLoading}
          />
        )}
        {activeTab === "quangcao" && (
          <ListProduct
            data={dataAd}
            dataBooking={dataBooking}
            socketio={socketio}
            result={handleResultListProduct}
            effectLoading={effectLoading}
          />
        )}
      </div>
    </div>
  )
}
export default ListProducts
