import { useState, useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css"
import { Modal} from "react-bootstrap"
import { MdCancel } from "react-icons/md";

function ModalReact({ size, modalTitle, theme, showModal, handleClose }) {
    const [sizeModal, setSizeModal] = useState("");
    const [themeModal, setThemeModal] = useState("")

    useEffect(() => {
        if (size) {
            setSizeModal(size)
        } else {
            setSizeModal("")
        }
    }, [sizeModal])
    


  useEffect(() => {
    if (theme) {
      setThemeModal(theme)
    }
  }, [])

  
    return (
      <Modal show={showModal} onHide={handleClose} className={size}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalTitle ? (
              <span className="fw-5 fs-18">{modalTitle}</span>
            ) : (
              "Tên modal"
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {themeModal}
          {/* Ẩn modal khi xem đến cuối */}
          <div className="text-center mt-3 border-top border-block-start-dashed">
            <button className="border-0 bg-transparent mt-2" onClick={handleClose}>
              <MdCancel className="fs-29 text-muted" />
            </button>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Lưu thay đổi
          </Button>
        </Modal.Footer> */}
      </Modal>
    )
}

export default ModalReact;

// Cách dùng.
/**
 * 1. tạo useState để bật tắt (mặc định là false).
 * 2. tạo hàm xử lý bật => state trả về true.
 * 3. tạo hàm xử lý tắt => state trả về false
 */