import HeaderDashboardMobile from "../Component/Header/ComponentHeaderDash"
import ProductLike from "./ProductLike"

function PageProductLike() {
    return (
      <>
        {/* Header */}
        <HeaderDashboardMobile namePage="Yêu thích"/>
        {/* Main */}
        <ProductLike />
        
      </>
    )
}

export default PageProductLike