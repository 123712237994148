import { useState } from "react"
import classNames from "classnames/bind"
import { IoFilterCircleOutline } from "react-icons/io5"


import HeaderDashboardMobile from "../Component/Header/ComponentHeaderDash"
import FilterEstateMobile from "./ComponentFilter"
import ModalReact from "../../../components/Modal/ModalReact"
import { FormFilterEstate } from "./ComponentFilter"
import styles from "../Mobile.module.scss"
const cx = classNames.bind(styles)

function FilterPageMobile() {
  const [dataFilter, setDataFilter] = useState()
  const resultDataFilter = (data) => {
    setDataFilter(data);
  }

    return (
      <div className={cx("mobile_wrapper")}>
        <HeaderDashboardMobile
          namePage="Bộ lọc"
          layoutRight={<LayoutRight result={resultDataFilter} />}
        />
        {/* Danh sách kết quả */}
        <div style={{ padding: "9px" }}>
          <FilterEstateMobile dataFilter={dataFilter} />
        </div>
      </div>
    )
}
export default FilterPageMobile

// Layout right.
function LayoutRight({result}) {
  const [modalFilter, setModalFilter] = useState(true)
  const handleShowModalFilter = () => {
    setModalFilter(true)
  }
  const handleCloseModalFilter = () => setModalFilter(false)
  
  // Kết quả trả về.
  const resultData = (data) => {
    result(data)
    setModalFilter(false)
  }
  return (
    <>
      <button
        className="border-0 bg-transparent"
        onClick={handleShowModalFilter}
      >
        <IoFilterCircleOutline className="fs-29" />
      </button>
      {/* modal */}
      <ModalReact
        modalTitle="Bộ lọc"
        showModal={modalFilter}
        handleClose={handleCloseModalFilter}
        theme={<FormFilterEstate result={resultData}/>}
      />
    </>
  )
}