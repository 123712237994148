import HeaderDashboardPage from "./Header"
import classNames from "classnames/bind"
import styles from "./Dashboard.module.scss"
import LichhenSidebar from "./Component/Lichhen"
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import PageCalendarMobile from "../../mobile/Calendar"

const cx = classNames.bind(styles)

function LichhenPage() {
  return (
    <CheckPCMobile>
      {(isMobile) => (isMobile ? <PageCalendarMobile /> : <LichhenPC />)}
    </CheckPCMobile>
  )
}

export default LichhenPage

// Giao diện lịch hẹn trên PC.
function LichhenPC() {
  return (
    <>
      <HeaderDashboardPage />
      <div className={cx("bg-page")}>
        <div className={cx("mt-3", "container-fluid p-0")}>
          <div className="row">
            <div className="col-xl-12 col-md-12">
              <LichhenSidebar />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

