import HeaderDashboardMobile from "../Component/Header/ComponentHeaderDash"
import DashboardPageMobile from "./DashboardPageMobile"

function DashboardMobile({ loading, dataEstates, dataBooking, dataCustomer }) {
  if (loading) {
    return <div className="text-center mt-3">Đang kết nối...</div>
  }

  return (
    <>
      {/* Header */}
      <HeaderDashboardMobile namePage="Bảng điều khiển" />
      {/* Main */}
      <DashboardPageMobile
        loading={loading}
        dataEstates={dataEstates}
        dataBooking={dataBooking}
        dataCustomer={dataCustomer}
      />
      
    </>
  )
}
export default DashboardMobile
