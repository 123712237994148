import { useEffect, useRef } from "react"
import { IoRibbonOutline } from "react-icons/io5"

import { handleFormatPrice } from "../../handle-reuses/reuses";

function AnalySisDetailEstate({ dataEstate }) {
    const object = dataEstate.find((item) => item);
    const price_ = useRef(0);
    useEffect(() => {
        if (object.cate.toLowerCase().includes("bán")) {
          if (parseInt(object.dtmb) > 0) {
            const priceFormat = (Number(object.price) / parseInt(object.dtmb.replace(/[,]/g, "."))).toFixed();
            price_.current = handleFormatPrice(priceFormat)
                
          }
        }
    }, [])
    if (parseInt(object.dtmb) < 5 || object.cate.toLowerCase().includes("cho thuê") || Number(object.price) === 0 || price_.current === 0) {
      return
    }

    return (
      <>
        <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
        <div className="row fs-18">
          <div className="col-12">
            <span className="me-1 text-muted">
              <span className="text-success me-1">
                <IoRibbonOutline className="fs-18" />
              </span>
              Loại sản phẩm{" "}
              <span className="text-success">
                {object.form_cate.toLowerCase()}
              </span>{" "}
              này đang được rao
            </span>
            <span className="text-success">
              {price_.current}/m<sup>2</sup>
            </span>
            <span className="text-muted ms-1">
              {(object.cate.toLowerCase().includes("căn hộ") ||
              object.cate.toLowerCase().includes("chung cư"))
                ? ""
                : "bao gồm cả tài sản trên đất."}
            </span>
          </div>
          <div className="col-6 fw-5"></div>
          {/* <div className="col-6">Giá: 30tr/m2</div> */}
        </div>
      </>
    )
}

export default AnalySisDetailEstate