import { useState, useEffect, useContext, useRef } from "react"
import moment from "moment"
import { useForm } from "react-hook-form"
import classNames from "classnames/bind"
import Tippy from "@tippyjs/react/headless"
import { Dropdown } from "react-bootstrap"
import { IoAddCircleOutline, IoSearchOutline } from "react-icons/io5"
import { PiDotsThreeOutlineLight, PiNotepad } from "react-icons/pi"
import { RxPencil2 } from "react-icons/rx"
import { PiShoppingBagOpenDuotone } from "react-icons/pi"
import { GoDotFill } from "react-icons/go"

import SearchSimpleNoborder from "../../../../../components/Search/SearchSimpleNoborder"
import styles from "../../Dashboard.module.scss"
import FormAddCustomer from "./FormAddCustomer"
import AppContext from "../../../../../handle-reuses/Contexts/CreateContexts"
import { LoadingSpinner } from "../../../../../components/Loading"
import { handleFormatPrice } from "../../../../../handle-reuses/reuses"
import ModalReact from "../../../../../components/Modal/ModalReact"
import { handleAddNoteCustomer } from "./Handle/Method"
import { handleMethodEditData } from "../../../../../handle-reuses/reuses/method"
import FormEditCustomer from "./FormEditCustomer"
import { ListImageTitle } from "../../../../../components/List-Item"

const cx = classNames.bind(styles)

const optionCate = [
  { id: 1, cate: "Mua nhà" },
  { id: 2, cate: "Mua đất" },
  { id: 3, cate: "Mua đầu tư" },
  { id: 4, cate: "Thuê nhà riêng" },
  { id: 5, cate: "Thuê phòng trọ" },
  { id: 6, cate: "Thuê căn hộ" },
  { id: 7, cate: "Thuê chung cư" },
  { id: 8, cate: "Thuê mặt bằng" },
]

function LayoutRight() {
  const dataG = useContext(AppContext)
  const dataUser = dataG.dataUser
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const dataProRef = useRef()
  const limit = 50
  const pageRef = useRef(1)
  const [loadMore, setLoadMore] = useState(true)

  const fetchData = async (page) => {
    const response = await fetch(
      process.env.REACT_APP_APIGetDataMarketBuyer +
        `?only=${dataUser[0].id}&quantity=${limit}&offset=${
          (page - 1) * limit
        }`,
    )
    const dataRes = await response.json();
    const sortData = dataRes.sort((a, b) => {
      let min_ = new Date(a.addtime).getTime()
      let max_ = new Date(b.addtime).getTime()
      return max_ - min_
    })

    // Get data phù hợp với yêu cầu.
    const responsePro = await fetch(
      process.env.REACT_APP_APIGetDataMarketSimple,
    )
    const dataResPro = await responsePro.json()
    const filterPro = dataResPro.filter((item) => {
      return item.status === 1
    })

    if (dataRes.length > 0) {
      setData((old) => [...old, ...sortData])
      dataProRef.current = filterPro
      
    }
    if (dataRes.length < limit) {
      setLoadMore(false)
    }
    setLoading(false)
  }

  // Get data ban đầu
  useEffect(() => {
    setTimeout(() => {
      fetchData(pageRef.current)
    }, 100)
  }, [])

  // Xử lý scroll.
  useEffect(() => {
    const container = document.getElementById("containerListCustomer")
    const handleScroll = () => {
      const totalHeight = container.scrollHeight
      const setHeight = container.offsetHeight
      const scrollY = container.scrollTop
      if (setHeight + scrollY + 1 >= totalHeight && loadMore) {
        setTimeout(() => {
          pageRef.current = pageRef.current + 1
          fetchData(pageRef.current)
        }, 500)
      }
    }
    if (container) {
      container.addEventListener("scroll", handleScroll)
    }
    return () => {
      container.removeEventListener("scroll", handleScroll)
    }
  }, [pageRef.current])

  // check edit success.
  const handleCheckSuccess = (result) => {
    setLoading(result)
    if (result) {
      setTimeout(() => {
        fetchData(pageRef.current)
      }, 300);
    }
  }

  // Modal search
  const [searchModal, setSearchModal] = useState(false)
  const handleEventSearchData = () => {
    setSearchModal(true)
  }
  const handleCloseSearch = () => setSearchModal(false)

  // Add customer.
  const [showAddModal, setShowAddModal] = useState(false)
  const handleShowAddModal = () => {
    setShowAddModal(true)
  }
  const handleCloseAddModal = () => setShowAddModal(false)
  // trả về trạng thái khi add success.
  const handleAddSuccess = async (result) => {
    // code xử lý tiếp...
    if (result) {
      setShowAddModal(false)
      const dataRes = await handleUpdateDataCus(
        dataUser,
        limit,
        pageRef.current,
      )
      setData(dataRes)
    }
  }

  return (
    <>
      <div>
        {/* Danh mục */}
        <div
          className="d-flex justify-content-start flex-direction-column gap-3"
          style={{ position: "absolute", right: "12px" }}
        >
          <button
            className="fs-16 btn btn-outline-dark d-flex align-items-center"
            onClick={handleShowAddModal}
          >
            <IoAddCircleOutline className="fs-21" />
          </button>
          <button
            onClick={handleEventSearchData}
            className="btn btn-outline-dark fs-16"
            // style={{ backgroundColor: "inherit" }}
          >
            <IoSearchOutline className="fs-21" />
          </button>
        </div>
        {/* Danh sách */}
        <div
          className="col-xl-12 col-md-12 rounded-4"
          style={{
            backgroundColor: "white",
            padding: "16px",
          }}
        >
          <div
            className="col-xl-12 col-md-12"
            style={{ borderTop: "1px dashed #f3f3f3" }}
          >
            {loading ? <LoadingSpinner /> : ""}
            <div className="table-responsive" id="containerListCustomer" style={{height: "calc(100vh - 10rem)"}}>
              {data.length > 0 && (
                <CustomerListRight
                  data={data}
                  dataPro={dataProRef.current}
                  result={handleCheckSuccess}
                />
              )}

              <div className="text-center mt-3">
                {!loading && data.length === 0 && "Chưa có khách hàng"}
                {!loadMore && data.length > 0 ? "Bạn đã xem hết rồi" : ""}
                {loadMore && data.length > 0 && "Loading..."}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal add */}
      <ModalReact
        modalTitle="Thêm mới khách hàng"
        showModal={showAddModal}
        handleClose={handleCloseAddModal}
        theme={<FormAddCustomer result={handleAddSuccess} />}
      />
      {/* Modal search */}
      {dataProRef.current ? (
        <ModalReact
          modalTitle="Tìm kiếm khách hàng"
          size="modal-lg"
          showModal={searchModal}
          handleClose={handleCloseSearch}
          theme={<ModalSearchDataCustomer dataPro={dataProRef.current} />}
        />
      ) : (
        ""
      )}
    </>
  )
}

export default LayoutRight

// Xử  lý update data cus mỗi khi edit hoặc thêm mới.
async function handleUpdateDataCus(dataUser, limit, page) {
  const object = {
    only: dataUser[0].id,
    quantity: limit,
    offset: (page - 1) * limit,
  }
  const methodPost = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      identification: process.env.REACT_APP_IDENTIFICATION,
    },
    body: JSON.stringify(object),
  }
  const response = await fetch(
    process.env.REACT_APP_APIGetDataForCustomer,
    methodPost,
  )
  const dataRes = await response.json()

  return dataRes
}

// Modal search data. (Chức năng tìm kiếm khách hàng)
function ModalSearchDataCustomer({ dataPro }) {
  const dataG = useContext(AppContext)
  const dataUser = dataG.dataUser
  const [data, setData] = useState([])
  const [cateOption, setCateOption] = useState("")
  const cateRef = useRef("")
  const inputText = useRef("")
  const [isLoading, setIsLoading] = useState(false)

  const handleOptionCate = (e) => {
    setCateOption(e.target.value)
    cateRef.current = e.target.value
  }
  // Get data search
  const fetchDataSearch = async (keyword) => {
    const response = await fetch(
      process.env.REACT_APP_APIGetDataMarketBuyer +
        `?only=${dataUser[0].id}&keyword=${keyword}`,
    )
    const dataRes = await response.json()
    let filterCateData = dataRes
    if (cateRef.current !== "") {
      filterCateData = dataRes.filter((item) => {
        return item.loaisanpham.includes(cateRef.current)
      })
    }
    setIsLoading(true)
    if (filterCateData) {
      setTimeout(() => {
        setIsLoading(false)
        setData(filterCateData)
      }, 100)
    }
  }

  const handleGetValueSearch = (result) => {
    inputText.current = result
    fetchDataSearch(result)
  }
  // Khi edit xong trả về trạng thái thành công
  const handleResult = (result) => {
    if (result) {
      // fetchDataSearch(inputText.current)
      // console.log("oke")
    }
  }

  return (
    <>
      <div className="col-xl-12 d-flex gap-1">
        <div className="col-3">
          <select
            value={cateOption}
            className="form-control rounded-1 fs-14"
            onChange={handleOptionCate}
            style={{ border: "1px solid #e6e6e6" }}
          >
            <option value="">Chọn danh mục</option>
            {optionCate.map((option) => {
              return (
                <option key={option.id} value={option.cate}>
                  {option.cate}
                </option>
              )
            })}
          </select>
        </div>
        <div className="col-9">
          <SearchSimpleNoborder result={handleGetValueSearch} />
        </div>
      </div>
      {/* Kết quả */}
      <div>
        {isLoading ? (
          <LoadingSpinner />
        ) : data.length > 0 ? (
          <CustomerListRight
            data={data}
            dataPro={dataPro}
            result={handleResult}
          />
        ) : (
          <div className="text-center mt-3">Không có kết quả phù hợp.</div>
        )}
      </div>
    </>
  )
}

// Danh sách khách hàng
function CustomerListRight({ data, dataPro, result }) {
  // Xử lý thêm ghi chú
  const [activeModal, setActiveModal] = useState(false)
  const [idPro, setIdPro] = useState(0)
  const handleShowModal = (id_) => {
    setActiveModal(true)
    setIdPro(id_)
  }
  const handleClose = () => {
    setActiveModal(false)
    setIdPro(0)
  }

  // Xử lý edit data.
  const [activeEditModal, setActiveEditModal] = useState(false)
  const [dataEdit, setDataEdit] = useState([])
  const handleShowModalEdit = (id_) => {
    setActiveEditModal(true)
    const filterDataEdit = data.filter((item) => {
      return item.id === id_
    })
    setDataEdit(filterDataEdit)
  }
  const handleEditClose = () => {
    setActiveEditModal(false)
    setDataEdit([])
  }
  // Check edit success.
  const handleEventSuccess = (result_) => {
    result(result_)
    if (result_) {
      setActiveEditModal(false)
      setDataEdit([])
    }
  }

  // Xử lý hiển thị danh sách sản phẩm phù hợp.
  const [showHideModal, setShowHideModal] = useState(false)
  const [dataListPro, setDataListPro] = useState([])

  const handleShowModalListPro = (dataListPro) => {
    setShowHideModal(true)
    setDataListPro(dataListPro)
  }
  const handleListProClose = () => {
    setShowHideModal(false)
    setDataListPro([])
  }

  return (
    <>
      <table className="table card-table table-vcenter text-nowrap mb-0">
        <tbody className="active-tab">
          {data.map((item, index) => {
            // Filter product phù hợp.
            let filterDataPro = []
            if (dataPro) {
              filterDataPro = handleFilterPro(
                dataPro,
                item.tuyenduong,
                item.quanhuyen,
                item.thanhpho,
                item.loaisanpham,
                item.giasanpham,
              )
            }
            return (
              <tr key={Number(item.id) + "-" + index}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="align-items-center">
                      <span className="fs-15 text-muted fw-5">
                        {item.tenlienhe}
                      </span>
                      <p className="mb-0 fs-15">{item.sodienthoai}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="align-items-center">
                    <span className="fs-15 text-muted">Yêu cầu</span>
                    <p className="mb-0 fs-15">
                      <span
                        className={cx(
                          "badge rounded-5",
                          item.loaisanpham.toLowerCase().includes("thuê")
                            ? "bg-warning op-8"
                            : item.loaisanpham.toLowerCase().includes("đầu tư")
                            ? "bg-success op-8"
                            : "bg-primary op-8",
                        )}
                      >
                        {item.loaisanpham}
                      </span>
                    </p>
                  </div>
                </td>
                <td>
                  <div className="align-items-center">
                    <span className="fs-15 text-muted">Phù hợp</span>
                    <p className="mb-0 fs-15">
                      {Number(filterDataPro.length).toLocaleString()}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="align-items-center">
                    <span className="fs-15 text-muted">Khu vực</span>
                    <p className="mb-0 fs-15">
                      {item.tuyenduong ? item.tuyenduong + ", " : ""}{" "}
                      {item.quanhuyen}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="align-items-center">
                    <span className="fs-15 text-muted">Giá</span>
                    <p className="mb-0 fw-semibold fs-15">
                      {handleFormatPrice(Number(item.giasanpham))}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="align-items-center">
                    <span className="fs-15 text-muted">Thời gian</span>
                    <p className="mb-0 fs-15">
                      {moment(item.addtime).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="align-items-center">
                    <span className="fs-15 text-muted">Trạng thái</span>
                    <p className="mb-0 fs-15">
                      {item.tinhtrang === 1 ? <span className="text-success fw-5">Đã giao dịch</span>: <span className="text-warning fw-5">Chờ xử lý</span>}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="hstack gap-1 justify-content-center">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="outline-dark border-1 p-2 py-1" // class mặc định
                        id="dropdown-basic"
                        bsPrefix="custom-dropdown-toggle" // đặt tên cho class fix loại bỏ class dropdown-toggle
                      >
                        <PiDotsThreeOutlineLight />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <button
                          onClick={() => handleShowModal(item.id)}
                          className="dropdown-item d-flex fs-15 border-0"
                        >
                          <PiNotepad className="me-2 fs-18" />
                          Ghi chú
                        </button>
                        <button
                          className="dropdown-item d-flex fs-15 border-0"
                          onClick={() => handleShowModalEdit(item.id)}
                        >
                          <RxPencil2 className="me-2 fs-18" />
                          Sửa thông tin
                        </button>
                        <button
                          className="dropdown-item d-flex fs-15 border-0"
                          onClick={() => handleShowModalListPro(filterDataPro)}
                        >
                          <PiShoppingBagOpenDuotone className="me-2 fs-18" />
                          Sản phẩm phù hợp
                          <span className="badge bg-danger op-8 ms-2">
                            {Number(filterDataPro.length).toLocaleString()}
                          </span>
                        </button>
                      </Dropdown.Menu>
                    </Dropdown>

                    {/* <button
                      className={cx("border-0 fs-19", "option")}
                      style={{ background: "inherit" }}
                    >
                      <HiOutlineTrash />
                    </button> */}
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {/* Modal add note*/}
      {idPro ? (
        <ModalReact
          modalTitle="Lịch trình chăm sóc"
          showModal={activeModal}
          handleClose={handleClose}
          theme={<CustomerDetailInfo id={idPro} data={data} />}
        />
      ) : (
        ""
      )}
      {/* Modal edit data */}
      {dataEdit.length > 0 && (
        <ModalReact
          modalTitle="Sửa thông tin"
          showModal={activeEditModal}
          handleClose={handleEditClose}
          theme={
            <FormEditCustomer
              dataCusEdit={dataEdit}
              result={handleEventSuccess}
            />
          }
        />
      )}
      {/* Modal list product */}
      {dataListPro.length > 0 ? (
        <ModalReact
          modalTitle="Danh sách sản phẩm phù hợp"
          showModal={showHideModal}
          handleClose={handleListProClose}
          theme={<ListSuitableProduct data={dataListPro} />}
        />
      ) : (
        ""
      )}
    </>
  )
}

// Modal detail.
function CustomerDetailInfo({ id, data }) {
  const dataG = useContext(AppContext)
  const dataUser = dataG.dataUser
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const [showInput, setShowInput] = useState("none")
  const [dataNote, setDataNote] = useState([])
  const [loading, setLoading] = useState(true)

  // Filter data customer.
  const filterCus = data.find((item) => item.id === id)

  const fetchData = async () => {
    const response = await fetch(
      process.env.REACT_APP_APIGetDataMarketBuyerNote + `?buyerid=${id}`,
    )
    const dataRes = await response.json()
    const sortData = dataRes.sort((a, b) => {
      let min_ = new Date(a.addtime).getTime()
      let max_ = new Date(b.addtime).getTime()
      return max_ - min_
    })
    setTimeout(() => {
      setDataNote(sortData)
      setLoading(false)
    }, 100)
  }
  useEffect(() => {
    fetchData()
  }, [loading])
  // Bật trường input cho note
  const handleShowInput = () => {
    setShowInput("block")
  }
  // Check khi thêm mới hoàn tất.
  const handleCheckAddSuccess = () => {
    setLoading(true)
  }
  // Submit
  const onSubmit = (data) => {
    data.buyerid = id
    data.author = dataUser[0].id
    // Xử lý thêm mới
    handleAddNoteCustomer(data, reset, handleCheckAddSuccess)
    setShowInput("none")
  }

  // edit note.
  const [valueEdit, setValueEdit] = useState("")
  const [idEdit, setIdEdit] = useState(0)
  const handleGetValueEdit = (e, id_) => {
    setValueEdit(e.target.value)
    setIdEdit(id_)
  }
  const handleEventEditNote = (id_) => {
    // Xử lý sửa ghi chú.
    const objectEdit = {
      id: id_,
      note: valueEdit,
    }
    handleMethodEditData(
      objectEdit,
      () => {},
      process.env.REACT_APP_APIEditDataMarketBuyerNote,
    )
    // setValueEdit("")
    setLoading(true)
  }

  // Xác nhận giao dịch (tình trạng).
  const handleCheckStatus = () => {
    const checkStatus = window.confirm("Khách hàng này đã chốt thành công?")
    if (checkStatus) {
      const object = {
        id: id,
        tinhtrang: 1,
      }
      handleMethodEditData(
        object,
        () => {
          alert("Đã chuyển trạng thái Giao Dịch Thành Công.")
        },
        process.env.REACT_APP_APIEditRowTinhtrangMarketBuyer,
      )
    }
  }

  return (
    <div className="p-1">
      <div className="d-flex mb-3">
        <button
          className="me-2 border-1 rounded-2"
          style={{ border: "1px solid rgba(178, 178, 178, 0.768627451)" }}
          onClick={handleShowInput}
        >
          Thêm ghi chú
        </button>
        {filterCus.tinhtrang === 0 ? (
          <button
            className="me-2 border-1 rounded-2"
            style={{ border: "1px solid rgba(178, 178, 178, 0.768627451)" }}
            onClick={handleCheckStatus}
          >
            Xác nhận giao dịch
          </button>
        ) : (
          <button
            className="badge me-2 border-1 rounded-2 bg-success fs-12"
            style={{ border: "1px solid rgba(178, 178, 178, 0.768627451)" }}
          >
            Giao dịch thành công
          </button>
        )}
      </div>
      <div style={{ display: showInput }} className="mb-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2">
            <input
              type="text"
              {...register("note", { required: true })}
              className="form-control"
            />
            {errors.note && (
              <span className="text-danger fs-12">Bạn cần viết ghi chú.</span>
            )}
          </div>
          <div>
            <button className="badge bg-success border-0 p-2" type="submit">
              Thêm mới
            </button>
          </div>
        </form>
      </div>

      <ul className="list-unstyled mb-0 crm-recent-activity">
        {loading ? <LoadingSpinner /> : ""}
        {!loading && dataNote.length === 0 ? "Chưa có ghi chú" : ""}
        {!loading &&
          dataNote.length > 0 &&
          dataNote.map((item) => {
            return (
              <li className="crm-recent-activity-content" key={item.id}>
                <div className="d-flex align-items-top">
                  <div className="me-3">
                    <span className="avatar avatar-xs bg-warning-transparent avatar-rounded">
                      <GoDotFill className="fs-15" />
                    </span>
                  </div>
                  <div className="crm-timeline-content">
                    <span className="fs-14">
                      {idEdit === item.id && valueEdit !== ""
                        ? valueEdit
                        : item.note}
                    </span>
                    <div className="d-block fs-12 text-muted me-2">
                      <span className="me-1">
                        {moment(item.addtime).locale("vi").startOf().fromNow()}
                      </span>
                    </div>
                  </div>
                  <div className="flex-fill text-end">
                    <span className="d-block text-muted fs-13">
                      {moment(item.addtime).format("DD/MM/YYYY HH:mm")}
                    </span>
                    <span
                      className="me-2 text-muted"
                      style={{ textTransform: "capitalize" }}
                    >
                      {moment(item.addtime).locale("vi").format("dddd")}
                    </span>
                    <Tippy
                      interactive
                      delay={[0, 0]}
                      // placement="top"
                      trigger="click"
                      render={() => {
                        return (
                          <div
                            className="p-3 rounded-3"
                            style={{
                              backgroundColor: "white",
                              borderColor: "var(--default-border)",
                              boxShadow:
                                "0 1rem 1.125rem rgba(169, 169, 169, 0.15)",
                            }}
                          >
                            <label className="fs-14 mb-1">Sửa ghi chú</label>
                            <textarea
                              type="text"
                              className="form-control"
                              rows={5}
                              defaultValue={item.note ? item.note : valueEdit}
                              onChange={(e) => {
                                handleGetValueEdit(e, item.id)
                              }}
                            />
                            <button
                              type="button"
                              className="mt-2 border-0 rounded-2 p-2 px-3"
                              onClick={() => handleEventEditNote(item.id)}
                            >
                              Lưu lại
                            </button>
                          </div>
                        )
                      }}
                    >
                      <button className="border-0 fs-12 rounded-5 px-3">
                        Sửa
                      </button>
                    </Tippy>
                  </div>
                </div>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

// Xử lý hiển thị danh sách sản phẩm phù hợp.
function ListSuitableProduct({ data }) {
  const [dataImagePro, setDataImagePro] = useState([])
  const [getImages, setGetImages] = useState(true)
  const fetchData = async (id_) => {
    const response = await fetch(
      process.env.REACT_APP_APIGetDataMarketSimpleImages + `?only=${id_}`,
    )
    const dataRes = await response.json()
    setDataImagePro((old) => [...old, ...dataRes])
  }

  useEffect(() => {
    let i = 0
    const handleGetImages = () => {
      if (i < data.length) {
        fetchData(data[i].id)
        i++
        handleGetImages()
      } else {
        setTimeout(() => {
          setGetImages(false)
        }, 100)
      }
    }
    handleGetImages()
  }, [])

  return (
    <>
      {getImages ? (
        <LoadingSpinner />
      ) : (
        <ListImageTitle data={data} dataImages={dataImagePro} />
      )}
    </>
  )
}

// Xử lý filter data.
function handleFilterPro(
  dataPro,
  tuyenduong,
  quanhuyen,
  thanhpho,
  loaisanpham,
  giasanpham,
) {
  const filterData = dataPro.filter((item) => {
    let cate = ""
    if (item.cate.toLowerCase().includes("cho thuê nhà")) {
      cate = "thuê nhà riêng"
    } else if (item.cate.toLowerCase().includes("cho thuê căn hộ")) {
      cate = "thuê căn hộ"
    } else if (item.cate.toLowerCase().includes("cho thuê chung cư")) {
      cate = "thuê chung cư"
    } else if (item.cate.toLowerCase().includes("cho thuê phòng trọ")) {
      cate = "thuê phòng trọ"
    } else if (item.cate.toLowerCase().includes("cho thuê mặt bằng")) {
      cate = "thuê mặt bằng"
    } else if (
      item.cate.toLowerCase().includes("bán nhà") ||
      item.cate.toLowerCase().includes("bán căn hộ") ||
      item.cate.toLowerCase().includes("bán chung cư")
    ) {
      cate = "mua nhà"
    } else if (item.cate.toLowerCase().includes("bán đất")) {
      cate = "mua đất"
    }

    if (tuyenduong.length > 0) {
      return (
        item.address.toLowerCase().includes(tuyenduong.toLowerCase()) &&
        item.districts.toLowerCase().includes(quanhuyen.toLowerCase()) &&
        item.city.toLowerCase().includes(thanhpho.toLowerCase()) &&
        cate.includes(loaisanpham.toLowerCase()) &&
        Number(item.price) <= Number(giasanpham) + 50000000
      )
    } else {
      return (
        item.districts.toLowerCase().includes(quanhuyen.toLowerCase()) &&
        item.city.toLowerCase().includes(thanhpho.toLowerCase()) &&
        cate.includes(loaisanpham.toLowerCase()) &&
        Number(item.price) <= Number(giasanpham) + 50000000
      )
    }
  })

  return filterData
}
