import HeaderDashboardPage from "./Header"
import classNames from "classnames/bind"
import styles from "./Dashboard.module.scss"
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import CustomerList from "./Component/Khachhang"
import PageCustomerMobile from "../../mobile/Customer"

const cx = classNames.bind(styles)

function KhachthuePage() {
  return (
    <CheckPCMobile>
      {(isMobile) => (isMobile ? <PageCustomerMobile /> : <PageCustomerPC />)}
    </CheckPCMobile>
  )
}

export default KhachthuePage

function PageCustomerPC() {
  return (
    <>
      <HeaderDashboardPage />
      <div className={cx("bg-page")}>
        <div className={cx("mt-3", "container-fluid p-0")}>
          <CustomerList />
        </div>
      </div>
    </>
  )
}
