import classNames from "classnames/bind";
import LayoutMainMobile from "./LayoutMainMobile";
import styles from "../Mobile.module.scss"
import { Helmet } from "react-helmet";
const cx = classNames.bind(styles);


function PageHomeMobile() {
  return (
    <>
      {/* <Helmet>
        <title>Bất động sản view360</title>
        <meta property="og:title" content="Bất động sản view360"></meta>
        <meta
          name="description"
          content="Kênh thông tin bất động sản như bán nhà, bán đất, cho thuê căn hộ..."
        />
        <meta
          property="og:description"
          content="Kênh thông tin bất động sản như bán nhà, bán đất, cho thuê căn hộ..."
        ></meta>
        <meta property="og:image" content="%PUBLIC_URL%/logo-512.png"></meta>
        <meta property="og:image:width" content="500"></meta>
        <meta property="og:image:height" content="500"></meta>
        <meta property="og:url" content="https://batdongsan.view360.vn"></meta>
      </Helmet> */}
      <div className={cx("mobile_wrapper")}>
        <LayoutMainMobile />
      </div>
    </>
  )
}

export default PageHomeMobile