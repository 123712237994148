import QRCode  from "qrcode.react"

function QRCodeApp({url}) {
    return (
      <div className="bg-tippy-a">
        <h6 className="fs-15 text-center">Quét mã để truy cập trang web</h6>
        <QRCode
          value={url} // là URL hoặc chuỗi bạn muốn mã hóa.
          renderAs="canvas"
          size={256} //  là kích thước của mã QR code.
          bgColor="#ffffff" //  là màu nền của mã QR code.
          fgColor="#000000" // là màu của các điểm trong mã QR code.
          level="H" // là mức độ sửa lỗi (L, M, Q, H).
          includeMargin={true} // là thuộc tính để thêm lề xung quanh mã QR code.
        />
      </div>
    )
}
export default QRCodeApp