
// Hàm tạo mã định danh (dùng để thống kê lượt truy cập với khách vãng lai)
 export const setMaDinhdanh = () => {
    // Thực hiện logic để tạo mã định danh, ví dụ: sử dụng timestamp hoặc UUID
    return 'visitor_' + Date.now(); // Ví dụ: sử dụng timestamp
  };

/** ===== handle load more ===== */
export class loadMoreSystem {
  // Khởi tạo constructor.
  constructor(data, quantity, pages, locationIdTextLoadMore) {
    // out input.
    this.dataInput = data // đầu vào dữ liệu
    this.locationId = locationIdTextLoadMore // Vị trí nút xem thêm
    this.quantity = quantity
    this.pages = pages
    this.newArr = []
    this.totalPage = Math.ceil(Number(this.dataInput.length) / this.quantity)
    this.locationButtonLoadMore = document.getElementById(this.locationId)
  }

  // Giới hạn hiển thị ban đầu.
  handleLimitDataStart() {
    let start = (this.pages - 1) * this.quantity
    let end = (this.pages - 1) * this.quantity + this.quantity
    this.newArr = this.dataInput.slice(start, end)

    if (this.newArr.length === this.dataInput.length) {
      // Ẩn nút load more
      if (this.locationButtonLoadMore) {
        this.locationButtonLoadMore.innerHTML = ""
      }
      
    }
    return this.newArr
  }

  // Hiển thị dữ liệu khi click xem thêm.
  handleLoadMoreData() {
    this.pages = this.pages + 1
    let start = (this.pages - 1) * this.quantity
    let end = (this.pages - 1) * this.quantity + this.quantity
    this.newArr = this.dataInput.slice(start, end)
    console.log(this.pages)
    console.log(this.totalPage)
    if (this.pages > this.totalPage) {
      // Ẩn nút load more
      // this.locationButtonLoadMore.innerHTML = `<div class="py-2 d-flex justify-content-center effectIcon"><i class="fi fi-rr-check-circle me-1 font-21"></i>Bạn đã xem hết rồi.</div>`
      return []
    }
    return this.newArr
  }
}
/** ===== *handle load more ===== */


/** === handle input === */
// Tự động dãn trường input cho phù hợp với nội dung
export function autoResizeInput(inputId, height_) {
  let eventId = document.querySelector("#" + inputId + "")
  eventId.addEventListener("input", function (e) {
    let valueText = eventId.value
    if (valueText.length > height_) {
      eventId.style.height = eventId.scrollHeight + "px"
    } else {
      eventId.style.height = height_ + "px"
    }
  })
}
// Trường hợp gán giá trị (Kiểu edit)
export function autoResizeInputEdit(inputId) {
  let textarea = document.querySelector("#" + inputId + "")

  // Lấy chiều cao của nội dung dựa trên giá trị của textarea
  var contentHeight = textarea.scrollHeight

  // Đặt chiều cao của textarea bằng chiều cao của nội dung
  textarea.style.height = contentHeight + "px"
}
/** === *handle input === */


/** === nút sao chép (button copy) */
export function copyToClipboard(path) {
  const textToCopy = path // Đường dẫn bạn muốn sao chép
  navigator.clipboard
    .writeText(textToCopy)
    .then(() => {
      alert("Đã sao chép đường dẫn thành công!")
    })
    .catch((err) => {
      alert("Lỗi khi sao chép đường dẫn:", err)
    })
}


/** ================================ handle category ================================*/
// Event category active.
export function handleEventActive(classEvent) {
  let class_ = document.querySelectorAll("." + classEvent + "")
  for (let i = 0; i < class_.length; i++) {
    class_[i].addEventListener("click", function (e) {
      let classActive = document.querySelectorAll("." + classEvent + ".active")
      for (let i1 = 0; i1 < classActive.length; i1++) {
        classActive[i1].classList.remove("active")
      }
      class_[i].classList.add("active")
    })
  }
}
// *Event category active.
/** ================================ *handle category ================================*/


/** ======== handle price ======= */
export function handleFormatPriceInput(value) {
    let format = value.replace(/[.,]/g, "")
    let formatPrice = Number(format).toLocaleString().replace(/[.]/g, ",")
    return formatPrice
}

// Xử lý kiểm tra là sô nguyên hay số thập phân (đúng trả về true, sai thì false).
function isInteger(number) {
  return Math.round(number) === number
}

// Format price
export function handleFormatPrice(price_) {
  let price, checkPrice
  if (price_ >= 1000000000) {
    price = price_ / 1000000000
    checkPrice = isInteger(price_ / 1000000000)
    if (checkPrice === true) {
      price = price + " tỷ"
    } else {
      price = price.toFixed(3).replace(/[., ]/g, " tỷ ")
    }
  } else if (price_ >= 1000000 && price_ < 1000000000) {
    price = price_ / 1000000
    checkPrice = isInteger(price_ / 1000000)
    if (checkPrice === true) {
      price = price + " triệu"
    } else {
      price = price.toFixed(1).replace(".", ",") + " triệu"
    }
  } else if (price_ < 1000000) {
    price = price_ / 1000
    checkPrice = isInteger(price_ / 1000)
    if (checkPrice === true) {
      price = price + " nghìn"
    } else {
      price = price.toFixed(1).replace(".", ",") + " nghìn"
    }
  }

  return price
}
/** ======== handle price ======= */


/** ======== handle Number ======= */
// Định dạng số hàng nghìn -> hàng triệu.
export function handleFormatNumberUnit(number) {
  let formatNumber=0

  if (number < 1000) {
    let textNumber = number.toLocaleString()
    formatNumber = textNumber.replace(/[.]/g, ",")
  } else if (number >= 1000 && number < 1000000) {
    let textNumber = Number(number) / 1000
    let checkNumber = isInteger(textNumber)
    if (checkNumber === true) {
      textNumber = textNumber.toFixed() + " N"
    } else {
      textNumber = textNumber.toFixed(1) + " N"
    }
    formatNumber = textNumber.replace(/[.]/g, ",")
  } else {
    let textNumber = Number(number) / 1000000
    let checkNumber = isInteger(textNumber)
    if (checkNumber === true) {
      textNumber = textNumber.toFixed() + " Tr"
    } else {
      textNumber = textNumber.toFixed(1) + " Tr"
    }
    formatNumber = textNumber.replace(/[.]/g, ",")
  }

  return formatNumber
}
/** ======== * handle Number ======= */


/** ======================== handle text ========================= */
/** Giới hạn ký tự tiêu đề */
export function handleLimitTitle(classTitle, limitNumber) {
  let class_ = document.querySelectorAll("." + classTitle + "")
  for (let i = 0; i < class_.length; i++) {
    let titleText = class_[i].innerText
    let maxLength = Number(limitNumber)
    if (titleText.length > maxLength) {
      class_[i].innerText = titleText.substring(0, maxLength) + " ...";
    } else {
      class_[i].innerText = titleText
    }
  }
}
// Giới hạn chiều dài ký tự.
export function handleLimitString(text, limitNumber) {
  let maxLength = Number(limitNumber)
  if (text.length > maxLength) {
   return text.substring(0, maxLength) + " ..."
  } else {
    return text
  }
}

/** Giới hạn ký tự trong react. */
export function TextLimit({ text, number }) {
  if (text.length >= Number(number)) {
    return text.substring(0, Number(number)) + "..."
  } else {
    return text
  }
}

// Xử lý loại bỏ dấu cho đoạn văn bản
export function deleteAccentedText(inputString) {
  // Dùng bảng mã Unicode để thay thế các ký tự có dấu thành không dấu
  const accentMap = {
    À: "A",
    Á: "A",
    Ă: "A",
    Â: "A",
    Ã: "A",
    Ạ: "A",
    Ả: "A",
    Ấ: "A",
    Ầ: "A",
    Ẩ: "A",
    Ẫ: "A",
    Ậ: "A",
    Ắ: "A",
    Ằ: "A",
    Ẳ: "A",
    Ẵ: "A",
    Ặ: "A",
    È: "E",
    É: "E",
    Ê: "E",
    Ẽ: "E",
    Ẹ: "E",
    Ẻ: "E",
    Ế: "E",
    Ề: "E",
    Ể: "E",
    Ễ: "E",
    Ệ: "E",
    Ì: "I",
    Í: "I",
    Ị: "I",
    Ỉ: "I",
    Ĩ: "I",
    Ò: "O",
    Ó: "O",
    Ô: "O",
    Õ: "O",
    Ọ: "O",
    Ỏ: "O",
    Ồ: "O",
    Ố: "O",
    Ổ: "O",
    Ỗ: "O",
    Ộ: "O",
    Ớ: "O",
    Ờ: "O",
    Ở: "O",
    Ỡ: "O",
    Ợ: "O",
    Ù: "U",
    Ú: "U",
    Ụ: "U",
    Ủ: "U",
    Ứ: "U",
    Ừ: "U",
    Ử: "U",
    Ữ: "U",
    Ự: "U",
    Ũ: "U",
    Ỳ: "Y",
    Ý: "Y",
    Ỵ: "Y",
    Ỷ: "Y",
    Ỹ: "Y",
    Đ: "D",
    à: "a",
    á: "a",
    â: "a",
    ã: "a",
    ạ: "a",
    ả: "a",
    ấ: "a",
    ầ: "a",
    ẩ: "a",
    ẫ: "a",
    ậ: "a",
    ắ: "a",
    ằ: "a",
    ẳ: "a",
    ẵ: "a",
    ặ: "a",
    ă: "a",
    è: "e",
    é: "e",
    ê: "e",
    ẽ: "e",
    ẹ: "e",
    ẻ: "e",
    ế: "e",
    ề: "e",
    ể: "e",
    ễ: "e",
    ệ: "e",
    ì: "i",
    í: "i",
    ị: "i",
    ỉ: "i",
    ĩ: "i",
    ò: "o",
    ó: "o",
    ô: "o",
    õ: "o",
    ọ: "o",
    ỏ: "o",
    ố: "o",
    ồ: "o",
    ổ: "o",
    ỗ: "o",
    ộ: "o",
    ớ: "o",
    ờ: "o",
    ở: "o",
    ỡ: "o",
    ợ: "o",
    ù: "u",
    ú: "u",
    ụ: "u",
    ủ: "u",
    ứ: "u",
    ừ: "u",
    ử: "u",
    ữ: "u",
    ũ: "u",
    ự: "u",
    ỳ: "y",
    ý: "y",
    ỷ: "y",
    ỹ: "y",
    ỵ: "y",
    đ: "d",
    Ơ: "O",
    Ư: "U",
    ơ: "o",
    ư: "u",
  }
  // Sử dụng Regular Expression để thay thế ký tự có dấu thành ký tự không dấu
  return inputString.replace(/[^A-Za-z0-9]/g, function (matched) {
    return accentMap[matched] || matched
  })
}
// *Xử lý loại bỏ dấu cho đoạn văn bản


/** ======================== handle text ========================= */












