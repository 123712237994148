import classNames from "classnames/bind"
import { useState, useEffect, useRef, useContext,memo } from "react"
import { Link } from "react-router-dom"
import { IoFilterCircleOutline, IoQrCodeOutline } from "react-icons/io5"
import Tippy from "@tippyjs/react/headless"

import styles from "../Mobile.module.scss"
// import SearchHomeMobile from "../Search/SearchHome/SearchHomeMobile"
import MenuFooter from "../MenuFooter/MenuFooter"
import InfoUserMobile from "../Component/InfoUser/InfoUserMobile"
import { SlideBanner } from "../../../components/Slider"
import {imageLogo } from "../../../assets/images"
import ComponentGetListEstateMobile from "./ListEstateMobile"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import SearchTwoInput from "../../../components/Search/SearchTwoInput"
import IconNotifiMobile from "../Notification/IconNotifiMobile"
import QRCodeApp from "../../../components/QR-Code/QRCode"

const cx = classNames.bind(styles)


function LayoutMainMobile() {
  /** =========== Xử lý logic ================ */
  const { socketio } = useContext(AppContext)


  // Count notification.
  const [notifiCount, setNotifiCount] = useState(0)
  useEffect(() => {
    socketio.on("server-notification", function (data) {
      setNotifiCount(data.length)
    })
    return () => {
      socketio.off("server-notication")
    }
  }, [])

  // Kết quả cho bộ lọc page home.
  const [valueFilter, setValueFilter] = useState({city: "", districts: ""})
  const hanldeValueSearchPageHome = (keywords) => {
    if (keywords) {
      if (keywords.city !== "" && keywords.districts !== "") {
        setValueFilter(keywords)
      } else if (keywords.city === "" && keywords.districts === "") {
        setValueFilter(keywords)
      } else if (keywords.city !== "" && keywords.districts === "") {
        setValueFilter(keywords)
      } else {
        setValueFilter(valueFilter)
      }
    }
  }

  // Hiệu ứng cuộn để ẩn filter page home.
  const [hideFilterPagehome, setHideFilterPageHome] = useState(false)
  const scrollYRef = useRef(0)
  useEffect(() => {
    const handleScrollWin = () => {
      const scrollY_ = window.scrollY;
      if (scrollY_ >= scrollYRef.current) {
        setHideFilterPageHome(true)
        scrollYRef.current = scrollY_
      }
      if (scrollY_ < scrollYRef.current - 200) {
        setHideFilterPageHome(false)
        
      }
    }
    window.addEventListener("scroll", handleScrollWin)
    return () => {
      window.removeEventListener("scroll", handleScrollWin)
    }
  }, [])


  /** =========== * Xử lý logic ================ */

  return (
    <div id="wrapperHomeMobile">
      {/* Header */}
      <div
        className={cx(
          "d-flex align-items-center justify-content-between mb-3",
          hideFilterPagehome ? "hideSearchTwoPageHome" : "headerMobile",
        )}
        style={{ padding: "0px 12px", paddingTop: "9px" }}
      >
        <div>
          <Link to="/">
            <img src={imageLogo.logo} alt="Logo" width={120} height={30} />
          </Link>
        </div>
        <div
          className={cx(
            "col-6 d-flex justify-content-end align-items-center gap-3",
          )}
        >
          {/* qr code */}
          <Tippy
            interactive
            placement="bottom"
            trigger="click"
            render={()=> <QRCodeApp url="https://batdongsan.view360.vn" />}
          >
            <button
              className="p-0 border-0 bg-transparent"
              style={{ color: "#485056" }}
            >
              <IoQrCodeOutline className="fs-25" />
            </button>
          </Tippy>
          {/* filter */}
          <Link to="/filter">
            <IoFilterCircleOutline className="fs-29" />
          </Link>
          <Link to="/notification" className={cx("position-relative")}>
            {/* <IoNotificationsOutline className="fs-29" />
            {notifiCount > 0 ? (
              <span className={cx("countNotifiMobi")}>{notifiCount}</span>
            ) : (
              ""
            )} */}
            <IconNotifiMobile classIcon="fs-29" />
          </Link>

          <InfoUserMobile height={33} width={33} />
        </div>
      </div>

      {/* Search */}
      <div
        className={cx(
          "col-12",
          !hideFilterPagehome
            ? "wrapper_searchHome"
            : "hideSearchTwoPageHome d-flex align-items-center",
          "d-flex align-items-center",
        )}
        style={{ padding: "0px 12px", height: "59px" }}
      >
        {/* <SearchHomeMobile /> */}
        <SearchTwoInput
          result={hanldeValueSearchPageHome}
          placeholder="Quận, Huyện"
          placeholder1="Thành phố"
          className="rounded-1"
        />
      </div>

      {/* nội dung cần cuộn */}
      <div className={cx("app_content")}>
        {/* Slider banner */}
        <div className="mt-3 mb-3">
          <SlideBanner />
        </div>

        {/* List items */}
        <ComponentGetListEstateMobile
          socketio={socketio}
          valueFilter={valueFilter}
        />
      </div>

      {/* MenuFooter */}
      <MenuFooter />
    </div>
  )
}
export default memo(LayoutMainMobile)
