import { useEffect, useLayoutEffect, useState, useContext, useRef } from "react"
import { Helmet } from "react-helmet"
import moment from "moment"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import classNames from "classnames/bind"
import { SlHeart } from "react-icons/sl"
import { FaHeart } from "react-icons/fa6"
import { PiCertificateLight, PiHandshakeLight, PiKeyholeLight, PiMegaphoneLight, PiSealCheckFill, PiUserCircleGearLight } from "react-icons/pi"

import {
  TextLimit,
  handleFormatPrice,
  deleteAccentedText,
} from "../../../handle-reuses/reuses"
import { SliderImageList } from "../../../components/Slider"
import { images } from "../../../assets/images"
import styles from "../Mobile.module.scss"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import {
  handleMethodCreateData,
  handleMethodEditData,
} from "../../../handle-reuses/reuses/method"
import ListCategory from "../../pc/Home/ListCategory"
import InfoAuthorPageHome from "./InfoAuthorPageHome"
import LikeCopyEstate from "./LikeCopy"
import ModalReact from "../../../components/Modal/ModalReact"
import DetailEstate from "../../../components/DetailEstate/DetailEstate"
import ListEstateForUser from "./ListEstateForUser"

const cx = classNames.bind(styles)



// Component xử lý get data và truyền data (xuống dưới).
function ComponentGetListEstateMobile({socketio, valueFilter}) {
  const [dataEstate, setDataEstate] = useState([])
  const limit = 6
  const page = useRef(1)
  const isLoading = useRef(false)
  const containerDiv = useRef(null)
  const loadData = useRef(false)
  const dataGetSimpleLikeDislike = useRef([])

  /** ============== search for page home. ====================*/
  const handleGetDataFilterCityDistricts = (limit_, page_) => {
    const paramsFilter = {
      iden: process.env.REACT_APP_IDENTIFICATION,
      object: {
        city: valueFilter.city,
        districts: valueFilter.districts,
        limit: limit_,
        offset: (page_ - 1) * limit_,
      },
    }
    socketio.emit("client-filter-city-districts-page-home", paramsFilter)
  }


  useEffect(() => {
    if (valueFilter.city !== "" && valueFilter.districts !== "") {
      handleGetDataFilterCityDistricts(limit, page.current)
    }
    return () => {
      socketio.off("client-filter-city-districts-page-home")
    }
  }, [valueFilter])
  /** ============== *search for page home. ====================*/

  // Quản lý Gọi dữ liệu (khi city, districts rỗng)
  const handleGetDataEstate = (limit_, page_) => {
    // gọi dữ liệu
    const params = {
      iden: process.env.REACT_APP_IDENTIFICATION,
      object: {
        limit: limit_,
        offset: (page_ - 1) * limit_,
      },
    }
    socketio.emit("client-estate-page-home", params)
  }

  // Get data start (hiển thị dữ liệu ban đầu)
  useEffect(() => {
    if (valueFilter.city === "" && valueFilter.districts === "") {
      handleGetDataEstate(limit, 1)
    }
    return () => {
      socketio.off("client-estate-page-home")
    }
  }, [valueFilter])

  // Quản lý nhận dữ liệu.
  useEffect(() => {
    if (valueFilter.city !== "" && valueFilter.districts !== "") {
      // set về giá trị ban đầu
      page.current = 1
      setDataEstate([])
      // Danh cho filter city districts
      socketio.on("server-filter-city-districts-page-home", (data) => {
        const sortData = data.sort((a, b) => {
          let min_ = a.admoney
          let max_ = b.admoney
          let minTime_ = new Date(a.update_time).getTime()
          let maxTime_ = new Date(b.update_time).getTime()
          return maxTime_ - minTime_ //&& max_ - min_
        });
        setDataEstate((old) => [...old, ...sortData])
        dataGetSimpleLikeDislike.current = sortData
        loadData.current = true // xác định đã tải dữ liệu xong
        if (limit > data.length) {
          // Đã tải hết dữ liệu
          isLoading.current = true
        } else {
          isLoading.current = false
        }
      })
    } else {
      // set về giá trị ban đầu
      page.current = 1
      setDataEstate([])
      // Khi city, districts có giá trị rỗng
      socketio.on("server-estate-page-home", (data) => {
        const sortData = data.sort((a, b) => {
          let min_ = a.admoney
          let max_ = b.admoney
          let minTime_ = new Date(a.update_time).getTime()
          let maxTime_ = new Date(b.update_time).getTime()
          return maxTime_ - minTime_ //&& max_ - min_
        })
        setDataEstate((old) => [...old, ...sortData])
        loadData.current = true // xác định đã tải dữ liệu xong
        // Data danh cho get simple like, dislike.
        dataGetSimpleLikeDislike.current = sortData
        if (limit > data.length) {
          // Đã tải hết dữ liệu
          isLoading.current = true
        } else {
          isLoading.current = false
        }
      })
    }
    return () => {
      socketio.off("server-filter-city-districts-page-home")
      socketio.off("server-estate-page-home")
    }
  }, [valueFilter])
  

  // Quản lý sự kiện scroll.
  useEffect(() => {
    // Lấy thông tin khối div cần scroll.
    const myDiv = containerDiv.current
    // hàm xử lý sự kiện.
    const handleScroll = () => {
      // Lấy tổng chiều cao của myDiv.
      const totalHeight = myDiv.scrollHeight //console.log("total: " + totalHeight)
      // Lấy tổng chiều cao của window.
      const windowHeight_ = window.innerHeight //console.log("set: " + windowHeight_)
      // Lấy chiều cao của scroll của window.
      const scrollY_ = window.scrollY //console.log("scroll: " + scrollY_)
      if (
        windowHeight_ + scrollY_ + 100 > totalHeight &&
        loadData.current &&
        !isLoading.current
      ) {
        page.current = page.current + 1
        setTimeout(() => {
          if (valueFilter.city === "" && valueFilter.districts === "") {
            // Cuộn theo dữ liệu ban đầu
            handleGetDataEstate(limit, page.current)
          } else {
            // Cuộn theo dữ liệu filter
            handleGetDataFilterCityDistricts(limit, page.current)
          }
        }, 500)
        loadData.current = false // xác định đã tải thêm dữ liệu
      }
    }
    // Điều kiện khối div đã được render thì event mới được tiến hành.
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [valueFilter])

  // Quản lý hiển thị theo danh mục.
  const handleCateShowHome = (nameCate) => {
    let cateInput = nameCate.toLowerCase()
    if (cateInput === "home") {
      setDataEstate([])
      isLoading.current = false
      page.current = 1

      // Kiểm tra điều kiện filter có tồn tại không.
      if (valueFilter.city !== "" && valueFilter.districts !== "") {
        handleGetDataFilterCityDistricts(limit, page.current)
      } else {
        // Get lại dữ liệu
        handleGetDataEstate(limit, page.current)
      }
    } else {
      socketio.emit("client-get-data-cate", cateInput)
      isLoading.current = true
    }
  }
  // Quản lý Nhận data theo danh mục
  useEffect(() => {
    socketio.on("server-get-data-cate", (data) => {
      const sortData = data.sort((a, b) => {
        let min_ = a.admoney
        let max_ = b.admoney
        let minTime_ = new Date(a.update_time).getTime()
        let maxTime_ = new Date(b.update_time).getTime()
        return maxTime_ - minTime_ //&& max_ - min_
      })
      // filter data theo dữ liệu của filter header.
      const filterData = sortData.filter((item) => {
        return (
          item.city.toLowerCase().includes(valueFilter.city.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(valueFilter.districts.toLowerCase())
        )
      })
      if (valueFilter.city !== "" && valueFilter.districts !== "") {
        setDataEstate(filterData)
        dataGetSimpleLikeDislike.current = filterData
      } else {
        setDataEstate(sortData)
        dataGetSimpleLikeDislike.current = sortData
      }
    })
    return () => {
      socketio.off("server-get-data-cate")
      socketio.off("client-get-data-cate")
    }
  }, [valueFilter])

  /** ============== search for page home. ====================*/


  return (
    <div className={cx("containerItem", "row gap-3")} ref={containerDiv}>
      {/* button search */}
      {/* {buttonSearch ? (
        <div className={cx("buttonSearchPageHome")}>
          <button
            className="border-0 rounded-5 px-3"
            onClick={handleButtonSearch}
          >
            Tìm kiếm
          </button>
        </div>
      ) : (
        ""
      )} */}
      {/* Danh mục sản phẩm */}
      <div className={cx("listCateHomeMobile", "mb-3")}>
        <ListCategory result={handleCateShowHome} />
      </div>
      {/* List sản phẩm */}
      {dataEstate.length > 0 && (
        <ListEstateMobile
          dataEstate={dataEstate}
          socketio={socketio}
          dataGetSimpleLike={dataGetSimpleLikeDislike.current}
        />
      )}
      {/* Tạo hiệu ứng loading... */}
      <div style={{ marginBottom: "100px" }}>
        {!isLoading.current && <div className="text-center">Đang tải...</div>}
        {isLoading.current && dataEstate.length > 0 && (
          <div className="text-center mt-1 mb-2">Bạn đã xem hết rồi.</div>
        )}
        {dataEstate.length === 0 && isLoading.current && (
          <div className="text-center mt-3">Chưa có sản phẩm.</div>
        )}
      </div>
    </div>
  )
}
export default ComponentGetListEstateMobile



// Component hiển thị danh sách sản phẩm
function ListEstateMobile({ dataEstate, socketio, dataGetSimpleLike }) {
  const { dataUser } = useContext(AppContext)
  // Xử lý lưu lại sản phẩm cho khách hàng.
  const [dataSaved, setDataSaved] = useState([])
  const simpleSavedId = useRef()
  const handleEventSaved = (simpleId) => {
    if (dataUser.length > 0) {
      // kiểm tra xem tồn tại đã từng lưu chưa.
      const params = { simpleid: simpleId, userid: dataUser[0].id }
      socketio.emit("client-simple-saved", params)
      simpleSavedId.current = simpleId
    } else {
      alert("Bạn cần đăng nhập trước khi lưu")
    }
  }
  useEffect(() => {
    socketio.on("server-simple-saved", (dataSaved_) => {
      handleCreateEditSaved(
        dataSaved_,
        simpleSavedId.current,
        dataUser,
        handleUpdateDataSaved,
      )
    })
    return () => {
      socketio.off("server-simple-saved")
      socketio.off("client-simple-saved")
    }
  }, [])

  // Xác định user đã lưu sản phẩm hay chưa.
  const handleUpdateDataSaved = () => {
    socketio.emit("client-saved", dataUser[0].id)
    socketio.on("server-saved", (dataSimpleSaved) => {
      setDataSaved(dataSimpleSaved)
    })
  }
  useEffect(() => {
    // Chạy lần đầu
    if (dataUser.length > 0) {
      handleUpdateDataSaved()
    }
    return () => {
      socketio.off("client-saved")
      socketio.off("server-saved")
    }
  }, [])

  // count view khi bấm chuột phải.
  const checkEvent = useRef(0)
  const idSimpleOld = useRef(0)
  const handleCountview = (simpleId) => {
    // setCheckEvent(simpleId)
    checkEvent.current = simpleId
    socketio.emit("client-detail-product", simpleId)
  }
  useEffect(() => {
    // Tăng view khi click.

    socketio.on("server-detail-product", (dataUpdate) => {
      if (checkEvent.current !== 0) {
        const params = {
          id: dataUpdate[0].id,
          countview: Number(dataUpdate[0].countview) + 1,
        }
        if (checkEvent.current !== idSimpleOld.current) {
          socketio.emit("client-click-views", params)
        }
        // Xác nhận id này vừa mới tăng view rồi.
        idSimpleOld.current = checkEvent.current
      }
    })

    return () => {
      socketio.off("client-detail-product")
      socketio.off("server-detail-product")
      socketio.off("client-click-views")
    }
  }, [checkEvent.current])

  // Modal detai estate.
  const [modalDetaiEstate, setModalDetailEstate] = useState(false)
  const dataDetailEstate = useRef([])
  const totalLikeDetail = useRef(0)
  const likeDislikeDetail = useRef([])
  const handleShowDetailEstateHome = (simpleid, totalLike, likeDislike) => {
    setModalDetailEstate(true)
    let filterData = dataEstate.filter((item) => item.id === simpleid)
    dataDetailEstate.current = filterData
    totalLikeDetail.current = totalLike
    likeDislikeDetail.current = likeDislike
  }
  const handleCloseDetailEstateHome = () => {
    setModalDetailEstate(false)
    dataDetailEstate.current = []
    totalLikeDetail.current = 0
    likeDislikeDetail.current = []
  }

  // Xử lý create, edit simple like.
  const [dataSimpleLike, setDataSimpleLike] = useState([])
  const arrSimpleLike = useRef([])
  const [checkLikeDislike, setCheckLikeDislike] = useState([])
  const [checkChangeLikeDislike, setCheckChangeLikeDislike] = useState([])

  const handleGetDataSimpleLike = () => {
    for (let i = 0; i < dataGetSimpleLike.length; i++) {
      const params = {
        iden: process.env.REACT_APP_IDENTIFICATION,
        simpleid: dataGetSimpleLike[i].id,
      }
      socketio.emit("client-get-simple-like", params)
    }
  }
  useLayoutEffect(() => {
    handleGetDataSimpleLike()
    return () => {
      socketio.off("client-get-simple-like")
    }
  }, [dataGetSimpleLike])
  // Quản lý nhận dữ liệu data simple like
  useLayoutEffect(() => {
    socketio.on("server-get-simple-like", (data) => {
      if (data.length > 0) {
        // mảng duy trì cho sự thay đổi nếu có edit.
        let filter = arrSimpleLike.current.filter(
          (item) => item.simpleid === data[0].simpleid,
        )
        if (filter.length === 0) {
          arrSimpleLike.current.push(data[0])
        } else {
          for (let i = 0; i < arrSimpleLike.current.length; i++) {
            if (arrSimpleLike.current[i].simpleid === data[0].simpleid) {
              arrSimpleLike.current[i] = data[0]
            }
          }
        }
      }
      setDataSimpleLike((old) => [...old, ...data])
    })
    return () => {
      socketio.off("server-get-simple-like")
    }
  }, [dataEstate])
  // Nhận check like, dislike để xử lý dữ liệu khi edit or create.
  useLayoutEffect(() => {
    socketio.on("server-check-data-simple-like", (objectSimpleLike) => {
      // console.log(objectSimpleLike)
      setCheckChangeLikeDislike([...objectSimpleLike.data])
      if (objectSimpleLike.data.length > 0) {
        const editParams = {
          iden: process.env.REACT_APP_IDENTIFICATION,
          simpleid: objectSimpleLike.simpleid,
          object: {
            id: objectSimpleLike.data[0].id,
            quantity: objectSimpleLike.data[0].quantity === 0 ? 1 : 0,
          },
        }

        // Xử lý edit
        socketio.emit("client-edit-quantity-simple-like", editParams)
        // Cập nhật lại biểu tượng like or dislike.
        socketio.emit("client-result-for-like-dislike", {
          userid: dataUser[0].id,
        })
      } else {
        // Thêm mới
        const createParams = {
          iden: process.env.REACT_APP_IDENTIFICATION,
          object: {
            simpleid: objectSimpleLike.simpleid,
            quantity: 1,
            userid: dataUser[0].id,
          },
        }
        socketio.emit("client-add-simple-like", createParams)
        // Cập nhật lại biểu tượng like or dislike.
        socketio.emit("client-result-for-like-dislike", {
          userid: dataUser[0].id,
        })
      }
    })
    return () => {
      socketio.off("server-check-data-simple-like")
      socketio.off("client-add-simple-like")
      socketio.off("client-edit-status-simple-like")
      socketio.off("client-result-for-like-dislike")
    }
  }, [checkChangeLikeDislike])
  // cập nhật data mới nhất khi edit, create thành công.
  useLayoutEffect(() => {
    // Nhận edit
    socketio.on("server-edit-quantity-simple-like", (dataUpdateEdit) => {
      // Cập nhật vào mảng ban đầu.
      let newArrSimpleLike = [...arrSimpleLike.current]
      for (let i = 0; i < newArrSimpleLike.length; i++) {
        if (newArrSimpleLike[i].simpleid === dataUpdateEdit[0].simpleid) {
          newArrSimpleLike[i] = dataUpdateEdit[0]
        } else {
          let filterNewArr = newArrSimpleLike.filter(
            (item) => item.simpleid === dataUpdateEdit[0].simpleid,
          )
          if (filterNewArr.length === 0) {
            newArrSimpleLike.push(dataUpdateEdit[0])
          }
        }
      }
      setDataSimpleLike(newArrSimpleLike)
    })
    // Nhận create.
    socketio.on("server-add-simple-like", (dataCreate) => {
      let newArrSimpleLike = [...arrSimpleLike.current]
      for (let i = 0; i < newArrSimpleLike.length; i++) {
        if (newArrSimpleLike[i].simpleid === dataCreate[0].simpleid) {
          newArrSimpleLike[i] = dataCreate[0]
        } else {
          let filterNewArr = newArrSimpleLike.filter(
            (item) => item.simpleid === dataCreate[0].simpleid,
          )
          if (filterNewArr.length === 0) {
            newArrSimpleLike.push(dataCreate[0])
          }
        }
      }
      setDataSimpleLike(newArrSimpleLike)
    })
    return () => {
      socketio.off("server-edit-quantity-simple-like")
      socketio.off("server-add-simple-like")
    }
  }, [checkChangeLikeDislike, checkLikeDislike])

  // Trang thái ban đầu like, dislike của từng user
  useLayoutEffect(() => {
    socketio.emit("client-result-for-like-dislike", {
      userid: dataUser.length > 0 ? dataUser[0].id : 0,
    })
    return () => {
      socketio.off("client-result-for-like-dislike")
    }
  }, [])

  // Nhận dữ liệu với sự thay đổi của từng user cho like và dislike.
  useLayoutEffect(() => {
    socketio.on("server-result-for-like-dislike", (data) => {
      
      // let filterData = data.filter((item)=>item.userid === )
      let newData = [...data]
      setCheckLikeDislike(newData)
    })
    return () => {
      socketio.off("server-result-for-like-dislike")
    }
  }, [dataSimpleLike.current])

  // Hiện thị full phone.
  // const [fullPhone, setFullPhone] = useState(0)
  // const handleShowFullPhone = (simpleid) => {
  //   setFullPhone(simpleid)
  // }
  // Hiển thị hết nội dung sản phẩm.
  const [fullDiscription, setFullDiscription] = useState(0)
  const handleShowDiscription = (simpleid) => {
    setFullDiscription(simpleid)
  }

  // Click xem danh sách sản phẩm của từng user tại trang home.
  const [modalListEstateUser, setModalListEstateUser] = useState(false)
  const useridEstate = useRef(0)
  const dataInfoEstate = useRef([])
  const handleModalListEstateUser = (userid, dataInfo) => {
    setModalListEstateUser(true)
    useridEstate.current = userid
    dataInfoEstate.current = [dataInfo]
  }
  const handleCloseModalListEstateUser = () => {
    setModalListEstateUser(false)
    useridEstate.current = 0
    dataInfoEstate.current = []
  }
  return (
    <>
      {/* Thêm thuộc tính vào <head> */}
      <Helmet>
        <title>Bất động sản view360</title>
        <meta
          name="description"
          content="Bất động sản view360 là trang website chuyên về bất động sản toàn quốc, cung cấp giải pháp chuyển đổi số như cho thuê chung cư, cho thuê căn hộ, bán nhà mặt phố hay bán nhà trong ngõ"
        />
        <meta
          name="keywords"
          content="Bất động sản, bất động sản view360, nhà đất,bán nhà phố,bán nhà trong ngõ, bán nhà dự án, bán chung cư, bán căn hộ, bán dự án hoàng huy, bán dự án vinhome, thuê nhà trọ, thuê phòng trọ sinh viên "
        />
      </Helmet>

      {/* Nội dung page home */}
      {dataEstate.map((item, index) => {
        let listImages = []
        if (item.images !== null) {
          listImages = item.images.split(",")
        }
        let filterSaved = dataSaved
        if (filterSaved.length > 0) {
          filterSaved = dataSaved.filter((item_) => {
            return item_.simpleid === item.id
          })
        }
        // format url detail.
        const formatCateUrl = deleteAccentedText(item.cate).toLowerCase()
        const cate = formatCateUrl.replace(/[ ]/g, "-")
        const formatAddress = deleteAccentedText(item.address).toLowerCase()
        const address = formatAddress.replace(/[ ]/g, "-")

        // count like.
        let arrLike,
          totalLike = 0
        if (dataSimpleLike.length > 0) {
          arrLike = dataSimpleLike.find(
            (item_) => item_.simpleid === item.id,
          )
          if (arrLike) {
            totalLike = arrLike.total_like
            //console.log("simpleid " + arrLike.simpleid + ":" + arrLike.total_like)
          }
        }
        // check trang thái like dislike của từng user.
        let likeDislike
        if (checkLikeDislike.length > 0) {
          likeDislike = checkLikeDislike.find(
            (item_) => item_.simpleid === item.id,
          )
        }

        // Xử lý đường dẫn cho copy path.
        let pathEstate =
          "https://batdongsan.view360.vn/" +
          cate +
          "-" +
          address +
          "/" +
          item.alias +
          "-pr" +
          item.id

        return (
          <div
            className="col-xl-3 col-sm-12 col-md-12 mb-1"
            key={item.id + "-" + index}
            onTouchStart={() => handleCountview(item.id)}
            // onMouseEnter={() => handleCountview(item.id)}
          >
            <div className="card border-0 mb-1">
              {/* Info author */}
              <div
                onClick={() =>
                  handleModalListEstateUser(item.userid, {
                    hoten: item.hoten,
                    reputation: item.reputation,
                    connect: item.online_offline,
                    avatar_author: item.avatar_author,
                    phone: item.phone,
                    customer_name: item.customer_name,
                    imageEstate:
                      listImages.length > 0 ? "/" + listImages[0] : images.user,
                    chuyenmon: item.chuyenmon,
                  })
                }
                className="position-relative"
              >
                <InfoAuthorPageHome
                  dataEstate={item}
                  nameAuthor={
                    item.customer_name ? item.customer_name : item.hoten
                  }
                  avatarAuthor={
                    item.customer_name
                      ? "/" + listImages[0]
                      : item.avatar_author
                      ? "/" + item.avatar_author
                      : "/" + listImages[0]
                  }
                  onlineOffline={item.online_offline}
                  updateTime={moment(item.update_time)
                    .locale("vi")
                    .fromNow()
                    .replace("một", "1")}
                  checkTick={item.reputation === 1 ? true : false}
                  checkMoney={item.admoney}
                />
              </div>
              {/* *Info author */}

              {/* Nội dung sản phẩm */}
              <div className="card-body p-0 mb-3">
                <div className="d-flex justify-content-between">
                  <h6 className="card-title fw-6 mb-1 titleAddress">
                    <TextLimit
                      text={
                        item.address + " " + item.districts + " " + item.city
                      }
                      number={68}
                    />
                  </h6>
                  {/* <div className="d-flex align-items-center">
                            <Star
                              style={{ width: "15px", height: "15px" }}
                              className="me-1"
                            />
                            689
                          </div> */}
                </div>
                <p className="card-text mb-1 text-muted limitDiscription">
                  {fullDiscription === item.id ? (
                    item.title
                  ) : (
                    <TextLimit text={item.title} number={50} />
                  )}
                  {/* Đã có{" "}
                    {Number(item.countview)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}{" "}
                    lượt tham khảo sản phẩm{" "}
                    {item.countclick > 20 ? (
                      <span>{item.countclick} lượt tương tác</span>
                    ) : (
                        <span>
                          cập nhật mới nhất{" "}
                          {moment(item.update_time).format("DD/MM/YYYY")}
                        </span>
                    )} */}
                  <button
                    className="border-0 bg-transparent p-0 fw-5 ms-1 text-muted"
                    onClick={() => handleShowDiscription(item.id)}
                    style={{
                      display: fullDiscription === item.id ? "none" : "",
                    }}
                  >
                    Xem thêm
                  </button>
                </p>

                <p className="card-text mb-0">
                  <span className="fw-5 me-1 fs-16">
                    Giá:{" "}
                    {Number(item.price) > 0
                      ? handleFormatPrice(Number(item.price))
                      : "Liên hệ"}
                  </span>
                  <span>
                    {item.donvi === "Thỏa thuận" ||
                    item.donvi.toLowerCase() === ""
                      ? ""
                      : "/ " + item.donvi.toLowerCase()}
                  </span>
                </p>
              </div>
              {/* *Nội dung sản phẩm */}

              {/* ảnh sản phẩm */}
              <div style={{ height: "283px", borderRadius: "15px" }}>
                <div className="position-absolute zIndex-9 col-12 p-3">
                  <div className="d-flex justify-content-between">
                    <div
                      className={cx("fs-15 fw-5", "estateCate")}
                      style={{ maxHeight: "35px" }}
                    >
                      {item.cate}
                    </div>
                    <div className="d-flex flex-direction-column align-items-center">
                      <button
                        className={cx("buttonSave")}
                        onClick={() => handleEventSaved(item.id)}
                      >
                        {filterSaved.length > 0 ? (
                          filterSaved[0].status === 1 ? (
                            <FaHeart className="fs-21" />
                          ) : (
                            <SlHeart className="fs-21" />
                          )
                        ) : (
                          <SlHeart className="fs-21" />
                        )}
                      </button>
                      {item.view360 ? (
                        <img src={images.view360gif} alt="view360" width={36} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {/* list image */}
                <div className="sliderImageMobile">
                  {listImages.length > 0 ? (
                    <SliderImageList data={listImages} />
                  ) : (
                    <img
                      src={images.noImagePro}
                      className="d-block w-100"
                      alt="..."
                      height={283}
                      style={{ borderRadius: "15px" }}
                    />
                  )}
                </div>
                {/* *list image */}
              </div>
              {/* *ảnh sản phẩm */}

              {/* Nút chi tiết bài viết */}
              <div
                className={cx(
                  "wrapper_button_detail_estate",
                  "p-2 rounded-2 mt-2",
                )}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span
                    className="col-8 fw-5 fs-16"
                    style={{ textTransform: "uppercase" }}
                  >
                    <TextLimit
                      text={
                        item.cate +
                        " " +
                        item.form_cate.toLowerCase() +
                        " " +
                        item.address.toLowerCase() +
                        ", " +
                        item.districts.toLowerCase() +
                        ", " +
                        item.city.toLowerCase()
                      }
                      number={39}
                    />
                    {/* <p className="mb-0">
                      <TextLimit
                        text={item.customer_phone}
                        number={fullPhone === item.id ? 15 : 6}
                      />
                      {fullPhone === item.id ? (
                        ""
                      ) : (
                        <button
                          className="border-0 bg-transparent text-black p-0"
                          onClick={() => handleShowFullPhone(item.id)}
                          style={{height: "27px"}}
                        >
                          <BsEyeSlash className="fs-21" />
                        </button>
                      )}
                    </p> */}
                  </span>
                  <button
                    className="border-0 rounded-2 px-2 col-3"
                    onClick={() =>
                      handleShowDetailEstateHome(
                        item.id,
                        totalLike,
                        likeDislike,
                      )
                    }
                  >
                    Chi tiết
                  </button>
                </div>
              </div>
              {/* Chức năng like copy */}
              <LikeCopyEstate
                dataEstate={item}
                totalLike={totalLike}
                likeDislike={likeDislike}
                path={pathEstate}
                modalDetaiEstate={modalDetaiEstate}
              />
              {/* *Chức năng like copy */}
            </div>
          </div>
        )
      })}
      {/* Hiệu ứng lưu sản phẩm */}
      <ToastContainer />
      {/* modal detail estate */}
      {dataDetailEstate.current.length > 0 && (
        <ModalReact
          modalTitle="Chi tiết sản phẩm"
          showModal={modalDetaiEstate}
          handleClose={handleCloseDetailEstateHome}
          theme={<DetailEstate dataEstate={dataDetailEstate.current} />}
        />
      )}
      {/* Modal list estate user */}
      {useridEstate.current !== 0 && (
        <ModalReact
          modalTitle={
            <InfoAuthorListEstate dataInfo={dataInfoEstate.current} />
          }
          showModal={modalListEstateUser}
          handleClose={handleCloseModalListEstateUser}
          theme={<ListEstateForUser userid={useridEstate.current} />}
        />
      )}
    </>
  )
}

// Thông tin user.
function InfoAuthorListEstate({ dataInfo }) {

  return (
    <div className="d-flex gap-2 align-items-center">
      <span
        className={
          dataInfo[0].connect === 1
            ? "avatar avatar-lg avatar-rounded online"
            : "avatar avatar-lg avatar-rounded"
        }
      >
        <LazyLoadImage
          effect="blur"
          src={
            dataInfo[0].avatar_author
              ? "/" + dataInfo[0].avatar_author
              : dataInfo[0].imageEstate
          }
          width="3rem"
          height="3rem"
        />
      </span>

      <div>
        <h6 className="mb-1">
          {dataInfo[0].hoten}{" "}
          {dataInfo[0].reputation === 1 && (
            <PiSealCheckFill className="text-reputation" />
          )}
        </h6>
        <p className="mb-0 text-muted fs-15 fw-4">
          <span className="text-muted d-flex align-items-center">
            {dataInfo[0].chuyenmon === "Đơn vị truyền thông" && (
              <PiMegaphoneLight className="me-1 fs-21" />
            )}
            {dataInfo[0].chuyenmon === "Đầu tư" && (
              <PiKeyholeLight className="me-1 fs-21" />
            )}
            {dataInfo[0].chuyenmon === "Cộng tác viên" && (
              <PiHandshakeLight className="me-1 fs-21" />
            )}
            {dataInfo[0].chuyenmon === "Môi giới" && (
              <PiCertificateLight className="me-1 fs-21" />
            )}
            {dataInfo[0].chuyenmon === "Cá nhân" && (
              <PiUserCircleGearLight className="me-1 fs-21" />
            )}
            {dataInfo[0].chuyenmon}
          </span>
          {/* Danh sách của <span className="text-info">{dataInfo[0].hoten}</span> */}
        </p>
      </div>
    </div>
  )
}


// Xử lý create edit saved
function handleCreateEditSaved(
  dataSaved_,
  simpleId,
  dataUser,
  handleUpdateDataSaved,
) {
  if (dataSaved_.length === 0) {
    // thêm mới
    const object = {
      simpleid: simpleId,
      status: 1,
      author: dataUser[0].id,
    }
    handleMethodCreateData(
      object,
      () => {
        alert("Bạn đã lưu thành công. Vào trang quản lý để xem.")
        handleUpdateDataSaved()
      },
      process.env.REACT_APP_APICreateMarketSimpleSaved,
    )
  } else {
    // Hủy lưu
    let object, alertSeved
    if (dataSaved_[0].status === 0) {
      object = {
        id: dataSaved_[0].id,
        status: 1,
      }
      alertSeved = "Bạn đã lưu thành công."
      handleMethodEditData(
        object,
        () => {
          toast.success("Lưu thành công", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            progress: undefined,
            theme: "light",
          })
          handleUpdateDataSaved()
        },
        process.env.REACT_APP_APIEditStatusMarketSimpleSaved,
      )
    } else {
      object = {
        id: dataSaved_[0].id,
        status: 0,
      }
      alertSeved = "Bạn đã HỦY thành công."
      handleMethodEditData(
        object,
        () => {
          toast.error("Đã hủy", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            progress: undefined,
            theme: "light",
          })
          handleUpdateDataSaved()
        },
        process.env.REACT_APP_APIEditStatusMarketSimpleSaved,
      )
    }
  }
}