import { useLayoutEffect, useRef, useState, useEffect, useContext } from "react"
// import io from "socket.io-client"
import { useNavigate } from "react-router-dom"
import classNames from "classnames/bind"
import moment from "moment"
import { BsDot } from "react-icons/bs"
import { FaRegEyeSlash } from "react-icons/fa"
import { MdOutlineElectricalServices } from "react-icons/md"
import { BsWater } from "react-icons/bs"
import { IoWarningOutline } from "react-icons/io5"
import { PiHandshakeLight } from "react-icons/pi"
import { BsCreditCard2Back } from "react-icons/bs"
import { TbAirConditioning } from "react-icons/tb"
import { MdHotTub } from "react-icons/md"
import { FaHeart, FaRegHeart } from "react-icons/fa6"
import { MdCancel } from "react-icons/md";

import styles from "./detail.module.scss"
import { SliderImageList } from "../../../components/Slider"
import { handleLimitString, handleFormatPrice, TextLimit, deleteAccentedText } from "../../../handle-reuses/reuses"
import { images } from "../../../assets/images"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import SideBarHome from "../Home/SidebarHome"
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import { Helmet } from "react-helmet"

const cx = classNames.bind(styles)

function DetailEstate({ data }) {
  const { dataUser, socketio } = useContext(AppContext)
  const estate = data.find((item) => {
    return item
  });
  let listImages = data[0].images
  if (listImages) {
    listImages = listImages.split(",")
  } else {
    listImages = [images.noImagePro]
  }

  // lựa chọn loại hình xem.
  const [optionView, setOptionView] = useState("listImages")
  const handleOptionImagesView360 = (key) => {
    setOptionView(key)
  }
  // Quản lý get author cho product.
  useEffect(() => {
    socketio.emit("client-userid", estate.userid)
    return () => {
      socketio.off("client-userid")
    }
  }, [])
  // author tương ứng
  const [author, setAuthor] = useState([])
  useEffect(() => {
    // Lấy author ban đầu
    socketio.on("server-userid", (dataAuthor) => {
      if (dataAuthor.length > 0) {
        setAuthor(dataAuthor)
      }
    })

    // Xác định online-offline
    socketio.on("server-online-offline", (dataUserUpdate) => {
      if (dataUserUpdate.length > 0) {
        if (dataUserUpdate[0].id === estate.userid) {
          setAuthor(dataUserUpdate)
        }
      }
    })
    return () => {
      socketio.off("server-userid")
      socketio.off("server-online-offline")
    }
  }, [])


  // Litmit phone.
  const phone = handleLimitString(
    estate.customer_phone ? estate.customer_phone : estate.phone,
    3,
  )
  const [limitPhone, setLimitPhone] = useState(phone)
  const hidenButtonPhone = useRef("")
  const handleMorePhone = () => {
    setLimitPhone(estate.customer_phone ? estate.customer_phone : estate.phone)
    hidenButtonPhone.current = "hide"
    //   Count click phone.
    const params = {
      countclick: Number(data[0].countclick) + 1,
      id: data[0].id,
    }
    socketio.emit("client-click-phone", params)
  }

  //   Quản lý view 360
  useEffect(() => {
    if (optionView === "view360") {
      // Hiển thị view360.
      if (estate.view360.length > 0) {
        document.getElementById("iframeView360").innerHTML = `
        <iframe id="vt_iframe_${data[0].id}" allow="accelerometer; camera; display-capture; fullscreen; geolocation; gyroscope; magnetometer; microphone; midi; xr-spatial-tracking;" width="100%" height="450px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="${data[0].view360}"></iframe>
      `
      } else {
        document.getElementById(
          "iframeView360",
        ).innerHTML = `<div class="mt-5">Sản phẩm không có view 360.</div>`
      }
    }
  }, [optionView])

  // Quản lý description.
  useEffect(() => {
    // Hiển thị nội dung.
    document.getElementById("discriptionDetail").innerHTML = data[0].discription
  }, [])

  //Quản lý Trạng thái lưu sản phẩm.
  const [dataSimpleSaved, setDataSimpleSaved] = useState([])
  const [checkEdit, setCheckEdit] = useState(false)
  useEffect(() => {
    if (dataUser.length > 0) {
      const params = {
        userid: dataUser[0].id,
        simpleid: estate.id
      };
      socketio.emit("client-simple-saved", params)
      // Nhận socket.
      socketio.on("server-simple-saved", (dataSaved) => {
        if (dataSaved.length > 0) {
          const filterDataSaved = dataSaved.filter((item) => {
            return item.simpleid === estate.id && item.author === dataUser[0].id
          })
          setDataSimpleSaved(filterDataSaved)
        }
      })

      return () => {
        socketio.off("client-simple-saved")
        socketio.off("server-simple-saved")
      }
    }
  }, []);
  // Quản lý chuyển đổi trạng thái.
  const handleConvertSimpleSaved = (e) => {
    // e.preventDefault()
    if (dataUser.length > 0) {
      // Nếu chưa lưu lần nào.
      if (dataSimpleSaved.length === 0) {
        let params = {
          iden: process.env.REACT_APP_IDENTIFICATION,
          object: {
            simpleid: estate.id,
            status: 1,
            author: dataUser[0].id,
          },
        }
        socketio.emit("client-create-simple-saved", params)
        setCheckEdit(true) // xác nhận có sự thay đổi
      } else {
        // Kiểm tra trạng thái trước khi edit.
        let params = {
          id: dataSimpleSaved[0].id,
          status: dataSimpleSaved[0].status === 0 ? 1 : 0,
        }
        socketio.emit("client-edit-status-saved", params)
        setCheckEdit(true) // xác nhận có sự thay đổi
      }
    } else {
      alert("Bạn cần đăng nhập để lưu tin. Xin cảm ơn !")
    }
  }
  // Update data simple saved.
  useEffect(() => {
    socketio.on("server-edit-status-saved", (data) => {
      setDataSimpleSaved(data)
    })
    socketio.on("server-create-simple-saved", (data) => {
      setDataSimpleSaved(data)
    })
    return () => {
      socketio.off("server-edit-status-saved")
      socketio.off("server-create-simple-saved")
      socketio.off("client-create-simple-saved")
      socketio.off("client-edit-status-saved")
      setCheckEdit(false)
    }
  }, [checkEdit])

  // Kiểm tra hiển thi bảng dịch vụ cho thuê.
  const checkInfoService = useRef(false)
  useEffect(() => {
    if (
      estate.cate.toLowerCase() === "cho thuê nhà riêng" ||
      estate.cate.toLowerCase() === "cho thuê căn hộ" ||
      estate.cate.toLowerCase() === "cho thuê chung cư" ||
      estate.cate.toLowerCase() === "cho thuê mặt bằng" ||
      estate.cate.toLowerCase() === "cho thuê phòng trọ"
    ) {
      checkInfoService.current = true
    }
  }, [estate])

  return (
    <>
      <Helmet>
        {/* Các thẻ meta để khi gửi đường dẫn nó sẽ nhận diện tiêu đề nội dung và hình ảnh */}
        <meta property="og:title" content={estate.title} />
        <meta
          name="description"
          content={
            estate.cate +
            " " +
            estate.form_cate.toLowerCase() +
            " đường " +
            estate.address.toLowerCase() +
            " " +
            estate.districts.toLowerCase() +
            " " +
            estate.city.toLowerCase()
          }
        />
        <meta
          property="og:description"
          content={
            estate.cate +
            " " +
            estate.form_cate.toLowerCase() +
            " đường " +
            estate.address.toLowerCase() +
            " " +
            estate.districts.toLowerCase() +
            " " +
            estate.city.toLowerCase()
          }
        />
        <meta
          property="og:image"
          content={"https://batdongsan.view360.vn/" + listImages[0]}
        />
        {/* <meta property="og:image:width" content="600"></meta>
        <meta property="og:image:height" content="315"></meta> */}
        <meta property="og:type" content="article" />
      </Helmet>
      <div className={cx("container_wrapper", "mt-3")}>
        <div className={cx("content_wrapper")}>
          <div className={cx("row justify-content-between")}>
            {/* Left */}
            <div className={cx("col-xl-2 col-md-12")}>
              {/* <img src={images.bannerHeight} alt="" style={{ width: "100%" }} className={cx("rounded-4")} /> */}
              <div
                className={cx("d-flex justify-content-center")}
                style={{ position: "sticky", top: "121px" }}
              >
                <CheckPCMobile>
                  {(isMobile) => (isMobile ? "" : <SideBarHome />)}
                </CheckPCMobile>
              </div>
            </div>
            {/* Main */}

            <div
              className={cx("col-xl-6 col-md-12 row justify-content-center")}
            >
              {/* Title */}
              <h1 className={cx("fs-29 text-center title-detail")}>
                {estate.title}
              </h1>
              <div className="col-xl-11">
                <div className={cx("text-center fw-5")}>
                  <span className="fs-15">
                    {estate.cate}
                    <BsDot />
                    {estate.address}
                    <BsDot />
                    {estate.districts}
                    <BsDot />
                    {estate.city}
                  </span>
                  <div className={cx("fs-18")}>
                    <span className={cx("me-2 fw-5")}>Giá</span>
                    <span className="fw-5">
                      {Number(estate.price) > 0 &&
                        handleFormatPrice(estate.price)}
                    </span>
                    {estate.donvi.toLowerCase() === "thỏa thuận" ||
                    estate.donvi === ""
                      ? estate.donvi
                      : " / " + estate.donvi.toLowerCase()}
                    <BsDot />
                    <span className={cx("fs-15 text-muted")}>
                      Cập nhật{" "}
                      {moment(estate.update_time).locale("vi").fromNow()}
                    </span>
                  </div>
                </div>
                <div className={cx("col-12 d-flex justify-content-center")}>
                  <div
                    className={cx("mb-4 mt-3")}
                    style={{
                      width: "50%",
                      borderTop: "1px solid #495056",
                      fontSize: "21px",
                    }}
                  ></div>
                </div>

                {/* list images */}
                <div className="position-relative">
                  <div className={cx("d-flex mb-2 justify-content-center")}>
                    <button
                      className={cx(
                        "border-0 rounded-2 btn-outline-dark me-2 fs-18",
                      )}
                      onClick={() => handleOptionImagesView360("listImages")}
                    >
                      Hình ảnh
                    </button>
                    <button
                      className={cx(
                        "border-0 rounded-2 btn-outline-dark me-2 fs-18",
                      )}
                      onClick={() => handleOptionImagesView360("view360")}
                    >
                      View 360
                    </button>
                  </div>
                  {/* List images */}
                  <div
                    style={{
                      display: optionView === "listImages" ? "block" : "none",
                    }}
                  >
                    <SliderImageList data={listImages} height="450" />
                    {/* Quảng cáo */}
                    {socketio && (
                      <AdvertisementEstate
                        socketio={socketio}
                        idSimple={estate.id}
                      />
                    )}
                  </div>

                  {/* View 360 */}
                  <div
                    style={{
                      display: optionView === "view360" ? "block" : "none",
                      height: "450px",
                      textAlign: "center",
                      fontSize: "18px",
                      border: "1px solid #efefef",
                    }}
                    className="mt-3"
                    id="iframeView360"
                  ></div>
                  {/* Lưu sản phẩm */}
                  <button
                    className="border-0 roudend-5 bg-transparent"
                    style={{ position: "absolute", top: "50px", right: "21px" }}
                    onClick={handleConvertSimpleSaved}
                  >
                    {dataSimpleSaved.length > 0 ? (
                      dataSimpleSaved[0].status === 1 ? (
                        <FaHeart className="fs-26" />
                      ) : (
                        <FaRegHeart className="fs-26" />
                      )
                    ) : (
                      <FaRegHeart className="fs-26" />
                    )}
                  </button>
                </div>

                {/* Info dtmb */}
                <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
                <div className="text-center fs-16 fw-5">
                  <div className="mb-2 d-flex gap-3 align-items-center">
                    <span>
                      Diện tích MB: {estate.dtmb ? estate.dtmb : "0"}m
                      <sup>2</sup>
                    </span>
                    <BsDot />
                    <span>
                      Mặt tiền: {estate.mattien ? estate.mattien : "0"}m
                    </span>
                    <BsDot />
                    <span>
                      Hướng: {estate.direction ? estate.direction : "Liên hệ"}
                    </span>
                  </div>
                  <div className="d-flex gap-3 align-items-center">
                    
                      {estate.sotang && (
                        <span>
                          Số tầng: {estate.sotang} tầng
                          <BsDot />
                        </span>
                      )}
                    {estate.duongngo > 0 && (
                      <>
                        <span>
                          Đường/Ngõ: {estate.duongngo}m <BsDot />
                        </span>
                      </>
                    )}

                    <span>
                      {estate.phaply
                        ? "Pháp lý: " + estate.phaply
                        : "Pháp lý: Liên hệ"}
                    </span>
                  </div>
                </div>
                <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
                {/* *Info dtmb */}

                {/* Info rent */}
                {checkInfoService.current ? (
                  <div className={cx("d-flex justify-content-center mt-3")}>
                    {/* <div
                  className={cx(
                    "mb-3 mt-4 border-top border-block-start-dashed",
                  )}
                ></div> */}
                    <InfoRentEstate data={data} />
                  </div>
                ) : (
                  ""
                )}
                {/* description */}
                {/* <div
                  className={cx(
                    "mb-3 mt-3 border-top border-block-start-dashed",
                  )}
                ></div> */}
                <div className={cx("mt-3 fs-18")}>
                  {/* <div className="fw-6 text-center">
                    Mô tả
                    <div className={cx("col-12 d-flex justify-content-center")}>
                      <div
                        className={cx("mb-3 mt-3")}
                        style={{
                          width: "35%",
                          borderTop: "1px solid #495056",
                          fontSize: "21px",
                        }}
                      ></div>
                    </div>
                  </div> */}
                  {/* Hiển thị mô tả */}
                  <div
                    id="discriptionDetail"
                    className={cx("mb-3", "discriptionDetail", "fs-18")}
                  ></div>
                </div>
              </div>
            </div>
            {/* Right */}
            <div className={cx("col-xl-4 col-md-12")}>
              <div className={cx("formBooking", "hs", "col-xl-10")}>
                {/* Info author */}
                <div className={cx("d-flex col-12 gap-1")}>
                  <span
                    className={cx(
                      "avatar avatar-lg me-2 avatar-rounded col-2",
                      author.length > 0
                        ? author[0].online_offline === 1
                          ? "online"
                          : "offline"
                        : "",
                    )}
                  >
                    <img
                      src={
                        author.length > 0
                          ? estate.customer_phone !== ""
                            ? images.user
                            : "/" + author[0].avatar
                            ? "/" + author[0].avatar
                            : images.user
                          : images.user
                      }
                      alt=""
                      style={{ border: "1px solid #e9e9e9", width: "48px" }}
                    />
                  </span>

                  <div className={cx("fs-16 fw-5 ms-2 col-9 fontSize")}>
                    <span>
                      {estate.customer_phone
                        ? estate.customer_name
                        : estate.hoten}
                    </span>
                    <BsDot />
                    <span>{limitPhone}</span>
                    <button
                      className={cx(
                        "border-0",
                        "bg-none",
                        hidenButtonPhone.current,
                      )}
                      onClick={handleMorePhone}
                    >
                      <FaRegEyeSlash />
                    </button>
                    <p className={cx("text-muted mb-2")}>
                      {author.length > 0 &&
                        (author[0].online_offline === 1
                          ? "Đang online"
                          : "Cập nhật " +
                            moment(author[0].connect_time)
                              .locale("vi")
                              .fromNow())}
                      {/* Điều kiện hiển thị nút gọi ngay */}
                      {hidenButtonPhone.current === "hide" && (
                        <>
                          <BsDot />
                          <a href={"tel: " + limitPhone}>BẤM GỌI</a>
                        </>
                      )}
                    </p>
                  </div>
                </div>
                <CountViewEstate data={estate} />
                <div
                  className={cx("mb-3 border-top border-block-start-dashed")}
                ></div>
                <BookingEstate dataEstate={estate} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DetailEstate

// info cho thuê.
const InfoRentEstate = ({ data }) => {
  // tiến độ thanh toán.
  let paymentMethods = data[0].payment_methods
  if (paymentMethods === 1) {
    paymentMethods = "Đêm"
  } else if (paymentMethods === 2) {
    paymentMethods = "Ngày"
  } else if (paymentMethods === 3) {
    paymentMethods = "Tháng"
  } else if (paymentMethods === 4) {
    paymentMethods = "Năm"
  }
  return (
    <div className="fs-18">
      {/* <div className="fw-6 text-center">Thông tin cho thuê</div>
      <div className={cx("col-12 d-flex justify-content-center")}>
        <div
          className={cx("mb-3 mt-3")}
          style={{
            width: "68%",
            borderTop: "1px solid #495056",
            fontSize: "21px",
          }}
        ></div>
      </div> */}
      <div className="mt-2">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center me-5">
            <TbAirConditioning className="me-3 fs-26" />
            <div>
              <div className="fw-5">Điều hòa</div>
              <div className="text-muted">
                {data[0].dieuhoa === 1 ? "Có" : "Không"}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <MdHotTub className="fs-26 me-2 ms-3" />
            <div>
              <div className="fw-5">Nóng lạnh</div>
              <div className="text-muted">
                {data[0].binhnonglanh === 1 ? "Có" : "Không"}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center mt-3">
          <MdOutlineElectricalServices className="me-3 fs-26" />
          <div>
            <div className="fw-5">Giá điện sử dụng</div>
            <div className="text-muted mt-0">
              <span className="me-1">Giá tiêu thụ điện năng</span>
              {Number(data[0].electricity_money) > 0
                ? Number(data[0].electricity_money)
                    .toLocaleString()
                    .replace(/[.]/g, ",") + "đ"
                : "theo giá nhà nước "}
              / số điện
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <BsWater className="me-3 fs-26" />
          <div>
            <div className="fw-5">Giá nước sử dụng</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Giá tiêu thụ nước sinh hoạt</span>
              {Number(data[0].water_money > 0)
                ? Number(data[0].water_money)
                    .toLocaleString()
                    .replace(/[.]/g, ",") + "đ "
                : "theo gia nhà nước "}
              / khối nước
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <IoWarningOutline className="me-3 fs-26" />
          <div>
            <div className="fw-5">Dịch vụ khác</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Chi phí cho các dịch vụ khác là</span>
              {Number(data[0].service_price)
                .toLocaleString()
                .replace(/[.]/g, ",")}
              đ / tháng
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <PiHandshakeLight className="me-3 fs-26" />
          <div>
            <div className="fw-5">Thời gian ký hợp đồng</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Thời gian khi ký hợp đồng </span>
              {Number(data[0].contract_time) > 0
                ? "là " + Number(data[0].contract_time) + " tháng trở lên"
                : "thỏa thuận"}
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <BsCreditCard2Back className="me-3 fs-26" />
          <div>
            <div className="fw-5">Số tiền đặt cọc khi thuê</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Số tiền cần đặt cọc khi thuê</span>
              {Number(data[0].deposit) > 0
                ? handleFormatPrice(Number(data[0].deposit))
                : "thỏa thuận"}
            </div>
          </div>
        </div>

        {/* <div className="d-flex align-items-center mt-3">
          <PiTimerBold className="me-3 fs-26" />
          <div>
            <div className="fw-5">Kỳ hạn thanh toán</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Tiến độ thanh toán được tính theo</span>(
              {paymentMethods})
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

// Xác định lượt xem sản phẩm.
function CountViewEstate({ data }) {
  const {socketio} = useContext(AppContext)
  const [updateView, setUpdateView] = useState(data.countview)

  useEffect(() => {
    socketio.on("server-click-views", (dataUpdate) => {
      if (data.id === dataUpdate[0].id) {
        setUpdateView(dataUpdate[0].countview)
      }
    })
    return () => {
      socketio.off("server-click-views")
    }
  }, [])

  return (
    <div className={cx("mb-2 text-muted fs-16 fontSize")}>
      {Number(updateView).toLocaleString().replace(/[.]/g, ",")} lượt xem
      <BsDot />
      {moment(data.update_time).locale("vi").fromNow()}
    </div>
  )
}

// Booking
function BookingEstate({ dataEstate }) {
  const { dataUser, socketio } = useContext(AppContext)

  const [object, setObject] = useState({
    fullname: dataUser.length > 0 ? dataUser[0].hoten : "",
    fullphone: dataUser.length > 0 ? dataUser[0].sodienthoai : "",
    discription: "",
  })
  const [checkName, setCheckName] = useState(true)
  const [checkPhone, setCheckPhone] = useState(true)
  const [checkDis, setCheckDis] = useState(true)

  useEffect(() => {
    socketio.on("server-add-booking", (data) => {
      // console.log(data)
    })
    return () => {
      socketio.off("server-add-booking")
    }
  }, [])

  const arrOption = [
    { id: 1, title: "Tôi cần tư vấn sản phẩm này !" },
    { id: 2, title: "Dòng sản phẩm tương tự như này ?" },
    { id: 3, title: "Các vấn sản phẩm " + dataEstate.address },
  ]
  const handleFullName = (e) => {
    setCheckName(true)
    setObject({
      fullname: e.target.value,
      fullphone: object.fullphone,
      discription: object.discription,
    })
  }
  const handleFullPhone = (e) => {
    setCheckPhone(true)
    setObject({
      fullname: object.fullname,
      fullphone: e.target.value,
      discription: object.discription,
    })
  }
  const handleDiscription = (e) => {
    setCheckDis(true)
    setObject({
      fullname: object.fullname,
      fullphone: object.fullphone,
      discription: e.target.value,
    })
  }

  const handleOptionContent = (key) => {
    const findOption = arrOption.find((item) => {
      return item.id === key
    })
    setCheckDis(true)
    setObject({
      fullname: object.fullname,
      fullphone: object.fullphone,
      discription: findOption.title,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Kiểm tra trường bắt buộc.
    if (object.fullname === "") {
      setCheckName(false)
    }
    if (object.fullphone === "") {
      setCheckPhone(false)
    }
    if (object.discription === "") {
      setCheckDis(false)
    }
    if (
      object.fullname !== "" &&
      object.fullphone !== "" &&
      object.discription !== ""
    ) {
      // Gửi yêu cầu đến server.
      object.simpleid = dataEstate.id
      object.author_simple = dataEstate.userid
      object.name_estate = dataEstate.title
      if (dataUser.length > 0) {
        object.email = dataUser[0].email
      } else {
        object.email = ""
      }
      
      socketio.emit("client-add-booking", object)
      
      alert("Cảm ơn bạn, yêu cầu đã được gửi. Tôi sẽ liên hệ sớm nhất có thể")
      setTimeout(() => {
        setObject({
          fullname: object.fullname,
          fullphone: object.fullphone,
          discription: "",
        })
        
      }, 100)
    }
  }

  return (
    // <div className={cx("fs-17")}>Gửi yêu cầu liên hệ</div>
    <form onSubmit={handleSubmit}>
      <div className="col-xl-12 col-md-12">
        <div className="form-floating mb-1 mt-2">
          <input
            type="text"
            value={object.fullname}
            className="form-control rounded-3 fs-16"
            placeholder="Họ tên"
            onChange={handleFullName}
          />
          <label className="fs-16">
            Họ tên
            <sup className="text-danger">*</sup>
          </label>
          {!checkName && (
            <span className={cx("text-danger", "fs-14")}>
              *Hãy cho tôi biết tên của bạn
            </span>
          )}
        </div>
      </div>
      <div className="col-xl-12 col-md-12 mt-3">
        <div className="form-floating mb-1">
          <input
            type="text"
            value={object.fullphone}
            className="form-control rounded-3 fs-16"
            placeholder="Số điện thoại"
            onChange={handleFullPhone}
          />
          <label className="fs-16">
            Số điện thoai <sup className="text-danger">*</sup>
          </label>
          {!checkPhone && (
            <span className={cx("text-danger", "fs-14")}>
              *Hãy để lại số điện thoại của bạn
            </span>
          )}
        </div>
      </div>
      <div className="col-xl-12 col-md-12 mt-3">
        <div className={cx("mb-3 fs-16")}>
          <button
            className={cx("border-0 rounded-5 px-3 py-1 mb-2 btn-outline-dark")}
            onClick={() => handleOptionContent(1)}
            type="button"
          >
            Tôi cần tư vấn sản phẩm này !
          </button>
          <button
            type="button"
            className={cx("border-0 rounded-5 px-3 py-1 mb-2 btn-outline-dark")}
            onClick={() => handleOptionContent(2)}
          >
            Dòng sản phẩm tương tự như này ?
          </button>
          <button
            type="button"
            className={cx("border-0 rounded-5 px-3 py-1 btn-outline-dark")}
            onClick={() => handleOptionContent(3)}
          >
            Các sản phẩm {dataEstate.address}
          </button>
        </div>
        <div className="mb-1">
          <textarea
            type="text"
            value={object.discription}
            className="form-control rounded-3 fs-16"
            placeholder="Nội dung *"
            rows={3}
            onChange={handleDiscription}
          />
          {!checkDis && (
            <span className={cx("text-danger", "fs-14")}>
              *Hãy để lại lời nhắn cho chủ sở hữu
            </span>
          )}
        </div>
      </div>
      <div className="px-4 d-flex mt-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
        <button
          className="btn btn-danger-light mt-3 op-9 fw-5 fs-16"
          type="submit"
          id="submitProduct"
        >
          Gửi yêu cầu
        </button>
      </div>
    </form>
  )
}

// Quảng cáo khoảng 10 bài viết khác random.
function AdvertisementEstate({ socketio, idSimple }) {
  const [activeAd, setActiveAd] = useState(false)
  const [hidenAd, setHideAd] = useState(true)
  const [dataAd, setDataAd] = useState([])
  const [listImages, setListImages] = useState([])
  // Ẩn quảng cáo
  const handleHideAd = () => {
    setHideAd(false)
  }

  // Hiện thị quảng cáo ban đầu.
  useEffect(() => {
    setTimeout(() => {
      socketio.emit("client-advertesement-simple", {iden: process.env.REACT_APP_IDENTIFICATION})
      setActiveAd(true)
    }, 3000);
    
    return () => {
      socketio.off("client-advertesement-simple")
    }
  }, []);
  // quản lý nhận data.
  useEffect(() => {
    socketio.on("server-advertesement-simple", (data) => {
      const randomIndex = Math.floor(Math.random() * data.length);
      // Điều kiện nếu random sản phẩm trung với tin đang xem sẽ không hiển thị.
      if (data[randomIndex].id !== Number(idSimple)) {
        setDataAd([data[randomIndex]])
        // List images.
        if (data.length > 0) {
          if (data[randomIndex].images) {
            const listImag = data[randomIndex].images.split(",")
            setListImages([...listImag])
          }
        }
      }
    })
    return () => {
      socketio.off("server-advertesement-simple")
    }
  }, []);
  if (!hidenAd) {
    return <div></div>
  }
  if (!activeAd) {
    return
  }
  if (dataAd.length === 0) {
    return
  }

  return (
    <div className={cx("wrapper_adEs", "d-flex")}>
      {/* Check mobile pc */}
      <CheckPCMobile>
        {(isMobile) =>
          isMobile ? (
            <ContentAdvertsementMobile
              dataAd={dataAd}
              listImages={listImages}
              socketio={socketio}
            />
          ) : (
            <ContentAdvertsementPC
              dataAd={dataAd}
              listImages={listImages}
              socketio={socketio}
            />
          )
        }
      </CheckPCMobile>
      {/* Button cancel */}
      <div className={cx("cancel_adEs")}>
        <button
          className={cx("border-0 bg-transparent p-0")}
          onClick={handleHideAd}
        >
          <MdCancel
            className="fs-21"
            style={{
              border: "2px solid rgba(255, 255, 255, 0.89)",
              borderRadius: "21px",
            }}
          />
        </button>
      </div>
    </div>
  )
}

// Nội dung quảng cáo trên mobile.
function ContentAdvertsementMobile({ dataAd, listImages, socketio }) {
  const navigate = useNavigate()
  // format url detail.
  const formatCateUrl = deleteAccentedText(dataAd[0].cate).toLowerCase()
  const cate = formatCateUrl.replace(/[ ]/g, "-")
  const formatAddress = deleteAccentedText(dataAd[0].address).toLowerCase()
  const address = formatAddress.replace(/[ ]/g, "-")
  // Tăng view khi click.
  const handleOpenDetail = (simpleId, path) => {
    // Tăng view khi click.
    socketio.emit("client-detail-product", simpleId)
    socketio.on("server-detail-product", (dataUpdate) => {
      const params = {
        id: simpleId,
        countview: Number(dataUpdate[0].countview) + 1,
      }
      socketio.emit("client-click-views", params)
      // Chuyển trang
      navigate("" + path + "")
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-detail-product")
      socketio.off("server-detail-product")
      socketio.off("client-click-views")
    }
  }, [])

  return (
    <div>
      <div className="d-flex align-items-center" style={{ height: "68px" }}>
        <img
          src={
            listImages.length > 0 ? "/" + listImages[0] : images.bannerPrView360
          }
          alt="img"
          width={68}
          height={68}
          style={{ padding: "6px", borderRadius: "100px" }}
        />
        <div className={cx("content_adEs", "ms-2")}>
          <h6 className="mb-1 fs-14" style={{ textTransform: "uppercase" }}>
            <TextLimit
              text={
                dataAd[0].address +
                " " +
                dataAd[0].districts +
                " " +
                dataAd[0].city
              }
              number="18"
            />
          </h6>
          <p className="text-muted mb-0 fw-5 fs-14">
            <TextLimit text={dataAd[0].cate} number={9} /> <BsDot />{" "}
            {Number(dataAd[0].price) > 0 ? (
              handleFormatPrice(Number(dataAd[0].price))
            ) : (
              <>
                {dataAd[0].dtmb}
                <span className="ms-1">
                  m<sup>2</sup>
                </span>
              </>
            )}
          </p>
        </div>
        <button
          className={cx("button_adEs", "border-0 roudend-2 ms-3 fs-12")}
          onClick={() =>
            handleOpenDetail(
              dataAd[0].id,
              "/" +
                cate +
                "-" +
                address +
                "/" +
                dataAd[0].alias +
                "-pr" +
                dataAd[0].id,
            )
          }
        >
          Chi tiết
        </button>
      </div>
    </div>
  )
}

// Nội dung quảng cáo trên pc .
function ContentAdvertsementPC({ dataAd, listImages, socketio }) {
  const navigate = useNavigate()
  // format url detail.
  const formatCateUrl = deleteAccentedText(dataAd[0].cate).toLowerCase()
  const cate = formatCateUrl.replace(/[ ]/g, "-")
  const formatAddress = deleteAccentedText(dataAd[0].address).toLowerCase()
  const address = formatAddress.replace(/[ ]/g, "-")
  // Tăng view khi click.
  const handleOpenDetail = (simpleId, path) => {
    // Tăng view khi click.
    socketio.emit("client-detail-product", simpleId)
    socketio.on("server-detail-product", (dataUpdate) => {
      const params = {
        id: simpleId,
        countview: Number(dataUpdate[0].countview) + 1,
      }
      socketio.emit("client-click-views", params)
      // Chuyển trang
      navigate("" + path + "")
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-detail-product")
      socketio.off("server-detail-product")
      socketio.off("client-click-views")
    }
  }, [])
  return (
    <>
      <div>
        <div className="d-flex align-items-center" style={{ height: "68px" }}>
          <img
            src={
              listImages.length > 0
                ? "/" + listImages[0]
                : images.bannerPrView360
            }
            alt="img"
            width={68}
            height={68}
            style={{ padding: "6px", borderRadius: "100px" }}
          />
          <div className={cx("content_adEs", "ms-2")}>
            <h6 className="mb-1 fs-14" style={{ textTransform: "uppercase" }}>
              <TextLimit
                text={
                  dataAd[0].address +
                  " " +
                  dataAd[0].districts +
                  " " +
                  dataAd[0].city
                }
                number="21"
              />
            </h6>
            <p className="text-muted mb-0 fw-5">
              {dataAd[0].cate} <BsDot />{" "}
              {Number(dataAd[0].price) > 0 ? (
                handleFormatPrice(Number(dataAd[0].price))
              ) : (
                <>
                  {dataAd[0].dtmb}
                  <span className="ms-1">
                    m<sup>2</sup>
                  </span>
                </>
              )}
            </p>
          </div>
          <button
            className={cx("button_adEs", "border-0 roudend-2 ms-3")}
            onClick={() =>
              handleOpenDetail(
                dataAd[0].id,
                "/" +
                  cate +
                  "-" +
                  address +
                  "/" +
                  dataAd[0].alias +
                  "-pr" +
                  dataAd[0].id,
              )
            }
          >
            Chi tiết
          </button>
        </div>
      </div>
    </>
  )
}
