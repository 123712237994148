import { useState, useContext } from "react"
import { IoAddOutline } from "react-icons/io5"
import { MdOutlineSupportAgent } from "react-icons/md"
import { Tb360View } from "react-icons/tb"

import { Link, useNavigate } from "react-router-dom"
import classNames from "classnames/bind"

import ModalReact from "../../../components/Modal/ModalReact"
import styles from "./PageHome.module.scss"
import FormSupport from "../Component/Support/FormSupport"
import FormRealEstateFast from "../../../components/Form/FromRealEstateFast"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import ChatBot from "../../../components/ChatBot"
import { images } from "../../../assets/images"

const cx = classNames.bind(styles)

function SideBarHome() {
  const { dataUser } = useContext(AppContext)
  // Button Add Product.
  const [modalAddProduct, setModalAddProduct] = useState(false)
  const navigate = useNavigate()
  const handleAddProduct = () => {
    if (dataUser.length > 0) {
      setModalAddProduct(true)
    } else {
      navigate("/login")
    }
  }
  const handleCloseAddProduct = () => setModalAddProduct(false)

  // Set modal support.
  const [modalSupport, setModalSupport] = useState(false)
  const handleShowModalSupport = () => {
    setModalSupport(true)
  }
  const handleCloseSupport = () => setModalSupport(false)
  // Set modal chat
  const [modalChat, setModalChat] = useState(false)
  const HandleShowModalChat = () => {
    setModalChat(true)
  }
  const handleCloseChat = () => setModalChat(false)
  // chuyển hướng sang view360
  const handleOpenView360 = () => {
    window.open("https://view360.com.vn")
  }

  // Ẩn modal nếu đăng tin thành công.
  const checkAddEstateSuccess = (status_) => {
    if (status_) {
      setModalAddProduct(false)
    }
  }

  return (
    <>
      <div className={cx("SidebarWrap")}>
        <div className={cx("Sidebar_Wrapper", "gap-3")}>
          <div className="d-flex flex-direction-column align-items-center gap-1">
            <button className={cx("AddProduct", "border-0")} onClick={handleAddProduct}>
              <IoAddOutline className={cx("fs-29", "IconAdd")} />
            </button>
            <span className={cx("fw-5 fs-15")}>Đăng tin</span>
          </div>
          {/* Danh sách danh mục */}
          <ul className={cx("Sidebar_list")}>
            <li>
              <button
                className={cx("Sidebar_item", "border-0 op-7")}
                onClick={handleShowModalSupport}
              >
                <MdOutlineSupportAgent className={cx("fs-23")} />
                <span className={cx("fw-5 fs-15")}>Hỗ trợ</span>
              </button>
            </li>

            <li>
              <button
                className={cx("Sidebar_item", "border-0 op-7")}
                onClick={handleOpenView360}
              >
                <Tb360View className={cx("fs-23")} />
                <span className={cx("fw-5 fs-15")}>View360</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
      {/* Modal Add Product */}
      <ModalReact
        // size="modal-lg"
        showModal={modalAddProduct}
        handleClose={handleCloseAddProduct}
        theme={<FormRealEstateFast result={checkAddEstateSuccess} />}
        modalTitle="Đăng tin"
      />
      {/* Modal support */}
      <ModalReact
        modalTitle="Hỗ trợ giải đáp 0378.052.118"
        showModal={modalSupport}
        handleClose={handleCloseSupport}
        theme={<FormSupport />}
      />
      {/* Modal Chat */}
      <ModalReact
        modalTitle={<AvatarChatBot />}
        size="modal-lg"
        showModal={modalChat}
        handleClose={handleCloseChat}
        theme={<ChatBot />}
      />
    </>
  )
}

export default SideBarHome

// đại diện cho modal chat bot.
function AvatarChatBot() {
  return (
    <div className={cx("d-flex align-items-center")}>
      <img src={images.noImage} alt="ChatBot" width={50} />
      <div>
        <div className={cx("fs-15")}>Chatbot</div>
        <p className={cx("mt-2 mb-0")}>Hãy đặt câu hỏi cho tôi ?</p>
      </div>
    </div>
  )
}
