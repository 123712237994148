import moment from "moment"
import {
  handleMethodCreateData,
  handleMethodDeleteData,
  handleMethodEditData,
} from "../../reuses/method"
import {
  handleAddPathImages,
  handleUploadFileImage,
  handleUpdateListPathImages,
} from "../../reuses/UploadFile"

import { deleteAccentedText } from "../../reuses"

export function handleAddDataMarketSimple(
  dataInput,
  objectFile,
  dataUser,
  dataBalance,
  editEstate,
  callback
) {
  /** === Phân loại data market_simple ===*/
  // file
  const dataFile = objectFile

  const formatAlias = deleteAccentedText(dataInput.title)
  // thêm giá trị vào đối tượng
  dataInput.alias = formatAlias
    .toLowerCase()
    .replace(/[,.]/g, "")
    .replace(/[/+]/g, "-")
    .replace(/[ ]/g, "-")
  // dataInput.address = data.length > 0 ? data[0].tuyenduong : ""
  // dataInput.districts = data.length > 0 ? data[0].quanhuyen : ""
  dataInput.starttime = moment().format("YYYY-MM-DD HH:mm:ss")
  dataInput.endtime = moment().add(1, "month").format("YYYY-MM-DD HH:mm:ss")
  dataInput.userid = dataUser.length > 0 ? dataUser[0].id : ""
  dataInput.hoten = dataUser.length > 0 ? dataUser[0].hoten : ""
  dataInput.phone = dataUser.length > 0 ? dataUser[0].sodienthoai : ""
  dataInput.price =
    dataInput.price !== "" ? dataInput.price.replace(/[,. ]/g, "") : "0"
  dataInput.dieuhoa = dataInput.dieuhoa !== "" ? dataInput.dieuhoa : "0"
  dataInput.binhnonglanh =
    dataInput.binhnonglanh !== "" ? dataInput.binhnonglanh : "0"
  dataInput.contract_time =
    dataInput.contract_time !== "" ? dataInput.contract_time : "0"
  dataInput.electricity_money =
    dataInput.electricity_money !== ""
      ? dataInput.electricity_money.replace(/[,. ]/g, "")
      : "0"
  dataInput.water_money =
    dataInput.water_money !== ""
      ? dataInput.water_money.replace(/[,. ]/g, "")
      : "0"
  dataInput.service_price =
    dataInput.service_price !== ""
      ? dataInput.service_price.replace(/[,. ]/g, "")
      : "0"
  dataInput.deposit =
    dataInput.deposit !== "" ? dataInput.deposit.replace(/[,. ]/g, "") : "0"
  dataInput.payment_methods =
    dataInput.payment_methods !== "" ? dataInput.payment_methods : "0"
  dataInput.periodic_payments =
    dataInput.periodic_payments !== "" ? dataInput.periodic_payments : "0"
  const dataSimple = dataInput
  // console.log(dataInput)

  // Chuyển sang xử lý edit nếu thỏa mãn điều kiện.
  if (editEstate === "editEstate") {
    handleEditEstate(dataFile, dataSimple, callback)
  } else if(editEstate === "addEstate") {
    // Xử lý thanh toán.
    handleCheckPayUserPost(
      dataUser,
      dataBalance,
      dataFile,
      dataSimple,
      callback
    )
  }
}

// Gợi ý tuyến đường.
export async function handleSuggestStreetEstate(streetText) {
  if (streetText !== "") {
    const response = await fetch(process.env.REACT_APP_APIGetStreetDistrictsCityMarketSimple + `?keyword=${streetText}`)
    const dataRes = await response.json()
    return dataRes
  }
}

// Xác định tuyến đường, quận huyện.
async function handleCheckStreetDistrict(textInput) {
  const formatText = textInput.toLowerCase()
  const response = await fetch(process.env.REACT_APP_API_STREET + `?keyword=${formatText}`)
  const dataRes = await response.json();
  const street = dataRes.filter((item) => {
    let street = item.tuyenduong.toLowerCase();
    return formatText.includes(street)
  });
  const district = street.filter((item) => {
    let district = item.quanhuyen.toLowerCase()
    return formatText.includes(district)
  })

  if (district.length > 0) {
    return (district)
  } else {
    return (street)
  }
}


// Kiểm tra Xử lý thanh toán.
function handleCheckPayUserPost(
  filterUser,
  dataPay,
  dataFile,
  dataSimple,
  callback
) {
  if (filterUser[0].post_free === 1) {
    // Xử lý thêm mới.
    handleCreateDataMarketSimple(
      dataFile,
      dataSimple,
      callback
    )
  } else {
    // Xử lý thanh toán trước khi đăng.
    paymentProcessing(
      dataPay,
      dataFile,
      dataSimple,
      callback
    )
  }
}

// Xử lý thanh toán trước khi đăng.
function paymentProcessing(
  dataPay,
  dataFile,
  dataSimple,
  callback
) {
  // Check money
  if (dataPay[0].total_balance >= 50000) {
    dataSimple.status = 1
    dataSimple.admoney = 50000
    // Tính toán trừ số dư tài khoản.
    const objectPay = {
      id: dataPay[0].id,
      total_deposit: dataPay[0].total_deposit,
      total_balance: Number(dataPay[0].total_balance) - 50000,
    }
    handleMethodEditData(
      objectPay,
      () => {
        // Xử lý thêm mới
        handleCreateDataMarketSimple(dataFile, dataSimple, callback)
      },
      process.env.REACT_APP_APIEditUserPay,
    )
    
  } else {
    dataSimple.status = 0
    dataSimple.admoney = 0
    // Xử lý thêm mới
    handleCreateDataMarketSimple(
      dataFile,
      dataSimple,
      callback
    )
    alert(
      "Tài khoản của bạn không đủ để quảng cáo. Bạn có thể vào quản lý sản phẩm để tiếp tục quảng cáo.",
    )
  }
}

// Xử lý thêm mới.
async function handleCreateDataMarketSimple(
  dataFile,
  dataSimple,
  callback
) {
  // Upload file
  await handleUploadFileImage(
    dataFile,
    process.env.REACT_APP_APIUploadFileDataMarketSimpleImages,
  )

  // Thêm dữ liệu.
  await handleMethodCreateData(
    dataSimple,
    async () => {
      // Thêm đường dẫn
      if (dataFile) {
        const methodPost = {
          method: "POST",
          headers: {
            "content-type": "application/json",
            identification: process.env.REACT_APP_IDENTIFICATION,
          }
        }
        fetch(process.env.REACT_APP_APICheckDataAddImagesMarketSimple, methodPost)
          .then((response) => {
            return response.json()
          })
          .then((dataSimpleNew) => {
            // Giá trị đầu vào
            let valueInputPath = {
              simpleid: Number(dataSimpleNew[0].id),
              path: "",
            }
            // Xử lý thêm đường dẫn
            handleAddPathImages(
              dataFile,
              valueInputPath,
              "images/real_estate/market_simple",
              process.env.REACT_APP_APICreateDataMarketSimpleImages,
            )
            callback(true) // ẩn bảng đăng tin khi thành công
          })
          .catch((err) => {
            console.error("Lỗi: " +err)
          })
        
      } else {
        callback(true) // ẩn bảng đăng tin khi thành công
      }
    },
    process.env.REACT_APP_APICreateDataMarketSimple,
  )
}



/** === Xử lý edit estate === */
// Edit data
async function handleEditEstate(dataFile, dataSimple, callback) {
  // Xử lý edit path images.
  await handleEditPathImages(dataSimple.id, dataFile)
  // Xử lý edit data.
  await handleMethodEditData(
    dataSimple,
    () => {
      alert("Cập nhật dữ liệu thành công")
      callback(true)
    },
    process.env.REACT_APP_APIEditDataMarketSimple,
  )
}

/** === Xử lý xóa path images === */
export function handleDeletePathImages(id_) {
  // Xử lý xóa đường dẫn.
  handleMethodDeleteData(id_, ()=>{}, process.env.REACT_APP_APIDeleteDataMarketSimpleImages)
}

/** === Xử lý sửa path images === */
async function handleEditPathImages(id_, objectFile, callback) {
  const classListImages = "listImageEdit"
  const apiEditPath = process.env.REACT_APP_APIUpdatetimeMarketSimpleImages
  const apiCreateImages = process.env.REACT_APP_APIUploadFileDataMarketSimpleImages
  const apiCreatePath = process.env.REACT_APP_APICreateDataMarketSimpleImages
  const folder = "images/real_estate/market_simple"
  await handleUpdateListPathImages(id_, objectFile, classListImages, apiEditPath, apiCreateImages, apiCreatePath, folder, callback)
}

/** === *Xử lý edit estate === */


/** === Xử lý gia hạn sản phẩm === */
export function handleExtensionProduct(data, admoneyOld, dataBalance, reset, result) {
  // format endtime.
  const formatEndtime = moment().add(data.endtime, "months").format("YYYY-MM-DD HH:mm:ss");
  const payment = (Number(data.endtime) * 50000);
  const balance = Number(dataBalance[0].total_balance)
  const remainingBalance = balance - payment;
  // kiểm tra thanh toán trước khi cho phép gian hạn.
  if (remainingBalance >= 0) {
    // Set đối tượng đầu vào product.
    data.endtime = formatEndtime
    data.admoney = payment + Number(admoneyOld)
    data.status = 1
    data.update_time = moment().format("YYYY-MM-DD HH:mm:ss")

    // Set đối tượng thanh toán.
    const objectPay = {
      id: dataBalance[0].id,
      total_deposit: dataBalance[0].total_deposit,
      total_balance: Number(dataBalance[0].total_balance) - payment,
    }

    // Xử lý thanh toán.
    handleMethodEditData(objectPay, () => { }, process.env.REACT_APP_APIEditUserPay)
    // Xử lý gia hạn sản phẩm.
    handleMethodEditData(data, () => {
      alert("Gia hạn thành công.")
      reset()
      result(true) // xác nhận sự thay đổi
    }, process.env.REACT_APP_APIEditEndtimeAdmoneyMarketSimple)
  } else {
    alert("Số dư của bạn không đủ để gia hạn với số tháng bạn chọn.")
  }
  
}
/** === *Xử lý gia hạn sản phẩm === */



/** === Get web_market_simple */
async function getDataMarketSimple() {
  return new Promise((resolve) => {
    fetch(process.env.REACT_APP_APIGetDataMarketSimple)
      .then((response) => response.json())
      .then((data) => resolve(data))
  })
}