import { useState, useEffect, useContext } from "react"
import classNames from "classnames/bind"

import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import HeaderDashboardPage from "./Header"
import { CardHorizontal, CardListEvent } from "../../../components/Card"
import styles from "./Dashboard.module.scss"
import { LoadingSpinner } from "../../../components/Loading"
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import DashboardMobile from "../../mobile/Dashboard"

const cx = classNames.bind(styles)

function DashboardPage() {
  const [dataEstates, setDataEstates] = useState([])
  const [dataBooking, setDataBooking] = useState([])
  const [dataCustomer, setDataCustomer] = useState([])
  const [loading, setLoading] = useState(true)
  const { dataUser } = useContext(AppContext)

  const fetchData = async () => {
    const response = await fetch(
      process.env.REACT_APP_APIGetDataMarketSimple +
        `?phone=${dataUser[0].sodienthoai}`,
    )
    const dataRes = await response.json()
    // Get dataBooking.
    if (dataUser.length > 0) {
      // data booking
      const responseBooking = await fetch(
        process.env.REACT_APP_APIGetForUserBooking + `?only=${dataUser[0].id}`,
      )
      const dataResBooking = await responseBooking.json()

      // Data customer
      const responseCustomer = await fetch(
        process.env.REACT_APP_APIGetDataMarketBuyer + `?only=${dataUser[0].id}`,
      )
      const dataResCustomer = await responseCustomer.json()

      setDataBooking(dataResBooking)
      setDataCustomer(dataResCustomer)
    }

    setDataEstates(dataRes)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <CheckPCMobile>
      {(isMobile) =>
        isMobile ? (
          <DashboardMobile
            loading={loading}
            dataEstates={dataEstates}
            dataBooking={dataBooking}
            dataCustomer={dataCustomer}
          />
        ) : (
          <DashboardPC
            loading={loading}
            dataEstates={dataEstates}
            dataBooking={dataBooking}
            dataCustomer={dataCustomer}
          />
        )
      }
    </CheckPCMobile>
  )
}

export default DashboardPage

// Dashboard for PC.
function DashboardPC({ loading, dataEstates, dataBooking, dataCustomer }) {
  return (
    <>
      <HeaderDashboardPage />
      <div style={{padding: "0px 80px"}}>
        <div className={cx("mt-3", "container-fluid p-0")}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <CardHorizontal
                  dataPro={dataEstates}
                  dataBooking={dataBooking}
                  dataCustomer={dataCustomer}
                />
              </div>
              {/* <div className="col-xl-6 col-md-12">
                <CardListEvent
                  dataPro={dataEstates}
                  dataBooking={dataBooking}
                />
              </div> */}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
