function LoadingSpinner() {
    return (
      <div
        className="text-center mt-3"
        style={{ transform: "translate(0px, 20%)" }}
      >
        <div className="spinner-border border-2" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
}

export default LoadingSpinner;