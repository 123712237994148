import { memo } from "react";
import HeaderDashboardMobile from "../Component/Header/ComponentHeaderDash"
import PageProduct from "./PageProduct"

function PageProductMobile({ loading, dataEstates, dataBooking, socketio, result, effectLoading, checkSearch }) {
    return (
      <>
        {/* Header */}
        <HeaderDashboardMobile namePage="Sản phẩm" />
        {/* Main */}
        <PageProduct
          loading={loading}
          dataEstates={dataEstates}
          dataBooking={dataBooking}
          socketio={socketio}
          result={result}
          effectLoading={effectLoading}
          checkSearch={checkSearch}
        />

        
      </>
    )
}

export default memo(PageProductMobile)