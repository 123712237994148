import { useContext, useState, useRef, useEffect } from "react"
import Tippy from "@tippyjs/react/headless"
import classNames from "classnames/bind"
import { IoNotificationsOutline } from "react-icons/io5"
import { BsDot } from "react-icons/bs";
import { Link } from "react-router-dom"

import styles from "./Notification.module.scss"
import AppContext from "../../handle-reuses/Contexts/CreateContexts"
import { images } from "../../assets/images"
import moment from "moment/moment"

const cx = classNames.bind(styles)

function NotificationHeaderHome({ classIcon, className }) {
  const { dataUser, socketio } = useContext(AppContext)
  const [dataNotifi, setDataNotifi] = useState([])
  const [dataNotifiLichHen, setDataNotifiLichHen] = useState([])

  useEffect(() => {
    if (dataUser.length > 0) {
      // Nhận dữ liệu ban đầu của booking.
      socketio.emit("client-dataBooking", dataUser[0].id)
      // Nhận dữ liệu ban đầu nạp tiền thành công.
      const paramsPayHis = {
        iden: process.env.REACT_APP_IDENTIFICATION,
        userid: dataUser[0].id
      }
      socketio.emit("client-list-notifi-pay-his-success", paramsPayHis)
    }

    return () => {
      socketio.off("client-dataBooking")
      socketio.off("client-list-notifi-pay-his-success")
    }
  }, [])

  // Quản lý nhận thông báo từ admin đã duyệt.
  useEffect(() => {
    // thanh toán.
    if (dataUser.length > 0) {
      socketio.on("server-list-notifi-pay-his-success", (dataNotifi) => {
        let filterUser = dataNotifi.filter((item) => {
          return item.userid === dataUser[0].id
        })
        if (filterUser.length > 0) {
          setDataNotifi((old) => [...old, ...dataNotifi])
        }
      })
    }

    // Lịch hẹn.
    if (dataUser.length > 0) {
      // Nhận dữ thông báo ban đầu.
      socketio.on("server-dataBooking", (dataCalendarStart) => {
        setDataNotifiLichHen(dataCalendarStart)
      })
      socketio.on("notification", (dataCalendar) => {
        let filterData = dataCalendar.filter((item) => {
          return item.author_simple === dataUser[0].id
        })
        setDataNotifiLichHen(filterData)
      })
    }
    return () => {
      socketio.off("server-list-notifi-pay-his-success")
      socketio.off("server-dataBooking")
      socketio.off("notification")
    }
  }, [])

  // Ẩn thông báo khi bấm vào chuông thông báo.
  const checkChangeNotifi = useRef()
  const handleWatchedPayHis = () => {
    // Danh sách nạp tiền thành công.
    const dataEditWatchedPayHis = [...dataNotifi];
    checkChangeNotifi.current = dataEditWatchedPayHis// đánh dấu sự thay đổi để tắt socketio

    for (let i = 0; i < dataEditWatchedPayHis.length; i++) {
      const paramsEditWatched = {
        iden: process.env.REACT_APP_IDENTIFICATION,
        object: {
          id: dataEditWatchedPayHis[i].id,
          watched: 1
        }
      }
      socketio.emit("client-edit-watched-pay-his", paramsEditWatched)
      
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-watched-pay-his")
    }
  }, [checkChangeNotifi.current])

  return (
    dataUser.length > 0 && (
      <Tippy
        interactive
        delay={[0, 0]}
        placement="bottom-start"
        trigger="click"
        render={() => <ListNotification dataNotifiPayHis={dataNotifi} dataNotifiLichHen={dataNotifiLichHen} socketio={socketio}/>}
      >
        <button className="border-0 bg-transparent position-relative" onClick={handleWatchedPayHis} >
          <IoNotificationsOutline className={cx(classIcon)} />
          {(dataNotifi.length > 0 || dataNotifiLichHen.length > 0) && (
            <span
              className="badge bg-danger rounded-pill header-icon-badge pulse pulse-danger"
              style={{ top: "3px", right: "3px" }}
            >
              {Number(dataNotifi.length) + Number(dataNotifiLichHen.length)}
            </span>
          )}
        </button>
      </Tippy>
    )
  )
}
export default NotificationHeaderHome

// List notification.
function ListNotification({ dataNotifiPayHis, dataNotifiLichHen, socketio }) {

  return (
    <div className="bg-tippy-a" style={{width: "360px", height: "calc(100vh - 5rem)"}}>
      <div className="">
        <div className="p-3">
          <div className="d-flex align-items-center justify-content-between">
            <p className="mb-0 fs-17 fw-semibold">Thông báo</p>
          </div>
        </div>
        <div className="dropdown-divider"></div>
        <ul className="list-unstyled mb-0" id="header-notification-scroll">
          {/* List thông báo đã thanh toán */}
          {dataNotifiPayHis.length > 0 &&
            dataNotifiPayHis.map((item, index) => {
              return (
                <li className="dropdown-item" key={"pay" + index}>
                  <div className="d-flex align-items-start">
                    <div className="pe-2">
                      <span className="avatar avatar-md bg-primary-transparent avatar-rounded">
                        <img
                          src={item.avatar ? "/" + item.avatar : images.user}
                          alt=""
                        />
                      </span>
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                      <div>
                        <p className="mb-0 fw-semibold">
                          {item.title}{" "}
                          {item.status === 1
                            ? " đã được phê duyệt"
                            : " đã bị hủy"}
                        </p>
                        <span className="text-success fw-normal fs-15 header-notification-text">
                          Tài khoản của bạn vừa được +
                          {Number(item.deposit)
                            .toLocaleString()
                            .replace(/[,]/g, ",")}
                          đ
                        </span>
                        <BsDot />
                        <span className="text-muted">{moment(item.update_time).locale("vi").fromNow().replace("một", "1")}</span>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}

          {/* List thông báo có user gửi yêu cầu lịch hẹn booking */}
          {dataNotifiLichHen.length > 0 &&
            dataNotifiLichHen.map((item) => {
              return (
                <li className="dropdown-item" key={"booking" + item.id}>
                  <Link to="/lich-hen">
                    <div className="d-flex align-items-start">
                      <div className="pe-2">
                        <span className="avatar avatar-md bg-primary-transparent avatar-rounded">
                          <img src={images.calendar} alt="" />
                        </span>
                      </div>
                      <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                        <div>
                          <span className="fw-normal fs-15 header-notification-text">
                            <span className="fw-5">
                              {item.fullname} <BsDot /> {item.fullphone}
                            </span>
                            <p className="mb-0 fs-14 fw-5">
                              Gửi cho bạn 1 yêu cầu{" "}
                              {moment(item.addtime).locale("vi").fromNow()}
                            </p>
                            <p className="mb-0 text-muted">{item.name_estate}</p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              )
            })}

          {/* Nêu không có thông báo */}
          {Number(dataNotifiPayHis.length) +
            Number(dataNotifiLichHen.length) ===
            0 && <div className="text-center fs-15">Bạn chưa có thông báo.</div>}
        </ul>
      </div>
    </div>
  )
}
