import { useState, useContext, useEffect, useRef } from "react"
import classNames from "classnames/bind"
import io from "socket.io-client"
import { IoFilterCircleOutline } from "react-icons/io5"

import styles from "./PageHome.module.scss"
import { LoadingSpinner } from "../../../components/Loading"
import { HeaderHome2 } from "../../../components/Header"
import ListCategory from "./ListCategory"
import ListItemRealEstate from "./ListItemRealEstate"
import Footer from "../../../components/Footer"
import SideBarHome from "./SidebarHome"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import ModalReact from "../../../components/Modal/ModalReact"
import { FilterEstate } from "../../../components/Filter"
const cx = classNames.bind(styles)

function HomePageLeftRight() {
  /** =========== Xử lý logic ================ */
  const { dataUser, socketio } = useContext(AppContext)
  const [dataEstate, setDataEstate] = useState([])
  const [loading, setLoading] = useState(true)
  const overPage = useRef(false)
  const limit = 20
  const page = useRef(1)
  const dataCate = useRef([])

  // Xac nhan duoc su dung tai nguyen
  const identification = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      identification: `${process.env.REACT_APP_IDENTIFICATION}`,
    },
  }

  const fetchData = async (limit_, page_) => {
    try {
      // data estate
      const response = await fetch(
        process.env.REACT_APP_APIGetDataPageHomeMarketSimpleF +
          `?quantity=${limit_}&offset=${(page_ - 1) * limit_}`,
        identification,
      )
      const dataRes = await response.json();
      if (dataRes.length > 0) {
        const sortData = dataRes.sort((a, b) => {
          let min_ = a.admoney
          let max_ = b.admoney;
          let minTime_ = new Date(a.update_time).getTime()
          let maxTime_ = new Date(b.update_time).getTime()
          return (maxTime_ - minTime_) //&& (max_ - min_)  
        });
        setDataEstate((old) => [...old, ...sortData])
        dataCate.current = sortData
        // Kiểm tra lần 1 xem hết dữ liệu chưa.
        if (dataRes.length !== limit) {
          overPage.current = true
        }
      } else {
        //Xác nhận Đã lấy hết dữ liệu
        overPage.current = true
      }
      setLoading(false)
    } catch (err) {
      console.log("Kết nối thất bại")
    }
  }
  useEffect(() => {
    fetchData(limit, page.current)
  }, [])

  // Sự kiện tự động load data khi cuộn xuống.
  const handleScrollAuto = ()=>{
    // tổng chiều dài của window.
    const totalHeight = window.innerHeight;
    const scrollY = window.scrollY;
    
    // 256 là kích thước của footer
    if (scrollY >= (totalHeight-256) && !overPage.current) {
      page.current = page.current + 1;
      fetchData(limit, page.current)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScrollAuto)
    return () => {
      window.removeEventListener("scroll", handleScrollAuto)
    }
  }, [])

  // Hiển thị theo danh mục
  const handleCateShowHome = (key) => {
    let cateInput = key.toLowerCase();
    if (cateInput === "home") {
      setDataEstate(dataCate.current)
    } else {
      socketio.emit("client-get-data-cate", cateInput)
    }
  }
  useEffect(() => {
    socketio.on("server-get-data-cate", (data) => {
      setDataEstate(data)
    })
    return () => {
      socketio.off("server-get-data-cate")
      socketio.off("client-get-data-cate")
    }
  }, [])

  
  // Modal filter.
  const [modalFilter, setModalFilter] = useState(false)
  const handleShowModalFilter = () => {
    setModalFilter(true)
  }
  const handleCloseModalFilter = () => setModalFilter(false)
  /** =========== * Xử lý logic ================ */

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <>
      {/* // Header */}
      <HeaderHome2 />
      {/* // Main  */}
      <div className={cx("Sidebar_Content")}>
        {/* Left */}
        <div className={cx("col-xl-12", "ContentPro")}>
          <div
            className={cx(
              "mb-3",
              "ListCategory",
              "d-flex justify-content-between align-items-center",
            )}
          >
            <ListCategory result={handleCateShowHome} />
            <button
              className={cx("me-4 fs-15 btn btn-outline-dark rounded-3")}
              onClick={handleShowModalFilter}
            >
              <IoFilterCircleOutline className="me-2 fs-21" />
              Bộ lọc
            </button>
          </div>
          <div className={cx("row col-12")}>
            <ListItemRealEstate dataEstate={dataEstate} />
          </div>
        </div>
        {/* Right */}
        <SideBarHome />
      </div>
      {/* Modal bộ lọc */}
      <ModalReact
        modalTitle="Bộ lọc sản phẩm"
        size="modal-lg"
        showModal={modalFilter}
        handleClose={handleCloseModalFilter}
        theme={<FilterEstate />}
      />
      {/* // Footer */}
      <Footer />
    </>
  )
}

export default HomePageLeftRight
