import io from "socket.io-client"
import { useState, useEffect, useRef, useContext } from "react";
import classNames from "classnames/bind"
import { IoNotificationsOutline, IoFilterCircleOutline } from "react-icons/io5"
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io"

import styles from "../../Mobile.module.scss"
import SearchHomeMobile from "../../Search/SearchHome/SearchHomeMobile";
import InfoUserMobile from "../InfoUser/InfoUserMobile";
import AppContext from "../../../../handle-reuses/Contexts/CreateContexts";
import IconNotifiMobile from "../../Notification/IconNotifiMobile";

const cx = classNames.bind(styles);

function ComponentHeader() {
  const {socketio} = useContext(AppContext)
  const [notifiCount, setNotifiCount] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    socketio.on("server-notification", function (data) {
      setNotifiCount(data.length)
    })
    return () => {
      socketio.off("server-notication")
    }
  }, [])
  // Nút quay lại.
  const goBack = () => {
    navigate(-1)
  }

    return (
      <div className={cx("Wrapper_HeaderMobile")}>
        <div
          className={cx(
            "d-flex align-items-center justify-content-between mb-2",
            "headerMobileDetail",
          )}
        >
          {/* <div>
            <Link to="/">
              <img src={imageLogo.logo} alt="Logo" width={120} height={30} />
            </Link>
          </div> */}
          <div className={cx("left", "d-flex align-items-center")}>
            <Link
              to="#"
              className={cx("headerButton", "goBack")}
              onClick={goBack}
            >
              <IoIosArrowBack className="me-1 fs-15" />{" "}
              <span className="fs-14">QUAY LẠI</span>
            </Link>
          </div>
          <div
            className={cx(
              "col-6 d-flex justify-content-end align-items-center",
            )}
            style={{ columnGap: "18px" }}
          >
            <Link to="/filter">
              <IoFilterCircleOutline className="fs-29" />
            </Link>
            <Link to="/notification" className={cx("position-relative")}>
              {/* <IoNotificationsOutline className="fs-29" />{" "}
              {notifiCount > 0 ? (
                <span className={cx("countNotifiMobi")}>{notifiCount}</span>
              ) : (
                ""
              )} */}
              <IconNotifiMobile classIcon="fs-29"/>
            </Link>
            <InfoUserMobile height={30} width={30} />
          </div>
        </div>
        {/* Search */}
        <SearchHomeMobile />
      </div>
    )
}

export default ComponentHeader
