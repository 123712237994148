import { useState, useContext, useEffect, useRef } from "react"
import { Nav } from "react-bootstrap"
import moment from "moment"
import classNames from "classnames/bind"

import styles from "../../Dashboard.module.scss"
import ListProduct from "./ListProduct"
import SearchSimple from "../../../../../components/Search/SearchSimple"
import AppContext from "../../../../../handle-reuses/Contexts/CreateContexts"

const cx = classNames.bind(styles)

function Sanpham({ data, dataBooking, socketio, result, effectLoading, checkSearch }) {
  const dataG = useContext(AppContext)
  const dataUser = dataG.dataUser
  const [activeTab, setActiveTab] = useState("home")
  const [dataEstates, setDataEstates] = useState([...data])
  const checkStatusSearch = useRef("")
  useEffect(() => {
    setDataEstates(data)
  }, [data])
  // Get value search.
  const handleGetValue = (valueText) => {
    // Kiểm tra nếu đang tìm kiếm thì chức năng cuộn tải dữ liệu dừng lại
    checkSearch(valueText) 
    checkStatusSearch.current = valueText
    // Kiểm tra điều kiện tìm kiếm
    if (valueText !== "") {
      const params = {
        iden: process.env.REACT_APP_IDENTIFICATION,
        object: {
          userid: dataUser[0].id,
          keyword: valueText,
        },
      };
      socketio.emit("client-search-dashboard-for-user", params)
      socketio.on("server-search-dashboard-for-user", (data_) => {
        setDataEstates(data_)
      })
    } else {
      // Trả về mảng ban đầu
      setDataEstates(data)
    }
  }
  
  // Quản lý dữ liệu search.
  useEffect(() => {
    return () => {
      socketio.off("server-search-dashboard-for-user")
      socketio.off("client-search-dashboard-for-user")
    }
  }, [])

  // Data không quảng cáo
  const dataNoAd = dataEstates.filter((item) => {
    return item.status === 0
  })
  // data hết hạn.
  const dataExpired = dataEstates.filter((item) => {
    let endDate = moment(item.endtime)
    let currentDate = moment()
    let diffTime = endDate.diff(currentDate, "days")
    return item.status === 1 && diffTime <= 0
  })
  // data quảng cáo.
  const dataAd = dataEstates.filter((item) => {
    let endDate = moment(item.endtime)
    let currentDate = moment()
    let diffTime = endDate.diff(currentDate, "days")
    return item.status === 1 && diffTime > 0
  })

  const handleTabSelect = (key) => {
    setActiveTab(key)
  }


  return (
    <div className="tab-style-2">
      <div className="row align-items-center">
        <Nav
          variant="tabs"
          defaultActiveKey="home"
          className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 mb-3"
        >
          <Nav.Item>
            <Nav.Link
              eventKey="home"
              onClick={() => handleTabSelect("home")}
              active={activeTab === "home"}
              className="d-flex flex-direction-column align-items-center fw-6 fs-15"
            >
              {/* <IoCalendarOutline className="mb-1 fs-21" /> */}
              Tất cả ({Number(data.length).toLocaleString()})
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="no-ad"
              onClick={() => handleTabSelect("no-ad")}
              active={activeTab === "no-ad"}
              className="d-flex flex-direction-column align-items-center fw-6 fs-15"
            >
              Không quảng cáo ({Number(dataNoAd.length).toLocaleString()})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="hethan"
              onClick={() => handleTabSelect("hethan")}
              active={activeTab === "hethan"}
              className="d-flex flex-direction-column align-items-center fw-6 fs-15"
            >
              Hết hạn ({Number(dataExpired.length).toLocaleString()})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="quangcao"
              onClick={() => handleTabSelect("quangcao")}
              active={activeTab === "quangcao"}
              className="d-flex flex-direction-column align-items-center fw-6 fs-15"
            >
              {/* <BsCalendar2Week className="mb-1 fs-19" /> */}
              Quảng cáo ({Number(dataAd.length).toLocaleString()})
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 d-flex justify-content-end mb-3">
          <SearchSimple result={handleGetValue} />
        </div>
      </div>

      {/* Nội dung của tab được hiển thị dựa trên activeTab */}
      <div className={cx("mt-3")}>
        {activeTab === "home" && (
          <div className="">
            <ListProduct
              data={dataEstates}
              dataBooking={dataBooking}
              socketio={socketio}
              result={result}
              effectLoading={effectLoading}
              statusSearch={checkStatusSearch.current}
            />
          </div>
        )}
        {activeTab === "no-ad" && (
          <div className="">
            <ListProduct
              data={dataNoAd}
              dataBooking={dataBooking}
              socketio={socketio}
              result={result}
              effectLoading={effectLoading}
              statusSearch={checkStatusSearch.current}
            />
          </div>
        )}
        {activeTab === "hethan" && (
          <div className="">
            <ListProduct
              data={dataExpired}
              dataBooking={dataBooking}
              socketio={socketio}
              result={result}
              effectLoading={effectLoading}
              statusSearch={checkStatusSearch.current}
            />
          </div>
        )}
        {activeTab === "quangcao" && (
          <div className="">
            <ListProduct
              data={dataAd}
              dataBooking={dataBooking}
              socketio={socketio}
              result={result}
              effectLoading={effectLoading}
              statusSearch={checkStatusSearch.current}
            />
          </div>
        )}
      </div>
    </div>
  )
}
export default Sanpham
