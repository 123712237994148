import { useContext, useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import classNames from "classnames/bind"

import styles from "../../Dashboard.module.scss"
import { handleExtensionProduct } from "../../../../../handle-reuses/Market-simple/Method/Market-simple"
import AppContext from "../../../../../handle-reuses/Contexts/CreateContexts"

const cx = classNames.bind(styles)

function TimeExtension({dataEstate, id, admoneyOld, status, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const { socketio, dataUser } = useContext(AppContext);
  const checkChange = useRef()
  const onSubmit = (data) => {
    data.id = id
    const checkGiahanQC = window.confirm("Tin của bạn sẽ được truyền thông, mức phí là 50,000đ/tháng. Bạn đồng ý chứ ?")
    if (checkGiahanQC) {
      socketio.emit("client-check-user-pay", dataUser[0].id)
      socketio.on("server-check-user-pay", (dataPayHis) => {
        handleExtensionProduct(data, admoneyOld, dataPayHis, reset, result)
        checkChange.current = data
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-check-user-pay")
      socketio.off("server-check-user-pay")
    }
    
  }, [checkChange.current])
  return (
    <div className="card-body">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-xl-12 col-md-12 py-3 px-3">
          <label className="form-label mb-2">
            {dataEstate.status === 1 ? "Gia hạn thời gian quảng cáo" : "Nhập thời gian quảng cáo"}<sup className="text-danger">*</sup>
          </label>
          <input
            type="number"
            {...register("endtime", { required: true })}
            className="form-control"
            placeholder="Nhập số tháng"
          />
          {errors.endtime && (
            <span className={cx("text-danger", "fs-9")}>
              *Bạn chưa thiết lập thời gian chạy quảng cáo.
            </span>
          )}
        </div>
        <div className="px-4 d-flex py-2 border-top border-block-start-dashed d-sm-flex justify-content-center">
          <button className="btn btn-outline-dark fs-12" type="submit">
            {status === 1 ? "Gia hạn" : "Quảng cáo"}
          </button>
        </div>
      </form>
    </div>
  )
}

export default TimeExtension
