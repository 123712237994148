import { useState, useEffect, useContext } from "react"
import { Nav } from "react-bootstrap"
import classNames from "classnames/bind"
import styles from "../../Dashboard.module.scss"
import Lichhen from "./Lichhen"
import SearchSimple from "../../../../../components/Search/SearchSimple"
import AppContext from "../../../../../handle-reuses/Contexts/CreateContexts"
import { LoadingSpinner } from "../../../../../components/Loading"

const cx = classNames.bind(styles)

function LichhenSidebar() {
  const dataG = useContext(AppContext)
  const dataUser = dataG.dataUser
  const [activeTab, setActiveTab] = useState("home")
  const [dataBooking, setDataBooking] = useState([])
  const [dataPending, setDataPending] = useState([])
  const [dataLoading, setDataLoading] = useState([])
  const [dataSuccess, setDataSuccess] = useState([])
  const [dataPro, setDataPro] = useState([])
  const [loading, setLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  // get data.
  const fetchData = async () => {
    // booking
    const response = await fetch(
      process.env.REACT_APP_APIGetForUserBooking + `?only=${dataUser[0].id}`,
    )
    const dataRes = await response.json()
    // product.
    const responsePro = await fetch(
      process.env.REACT_APP_APIGetDataMarketSimple +
        `?phone=${dataUser[0].sodienthoai}`,
    )
    const dataResPro = await responsePro.json()

    const filterDataPending = dataRes.filter((item) => item.status === 0)
    const filterDataLoading = dataRes.filter((item) => item.status === 1)
    const filterDataSuccess = dataRes.filter((item) => item.status === 2)
    setDataPending(filterDataPending)
    setDataLoading(filterDataLoading)
    setDataSuccess(filterDataSuccess)

    setDataBooking(dataRes)
    setDataPro(dataResPro)
    setLoading(false)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [isLoading])

  // Các danh mục
  const handleTabSelect = (key) => {
    setActiveTab(key)
  }

  // Handle search.
  const [dataSearch, setDataSearch] = useState([])
  const handleValueSearch = (valueInput) => {
    setActiveTab("searchData")
    const fetchDataSearch = async () => {
      const response = await fetch(
        process.env.REACT_APP_APIGetForUserBooking +
          `?only=${dataUser[0].id}&keyword=${valueInput}`,
      )
      const dataRes = await response.json()
      setDataSearch(dataRes)
    }
    fetchDataSearch()
  }

  // Kiểm tra trạng thái nếu được chuyển đổi
  const handleCheckStatus = (res) => {
    if (res === true) {
      setIsLoading(true)
    }
  }

  // Xác nhận chuyển đổi trạng thái thành công.
  const handleStatusSuccess = (status_) => {
    if (status_) {
      fetchData()
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div className={cx("tab-style-2 p-0", "listCate")}>
      <div className="d-flex align-items-center">
        <Nav variant="tabs" defaultActiveKey="home" className="col-8">
          <Nav.Item>
            <Nav.Link
              eventKey="home"
              onClick={() => handleTabSelect("home")}
              active={activeTab === "home"}
              className="d-flex flex-direction-column align-items-center fw-6 fs-16"
            >
              {/* <IoCalendarOutline className="mb-1 fs-21" /> */}
              Tất cả (
              {Number(dataBooking.length).toLocaleString().replace(/[.]/g, ",")}
              )
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="pending"
              onClick={() => handleTabSelect("pending")}
              active={activeTab === "pending"}
              className="d-flex flex-direction-column align-items-center fw-6 fs-16"
            >
              {/* <BsCalendar2Week className="mb-1 fs-19" /> */}
              Chờ xử lý (
              {Number(dataPending.length).toLocaleString().replace(/[.]/g, ",")}
              )
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="loading"
              onClick={() => handleTabSelect("loading")}
              active={activeTab === "loading"}
              className="d-flex flex-direction-column align-items-center fw-6 fs-16"
            >
              Đang xử lý (
              {Number(dataLoading.length).toLocaleString().replace(/[.]/g, ",")}
              )
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="success"
              onClick={() => handleTabSelect("success")}
              active={activeTab === "success"}
              className="d-flex flex-direction-column align-items-center fw-6 fs-16"
            >
              Đã xử lý (
              {Number(dataSuccess.length).toLocaleString().replace(/[.]/g, ",")}
              )
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="col-4 d-flex justify-content-end">
          <SearchSimple result={handleValueSearch} />
        </div>
      </div>

      {/* Nội dung của tab được hiển thị dựa trên activeTab */}
      <div className={cx("container-1", "mt-3")}>
        {activeTab === "home" && (
          <div className="">
            <Lichhen
              data={dataBooking}
              dataPro={dataPro}
              result={handleCheckStatus}
            />
          </div>
        )}
        {activeTab === "pending" && (
          <div className="">
            <Lichhen
              data={dataPending}
              dataPro={dataPro}
              result={handleStatusSuccess}
            />
          </div>
        )}
        {activeTab === "loading" && (
          <div className="">
            <Lichhen
              data={dataLoading}
              dataPro={dataPro}
              result={handleStatusSuccess}
            />
          </div>
        )}
        {activeTab === "success" && (
          <div className="">
            <Lichhen
              data={dataSuccess}
              dataPro={dataPro}
              result={handleStatusSuccess}
            />
          </div>
        )}
        {activeTab === "searchData" && (
          <div className="">
            <Lichhen
              data={dataSearch}
              dataPro={dataPro}
              result={handleStatusSuccess}
            />
          </div>
        )}
      </div>
    </div>
  )
}
export default LichhenSidebar
