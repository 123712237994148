import HomePageLeftRight from "./LayoutLeftRight"
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import PageHomeMobile from "../../mobile/Home"
import { Helmet } from "react-helmet"

function HomePage() {
  return (
    <>
      <Helmet>
        <title>Bất động sản view360</title>
        <meta
          name="description"
          content="Kênh thông tin bất động sản như bán nhà, bán đất, cho thuê căn hộ..."
        />
        <meta property="og:title" content="Bất động sản view360"></meta>
        <meta
          property="og:description"
          content="Kênh thông tin bất động sản như bán nhà, bán đất, cho thuê căn hộ..."
        ></meta>
        <meta property="og:image" content="%PUBLIC_URL%/logo-512.png"></meta>
        <meta property="og:image:width" content="500"></meta>
        <meta property="og:image:height" content="500"></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:url" content="https://batdongsan.view360.vn"></meta>
      </Helmet>
      <CheckPCMobile>
        {(isMobile) => (!isMobile ? <HomePageLeftRight /> : <PageHomeMobile />)}
      </CheckPCMobile>
    </>
  )
}

export default HomePage
