import classNames from "classnames/bind"
import { Link } from "react-router-dom"
import { useState } from "react"
import { IoHomeOutline } from "react-icons/io5"

import styles from "./Cate.module.scss"
const cx = classNames.bind(styles)

function CategoryHorizontal({ data, className, result}) {
    const [activeItem, setActiveItem] = useState("Home")
    const handleActiveItem = (key) => {
      setActiveItem(key)
      result(key)
    }
    return (
      <>
        <ul className={cx("d-flex", className, "ListCategory")}>
          <li>
            <Link
              to="/"
              className={cx(
                "warpper",
                "me-3",
                activeItem === "Home" ? "active" : "op-6",
              )}
              onClick={() => handleActiveItem("Home")}
            >
              <span className={cx("fs-23")}>
                <IoHomeOutline />
              </span>
              <span className={cx("fs-15")}>Trang chủ</span>
            </Link>
          </li>
          {data.map((item, index) => (
            <li key={item.id}>
              <Link
                to={item.path}
                className={cx(
                  "warpper",
                  "me-3",
                  activeItem === item.title ? "active" : "op-6",
                )}
                onClick={() => handleActiveItem(item.title)}
              >
                <span className={cx("fs-23")}>{item.icon}</span>
                <span className={cx("fs-15")}>{item.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </>
    )
}

export default CategoryHorizontal