import { useContext, useState, useEffect, useRef } from "react"
import SearchHorizontal from "../../../components/Search/SearchHorizontal"
import HeaderDashboardPage from "./Header"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import { TextLimit, deleteAccentedText, handleFormatPrice } from "../../../handle-reuses/reuses"
import { FaHeart } from "react-icons/fa6"
import { SlHeart } from "react-icons/sl"
import { SliderImageList } from "../../../components/Slider"
import { images } from "../../../assets/images"
import { Link } from "react-router-dom"
import moment from "moment"
import { ToastContainer, toast } from "react-toastify"
import { handleMethodCreateData, handleMethodEditData } from "../../../handle-reuses/reuses/method"

function ProductLikePagePC() {
    const { dataUser, socketio } = useContext(AppContext)
    const [dataEstate, setDataEstate] = useState([])
    const checkGetSavedLikePage = useRef(true) // chỉ cho phép gọi dữ liệu tại trang này
    const [loading, setLoading] = useState(true)
    const [dataSearch, setDataSearch] = useState()
    
    // Quản lý get data.
    useEffect(() => {
        // Điều kiện chỉ cho phép gọi dữ liệu tại trang này.
        if (checkGetSavedLikePage.current && dataUser.length > 0) {
            const params = {
              iden: process.env.REACT_APP_IDENTIFICATION,
              author: dataUser[0].id,
            }
            socketio.emit("client-status-success-saved", params)
        }
        return () => {
            socketio.off("client-status-success-saved")
        }
    }, [])

    
    // Quản lý nhận data.
    useEffect(() => {
        // data user saved 
        socketio.on("server-status-success-saved", (data) => {
          if (data.length === 0) {
            setLoading(false);
          } else {
            for (let i = 0; i < data.length; i++) {
              let params = {
                iden: process.env.REACT_APP_IDENTIFICATION,
                simpleid: data[i].simpleid,
              };
              socketio.emit("client-simple-saved-for-user", params)
            }
          }
        })

        // Nhận data simple for user.
        const arrEstate = []
        socketio.on("server-simple-saved-for-user", (data) => {
          if (data.length > 0) {
              let filterData = arrEstate.filter((item) => {
                return item.id === data[0].id
              })
              if (filterData.length === 0) {
                arrEstate.push(data[0])
              }
              setDataEstate(arrEstate)
              
            }
            setLoading(false)
        })
        
        return () => {
            socketio.off("server-status-success-saved")
            socketio.off("client-simple-saved-for-user")
            socketio.off("server-simple-saved-for-user")
        }

    }, []);
    // Cần mà mới dữ liệu khi có sự thay đổi trạng thái.
    const handleRefreshDataEstate = (status_, simpleId) => {
        if (status_) {
            // Làm mới data cũ.
            const updateDataEstate = [...dataEstate];
            const filterDataEstate = updateDataEstate.filter((item) => {
                return item.id !== simpleId
            })
            setDataEstate(filterDataEstate)
        }
    }
    // Giá trị search.
    const handleValueSearch = (result) => {
        if (result.length > 0) {
            const dataSearch_ = dataEstate.filter((item) => {
                return item.address.toLowerCase().includes(result.toLowerCase())
            });
            setDataSearch(dataSearch_)
         } else {
            setDataSearch()
        }
    }

    return (
      <>
        {/* Header */}
        <HeaderDashboardPage />
        {/* danh sách sản phẩm */}
        <div style={{ padding: "0 86px" }}>
          <div className="container-lg p-0 mt-3">
            {/* Search */}
            <div className="col-12 row justify-content-center">
              <div className="col-12 col-xl-6">
                <SearchHorizontal
                  result={handleValueSearch}
                  className="rounded-3"
                  placeholder="Tìm kiếm sản phẩm đã lưu..."
                />
              </div>
            </div>
            {/* Danh sách */}
            <div className="mt-4 row">
              {loading && <div className="text-center">Đang tải...</div>}
              {!loading && (
                <ListProductSavedPC
                  dataEstate={dataSearch ? dataSearch : dataEstate}
                  socketio={socketio}
                  result={handleRefreshDataEstate}
                />
              )}
            </div>
          </div>
        </div>
      </>
    )
}

export default ProductLikePagePC


// Danh sách sản phẩm đã lưu.
function ListProductSavedPC({dataEstate, socketio, result}) {
    const { dataUser } = useContext(AppContext)

    // Xử lý lưu lại sản phẩm cho khách hàng.
    const [dataSaved, setDataSaved] = useState([])
    const simpleSavedId = useRef()
    const handleEventSaved = (simpleId) => {
      if (dataUser.length > 0) {
        // kiểm tra xem tồn tại đã từng lưu chưa.
        const params = { simpleid: simpleId, userid: dataUser[0].id }
        socketio.emit("client-simple-saved", params)
        simpleSavedId.current = simpleId
        // Thông báo đã thay đổi trạng thái cần làm mới dữ liệu.
        result(true, simpleId)
      } else {
        alert("Bạn cần đăng nhập trước khi lưu")
      }
        
        
    }
    useEffect(() => {
      socketio.on("server-simple-saved", (dataSaved_) => {
        handleCreateEditSaved(
          dataSaved_,
          simpleSavedId.current,
          dataUser,
          handleUpdateDataSaved,
        )
      })
      return () => {
        socketio.off("server-simple-saved")
      }
    }, [])

    // Xác định user đã lưu sản phẩm hay chưa.
    const handleUpdateDataSaved = () => {
      socketio.emit("client-saved", dataUser[0].id)
      socketio.on("server-saved", (dataSimpleSaved) => {
        setDataSaved(dataSimpleSaved)
      })
    }
    useEffect(() => {
      // Chạy lần đầu
      if (dataUser.length > 0) {
        handleUpdateDataSaved()
      }
    }, [])

    // count view khi bấm chuột phải.
    const [checkEvent, setCheckEvent] = useState(0)
    const idSimpleOld = useRef(0)
    const handleCountview = (simpleId) => {
      setCheckEvent(simpleId)
      socketio.emit("client-detail-product", simpleId)
    }
    useEffect(() => {
      // Tăng view khi click.
      if (checkEvent !== 0) {
        socketio.on("server-detail-product", (dataUpdate) => {
          const params = {
            id: checkEvent,
            countview: Number(dataUpdate[0].countview) + 1,
          }
          if (checkEvent !== idSimpleOld.current) {
            socketio.emit("client-click-views", params)
          }
          // Xác nhận id này vừa mới tăng view rồi.
          idSimpleOld.current = checkEvent
        })
      }

      return () => {
        socketio.off("client-detail-product")
        socketio.off("server-detail-product")
        socketio.off("client-click-views")
      }
    }, [checkEvent])

    return (
      <>
        {/* Loading */}
        {!dataEstate && (
          <div className="text-center mt-5 fs-18">Đang tải...</div>
        )}
        {dataEstate.length === 0 && (
          <div className="text-center mt-5 fs-18">Chưa có sản phẩm.</div>
        )}
        {/* Nội dung page home */}
        {dataEstate.map((item, index) => {
          let listImages = []
          if (item.images) {
            listImages = item.images.split(",")
          }
          let filterSaved = dataSaved
          if (filterSaved.length > 0) {
            filterSaved = dataSaved.filter((item_) => {
              return item_.simpleid === item.id
            })
          }
          // format url detail.
          const formatCateUrl = deleteAccentedText(item.cate).toLowerCase()
          const cate = formatCateUrl.replace(/[ ]/g, "-")
          const formatAddress = deleteAccentedText(item.address).toLowerCase()
          const address = formatAddress.replace(/[ ]/g, "-")
          // Giới hạn title.
          let limitTitle = item.address + " " + item.districts + " " + item.city

          return (
            <div
              className="col-xl-3 col-sm-6 col-md-6"
              key={item.id + "-" + index}
              // onTouchStart={() => handleCountview(item.id)}
              onMouseEnter={() => handleCountview(item.id)}
            >
              <div className="card border-0">
                <div style={{ borderRadius: "15px" }} className="fh">
                  <div className="position-absolute zIndex-9 col-12 p-3">
                    <div className="d-flex justify-content-between">
                      <div className="fs-15 likePro fw-5 limitText">
                        {item.cate}
                      </div>
                      <button onClick={() => handleEventSaved(item.id)} className="border-0 bg-transparent">
                        {filterSaved.length > 0 ? (
                          filterSaved[0].status === 1 ? (
                            <FaHeart className="fs-21" />
                          ) : (
                            <SlHeart className="fs-21" />
                          )
                        ) : (
                          <SlHeart className="fs-21" />
                        )}
                      </button>
                    </div>
                  </div>
                  {/* list image */}
                  {listImages.length > 0 ? (
                    <SliderImageList data={listImages} />
                  ) : (
                    <img
                      src={images.noImagePro}
                      className="d-block w-100"
                      alt="..."
                      // height={268}
                      style={{ borderRadius: "15px" }}
                    />
                  )}
                  {/* list image */}
                </div>
                <div className="card-body p-0 mt-3 mb-3">
                  <Link
                    to={
                      "/" +
                      cate +
                      "-" +
                      address +
                      "/" +
                      item.alias +
                      "-pr" +
                      item.id
                    }
                  >
                    <div className="d-flex justify-content-between">
                      <h6 className="card-title fw-6 mb-1 titleAddress limitText">
                        <TextLimit text={limitTitle} number={30} />
                      </h6>
                      {/* <div className="d-flex align-items-center">
                            <Star
                              style={{ width: "15px", height: "15px" }}
                              className="me-1"
                            />
                            689
                          </div> */}
                    </div>
                    <p className="card-text mb-1 op-6 limitDiscription fs-16 fontSize">
                      đã có{" "}
                      {Number(item.countview)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}{" "}
                      lượt tham khảo sản phẩm,{" "}
                      {item.countclick > 20 ? (
                        <span>{item.countclick} lượt tương tác</span>
                      ) : (
                        <span>
                          cập nhật mới nhất{" "}
                          {moment(item.update_time).format("DD/MM/YYYY")}
                        </span>
                      )}
                    </p>
                    <p className="card-text mb-0">
                      <span className="fw-5 me-1 fs-16">
                        Giá:{" "}
                        {Number(item.price) > 0
                          ? handleFormatPrice(Number(item.price))
                          : "Liên hệ"}
                      </span>
                      <span>
                        {item.donvi === "Thỏa thuận" ||
                        item.donvi.toLowerCase() === ""
                          ? ""
                          : "/ " + item.donvi.toLowerCase()}
                      </span>
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          )
        })}
        <ToastContainer />
      </>
    )
}


// Xử lý create edit saved
function handleCreateEditSaved(
  dataSaved_,
  simpleId,
  dataUser,
  handleUpdateDataSaved,
) {
  if (dataSaved_.length === 0) {
    // thêm mới
    const object = {
      simpleid: simpleId,
      status: 1,
      author: dataUser[0].id,
    }
    handleMethodCreateData(
      object,
      () => {
        alert("Bạn đã lưu thành công. Vào bảng điều khiển để xem")
        handleUpdateDataSaved()
      },
      process.env.REACT_APP_APICreateMarketSimpleSaved,
    )
  } else {
    // Hủy lưu
    let object, alertSeved
    if (dataSaved_[0].status === 0) {
      object = {
        id: dataSaved_[0].id,
        status: 1,
      }
      alertSeved = "Bạn đã LƯU thành công."
      handleMethodEditData(
        object,
        () => {
          toast.success("Lưu thành công", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            progress: undefined,
            theme: "light",
          })
          handleUpdateDataSaved()
        },
        process.env.REACT_APP_APIEditStatusMarketSimpleSaved,
      )
    } else {
      object = {
        id: dataSaved_[0].id,
        status: 0,
      }
      alertSeved = "Bạn đã HỦY thành công."
      handleMethodEditData(
        object,
        () => {
          toast.error("Đã hủy", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            progress: undefined,
            theme: "light",
          })
          handleUpdateDataSaved()
        },
        process.env.REACT_APP_APIEditStatusMarketSimpleSaved,
      )
    }
  }
}