// Kiểu header chợ tốt Kết hợp tham khảo khác
import { useContext, useState } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames/bind"
import styles from "./Header.module.scss"
import { CiCompass1 } from "react-icons/ci"
import { PiMonitor } from "react-icons/pi"
import { PiUser} from "react-icons/pi"

import { NotificationHeaderHome } from "../Notification"
import SearchHome from "../Search/SearchHome"
import InfoUser from "../Info/InfoUser"
import AppContext from "../../handle-reuses/Contexts/CreateContexts"
import ModalReact from "../Modal/ModalReact"
import { HandleResult } from "../Phongthuy/Phongthuy"
import { imageLogo } from "../../assets/images"
import { TextLimit } from "../../handle-reuses/reuses"

const cx = classNames.bind(styles)

function HeaderHome2() {
  const { dataUser } = useContext(AppContext)

  // Modal liên hệ.
  const [introduceModal, setIntroduceModal] = useState(false)
  const handleModalIntroduce = () => {
    setIntroduceModal(true)
  }
  const handleCloseModalIntroduce = () => setIntroduceModal(false)
  // Modal phong thuy.
  const [showHide, setShowHide] = useState(false)
  const handleModalPhongthuy = () => {
    setShowHide(true)
  }
  const handleClose = () => setShowHide(false)
  return (
    <div className={cx("NavBar_Wrapper_2")}>
      {/* contact */}
      <div>
        <div
          className={cx("Navbar_Wrapper_Contact", "justify-content-between")}
        >
          <div className={cx("fs-13", "wrapper_contact_left")}>
            <ul className={cx("d-flex")}>
              {/* <li className={cx("contact-2", "me-3")}>
                <button className={cx("me-3", "bg-transparent", "border-0")} >
                  Liên hệ
                </button>
              </li> */}
              {/* <li className={cx("contact-2", "me-3")}>
                <Link to="/" className={cx("me-3")}>
                  Chuyển đổi số
                </Link>
              </li> */}
              <li className={cx("contact-2", "me-3 fw-5")}>
                <Link to="/" className={cx("me-3")}>
                  Bất động sản
                </Link>
              </li>
              <li className={cx("contact-2", "me-3 fw-5")}>
                <a href="https://view360.com.vn" className={cx("me-3")}>
                  View 360
                </a>
              </li>
              <li className={cx("contact-3")}>
                <button
                  className={cx("me-3", "bg-transparent", "border-0 fw-5 op-7")}
                  onClick={handleModalIntroduce}
                >
                  Giới thiệu
                </button>
              </li>
            </ul>
          </div>
          <div className={cx("wrapper_left", "fs-21", "d-flex")}>
            <NotificationHeaderHome classIcon="fs-26" className="me-2" />
            {/* <Tippy content="Phong thủy" className="fs-11">
              <span>
                <Phongthuy sizeIcon="fs-26" />
              </span>
            </Tippy> */}
            {/* <button
              type="button"
              className={cx("me-2", "border-0", "bg-transparent")}
            >
              <IoWalletOutline />
            </button> */}
          </div>
        </div>
      </div>
      {/* NavBar */}
      <div className={cx("NavBar_Wrapper_Home", "justify-content-between")}>
        <div className={cx("col-xl-2")}>
          <Link to="/">
            <img
              src={imageLogo.logo}
              className={cx("w_168")}
              alt="Bất động sản"
            />
          </Link>
        </div>
        <div className={cx("wrapper_main", "col-xl-4")}>
          <div className={cx("Navbar_Search")}>
            <SearchHome />
          </div>
        </div>
        <div className={cx("wrapper_right", "col-xl-5 d-flex")}>
          <Link
            to="#"
            className={cx("d-flex", "align-items-center", "me-4")}
            onClick={handleModalPhongthuy}
          >
            <CiCompass1
              className={cx("me-2", "text-muted", "bg_icon_header")}
            />
            <div className={cx("fs-15")}>
              <div className={cx("text-muted fw-5")}>Phong thủy</div>
              <div className={cx("fw-5")}>Xem hướng nhà</div>
            </div>
          </Link>
          <Link
            to="/dashboard"
            className={cx("d-flex", "align-items-center", "me-4")}
          >
            <PiMonitor className={cx("me-2", "text-muted", "bg_icon_header")} />
            <div className={cx("fs-15")}>
              <div className={cx("text-muted fw-5")}>Quản lý</div>
              <div className={cx("fw-5")}>Kho sản phẩm</div>
            </div>
          </Link>
          {dataUser.length > 0 ? (
            <LoginInfoSuccessUser data={dataUser} />
          ) : (
            <LoginInfoUser />
          )}
        </div>
      </div>
      <ModalReact
        modalTitle="Giới thiệu"
        showModal={introduceModal}
        handleClose={handleCloseModalIntroduce}
        theme={<IntroduceEstateModal />}
      />
      {/* Modal Phong thủy*/}
      <ModalReact
        modalTitle="Xem hướng nhà phù hợp theo năm sinh(Âm lịch)"
        showModal={showHide}
        handleClose={handleClose}
        theme={<HandleResult />}
      />
    </div>
  )
}

export default HeaderHome2

// Chưa đăng nhập.
function LoginInfoUser() {
  return (
    <Link to="/login">
      <div className={cx("d-flex", "align-items-center")}>
        <PiUser className={cx("me-2", "text-muted", "bg_icon_header")} />
        <div className={cx("fs-14")}>
          <div className={cx("text-muted")}>Tài khoản</div>
          <div className={cx("fw-5")}>Đăng ký/Đăng nhập</div>
        </div>
      </div>
    </Link>
  )
}

// Đã đăng nhập thành công.
function LoginInfoSuccessUser({ data }) {
  return (
    <div className={cx("d-flex", "align-items-center")}>
      <InfoUser className={cx("")} />
      <div className={cx("fs-15")}>
        <div className={cx("text-muted fw-5")}>Tài khoản</div>
        <div className={cx("fw-5")}>
          <TextLimit text={data[0].hoten} number={18}/>
        </div>
      </div>
    </div>
  )
}

// Giới thiệu về trang.
function IntroduceEstateModal() {
  return (
    <div className="fs-15">
      <h6 className="text-center">Bất động sản view360</h6>
      <p>
        Chào mừng đến với trang{" "}
        <span className="fw-6 fs-15">
          Bất động sản view360 (batdongsan.view360.vn)
        </span>{" "}
        của hệ sinh thái View360 - nơi bạn có thể khám phá và tìm hiểu về các
        căn nhà, căn hộ, và bất động sản khác trên khắp cả nước một cách dễ dàng
        và thuận tiện nhất.
      </p>
      <p>
        Với sứ mệnh làm việc không mệt mỏi để mang đến cho bạn trải nghiệm tuyệt
        vời nhất trong việc tìm kiếm và khám phá bất động sản, chúng tôi tự hào
        là điểm đến đáng tin cậy cho mọi nhu cầu về mua, bán, hoặc thuê nhà ở
        trên toàn quốc.
      </p>
      <div className="mb-3 border-top border-block-start-dashed"></div>
      <h6 className="text-center">Chuyển đổi số</h6>
      <p>
        Ngoài bất động sản chúng tôi còn cung cấp dịch vụ chuyển đổi số cho
        doanh nghiệp vừa và nhỏ, nhằm đáp ứng vận hành doanh nghiệp một cách
        chuyên nghiệp, tư động hóa, giúp việc quản lý từ xa dễ dàng, vận hành
        đơn giản, gia tăng hiệu suất công việc, tránh thất thoát thu chi trong
        quá trình vận hành.
      </p>
      <div className="mb-3 border-top border-block-start-dashed"></div>
      <h6 className="text-center">View 360</h6>
      <p>
        Chào mừng bạn đến với View360 - nơi bạn khám phá thế giới từ mọi góc độ
        thông qua công nghệ 360 độ. Với sứ mệnh làm thay đổi cách chúng ta trải
        nghiệm thế giới xung quanh, View360 mang đến cho bạn một cách tiếp cận
        mới mẻ và tuyệt vời đối với nhiều lĩnh vực đời sống, từ bất động sản, du
        lịch, đến khu di tích và showroom.
      </p>
      <p>
        Bạn là một người đang tìm kiếm căn nhà hoặc căn hộ mơ ước? Với View360,
        bạn không chỉ xem những hình ảnh tĩnh của căn nhà, mà còn có cơ hội thực
        sự "đặt chân" vào không gian thông qua chế độ xem 360 độ. Từ phòng khách
        đến phòng ngủ, từ khu vực ngoài trời đến bếp, mọi góc độ và không gian
        đều được hiển thị rõ ràng và sinh động, giúp bạn đưa ra quyết định mua
        nhà một cách tự tin nhất.
      </p>
      <p>
        Bạn đam mê du lịch và muốn khám phá điểm đến mới? View360 mang lại cho
        bạn trải nghiệm du lịch tuyệt vời mà không cần phải rời khỏi nhà. Từ các
        danh lam thắng cảnh đến những điểm đến nổi tiếng, bạn có thể thực sự
        "lạc mình" vào không gian đó thông qua hình ảnh 360 độ, giúp bạn lên kế
        hoạch cho hành trình tương lai một cách tỉ mỉ và chi tiết nhất.
      </p>
      <p>
        Ngoài ra, View360 còn áp dụng công nghệ xem nhà 360 độ vào nhiều lĩnh
        vực khác như khu di tích, showroom, và nhiều hơn nữa. Với khả năng tùy
        chỉnh linh hoạt và chất lượng hình ảnh sắc nét, chúng tôi cam kết mang
        lại trải nghiệm thú vị và đầy ấn tượng cho mọi người dùng.{" "}
        <a href="https://view360.com.vn" className="text-primary">
          Chuyển đến view360.com.vn
        </a>
      </p>
    </div>
  )
}
