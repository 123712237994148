import { Link } from "react-router-dom"
import { useState, useContext, useEffect, useLayoutEffect } from "react"
import io from "socket.io-client"
import { Dropdown } from "react-bootstrap"
import {
  HiOutlineUserCircle,
  HiOutlineCalendarDays,
  HiOutlineCreditCard,
  HiOutlineCog,
} from "react-icons/hi2"
import { HiOutlineLogout } from "react-icons/hi"
import { IoPersonOutline } from "react-icons/io5"


import AppContext from "../../handle-reuses/Contexts/CreateContexts"
import ModalReact from "../Modal/ModalReact"
import { WalletMain } from "../../pages/pc/Component/Wallet"
import ProfilePage from "../../pages/pc/Profile"

function InfoUser({ height, width }) {
  const { dataUser } = useContext(AppContext)
  const [dataUserNew, setDateUserNew] = useState([])
  useEffect(() => {
    setDateUserNew([...dataUser])
  }, [dataUser])
// console.log(dataUserNew)
  return (
    <div className="header-element">
      {/* <!-- Start::header-link|dropdown-toggle --> */}
      <div className="d-flex align-items-center">
        <div className="me-sm-2 me-0 position-relative">
          {dataUserNew.length > 0 ? (
            <HandleDisplayDropdown
              dataUser={dataUserNew}
              height={height}
              width={width}
            />
          ) : (
            <i className="fi fi-rr-circle-user fs-27"></i>
          )}
        </div>
      </div>
    </div>
  )
}

export default InfoUser

// Xử lý điều kiện hiển thị
const HandleDisplayDropdown = ({ dataUser, height, width }) => {
  // Modal payment.
  const [showHideWallet, setShowHideWallet] = useState(false)
  const handleShowWallet = () => {
    setShowHideWallet(true)
  }
  const handleCloseWallet = () => setShowHideWallet(false)

  // Delete localStorage.
  const handleDeleteLocalStorage = () => {
    localStorage.setItem("object", "")
  }

  // Modal info profile.
  const [modalInfoProfile, setModalInfoProfile] = useState(false);
  const handleModalInfoProfile = () => {
    setModalInfoProfile(true)
  }
  const handleCloseModalInfoProfile = () => setModalInfoProfile(false);

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-line border-0 p-0" // class mặc định
          bsPrefix="custom-dropdown-toggle" // đặt tên cho class fix loại bỏ class dropdown-toggle
        >
          <img
            src={
              "https://batdongsan.view360.vn/" +
              dataUser[0].avatar +
              `?t=${new Date().getTime()}`
            } // `?t=${new Date().getTime()}` để tránh việc google, safari lưu cache (để cả tên miền)
            width={width ? width : 32}
            height={height ? height : 32}
            className="rounded-5"
            alt=""
          />
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{ minWidth: "200px", marginTop: "6px" }}
          align="start"
        >
          <button
            className="dropdown-item d-flex align-items-center border-0 bg-transparent"
            onClick={handleModalInfoProfile}
          >
            <IoPersonOutline className="fs-18 me-2" />
            Thông tin cá nhân
          </button>
          <Link
            to="/dashboard"
            className="dropdown-item d-flex align-items-center"
          >
            <HiOutlineUserCircle className="fs-18 me-2" />
            Trang quản lý
          </Link>
          <Link
            to="/lich-hen"
            className="dropdown-item d-flex align-items-center"
          >
            <HiOutlineCalendarDays className="fs-18 me-2" />
            Lịch hẹn
            {/* <span className="badge bg-success-transparent ms-auto">25</span> */}
          </Link>
          <button
            className="dropdown-item d-flex align-items-center"
            onClick={handleShowWallet}
          >
            <HiOutlineCreditCard className="fs-18 me-2" />
            Ví của bạn
          </button>
          {/* <Link to="" className="dropdown-item d-flex align-items-center">
              <HiOutlineInformationCircle className="fs-18 me-2" />
              Hỗ trợ
            </Link> */}
          {/* {dataUser[0].roleid === 1 || dataUser[0].roleid === 2 ? (
            <Link
              to="/setting"
              className="dropdown-item d-flex align-items-center"
            >
              <HiOutlineCog className="fs-18 me-2" />
              Cài đặt
            </Link>
          ) : (
            ""
          )} */}
          <Link
            to="/login"
            className="dropdown-item d-flex align-items-center"
            onClick={handleDeleteLocalStorage}
          >
            <HiOutlineLogout className="fs-18 me-2" />
            Đăng xuất
          </Link>
        </Dropdown.Menu>
      </Dropdown>
      {/* Modal payment */}
      <ModalReact
        modalTitle="Ví của bạn"
        showModal={showHideWallet}
        handleClose={handleCloseWallet}
        theme={<WalletMain />}
      />
      {/* Modal thông tin cá nhân */}
      <ModalReact
        modalTitle="Thông tin"
        showModal={modalInfoProfile}
        handleClose={handleCloseModalInfoProfile}
        theme={<ProfilePage />}
      />
    </>
  )
}
