import CheckPCMobile from "../../handle-reuses/reuses/check.pc.mobile"
import PageAdminMobile from "./Page/mobile"
import PageAdminPC from "./Page/pc"

function AdminPage() {
    return (
        <CheckPCMobile>
            {(isMobile) => isMobile ? <PageAdminMobile /> : <PageAdminPC />}
        </CheckPCMobile>
    )
}

export default AdminPage