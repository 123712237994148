import { useEffect, useContext, useState } from "react"
import classNames from "classnames/bind"
import { Link, useLocation } from "react-router-dom"

import styles from "./Header.module.scss"
import HeaderRight from "./Component/HeaderRight"
import AppContext from "../../handle-reuses/Contexts/CreateContexts"

const cx = classNames.bind(styles)

function HeaderDashboard({ data }) {
  const { socketio, dataUser, token } = useContext(AppContext)
  const location = useLocation()
  const currentPath = location.pathname
  const [notifiCalendar, setNotifiCalendar] = useState(0)

  useEffect(() => {
    // socket.connect()

    // gọi data booking notification ban đầu.
    socketio.emit("client-dataBooking", dataUser[0].id)

    // nắng nghe dữ liệu trả về.
    socketio.on("server-dataBooking", (dataBooking) => {
      const numberNotifi = Number(dataBooking.length)
      setNotifiCalendar(numberNotifi)
    })

    socketio.on("notification", (dataBooking) => {
      if (dataUser[0].id === dataBooking[0].author_simple) {
        console.log(dataBooking)
        const numberNotifi = Number(dataBooking.length)
        setNotifiCalendar(numberNotifi)
      }
    })

    return () => {
      socketio.off("client-dataBooking")
      socketio.off("server-dataBooking")
      socketio.off("notification")
    }
  }, [])

  return (
    <div className={cx("tab-style-2", "wrapper_header")}>
      <div className={cx("d-flex align-items-center")} style={{height: "86px", background: "white"}}>
        <div className="col-xl-8 col-md-12 nav nav-tabs">
          {data.map((item, index) => {
            return (
              <Link
                key={index}
                to={item.path}
                className={cx(
                  "d-flex flex-direction-column align-items-center fw-4 font-13 cate position-relative",
                  `${item.path === currentPath ? "active" : ""}`,
                  "cate",
                )}
              >
                <span className="fs-23">{item.icon}</span>
                <span className="fs-16 fw-5">{item.name}</span>
                {item.path === "/lich-hen" ? (
                  <span id="notificalendar" className={cx("notifiCalendar")}>
                    {notifiCalendar > 0 ? notifiCalendar : ""}
                  </span>
                ) : (
                  ""
                )}
              </Link>
            )
          })}
        </div>
        <div className="col-xl-4 col-md-12 d-flex justify-content-end">
          <HeaderRight />
        </div>
      </div>
    </div>
  )
}

export default HeaderDashboard
