import { PiSealCheckFill } from "react-icons/pi";
import { IoRibbon } from "react-icons/io5";
import { BsDot } from "react-icons/bs";
import { useEffect, useRef } from "react";
import moment from "moment";

function InfoAuthorPageHome({dataEstate, nameAuthor, avatarAuthor, onlineOffline, updateTime, checkTick, checkMoney }) {
  const checkTime = useRef(false)
  useEffect(() => {
    const addtime_ = new Date(dataEstate.addtime).getTime();
    const updateTime = new Date(dataEstate.update_time).getTime();
    const currentTime = moment()
    const updateTimeDay = moment(dataEstate.update_time)
    const diffDay_ = currentTime.diff(updateTimeDay, "days");
    if (addtime_ === updateTime) {
      checkTime.current = false
    }
    if (diffDay_ < 1 && addtime_ !== updateTime) {
      checkTime.current = true
    }
  }, [dataEstate])

    return (
      <div className="d-flex mb-3">
        <span
          className={
            onlineOffline === 1
              ? "avatar avatar-lg me-2 avatar-rounded online"
              : "avatar avatar-lg me-2 avatar-rounded"
          }
        >
          <img
            src={
              dataEstate.reputation === 1
                ? "/" + dataEstate.avatar_author
                : avatarAuthor
            }
            alt="avatar"
          />
        </span>
        <div>
          <div className="fw-5">
            <span>
              {dataEstate.reputation === 1 ? dataEstate.hoten : nameAuthor}
            </span>
            {/* <span className="fw-4 ms-1 text-muted">đăng bởi</span> {dataEstate.hoten}  */}
            {checkTick ? (
              <PiSealCheckFill
                className="text-reputation fs-15 ms-1"
                title="Đã kiểm duyệt"
              />
            ) : (
              ""
            )}
          </div>
          <p className="text-muted mb-0 fs-15">
            {checkTime.current ? "Được làm mới " + updateTime : updateTime}{" "}
            {Number(checkMoney) > 500000 ? (
              <>
                <BsDot className="text-muted" />
                <IoRibbon className="fs-15 text-muted" />
              </>
            ) : (
              ""
            )}
          </p>
        </div>
      </div>
    )
}

export default InfoAuthorPageHome