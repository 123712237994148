import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import classNames from "classnames/bind"
import styles from "./Slider.scss"
const cx = classNames.bind(styles)

const ImageModal = ({ data, onClose, web }) => {
  
  return (
    <div className="modal" style={{ background: "black", display: "block" }}>
      <div
        style={{
          maxWidth: "550px",
          maxHeight: "700px",
          margin: "auto",
          height: "inherit",
        }}
      >
        <div className="position-relative" style={{ height: "inherit" }}>
          <ListImagesDetail data={data} web={web} />
          <button
            className="close border-0 fs-29 bg-transparent"
            onClick={onClose}
            style={{
              position: "absolute",
              zIndex: 99,
              color: "white",
              bottom: "9px",
              right: "0px",
              left: "0px",
              fontSize: "3rem",
            }}
          >
            &times;
          </button>
        </div>
      </div>
    </div>
  )
}

export { ImageModal }

// List images.
function ListImagesDetail({data, web}) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    // nextArrow: "",
    // prevArrow: "",
    // adaptiveHeight: true,
    className: "slider-wrapper",
  }

  return (
    <div className="slider-container bg-transparent">
      <Slider {...settings}>
        {data.map((value, index) => (
          <div key={value.id ? value.id : index} className="bg-transparent">
            <div className="slide-container-image">
              <LazyLoadImage
                alt="Image 1"
                effect="blur"
                src={web ? web + value : "/" + value}
                className="d-block w-100"
                style={{ maxHeight: "500px" }}
              />
              {/* <img
                src={web ? web + value : "/" + value}
                className="d-block w-100"
                alt="..."
                style={{ maxHeight: "600px" }}
              /> */}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}
