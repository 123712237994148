import { useContext, useState, useEffect, useRef } from "react"
import classNames from "classnames/bind"
import { BsCreditCard2Back, BsDot, BsWater } from "react-icons/bs"
import { IoEyeOffOutline, IoMailOutline, IoWarningOutline } from "react-icons/io5"
import { MdCancel, MdHotTub, MdOutlineElectricalServices } from "react-icons/md"
import { PiHandshakeLight} from "react-icons/pi"
import { TbAirConditioning } from "react-icons/tb"
import { FaHeart, FaRegHeart } from "react-icons/fa6"
import { AiFillLike, AiOutlineLike, AiOutlineLink } from "react-icons/ai"
import { GoDot } from "react-icons/go"

import AppContext from "../../handle-reuses/Contexts/CreateContexts"
import { TextLimit, copyToClipboard, deleteAccentedText, handleFormatNumberUnit, handleFormatPrice, handleLimitString } from "../../handle-reuses/reuses"
import moment from "moment"
import { SliderImageList } from "../Slider"
import { images } from "../../assets/images"
import styles from "./DetailEstate.module.scss"
import { useNavigate } from "react-router-dom"
import { handleMethodCreateData, handleMethodEditData } from "../../handle-reuses/reuses/method"
import AnalySisDetailEstate from "./AnalysisDetailEstate"
const cx = classNames.bind(styles)

function DetailEstate({ dataEstate }) {
  const { dataUser, socketio } = useContext(AppContext)

  const [showPhone, setShowPhone] = useState(false)
  const [buttonShow, setButtonShow] = useState(false)
  const [checkTime, setCheckTime] = useState("")
  const handleShowPhone = () => {
    setShowPhone(
      dataEstate[0].customer_phone
        ? dataEstate[0].customer_phone
        : dataEstate[0].phone,
    )
    setButtonShow(true)
    // Xử lý cộng thêm lượt xem phone.
    socketio.emit("client-detail-product", dataEstate[0].id)
  }
  // Quản lý thay đổi countclick (phone)
  useEffect(() => {
    // lấy dữ liệu mới nhất để thay đổi countclick
    socketio.on("server-detail-product", (data) => {
      const params = {
        id: data[0].id,
        countclick: Number(data[0].countclick) + 1,
      }
      socketio.emit("client-click-phone", params)
    })
    return () => {
      socketio.off("server-detail-product")
      socketio.off("client-detail-product")
      socketio.off("client-click-phone")
    }
  }, [])

  useEffect(() => {
    // Giới hạn phone.
    const hidePhone = handleLimitString(
      dataEstate[0].customer_phone
        ? dataEstate[0].customer_phone
        : dataEstate[0].phone,
      3,
    )
    setShowPhone(hidePhone)
    // Check time.
    const addtime = moment(dataEstate[0].addtime)
    const currentTime = moment()
    const diffDays = currentTime.diff(addtime, "days")
    const formatTime = moment(dataEstate[0].addtime).format("DD/MM/YYYY HH:mm")
    const formatTime_ = moment(dataEstate[0].addtime)
      .locale("vi")
      .startOf("hour")
      .fromNow()
    if (diffDays >= 15) {
      setCheckTime(formatTime)
    } else {
      setCheckTime(formatTime_)
    }
    // Hiển thị nội dung.
    document.getElementById("discriptionDetailModal").innerHTML =
      dataEstate[0].discription
  }, [dataEstate])

  // Xem ảnh hoặc view 360.
  const [viewMethods, setViewMethods] = useState("imagesView")
  const handleEventClickView = (tab) => {
    setViewMethods(tab)
  }
  useEffect(() => {
    if (viewMethods === "view360") {
      // console.log(dataEstate)
      // Hiển thị view360.
      if (dataEstate[0].view360) {
        document.getElementById("iframeView360").innerHTML = `
        <iframe id="vt_iframe_${dataEstate[0].id}" allow="accelerometer; camera; display-capture; fullscreen; geolocation; gyroscope; magnetometer; microphone; midi; xr-spatial-tracking;" width="100%" height="363px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="${dataEstate[0].view360}"></iframe>
      `
      } else {
        document.getElementById(
          "iframeView360",
        ).innerHTML = `<div class="text-center mt-3 fs-18">Sản phẩm chưa có view 360</div>`
      }
    }
  }, [viewMethods])

  // Quản lý danh sách hình ảnh.
  const [listImagesPro, setListImagesPro] = useState([images.noImagePro])
  useEffect(() => {
    if (dataEstate[0].images) {
      let listImages = dataEstate[0].images.split(",")
      setListImagesPro(listImages)
    }
  }, [])

  // Quản lý booking.
  const [activeBooking, setActiveBooking] = useState(false)
  const handleShowFormBooking = () => {
    setActiveBooking(true)
  }
  const handleCloseFormBooking = () => setActiveBooking(false)

  /** === Quản lý sao chép đường dẫn. ===*/
  // format url detail.
  const formatCateUrl = deleteAccentedText(dataEstate[0].cate).toLowerCase()
  const cate = formatCateUrl.replace(/[ ]/g, "-")
  const formatAddress = deleteAccentedText(dataEstate[0].address).toLowerCase()
  const address = formatAddress.replace(/[ ]/g, "-")
  // Sao chép
  const handleCopyPath = (path) => {
    copyToClipboard(path)
  }
  /** === *Quản lý sao chép đường dẫn. ===*/

  /** === Quản lý lưu tin. ===*/
  const [dataSimpleSaved, setDataSimpleSaved] = useState([])
  // Get data simple saved
  const handleUpdateDataSaved = () => {
    socketio.emit("client-check-simple-saved", {
      simpleid: dataEstate[0].id,
      userid: dataUser[0].id,
    })
  }
  useEffect(() => {
    if (dataUser.length > 0) {
      // Get data ban đầu.
      handleUpdateDataSaved()
      return () => {
        socketio.off("client-check-simple-saved")
      }
    }
  }, [])
  // Nhận sự kiện.
  useEffect(() => {
    // Nhận socket.
    socketio.on("server-check-simple-saved", (dataSaved) => {
      if (dataSaved.length > 0) {
        const filterDataSaved = dataSaved.filter((item) => {
          return (
            item.simpleid === dataEstate[0].id && item.author === dataUser[0].id
          )
        })
        setDataSimpleSaved(filterDataSaved)
      }
    })
    // Nhận dữ liệu khi edit thành công.
    socketio.on("server-edit-status-saved", (dataUpdate) => {
      setDataSimpleSaved(dataUpdate)
    })
    // Nhận dữ liệu khi create thành công.
    socketio.on("server-create-simple-saved", (dataUpdate) => {
      setDataSimpleSaved(dataUpdate)
    })
    return () => {
      socketio.off("server-check-simple-saved")
      socketio.off("server-edit-status-saved")
      socketio.off("server-create-simple-saved")
    }
  }, [])
  // Quản lý click chuyển đổi trạng thái (button).
  const handleConvertSimpleSaved = (simpleId) => {
    if (dataUser.length > 0) {
      if (dataSimpleSaved.length > 0) {
        // Xử lý edit
        if (dataSimpleSaved[0].status === 0) {
          socketio.emit("client-edit-status-saved", {
            id: dataSimpleSaved[0].id,
            status: 1,
          })
        } else {
          socketio.emit("client-edit-status-saved", {
            id: dataSimpleSaved[0].id,
            status: 0,
          })
        }
      } else {
        // Xử lý thêm mới.
        let paramsCreate = {
          iden: process.env.REACT_APP_IDENTIFICATION,
          object: {
            simpleid: dataEstate[0].id,
            status: 1,
            author: dataUser[0].id,
          },
        }
        socketio.emit("client-create-simple-saved", paramsCreate)
      }
    } else {
      alert("Bạn cần đăng nhập trước khi lưu")
    }
  }
  // Hủy sự kiện khi xử lý xong với simple saved
  // useEffect(() => {
  //   return () => {
  //     // socketio.off("client-check-simple-saved")
  //     // socketio.off("client-edit-status-saved")
  //     // socketio.off("client-create-simple-saved")
  //   }
  // }, [])

  /** === *Quản lý lưu tin. ===*/

  /** === Quản lý get author product. ===*/
  useEffect(() => {
    socketio.emit("client-userid", dataEstate[0].userid)
    return () => {
      socketio.off("client-userid")
    }
  }, [])
  // author tương ứng
  const [author, setAuthor] = useState([])
  useEffect(() => {
    // Lấy author ban đầu
    socketio.on("server-userid", (dataAuthor) => {
      if (dataAuthor.length > 0) {
        setAuthor(dataAuthor)
      }
    })

    // Xác định online-offline
    socketio.on("server-online-offline", (dataUserUpdate) => {
      if (dataUserUpdate.length > 0) {
        if (dataUserUpdate[0].id === dataEstate[0].userid) {
          setAuthor(dataUserUpdate)
        }
      }
    })
    return () => {
      socketio.off("server-userid")
      socketio.off("server-online-offline")
    }
  }, [])
  /** === *Quản lý get author product. ===*/

  // Quản lý trạng thái like, dislike.
  const [totalLikeDetail, setTotalLikeDetail] = useState(0)
  const [checkLikeDislikeDetail, setCheckLikeDisLikeDetail] = useState([])
  // Get data like ban đầu
  useEffect(() => {
    socketio.emit("client-get-simple-like", {
      iden: process.env.REACT_APP_IDENTIFICATION,
      simpleid: dataEstate[0].id,
    })
    socketio.on("server-get-simple-like", (dataLike) => {
      if (dataLike.length > 0) {
        if (dataLike[0].simpleid === dataEstate[0].id) {
          setTotalLikeDetail(dataLike[0].total_like)
        }
      }
    })
    return () => {
      socketio.off("client-get-simple-like")
      socketio.off("server-get-simple-like")
    }
  }, [])
  // Quản lý button like, dislike.
  useEffect(() => {
    socketio.emit("client-result-for-like-dislike-detail", {
      userid: dataUser.length > 0 ? dataUser[0].id : 0,
      simpleid: dataEstate[0].id,
    })
    socketio.on("server-result-for-like-dislike-detail", (dataLikeDislike) => {
      setCheckLikeDisLikeDetail(dataLikeDislike)
    })
    return () => {
      socketio.off("client-result-for-like-dislike-detail")
      socketio.off("server-result-for-like-dislike-detail")
    }
  }, [totalLikeDetail])

  const handleStatusLikeDisLike = (simpleid) => {
    
    // console.log(simpleid)
    if (dataUser.length > 0) {
      if (checkLikeDislikeDetail.length > 0) {
        // Xử lý edit
        const paramsEdit = {
          iden: process.env.REACT_APP_IDENTIFICATION,
          simpleid: simpleid,
          object: {
            quantity: checkLikeDislikeDetail[0].quantity === 1 ? 0 : 1,
            id: checkLikeDislikeDetail[0].id,
          },
        };
        socketio.emit("client-edit-quantity-simple-like", paramsEdit)
        // Cập nhật lại trang thái like, dislike page home
        socketio.emit("client-result-for-like-dislike", {
          userid: dataUser.length > 0 ? dataUser[0].id : 0,
        })
        // thay đổi trạng thái like, dislike
        socketio.emit("client-result-for-like-dislike-detail", {
          userid: dataUser.length > 0 ? dataUser[0].id : 0,
          simpleid: dataEstate[0].id,
        })
      } else {
        // Xử lý thêm mới
        const paramsCreate = {
          iden: process.env.REACT_APP_IDENTIFICATION,
          object: {
            simpleid: simpleid,
            quantity: 1,
            userid: dataUser[0].id,
          },
        }
        socketio.emit("client-add-simple-like", paramsCreate)
        // Cập nhật lại trang thái like, dislike page home
        socketio.emit("client-result-for-like-dislike", {
          userid: dataUser.length > 0 ? dataUser[0].id : 0,
        })
      }
    } else {
      alert("Bạn cần đăng nhập trước đã.")
    }
  }
  // cập nhật data mới nhất khi edit, create thành công.
  useEffect(() => {
    // Cập nhật khi edit thành công
    socketio.on("server-edit-quantity-simple-like", (data) => {
      setTotalLikeDetail(data[0].total_like)
    })

    // Cập nhật khi create thành công.
    socketio.on("server-add-simple-like", (data) => {
      // console.log(data)
      setTotalLikeDetail(data[0].total_like)
    })
    return () => {
      // socketio.off("server-edit-quantity-simple-like") //tắt tại trang home trên mobile
      // socketio.off("server-add-simple-like") //tắt tại trang home trên mobile
      // socketio.off("client-result-for-like-dislike")
      // socketio.off("client-edit-quantity-simple-like")
      socketio.off("client-add-simple-like")
    }
  }, [checkLikeDislikeDetail])

  // Kiểm tra hiển thi bảng dịch vụ cho thuê.
  const checkInfoService = useRef(false)
  useEffect(() => {
    if (
      dataEstate[0].cate.toLowerCase() === "cho thuê nhà riêng" ||
      dataEstate[0].cate.toLowerCase() === "cho thuê căn hộ" ||
      dataEstate[0].cate.toLowerCase() === "cho thuê chung cư" ||
      dataEstate[0].cate.toLowerCase() === "cho thuê mặt bằng" ||
      dataEstate[0].cate.toLowerCase() === "cho thuê phòng trọ"
    ) {
      checkInfoService.current = true
    }
  }, [dataEstate])

  return (
    <div className="col-12">
      <div className="d-flex mb-2">
        <button
          className="border-0 rounded-2 btn-outline-dark me-2"
          onClick={() => handleEventClickView("imagesView")}
        >
          Hình ảnh
        </button>
        <button
          className="border-0 rounded-2 btn-outline-dark"
          onClick={() => handleEventClickView("view360")}
        >
          View 360
        </button>
      </div>
      {viewMethods === "imagesView" && (
        <div className="position-relative">
          <SliderImageList data={listImagesPro} height="363" />
          {/* Quảng cáo */}
          <AdvertisementEstate
            socketio={socketio}
            idSimple={dataEstate[0].id}
          />
        </div>
      )}
      {viewMethods === "view360" && (
        <div
          style={{
            height: "363px",
            width: "100%",
            borderRadius: "19px",
            overflow: "hidden",
          }}
        >
          <div id="iframeView360"></div>
        </div>
      )}
      <div className="mt-3">
        <div>
          <span className="fs-18 fw-6">{dataEstate[0].title}</span>
          <div className="mt-2">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center fs-15">
                <div className="fs-18 text-muted">
                  <GoDot className="me-1" />
                  <TextLimit text={dataEstate[0].cate} number={15} />
                </div>
              </div>
              <div>
                <span className="fs-17">
                  {/* <GiPriceTag className="me-1" /> */}
                  <span className="fw-6 me-1 text-h text-danger">
                    <span className="me-1">Giá:</span>
                    {dataEstate[0].donvi !== "Thỏa thuận"
                      ? handleFormatPrice(Number(dataEstate[0].price))
                      : ""}
                  </span>
                  {dataEstate[0].donvi === "Thỏa thuận"
                    ? dataEstate[0].donvi
                    : dataEstate[0].donvi === ""
                    ? ""
                    : " / " + dataEstate[0].donvi}
                </span>
              </div>
            </div>
            <span className="fs-18 text-muted">
              <GoDot className="me-1" />
              <TextLimit
                text={
                  dataEstate[0].address +
                  ", " +
                  dataEstate[0].districts +
                  ", " +
                  dataEstate[0].city
                }
                number={30}
              />
              {/* {dataEstate[0].address}, {dataEstate[0].districts},{" "}
              {dataEstate[0].city} */}
            </span>
          </div>
        </div>
        <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
        {/* info author */}
        <div className="fs-15">
          {/* <div className="mb-2 fw-6">Thông tin liên hệ</div> */}
          <div className="d-flex">
            <div className="me-2">
              <span
                className={cx(
                  "avatar avatar-lg me-2 avatar-rounded col-2",
                  author.length > 0
                    ? author[0].online_offline === 1
                      ? "online"
                      : "offline"
                    : "",
                )}
              >
                <img
                  src={
                    author.length > 0
                      ? dataEstate[0].customer_phone !== ""
                        ? images.user
                        : "/" + author[0].avatar
                        ? "/" + author[0].avatar
                        : images.user
                      : images.user
                  }
                  alt=""
                  style={{ border: "1px solid #e9e9e9", width: "48px" }}
                />
              </span>
            </div>
            <div className="fs-18 fontSize">
              <div className="fw-5">
                <span>
                  {dataEstate[0].customer_name
                    ? dataEstate[0].customer_name
                    : dataEstate[0].hoten}
                </span>{" "}
                <GoDot className="fs-9" />{" "}
                <span className="phoneNumber">
                  {showPhone.length >= 9 ? (
                    <a href={"tel:" + showPhone}>{showPhone}</a>
                  ) : (
                    showPhone
                  )}
                </span>
                {buttonShow ? (
                  ""
                ) : (
                  <button
                    className="border-0"
                    style={{ background: "none" }}
                    onClick={handleShowPhone}
                  >
                    <IoEyeOffOutline />
                  </button>
                )}
              </div>
              <div className="text-muted">
                {/* <span className="mb-0 text-muted fs-15">
                  {dataEstate[0].transaction === 1
                    ? "Đã giao dịch"
                    : "Chưa giao dịch"}
                </span> */}
                {/* <CountViewEstate data={dataEstate} /> */}
                {moment(dataEstate[0].update_time)
                  .locale("vi")
                  .fromNow()
                  .replace("một", "1")}
              </div>
            </div>
          </div>
        </div>
        {/* Lượt xem & thời gian */}
        {/* <div className="fs-16 text-muted">
         
        </div> */}
        {/* Other */}
        <div className="mb-2 mt-2 border-top border-block-start-dashed"></div>
        <div className="d-flex justify-content-between align-items-center fs-16 text-muted">
          <div className="d-flex align-items-center text-muted">
            {totalLikeDetail > 0 && (
              <>
                <AiFillLike className="fs-18 me-1" />
                {handleFormatNumberUnit(Number(totalLikeDetail))}
              </>
            )}
          </div>
          <div>
            <span className="me-2">
              <CountViewEstate data={dataEstate} />
            </span>
            <span>
              {handleFormatNumberUnit(Number(dataEstate[0].countclick))} lượt
              tương tác
            </span>
          </div>
        </div>
        <div className="mb-3 mt-2 border-top border-block-start-dashed"></div>
        <div className="d-flex justify-content-between gap-2 mt-2">
          <div>
            <button
              className={
                checkLikeDislikeDetail.length > 0
                  ? checkLikeDislikeDetail[0].quantity === 1
                    ? "fw-5 border-0 fs-16 rounded-2 bg-transparent d-flex align-items-center p-0 text-danger"
                    : "fw-5 border-0 fs-16 rounded-2 bg-transparent d-flex align-items-center p-0 text-muted"
                  : "fw-5 border-0 fs-16 rounded-2 bg-transparent d-flex align-items-center p-0 text-muted"
              }
              onClick={() => handleStatusLikeDisLike(dataEstate[0].id)}
            >
              {checkLikeDislikeDetail.length > 0 ? (
                checkLikeDislikeDetail[0].quantity === 1 ? (
                  <AiFillLike className="fs-18 me-1" />
                ) : (
                  <AiOutlineLike className="fs-18 me-1" />
                )
              ) : (
                <AiOutlineLike className="fs-18 me-1" />
              )}
              Thích
            </button>
          </div>

          <div>
            <button
              className="text-muted fw-5 border-0 fs-16 rounded-2 bg-transparent d-flex align-items-center p-0"
              onClick={() =>
                handleCopyPath(
                  "https://batdongsan.view360.vn/" +
                    cate +
                    "-" +
                    address +
                    "/" +
                    dataEstate[0].alias +
                    "-pr" +
                    dataEstate[0].id,
                )
              }
            >
              <AiOutlineLink className="fs-18 me-1" />
              Sao chép
            </button>
          </div>
          <div>
            <button
              className={cx(
                "fw-5 border-0 fs-16 rounded-2 bg-transparent d-flex align-items-center p-0",
                activeBooking ? "" : "text-muted",
              )}
              onClick={handleShowFormBooking}
            >
              <IoMailOutline className="fs-18 me-1" />
              Tư vấn
            </button>
          </div>
          <div>
            <button
              className="text-muted fw-5 border-0 fs-16 rounded-2 bg-transparent d-flex align-items-center p-0"
              onClick={() => handleConvertSimpleSaved(dataEstate[0].id)}
            >
              {dataSimpleSaved.length > 0 ? (
                dataSimpleSaved[0].status === 1 ? (
                  <>
                    <FaHeart className="fs-16 me-1" />
                    Đã lưu
                  </>
                ) : (
                  <>
                    <FaRegHeart className="fs-16 me-1" />
                    Lưu tin
                  </>
                )
              ) : (
                <>
                  <FaRegHeart className="fs-16 me-1" />
                  Lưu tin
                </>
              )}
            </button>
          </div>
        </div>
        {/* Bảng booking */}
        <div style={{ display: activeBooking ? "block" : "none" }}>
          <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
          <div
            className="mt-3 text-center position-relative rounded-3"
            style={{ background: "#eeeeee6b", padding: "12px" }}
          >
            <span>Gửi yêu cầu tư vấn</span>
            <button
              className="border-0 bg-transparent p-0"
              style={{ position: "absolute", right: "0", top: "-12px" }}
              onClick={handleCloseFormBooking}
            >
              <MdCancel className="fs-21" />
            </button>
            <BookingEstate dataEstate={dataEstate} />
          </div>
        </div>

        {/* Thông tin diện tích */}
        <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
        <div className="d-flex justify-content-start mt-3 mb-3 gap-3">
          <div className="fs-16">
            Diện tích:{" "}
            <span className="fw-6">
              {dataEstate[0].dtmb.length > 0 ? dataEstate[0].dtmb : "0"}m
              <sup>2</sup>
            </span>
          </div>
          <div className="fs-16">
            Mặt tiền:{" "}
            <span className="fw-6">
              {dataEstate[0].mattien.length > 0 ? dataEstate[0].mattien : "0"}m
            </span>
          </div>
          <div className="fs-16">
            Số tầng: <span className="fw-6">{dataEstate[0].sotang}</span>
          </div>
        </div>
        <div className="d-flex justify-content-start mt-3 mb-3 gap-3">
          {dataEstate[0].duongngo > 0 && (
            <div className="fs-16">
              <span className="me-1">Đường/Ngõ:</span>
              <span className="fw-6">{dataEstate[0].duongngo}m</span>
            </div>
          )}
          <div className="fs-16">
            Hướng:{" "}
            <span className="fw-6">
              {dataEstate[0].direction.length > 0
                ? dataEstate[0].direction
                : "Liên hệ"}
            </span>
          </div>
          <div className="fs-16">
            Pháp lý:{" "}
            <span className="fw-6">
              {dataEstate[0].phaply.length > 0
                ? dataEstate[0].phaply
                : "Liên hệ"}
            </span>
          </div>
        </div>
        {/* Phân tích chi tiết sản phẩm */}
        <AnalySisDetailEstate dataEstate={dataEstate} />
        {/* Thông tin cho thuê */}
        {checkInfoService.current ? <InfoRentEstate data={dataEstate} /> : ""}
        {/* Nội dung mô tả */}
        <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
        <div className="fs-16">
          {/* <div className="fw-6">Nội dung mô tả</div> */}
          <div className="mt-2 fs-17" id="discriptionDetailModal">
            {/* {data[0].discription.replace(/<[^>]*>?/gm, "")} */}
          </div>
          {/* Tác giả */}
          <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
          <div className="text-end">
            <span className="me-1">Tác giả:</span>
            <span>{dataEstate[0].hoten}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailEstate

// Thông tin nếu là cho thuê.
const InfoRentEstate = ({ data }) => {
  // tiến độ thanh toán.
  let paymentMethods = data[0].payment_methods
  if (paymentMethods === 1) {
    paymentMethods = "Đêm"
  } else if (paymentMethods === 2) {
    paymentMethods = "Ngày"
  } else if (paymentMethods === 3) {
    paymentMethods = "Tháng"
  } else if (paymentMethods === 4) {
    paymentMethods = "Năm"
  }
  return (
    <div className="mt-3 fs-15 border-top border-block-start-dashed">
      {/* <div className="fw-6 mt-3">Thông tin cho thuê</div> */}
      <div className="mt-3">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center me-5">
            <TbAirConditioning className="me-3 fs-21" />
            <div className="fw-5 fs-16">
              <div>Điều hòa</div>
              <div className="text-muted">
                {data[0].dieuhoa === 1 ? "Có" : "Không"}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <MdHotTub className="fs-21 me-2 ms-3" />
            <div className="fw-5 fs-16">
              <div>Nóng lạnh</div>
              <div className="text-muted">
                {data[0].binhnonglanh === 1 ? "Có" : "Không"}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center mt-3">
          <MdOutlineElectricalServices className="me-3 fs-21" />
          <div className="fw-5 fs-16">
            <div className="">Giá điện sử dụng</div>
            <div className="text-muted mt-0">
              <span className="me-1">Giá tiêu thụ điện năng</span>
              {Number(data[0].electricity_money) > 0
                ? Number(data[0].electricity_money)
                    .toLocaleString()
                    .replace(/[.]/g, ",") + "đ / số điện"
                : "theo giá nhà nước"}
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <BsWater className="me-3 fs-21" />
          <div className="fw-5 fs-16">
            <div className="">Giá nước sử dụng</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Giá tiêu thụ nước sinh hoạt</span>
              {Number(data[0].water_money) > 0
                ? Number(data[0].water_money)
                    .toLocaleString()
                    .replace(/[.]/g, ",") + "đ / khối nước"
                : " theo giá nhà nước."}
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <IoWarningOutline className="me-3 fs-21" />
          <div className="fw-5 fs-16">
            <div className="">Dịch vụ khác</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Chi phí cho các dịch vụ khác</span>
              {Number(data[0].service_price)
                .toLocaleString()
                .replace(/[.]/g, ",")}
              đ / tháng
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <PiHandshakeLight className="me-3 fs-21" />
          <div className="fw-5 fs-16">
            <div className="">Thời gian ký hợp đồng</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Thời gian khi ký hợp đồng là</span>
              {Number(data[0].contract_time) > 0
                ? Number(data[0].contract_time) + " tháng trở lên"
                : "thỏa thuận"}
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <BsCreditCard2Back className="me-3 fs-21" />
          <div className="fw-5 fs-16">
            <div className="">Số tiền đặt cọc khi thuê</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Số tiền cần đặt cọc khi thuê</span>
              {Number(data[0].deposit) > 0
                ? handleFormatPrice(Number(data[0].deposit))
                : "thỏa thuận."}
            </div>
          </div>
        </div>

        {/* <div className="d-flex align-items-center mt-3">
          <PiTimerBold className="me-3 fs-21" />
          <div>
            <div className="">Kỳ hạn thanh toán</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Tiến độ thanh toán được tính theo</span>(
              {paymentMethods})
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}


// Xác định lượt xem sản phẩm.
function CountViewEstate({ data }) {
  const {socketio} = useContext(AppContext)
  const [updateView, setUpdateView] = useState(data[0].countview)

  useEffect(() => {
    socketio.on("server-click-views", (dataUpdate) => {
      if (data[0].id === dataUpdate[0].id) {
        setUpdateView(dataUpdate[0].countview)
      }
    })
    return () => {
      socketio.off("server-click-views")
    }
  }, [])

  return (
    <span className={"mb-2 text-muted fs-16 fontSize"}>
      {handleFormatNumberUnit(Number(updateView))} lượt xem
      {/* <BsDot /> */}
      {/* {moment(data[0].update_time).locale("vi").fromNow()} */}
    </span>
  )
}


// Booking
function BookingEstate({ dataEstate }) {
  const { dataUser, socketio } = useContext(AppContext)

  const [object, setObject] = useState({
    fullname: dataUser.length > 0 ? dataUser[0].hoten : "",
    fullphone: dataUser.length > 0 ? dataUser[0].sodienthoai : "",
    discription: "",
  })
  const [checkName, setCheckName] = useState(true)
  const [checkPhone, setCheckPhone] = useState(true)
  const [checkDis, setCheckDis] = useState(true)

  useEffect(() => {
    socketio.on("server-add-booking", (data) => {
      // console.log(data)
    })
    return () => {
      socketio.off("server-add-booking")
    }
  }, [])

  const arrOption = [
    { id: 1, title: "Tôi cần tư vấn sản phẩm này !" },
    { id: 2, title: "Dòng sản phẩm tương tự như này ?" },
    { id: 3, title: "Tôi cần tư vấn các sản phẩm đường " + dataEstate[0].address + ", " + dataEstate[0].districts + ", " + dataEstate[0].city },
  ]
  const handleFullName = (e) => {
    setCheckName(true)
    setObject({
      fullname: e.target.value,
      fullphone: object.fullphone,
      discription: object.discription,
    })
  }
  const handleFullPhone = (e) => {
    setCheckPhone(true)
    setObject({
      fullname: object.fullname,
      fullphone: e.target.value,
      discription: object.discription,
    })
  }
  const handleDiscription = (e) => {
    setCheckDis(true)
    setObject({
      fullname: object.fullname,
      fullphone: object.fullphone,
      discription: e.target.value,
    })
  }

  const handleOptionContent = (key) => {
    const findOption = arrOption.find((item) => {
      return item.id === key
    })
    setCheckDis(true)
    setObject({
      fullname: object.fullname,
      fullphone: object.fullphone,
      discription: findOption.title,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Kiểm tra trường bắt buộc.
    if (object.fullname === "") {
      setCheckName(false)
    }
    if (object.fullphone === "") {
      setCheckPhone(false)
    }
    if (object.discription === "") {
      setCheckDis(false)
    }
    if (
      object.fullname !== "" &&
      object.fullphone !== "" &&
      object.discription !== ""
    ) {
      // Gửi yêu cầu đến server.
      object.simpleid = dataEstate[0].id
      object.author_simple = dataEstate[0].userid
      object.name_estate = dataEstate[0].title
      if (dataUser.length > 0) {
        object.email = dataUser[0].email
      } else {
        object.email = ""
      }
      
      socketio.emit("client-add-booking", object)
      alert("Cảm ơn bạn, yêu cầu đã được gửi. Tôi sẽ liên hệ sớm nhất có thể")
      setTimeout(() => {
        setObject({
          fullname: object.fullname,
          fullphone: object.fullphone,
          discription: "",
        })
        
      }, 100)
    }
  }

  return (
    // <div className={cx("fs-17")}>Gửi yêu cầu liên hệ</div>
    <form onSubmit={handleSubmit}>
      <div className="col-xl-12 col-md-12">
        <div className="form-floating mb-1 mt-2">
          <input
            type="text"
            value={object.fullname}
            className="form-control rounded-3 fs-16 op-8"
            placeholder="Họ tên"
            onChange={handleFullName}
          />
          <label className="fs-16">
            Họ tên
            <sup className="text-danger">*</sup>
          </label>
          {!checkName && (
            <span className={cx("text-danger", "fs-14")}>
              *Hãy cho tôi biết tên của bạn
            </span>
          )}
        </div>
      </div>
      <div className="col-xl-12 col-md-12 mt-3">
        <div className="form-floating mb-1">
          <input
            type="text"
            value={object.fullphone}
            className="form-control rounded-3 fs-16 op-8"
            placeholder="Số điện thoại"
            onChange={handleFullPhone}
          />
          <label className="fs-16">
            Số điện thoai <sup className="text-danger">*</sup>
          </label>
          {!checkPhone && (
            <span className={cx("text-danger", "fs-14")}>
              *Hãy để lại số điện thoại của bạn
            </span>
          )}
        </div>
      </div>
      <div className="col-xl-12 col-md-12 mt-3">
        <div className={cx("mb-3 fs-16")}>
          <button
            className={cx("border-0 rounded-5 px-3 py-1 mb-2 btn-outline-dark")}
            onClick={() => handleOptionContent(1)}
            type="button"
          >
            Tôi cần tư vấn sản phẩm này !
          </button>
          <button
            type="button"
            className={cx("border-0 rounded-5 px-3 py-1 mb-2 btn-outline-dark")}
            onClick={() => handleOptionContent(2)}
          >
            Dòng sản phẩm tương tự như này ?
          </button>
          <button
            type="button"
            className={cx("border-0 rounded-5 px-3 py-1 btn-outline-dark")}
            onClick={() => handleOptionContent(3)}
          >
            Các sản phẩm {dataEstate[0].address}
          </button>
        </div>
        <div className="mb-1">
          <textarea
            type="text"
            value={object.discription}
            className="form-control rounded-3 fs-16"
            placeholder="Nội dung *"
            rows={3}
            onChange={handleDiscription}
          />
          {!checkDis && (
            <span className={cx("text-danger", "fs-14")}>
              *Hãy để lại lời nhắn cho chủ sở hữu
            </span>
          )}
        </div>
      </div>
      <div className="px-4 d-flex mt-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
        <button
          className="btn btn-danger-light mt-3 op-9 fw-5 fs-16"
          type="submit"
          id="submitProduct"
        >
          Gửi yêu cầu
        </button>
      </div>
    </form>
  )
}

// Quảng cáo khoảng 10 bài viết khác random.
function AdvertisementEstate({ socketio, idSimple }) {
  const [activeAd, setActiveAd] = useState(false)
  const [hidenAd, setHideAd] = useState(true)
  const [dataAd, setDataAd] = useState([])
  const [listImages, setListImages] = useState([])
  // Ẩn quảng cáo
  const handleHideAd = () => {
    setHideAd(false)
  }

  // Hiện thị quảng cáo ban đầu.
  useEffect(() => {
    setTimeout(() => {
      socketio.emit("client-advertesement-simple", {iden: process.env.REACT_APP_IDENTIFICATION})
      setActiveAd(true)
    }, 3000);
    
    return () => {
      socketio.off("client-advertesement-simple")
    }
  }, []);
  // quản lý nhận data.
  useEffect(() => {
    socketio.on("server-advertesement-simple", (data) => {
      const randomIndex = Math.floor(Math.random() * data.length);
      // Điều kiện nếu random sản phẩm trung với tin đang xem sẽ không hiển thị.
      if (data[randomIndex].id !== Number(idSimple)) {
        setDataAd([data[randomIndex]])
        // List images.
        if (data.length > 0) {
          if (data[randomIndex].images) {
            const listImag = data[randomIndex].images.split(",")
            setListImages([...listImag])
          }
        }
      }
    })
    return () => {
      socketio.off("server-advertesement-simple")
    }
  }, []);
  if (!hidenAd) {
    return <div></div>
  }
  if (!activeAd) {
    return
  }
  if (dataAd.length === 0) {
    return
  }

  return (
    <div className={cx("wrapper_adEs", "d-flex")}>
      {/* nội dung quảng cáo */}
      <ContentAdvertsementMobile
        dataAd={dataAd}
        listImages={listImages}
        socketio={socketio}
      />
      {/* Button cancel */}
      <div className={cx("cancel_adEs")}>
        <button
          className={cx("border-0 bg-transparent p-0")}
          onClick={handleHideAd}
        >
          <MdCancel
            className="fs-21"
            style={{
              border: "2px solid rgba(255, 255, 255, 0.89)",
              borderRadius: "21px",
            }}
          />
        </button>
      </div>
    </div>
  )
}
// Nội dung quảng cáo trên mobile.
function ContentAdvertsementMobile({ dataAd, listImages, socketio }) {
  const navigate = useNavigate()
  // format url detail.
  const formatCateUrl = deleteAccentedText(dataAd[0].cate).toLowerCase()
  const cate = formatCateUrl.replace(/[ ]/g, "-")
  const formatAddress = deleteAccentedText(dataAd[0].address).toLowerCase()
  const address = formatAddress.replace(/[ ]/g, "-")
  // Tăng view khi click.
  const handleOpenDetail = (simpleId, path) => {
    // Tăng view khi click.
    socketio.emit("client-detail-product", simpleId)
    socketio.on("server-detail-product", (dataUpdate) => {
      const params = {
        id: simpleId,
        countview: Number(dataUpdate[0].countview) + 1,
      }
      socketio.emit("client-click-views", params)
      // Chuyển trang
      navigate("" + path + "")
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-detail-product")
      socketio.off("server-detail-product")
      socketio.off("client-click-views")
    }
  }, [])

  return (
    <div>
      <div className="d-flex align-items-center" style={{ height: "68px" }}>
        <img
          src={
            listImages.length > 0 ? "/" + listImages[0] : images.bannerPrView360
          }
          alt="img"
          width={68}
          height={68}
          style={{ padding: "6px", borderRadius: "100px" }}
        />
        <div className={cx("content_adEs", "ms-2")}>
          <h6 className="mb-1 fs-14" style={{ textTransform: "uppercase" }}>
            <TextLimit
              text={
                dataAd[0].address +
                " " +
                dataAd[0].districts +
                " " +
                dataAd[0].city
              }
              number="15"
            />
          </h6>
          <p className="text-muted mb-0 fw-5 fs-14">
            <TextLimit text={dataAd[0].cate} number={9} /> <BsDot />{" "}
            {Number(dataAd[0].price) > 0 ? (
              handleFormatPrice(Number(dataAd[0].price))
            ) : (
              <>
                {dataAd[0].dtmb}
                <span className="ms-1">
                  m<sup>2</sup>
                </span>
              </>
            )}
          </p>
        </div>
        <button
          className={cx("button_adEs", "border-0 roudend-2 ms-3 fs-12")}
          onClick={() =>
            handleOpenDetail(
              dataAd[0].id,
              "/" +
                cate +
                "-" +
                address +
                "/" +
                dataAd[0].alias +
                "-pr" +
                dataAd[0].id,
            )
          }
        >
          Chi tiết
        </button>
      </div>
    </div>
  )
}


// Xử lý create edit saved
function handleCreateEditSaved(
  dataSaved_,
  simpleId,
  dataUser,
  handleUpdateDataSaved,
) {
  if (dataSaved_.length === 0) {
    // thêm mới
    const object = {
      simpleid: simpleId,
      status: 1,
      author: dataUser[0].id,
    }
    handleMethodCreateData(
      object,
      () => {
        alert("Bạn đã lưu thành công. Vào bảng điều khiển để xem")
        handleUpdateDataSaved()
      },
      process.env.REACT_APP_APICreateMarketSimpleSaved,
    )
  } else {
    // Hủy lưu
    let object, alertSeved
    if (dataSaved_[0].status === 0) {
      object = {
        id: dataSaved_[0].id,
        status: 1,
      }
      alertSeved = "Bạn đã LƯU thành công."
      handleMethodEditData(
        object,
        () => {
          // toast.success("Lưu thành công", {
          //   position: "bottom-right",
          //   autoClose: 1000,
          //   hideProgressBar: true,
          //   progress: undefined,
          //   theme: "light",
          // })
          handleUpdateDataSaved()
        },
        process.env.REACT_APP_APIEditStatusMarketSimpleSaved,
      )
    } else {
      object = {
        id: dataSaved_[0].id,
        status: 0,
      }
      alertSeved = "Bạn đã HỦY thành công."
      handleMethodEditData(
        object,
        () => {
          // toast.error("Đã hủy", {
          //   position: "bottom-right",
          //   autoClose: 1000,
          //   hideProgressBar: true,
          //   progress: undefined,
          //   theme: "light",
          // })
          handleUpdateDataSaved()
        },
        process.env.REACT_APP_APIEditStatusMarketSimpleSaved,
      )
    }
  }
}