import io from "socket.io-client"
import { useRef, useEffect } from "react"
import classNames from "classnames/bind"
import { BsDot } from "react-icons/bs"

import styles from "./Listitem.module.scss"
import { handleFormatPrice, deleteAccentedText } from "../../handle-reuses/reuses"

const cx = classNames.bind(styles)

function ListImageTitle({ data, dataImages }) {
  const socket = useRef()
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
    return () => {
      socket.current.disconnect()
    }
  }, [])
  // Quản lý click chi tiết
  const handleOpenDetail = (path, id_, countView) => {
    // window.open(path)
    const params = {
      countview: Number(countView) + 1,
      id: id_,
    }
    socket.current.emit("client-click-views", params)
  }
  // count view khi mouse.
  const handleCountview = (id_, countView) => {
    const params = {
      countview: Number(countView) + 1,
      id: id_,
    }
    socket.current.emit("client-click-views", params)
  }

  return (
    <div className="">
      {data.map((item) => {
        const filterImages = dataImages.filter(
          (row) => row.simpleid === item.id,
        )
        // format url detail.
        const formatCateUrl = deleteAccentedText(item.cate).toLowerCase()
        const cate = formatCateUrl.replace(/[ ]/g, "-")
        const formatAddress = deleteAccentedText(item.address).toLowerCase()
        const address = formatAddress.replace(/[ ]/g, "-")
        return (
          <a
            href={
              "/" + cate + "-" + address + "/" + item.alias + "-pr" + item.id
            }
            className={cx("d-flex mb-3", "bg-product")}
            key={item.id}
            onClick={() =>
              handleOpenDetail(
                "/" + cate + "-" + address + "/" + item.alias + "-pr" + item.id,
                item.id,
                item.countview,
              )
            }
            onMouseEnter={() => handleCountview(item.id, item.countview)}
          >
            <div className="col-2 d-flex justify-content-center align-items-center">
              <img
                src={filterImages[0].path}
                width="100%"
                height="68"
                className="rounded-3"
                alt=""
              />
            </div>
            <div className="col-10 px-2">
              <div className="fs-15">{item.title}</div>
              <div className="d-flex justify-content-between mt-2">
                <span>
                  {item.hoten}
                  <BsDot />
                  {item.phone}
                </span>
                <span>{handleFormatPrice(item.price)}</span>
              </div>
            </div>
          </a>
        )
      })}
    </div>
  )
}

export default ListImageTitle
