
import { Link } from "react-router-dom"
import classNames from "classnames/bind";

import styles from "../Mobile.module.scss"
import { LoadingSpinner } from "../../../components/Loading"
import { imagesCate, images } from "../../../assets/images"
import { SlideBanner } from "../../../components/Slider"
import { CardListEvent } from "../../../components/Card"
import { NotifiBooking } from "../../pc/Component/Notification"
import { useState } from "react";
import ModalReact from "../../../components/Modal/ModalReact";
import { WalletMain } from "../../pc/Component/Wallet";
import MenuFooter from "../MenuFooter/MenuFooter";

const cx = classNames.bind(styles)


function DashboardPageMobile({
  loading,
  dataEstates,
  dataBooking,
  dataCustomer,
}) {
  // Kiểm tra xem đã loading dữ liệu chưa
  if (loading) {
    return <LoadingSpinner />
  }
  return (
    <>
      <div className={cx("wrapper_dash")} style={{ overflow: "hidden" }}>
        {/* Danh mục */}
        <ListCategoryDashboardMobile />
        {/* Tài trợ */}
        <div className="col-12 mt-3">
          <h6 className="p-2">Tài trợ</h6>
          <SlideBanner />
        </div>
        {/* Lịch hẹn */}
        {/* <CalendarDashMobile
          dataEstates={dataEstates}
          dataBooking={dataBooking}
          dataCustomer={dataCustomer}
        /> */}
        {/* MenuFooter */}
        <MenuFooter />
      </div>
    </>
  )
}

export default DashboardPageMobile

// List danh mục
function ListCategoryDashboardMobile() {
  // Modal wallet.
  const [modalWallet, setModalWallet] = useState(false)
  const handleModalWallet = () => {
    setModalWallet(true)
  }
  const handleCloseModalWallet = () => {
    setModalWallet(false)
  }
  return (
    <div>
      <h6 className="p-2">Danh mục</h6>
      <ul className={cx("box-cate", "mt-3 gap-1")}>
        <li>
          <Link to="/san-pham">
            <div className={cx("icon-box", "bg-cate-1")}>
              <img src={imagesCate.product} alt="" width={30} height={30} />
              {/* <HiOutlineHomeModern
                    className={cx("fs-25", "text-warning")}
                  /> */}
            </div>
            Sản phẩm
          </Link>
        </li>
        <li>
          <Link to="/lich-hen" className="position-relative">
            <div className={cx("icon-box", "bg-cate-2")}>
              {/* <IoCalendarOutline className={cx("fs-25", "text-warning")} /> */}
              <img src={imagesCate.calendar} alt="" width={30} height={30} />
            </div>
            <NotifiBooking />
            Lịch hẹn
          </Link>
        </li>
        <li>
          <Link to="/khach-hang">
            <div className={cx("icon-box", "bg-cate-3")}>
              <img src={imagesCate.customer} alt="" width={30} height={30} />
              {/* <HiOutlineUsers className={cx("fs-25", "text-warning")} /> */}
            </div>
            Khách hàng
          </Link>
        </li>
        <li>
          <Link to="/product-like">
            <div className={cx("icon-box", "bg-cate-4")}>
              <img src={imagesCate.love} alt="" width={30} height={30} />
              {/* <HiOutlineUsers className={cx("fs-25", "text-warning")} /> */}
            </div>
            Yêu thích
          </Link>
        </li>
      </ul>
      {/*  */}
      <ul className={cx("box-cate", "mt-3 gap-1")}>
        <li className={cx("buttonCate")}>
          <button className="border-0 bg-transparent" onClick={handleModalWallet}>
            <div className={cx("icon-box", "bg-cate-4")}>
              <img src={imagesCate.wallet} alt="" width={30} height={30} />
              {/* <HiOutlineUsers className={cx("fs-25", "text-warning")} /> */}
            </div>
            Ví
          </button>
        </li>
      </ul>
      {/* Modal wallet */}
      <ModalReact
        modalTitle="Ví của bạn"
        showModal={modalWallet}
        handleClose={handleCloseModalWallet}
        theme={<WalletMain />}
      />
    </div>
  )
}

// Thống kê.

function CalendarDashMobile({ dataEstates, dataBooking, dataCustomer }) {
  return (
    <div className="mt-5">
      <CardListEvent dataPro={dataEstates} dataBooking={dataBooking} />
    </div>
  )
}
