import { useState, useContext, useEffect} from "react"
import Tippy from "@tippyjs/react/headless"
import Tippy1 from "@tippyjs/react"
import {
  IoWalletOutline,
  IoAddCircleOutline,
  IoNotificationsOutline,
} from "react-icons/io5"
import { PiWarningCircle } from "react-icons/pi"

import InfoUser from "../../Info/InfoUser"
import FormRealEstateFast from "../../Form/FromRealEstateFast"
import ModalA from "../../Modal/ModalA"
import ModalReact from "../../Modal/ModalReact"
import { WalletMain } from "../../../pages/pc/Component/Wallet"
import FormSupport from "../../../pages/pc/Component/Support/FormSupport"
import { NotificationList } from "../../Notification"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import { handleMethodEditData } from "../../../handle-reuses/reuses/method"

function HeaderRight() {
  const { dataUser } = useContext(AppContext)

  // Hiển thị modal đăng tin.
  const [modalAddEstate, setModalAddEstate] = useState(false)
  const handleModalAddEstate = () => {
    setModalAddEstate(true)
  }
  const handleCloseAddEstateModal = () => {
    setModalAddEstate(false)
  }
  

  const [showHideWallet, setShowHideWallet] = useState(false)
  const handleShowModal = () => {
    setShowHideWallet(true)
  }
  const handleCloseModal = () => setShowHideWallet(false)
  // Modal support.
  const [showHideSupport, setShowHideSupport] = useState(false)
  const handleShowSupport = () => {
    setShowHideSupport(true)
  }
  const handleCloseSupport = () => setShowHideSupport(false)
  // Modal Chatbot.
  const [showHideChat, setShowHideChat] = useState(false)
  const handleShowChat = () => {
    setShowHideChat(true)
  }
  const handleCloseChat = () => setShowHideChat(false)

  // count notifi.
  const [dataHisSuc, setDataHisSuc] = useState([])
  const [checkHideNoti, setCheckHideNoti] = useState(false)

  const fetchData = async () => {
    if (dataUser.length > 0) {
      const response = await fetch(
        process.env.REACT_APP_APIGetNotifiUserPayHis +
          `?only=${dataUser[0].id}&statusonly=${1}`,
      )
      const dataRes = await response.json()
      const filterData = dataRes.filter((item) => item.watched === 0)

      setDataHisSuc(filterData)
    }
  }
  useEffect(() => {
    fetchData()
  }, [checkHideNoti])

  // Check hide notification.
  const handleCheckHideNotifi = () => {
    setCheckHideNoti(true)
  }
  // handle notification.
  const [checkNotification, setCheckNotification] = useState(false)
  const [onOff, setOnOff] = useState(false)
  const handleEventNotification = (check) => {
    setCheckNotification(check)
    setOnOff(true)
    // Tắt thông báo khi click vào chuông thông báo.
    handleHideNotifiEventClick(dataHisSuc, handleCheckHideNotifi)
  }
  const handleCheckOnOffNoti = () => {
    setOnOff(false)
  }

  // Check đăng tin thành công.
  const checkPostSuccess = (status_) => {
    if (status_) {
      setModalAddEstate(false)
    }
  }

  return (
    <>
      <div className="d-flex">
        <Tippy1 content="Đăng tin">
          <button
            type="button"
            className="border-0 me-3 op-7"
            style={{ background: "none" }}
            onClick={handleModalAddEstate}
          >
            <IoAddCircleOutline className="fs-26" />
          </button>
        </Tippy1>
        {/* <Tippy1
          content="test"
        >
          <button
            type="button"
            className="border-0 me-3"
            style={{ background: "none" }}
            onClick={handleShowChat}
          >
            <IoAddCircleOutline className="fs-26" />
          </button>
        </Tippy1> */}
        <div>
          <Tippy
            interactive
            delay={[0, 0]}
            placement="bottom-end"
            trigger="click"
            render={() =>
              checkNotification ? (
                <NotificationList
                  status={onOff}
                  result={handleCheckOnOffNoti}
                />
              ) : (
                ""
              )
            }
          >
            <button
              type="button"
              className="border-0 me-3 position-relative op-7"
              style={{ background: "none" }}
              onClick={() => handleEventNotification(true)}
            >
              <IoNotificationsOutline className="fs-26" />
              {dataHisSuc.length > 0 ? (
                <div
                  className="position-absolute fs-12"
                  style={{
                    top: "0px",
                    right: "-1px",
                    backgroundColor: "rgb(255 62 54)",
                    color: "white",
                    borderRadius: "21px",
                    width: "19px",
                    height: "19px",
                    lineHeight: "19px",
                  }}
                >
                  {dataHisSuc.length <= 9 ? Number(dataHisSuc.length) : "9+"}
                </div>
              ) : (
                ""
              )}
            </button>
          </Tippy>
        </div>
        <Tippy1 content="Ví của bạn">
          <button
            type="button"
            className="border-0 me-3 op-7"
            style={{ background: "none" }}
            onClick={handleShowModal}
          >
            <IoWalletOutline className="fs-26" />
          </button>
        </Tippy1>
        <Tippy1 content="Hỗ trợ">
          <button
            type="button"
            className="border-0 me-3 op-7"
            style={{ background: "none" }}
            onClick={handleShowSupport}
          >
            <PiWarningCircle className="fs-26" />
          </button>
        </Tippy1>
        <InfoUser />
      </div>
      {/* Modal Add Product */}
      <ModalReact
        modalTitle="Thông tin đưa ra thị trường"
        showModal={modalAddEstate}
        handleClose={handleCloseAddEstateModal}
        theme={<FormRealEstateFast result={checkPostSuccess} />}
      />
      {/* Modal wallet */}
      <ModalReact
        modalTitle="Ví của bạn"
        showModal={showHideWallet}
        handleClose={handleCloseModal}
        theme={<WalletMain />}
      />
      {/* Modal support */}
      <ModalReact
        modalTitle="Hỗ trợ giải đáp"
        showModal={showHideSupport}
        handleClose={handleCloseSupport}
        theme={<FormSupport />}
      />
      {/* Modal Chat */}
      {/* <ModalReact
        modalTitle="Hãy đặt câu hỏi cho tôi"
        showModal={showHideChat}
        handleClose={handleCloseChat}
        theme="Giao diện chat"
      /> */}
    </>
  )
}

export default HeaderRight

// Xửu lý tắt thông báo
function handleHideNotifiEventClick(data, checkHideNoti) {
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const object = {
        id: data[i].id_his,
        watched: 1,
      }
      handleMethodEditData(
        object,
        () => {
          checkHideNoti()
        },
        process.env.REACT_APP_APIEditRowWatchedUserPayHis,
      )
    }
  }
}
