
import LayoutLeft from "./LayoutLeft";
import LayoutRight from "./LayoutRight";

function LayoutCustomer({dataCountCate}) {
    return (
      <div className="row col-xl-12 col-md-12">
        {/* Left */}
        <div className="col-xl-9 col-md-12">
          <LayoutRight />
        </div>
        {/* Right */}
        <div className="col-xl-3 col-md-12">
          <LayoutLeft dataCountCate={dataCountCate} />
        </div>
      </div>
    )
}

export default LayoutCustomer;