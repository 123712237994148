import { useState, useEffect, useContext, useRef, useLayoutEffect } from "react"
import classNames from "classnames/bind"
import styles from "../../Mobile.module.scss"
import { FiSearch } from "react-icons/fi"
import { MdCancel } from "react-icons/md"
import io from "socket.io-client"
import { LuDot } from "react-icons/lu"

import AppContext from "../../../../handle-reuses/Contexts/CreateContexts"
import { TextLimit, handleFormatPrice } from "../../../../handle-reuses/reuses"
import { images } from "../../../../assets/images"
import { LoadingSpinner } from "../../../../components/Loading"
import { deleteAccentedText } from "../../../../handle-reuses/reuses"
import { Link } from "react-router-dom"
import { BsDot } from "react-icons/bs"
import ModalReact from "../../../../components/Modal/ModalReact"
import DetailEstate from "../../../../components/DetailEstate/DetailEstate"

const cx = classNames.bind(styles)

function SearchHomeMobile() {
  const {socketio} = useContext(AppContext)
  const [valueText, setValueText] = useState("")
  // const socket = useRef()

  // // khởi tạo socket.
  // useEffect(() => {
  //   socket.current = io(process.env.REACT_APP_SERVER)
  //   return () => {
  //     socket.current.disconnect()
  //   }
  // }, [])

  const handleInputText = (e) => {
    setValueText(e.target.value)
  }
  // Xóa tìm kiếm.
  const handleClearInputText = () => {
    setValueText("")
  }
  // statistical search key.
  const handleSearchKeywordStatistical = (e) => {
    const inputKeywords = e.target.value
    if (inputKeywords !== "") {
      socketio.emit("client-add-search-keyword", inputKeywords)
    }
  }
  return (
    <div className="col-12" style={{ position: "relative" }}>
      <div
        className={cx(
          "Search_Wrapper",
          "d-flex justify-content-center align-items-center",
        )}
      >
        <div className={cx("SearchIcon")}>
          <FiSearch className="fs-15" />
        </div>
        <input
          type="text"
          placeholder="Tên đường, dự án..."
          className={cx("col-12", "Search_Input")}
          value={valueText}
          onChange={handleInputText}
          onBlur={handleSearchKeywordStatistical}
        />
        <div className={cx("Search_ClearText")}>
          {valueText !== "" ? <MdCancel onClick={handleClearInputText} /> : ""}
        </div>
      </div>
      {/* Kết quả tìm kiếm */}
      {valueText !== "" ? <SearchResultHome value={valueText} socketio={socketio} /> : ""}
    </div>
  )
}

export default SearchHomeMobile

// Kết quả search.
function SearchResultHome({ value, socketio }) {
  const { dataUser } = useContext(AppContext)
  const [resultData, setResultData] = useState([])
  const [dataSuggest, setDataSuggest] = useState([])
  const [cate, setCate] = useState("")
  const [districts, setDistricts] = useState("")
  const [city, setCity] = useState("")
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    
    socketio.emit("client-search", value)
    return () => {
      socketio.off("client-search")
    }
  }, [value])

  useEffect(() => {
    socketio.on("server-search", (data) => {
      setLoading(true)
      setDataSuggest(data)
      if (data) {
        setTimeout(() => {
          setLoading(false)
          setResultData(data)
        }, 1000)
      }
    })
    return () => {
      socketio.off("server-search")
    }
  }, [value])

  // Xử lý gợi ý danh mục
  const [optionCate, setOptionCate] = useState([])
  const [optionDis, setOptionDis] = useState([])
  const [optionCity, setOptionCity] = useState([])
  useLayoutEffect(() => {
    if (dataSuggest.length > 0) {
      // Xử lý gợi ý cate
      const filterOptionCate = dataSuggest.map((item) => {
        return item.cate
      })
      const filterCate = filterOptionCate.reduce((arr, value) => {
        if (arr.indexOf(value) === -1) {
          arr.push(value)
        }
        return arr
      }, [])
      setOptionCate(filterCate)

      // Xử lý gợi ý districts.
      const filterOptionDis = dataSuggest.map((item) => {
        return item.districts
      })
      const filterDis = filterOptionDis.reduce((arr, value) => {
        if (arr.indexOf(value) === -1) {
          arr.push(value)
        }
        return arr
      }, [])
      setOptionDis(filterDis)

      // Xử lý gợi ý city.
      const filterOptionCity = dataSuggest.map((item) => {
        return item.city
      })
      const filterCity = filterOptionCity.reduce((arr, value) => {
        if (arr.indexOf(value) === -1) {
          arr.push(value)
        }
        return arr
      }, [])
      setOptionCity(filterCity)
    }
  }, [value])

  // Cate
  const handleOptionCate = (e) => {
    setCate(e.target.value)
  }
  // districts.
  const handleOptionDis = (e) => {
    setDistricts(e.target.value)
  }
  // city
  const handleOptionCity = (e) => {
    setCity(e.target.value)
  }
  // Kết quả khi người dùng filter quanhuyen thanhpho
  const [arrFilter, setArrFilter] = useState([])
  useEffect(() => {
    const arrUpdate = resultData
    let filterData = handleFilterDataEstate(arrUpdate, cate, districts, city)
    setArrFilter(filterData)
  }, [cate, districts, city])

  // Lấy thống kê search keyword.
  useEffect(() => {
    if (cate !== "" && districts !== "" && city !== "") {
      const object = {
        cate: cate,
        title: value,
        districts: districts,
        city: city,
      }
      socketio.emit("client-add-all-search-keyword", object)
      return () => {
        socketio.off("client-add-all-search-keyword")
      }
    }
  }, [cate, districts, city])

  return (
    <div className={cx("resultSearch")}>
      <div className={cx("contentSearch")}>
        <div className={cx("searchTitle")}>
          <FiSearch className={cx("me-2", "fs-15")} />
          <span>
            Kết quả tìm kiếm cho '{value}'
          </span>
        </div>
        {/* Filter result data search */}
        <div className="col-xl-12 mt-3">
          {/* Option cate */}
          <div className="col-xl-12 col-md-12 position-relative">
            <div className="form-floating">
              <select
                className="form-control rounded-3"
                value={cate}
                onChange={handleOptionCate}
              >
                <option value="">Chọn danh mục</option>
                {dataSuggest.length > 0 &&
                  optionCate.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    )
                  })}
              </select>
              <label className="fs-14">Danh mục</label>
            </div>
          </div>
          <div className="row col-xl-12 col-md-12 mt-2">
            {/* Option districts */}
            <div className="col-xl-6 col-md-12 position-relative">
              <div className="form-floating mb-1">
                <select
                  className="form-control rounded-3"
                  value={districts}
                  onChange={handleOptionDis}
                >
                  <option value="">Chọn quận/huyện</option>
                  {dataSuggest.length > 0 &&
                    optionDis.map((item, index) => {
                      return (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      )
                    })}
                </select>
                <label className="fs-14">Danh mục</label>
              </div>
            </div>
            {/* Option city */}
            <div className="col-xl-6 col-md-12 position-relative mt-2">
              <div className="form-floating mb-1">
                <select
                  className="form-control rounded-3"
                  value={city}
                  onChange={handleOptionCity}
                >
                  <option value="">Chọn thành phố</option>
                  {dataSuggest.length > 0 &&
                    optionCity.map((item, index) => {
                      return (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      )
                    })}
                </select>
                <label className="fs-14">Danh mục</label>
              </div>
            </div>
          </div>
        </div>
        <div className={cx("Search_heading")}>
          <h5>Tổng kết quả</h5>
          <span>{resultData.length}</span>
        </div>
        {loading && (
          <div className={cx("fs-12 text-muted")}>
            <LoadingSpinner />
          </div>
        )}
        <div
          className={cx("mt-3")}
          style={{ display: loading ? "none" : "block" }}
        >
          {resultData.length > 0 ? (
            <ListItemEstate
              data={arrFilter.length > 0 ? arrFilter : resultData}
              socketio={socketio}
            />
          ) : (
            <span className={cx("fs-15", "text-muted")}>
              Không có kết quả phù hợp
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

// List sản phẩm được tìm kiếm.
function ListItemEstate({ data, socketio }) {
  // sắp xếp nhưng sản phẩm có money add cao nhất.
  const sortData = data.sort((a, b) => {
    let min_ = a.admoney
    let max_ = b.admoney
    return max_ - min_
  })


  // count view.
  const [checkEvent, setCheckEvent] = useState(0)
  const idSimpleOld = useRef(0)
  const handleCountview = (simpleId) => {
    setCheckEvent(simpleId)
    socketio.emit("client-detail-product", simpleId)
  }
  useEffect(() => {
    // Tăng view khi click.
    if (checkEvent !== 0) {
      socketio.on("server-detail-product", (dataUpdate) => {
        const params = {
          id: checkEvent,
          countview: Number(dataUpdate[0].countview) + 1,
        }
        if (checkEvent !== idSimpleOld.current) {
          socketio.emit("client-click-views", params)
        }
        // Xác nhận id này vừa mới tăng view rồi.
        idSimpleOld.current = checkEvent
      })
    }

    return () => {
      socketio.off("client-detail-product")
      socketio.off("server-detail-product")
      socketio.off("client-click-views")
    }
  }, [checkEvent])

  // Modal detail estate.
  const [modaleDetail, setModalDetail] = useState(false)
  const dataDetailSearch = useRef([])
  const handleModalDetailEstate = (id_) => {
    // filter data.
    const dataDetail = sortData.filter((item) => item.id === id_)
    setModalDetail(true)
    dataDetailSearch.current = dataDetail
  }
  const handleCloseModalDetail = () => {
    setModalDetail(false)
    dataDetailSearch.current = []
  }


  return (
    <>
      {sortData.map((item, index) => {
        let listImages
        if (item.images !== null) {
          listImages = item.images.split(",")
        }
        // format url detail.
        const formatCateUrl = deleteAccentedText(item.cate).toLowerCase()
        const cate = formatCateUrl.replace(/[ ]/g, "-")
        const formatAddress = deleteAccentedText(item.address).toLowerCase()
        const address = formatAddress.replace(/[ ]/g, "-")
        return (
          <div
            className={cx("d-flex align-items-center mb-3")}
            key={index}
            onMouseEnter={() => handleCountview(item.id)}
            onTouchStart={() => handleCountview(item.id)}
          >
            {listImages ? (
              <img
                src={"/" + listImages[0]}
                alt=""
                className={cx("images-search", "me-3")}
              />
            ) : (
              <img
                src={images.noImagePro}
                alt=""
                className={cx("images-search")}
              />
            )}
            <div className={cx("contentSearch", "p-0")}>
              <button
                className="border-0 bg-transparent text-left p-0"
                onClick={() => handleModalDetailEstate(item.id)}
              >
                <h6 className="fw-5 mb-1">
                  {item.address}, {item.districts}, {item.city}
                </h6>
                <p>
                  {item.cate}
                  <BsDot />
                  {Number(item.price) > 0
                    ? handleFormatPrice(Number(item.price))
                    : item.form_cate}
                  {item.donvi === "Thỏa thuận" || item.donvi === ""
                    ? ""
                    : " / " + item.donvi.toLowerCase()}
                </p>
              </button>
              {/* <Link
                to={
                  "/" +
                  cate +
                  "-" +
                  address +
                  "/" +
                  item.alias +
                  "-pr" +
                  item.id
                }
              >
                
              </Link> */}
            </div>
          </div>
        )
      })}
      {/* Modal detail */}
      {dataDetailSearch.current.length > 0 && (
        <ModalReact
          modalTitle="Chi tiết sản phẩm"
          showModal={modaleDetail}
          handleClose={handleCloseModalDetail}
          theme={
            <DetailEstate
              dataEstate={dataDetailSearch.current}
            />
          }
        />
      )}
    </>
  )
}

// Xử lý filter data.
function handleFilterDataEstate(arrUpdate, cate, districts, city) {
  let filterData = []
  if (cate !== "" && districts !== "" && city !== "") {
    filterData = arrUpdate.filter((item) => {
      return (
        item.cate.toLowerCase().includes(cate.toLowerCase()) &&
        item.districts.toLowerCase().includes(districts.toLowerCase()) &&
        item.city.toLowerCase().includes(city.toLowerCase())
      )
    })
  } else if (cate !== "" && districts !== "" && city === "") {
    filterData = arrUpdate.filter((item) => {
      return (
        item.cate.toLowerCase().includes(cate.toLowerCase()) &&
        item.districts.toLowerCase().includes(districts.toLowerCase())
      )
    })
  } else if (cate !== "" && districts === "" && city !== "") {
    filterData = arrUpdate.filter((item) => {
      return (
        item.cate.toLowerCase().includes(cate.toLowerCase()) &&
        item.city.toLowerCase().includes(city.toLowerCase())
      )
    })
  } else if (cate === "" && districts !== "" && city !== "") {
    filterData = arrUpdate.filter((item) => {
      return (
        item.districts.toLowerCase().includes(districts.toLowerCase()) &&
        item.city.toLowerCase().includes(city.toLowerCase())
      )
    })
  } else if (cate === "" && districts === "" && city !== "") {
    filterData = arrUpdate.filter((item) => {
      return item.city.toLowerCase().includes(city.toLowerCase())
    })
  } else if (cate === "" && districts !== "" && city === "") {
    filterData = arrUpdate.filter((item) => {
      return item.districts.toLowerCase().includes(districts.toLowerCase())
    })
  } else if (cate !== "" && districts === "" && city === "") {
    filterData = arrUpdate.filter((item) => {
      return item.cate.toLowerCase().includes(cate.toLowerCase())
    })
  }
  return filterData
}
