import HeaderDashboardMobile from "../Component/Header/ComponentHeaderDash"
import PageCalendar from "./PageCalendar"

function PageProductMobile() {
    return (
      <>
        {/* Header */}
        <HeaderDashboardMobile namePage="Lịch hẹn" />
        {/* Main */}
        <PageCalendar />
        
      </>
    )
}

export default PageProductMobile