import { useContext, useEffect, useRef, useState } from "react"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { images } from "../../../assets/images"
import { TextLimit, handleFormatPrice } from "../../../handle-reuses/reuses"
import { BsDot } from "react-icons/bs"
import SearchHorizontal from "../../../components/Search/SearchHorizontal"
import ModalReact from "../../../components/Modal/ModalReact"
import DetailEstate from "../../../components/DetailEstate/DetailEstate"

function ListEstateForUser({ userid }) {
  const { socketio } = useContext(AppContext)

  // Get danh sách data estate for user.
  const [dataEstate, setDataEstate] = useState([])

  const handleGetDataListEstate = () => {
    const params = {
      iden: process.env.REACT_APP_IDENTIFICATION,
      userid: userid,
    }
    socketio.emit("client-list-estate-for-user-all", params)
  }
  useEffect(() => {
    // Get data ban đầu.
    handleGetDataListEstate()

    return () => {
      socketio.off("client-list-estate-for-user-all")
    }
  }, [userid])

  // Nhận dữ liệu.
  useEffect(() => {
    socketio.on("server-list-estate-for-user-all", (data) => {
      setDataEstate((old) => [...old, ...data])
    })
    return () => {
      socketio.off("server-list-estate-for-user-all")
    }
  }, [])

  // Xử lý search data.
  const searchStatus = useRef("")
  const handleValueSearch = (keywords) => {
    searchStatus.current = keywords
    // Tìm kiếm trong danh sách của từng user.
    const paramsSearch = {
      iden: process.env.REACT_APP_IDENTIFICATION,
      object: {
        userid: userid,
        keywords: keywords,
      },
    }
    socketio.emit("client-search-List-estate-for-user-all", paramsSearch)
    socketio.on("server-search-List-estate-for-user-all", (dataSearch) => {
      setDataEstate([...dataSearch])
    })
  }
  // Nhận dữ liệu search
  useEffect(() => {
    return () => {
      socketio.off("client-search-List-estate-for-user-all")
      socketio.off("server-search-List-estate-for-user-all")
    }
  }, [searchStatus.current])

  // Xử lys hiển thị modal detail estate.
  const [modalDetailEstate, setModalDetailEstate] = useState(false)
  const dataDetail = useRef([])
  const handleModalDetail = (dataEstateDetail) => {
    setModalDetailEstate(true)
    dataDetail.current = [dataEstateDetail]
  }
  const handleCloseModalDetailEstate = () => {
    setModalDetailEstate(false)
    dataDetail.current = []
  }

  // count view estate.
  const checkEvent = useRef(0)
  const idSimpleOld = useRef(0)
  const handleCountview = (simpleId) => {
    // setCheckEvent(simpleId)
    checkEvent.current = simpleId
    socketio.emit("client-detail-product", simpleId)
  }
  useEffect(() => {
    // Tăng view khi click.
    socketio.on("server-detail-product", (dataUpdate) => {
      if (checkEvent.current !== 0) {
        const params = {
          id: dataUpdate[0].id,
          countview: Number(dataUpdate[0].countview) + 1,
        }
        if (checkEvent.current !== idSimpleOld.current) {
          socketio.emit("client-click-views", params)
        }
        // Xác nhận id này vừa mới tăng view rồi.
        idSimpleOld.current = checkEvent.current
      }
    })

    return () => {
      socketio.off("client-detail-product")
      socketio.off("server-detail-product")
      socketio.off("client-click-views")
    }
  }, [checkEvent.current])

  return (
    <>
      <div className="col-12">
        {/* Search sản phẩm */}
        <div className="mb-3">
          <SearchHorizontal result={handleValueSearch} className="rounded-3" />
        </div>
        {/* Danh sách sản phẩm */}
        <div className="d-flex gap-4 flex-direction-column">
          {dataEstate.map((item) => {
            let listImages = item.images
            if (listImages) {
              listImages = listImages.split(",")
            } else {
              listImages = []
            }
            return (
              <div
                className="d-flex align-items-center gap-2 rounded-3"
                style={{
                  height: "68px",
                  border: "1px solid #dcdcdc54",
                  padding: "6px",
                }}
                key={"listEstateUser" + item.id}
                onClick={() => handleModalDetail(item)}
                onTouchStart={() => handleCountview(item.id)}
              >
                {/* Image */}
                <div>
                  <LazyLoadImage
                    alt="Image 1"
                    effect="blur"
                    src={
                      listImages.length > 0
                        ? "/" + listImages[0]
                        : images.noImagePro
                    }
                    width={54}
                    height={54}
                    className="rounded-5"
                  />
                </div>
                {/* Nội dung */}
                <div className="fs-16">
                  <h6 className="mb-1">
                    <TextLimit
                      text={
                        item.address + ", " + item.districts + ", " + item.city
                      }
                      number={25}
                    />
                  </h6>
                  <p className="mb-0 text-muted">
                    <TextLimit text={item.cate} number={15} /> <BsDot />{" "}
                    {Number(item.price) > 0 ? (
                      handleFormatPrice(Number(item.price))
                    ) : (
                      <span>
                        {item.dtmb}m<sup>2</sup>
                      </span>
                    )}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {/* Modal DetailEstate */}
      {modalDetailEstate && (
        <ModalReact
          modalTitle="Chi tiết sản phẩm"
          showModal={modalDetailEstate}
          handleClose={handleCloseModalDetailEstate}
          theme={<DetailEstate dataEstate={dataDetail.current} />}
        />
      )}
    </>
  )
}

export default ListEstateForUser