import { Link, useNavigate } from "react-router-dom"
import classNames from "classnames/bind"
import { IoIosArrowBack } from "react-icons/io"
import { IoMenu } from "react-icons/io5"
import { Dropdown } from "react-bootstrap"
import { VscDashboard } from "react-icons/vsc"
import { HiOutlineHomeModern, HiOutlineSquare3Stack3D } from "react-icons/hi2"
import { IoCalendarOutline } from "react-icons/io5"
import { HiOutlineUsers } from "react-icons/hi2"

import styles from "../../Mobile.module.scss"
const cx = classNames.bind(styles)

function HeaderDashboardMobile({ namePage, layoutRight }) {
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }
  return (
    <>
      {/* App Header */}
      <div className={cx("appHeader")}>
        <div className={cx("wrapper_header")}>
          <div className={cx("left", "d-flex align-items-center")}>
            <Link
              to="#"
              className={cx("headerButton", "goBack")}
              onClick={goBack}
            >
              <IoIosArrowBack className="me-1 fs-15" />{" "}
              <span className="fs-14">QUAY LẠI</span>
            </Link>
          </div>
          <div className={cx("pageTitle")}>{namePage}</div>
          <div className={cx("right")}>
            {layoutRight ? (
              layoutRight
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  variant="btn btn-outline-light rounded-pill btn-wave border-0 p-1" // class mặc định
                  id="dropdown-basic"
                  bsPrefix="custom-dropdown-toggle" // đặt tên cho class fix loại bỏ class dropdown-toggle
                >
                  <IoMenu className="fs-25" />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ width: "200px" }}>
                  <ListMenuMobile />
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
      <div style={{ height: "56px" }} className="mb-1"></div>
      {/* *App Header */}
    </>
  )
}
export default HeaderDashboardMobile


// List menu mobile.
function ListMenuMobile() {
    return (
      <>
        <Link
          to="/dashboard"
          className="dropdown-item d-flex align-items-center"
        >
          <HiOutlineSquare3Stack3D className="fs-18 me-2" />
          Quản lý
        </Link>
        <Link
          to="/san-pham"
          className="dropdown-item d-flex d-flex align-items-center"
        >
          <HiOutlineHomeModern className="fs-18 me-2" />
          Sản phẩm
        </Link>
        <Link
          to="/lich-hen"
          className="dropdown-item d-flex d-flex align-items-center"
        >
          <IoCalendarOutline className="fs-18 me-2" />
          Lịch hẹn
        </Link>
        <Link
          to="/khach-hang"
          className="dropdown-item d-flex d-flex align-items-center"
        >
          <HiOutlineUsers className="fs-18 me-2" />
          Khách hàng
        </Link>
      </>
    )
}