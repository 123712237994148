import { useState, useEffect, useContext } from "react"
import AppContext from "../../../../handle-reuses/Contexts/CreateContexts";

function NotifiBooking() {
  // User.
  const { dataUser, socketio } = useContext(AppContext)
  const [notifi, setNotifi] = useState(0)
  // Nếu có user đặt lịch mới.
  useEffect(() => {
    if (dataUser.length > 0) {
      socketio.on("notification", (data) => {
        let filterData = data.filter((item) => item.author_simple === dataUser[0].id)
        if (filterData.length > 0) {
          setNotifi(Number(filterData.length))
        } else {
          setNotifi(0)
        }
      })
      
      return () => {
        socketio.off("notification")
      }
    }
  }, []);

  // Get data.
  useEffect(() => {
    if (dataUser.length > 0) {
      socketio.emit("client-dataBooking", dataUser[0].id)
      socketio.on("server-dataBooking", (data) => {
        setNotifi(Number(data.length))
      })

      return () => {
        socketio.off("client-dataBooking")
        socketio.off("server-dataBooking")
      }
    }
  }, [])



  if (notifi === 0) {
    return
  }
    return (
      <button
        className="position-absolute rounded-5 px-2"
        style={{
          top: "-8px",
          right: 0,
          background: "#ff6174",
          color: "white",
          border: "2px solid white",
        }}
      >
        {notifi}
      </button>
    )
}

export default NotifiBooking