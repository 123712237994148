
import classNames from "classnames/bind";
import styles from "./ProfileDefault.module.scss"
import ProfilePC from "./ProfilePC";
const cx = classNames.bind(styles);

function ProfilePage() {
  
    return (
      <div className={cx("wrapper_page")}>
        <ProfilePC />
      </div>
    )
}

export default ProfilePage;