import { useState, useContext, useEffect, useRef } from "react"
import { Controller, useForm} from "react-hook-form"
import classNames from "classnames/bind"
import io from "socket.io-client"

import styles from "../../Dashboard.module.scss"
import { handleFormatPriceInput } from "../../../../../handle-reuses/reuses"
import { handleAddData } from "./Handle/Method"
import AppContext from "../../../../../handle-reuses/Contexts/CreateContexts"

const cx = classNames.bind(styles)

const optionCate = [
  { id: 1, cate: "Mua nhà" },
  { id: 2, cate: "Mua đất" },
  { id: 3, cate: "Mua đầu tư" },
  { id: 4, cate: "Thuê nhà riêng" },
  { id: 5, cate: "Thuê phòng trọ" },
  { id: 6, cate: "Thuê căn hộ" },
  { id: 7, cate: "Thuê chung cư" },
  { id: 8, cate: "Thuê mặt bằng" },
]
const optionHuong = [
  { id: 1, title: "Đông" },
  { id: 2, title: "Tây" },
  { id: 3, title: "Nam" },
  { id: 4, title: "Băc" },
  { id: 5, title: "Đông Nam" },
  { id: 6, title: "Tây Bắc" },
  { id: 7, title: "Đông Bắc" },
  { id: 8, title: "Tây Nam" },
]

function FormAddCustomer({ result }) {
  const { dataUser } = useContext(AppContext)
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const [suggestStreet, setSuggestStreet] = useState([])
  const [suggestDistricts, setSuggestDistricts] = useState([])
  const [suggestCitys, setSuggestCitys] = useState([])
  const [street, setStreet] = useState("")
  const [districts, setDistricts] = useState("")
  const [citys, setCitys] = useState("")
  const [formatPrice, setFormatPrice] = useState("")

  // Khởi tạo socket
  const socket = useRef();
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
    return () => {
      socket.current.disconnect()
    }
  }, [])

  const fetchData = async (streetsText, districtsText, citysText) => {
    if (streetsText) {
      const params = {
        iden: process.env.REACT_APP_IDENTIFICATION,
        keyword: streetsText
      }
      socket.current.emit("client-suggest-address-simple", params)

    }
    if (districtsText) {
      const response = await fetch(
        process.env.REACT_APP_APIGetDataStreetProject +
          `?districts=${districtsText}`,
      )
      const dataResDistrict = await response.json()
      // Loại bỏ giá trị giống nhau.
      const arr = []
      const resultArr = dataResDistrict.filter((item) => {
        if (!arr[item.districts]) {
          arr[item.districts] = true
          return true
        }
      })
      setSuggestDistricts(resultArr)
    }
    if (citysText) {
      const response = await fetch(
        process.env.REACT_APP_APIGetDataStreetProject + `?citys=${citysText}`,
      )
      const dataResCity = await response.json()
      // Loại bỏ giá trị giống nhau.
      const arr = []
      const resultArr = dataResCity.filter((item) => {
        if (!arr[item.citys]) {
          arr[item.citys] = true
          return true
        }
      })
      setSuggestCitys(resultArr)
    }
  }

  // Quản lý gợi ý.
  useEffect(() => {
    // tên đường hoặc dự án.
    socket.current.on("server-suggest-address-simple", (data) => {
      // Loại bỏ giá trị giống nhau trong mảng.
      const arr = []
      const resultArr = data.filter((item) => {
        if (!arr[item.address]) {
          arr[item.address] = true
          return true
        }
      })
      setSuggestStreet(resultArr)
    })

    return () => {
      socket.current.off("server-suggest-address-simple")
    }
  },[])

  // Xử lý gợi ý street
  const handleSuggestStreet = (e) => {
    setStreet(e.target.value)
    fetchData(e.target.value)
    if (e.target.value === "") {
      setSuggestStreet([])
    }
  }
  const handleOptionStreet = (option) => {
    setStreet(option)
    setSuggestStreet([])
  }
  // Xử lý gợi ý districs.
  const handleSuggestDistricts = (e) => {
    setDistricts(e.target.value)
    fetchData("", e.target.value, "")
    if (e.target.value === "") {
      setSuggestDistricts([])
    }
  }
  const handleOptionDistricts = (option) => {
    setDistricts(option)
    setSuggestDistricts([])
  }
  // Xử lý gợi ý citys
  const handleSuggestCitys = (e) => {
    setCitys(e.target.value)
    fetchData("", "", e.target.value)
    if (e.target.value === "") {
      setSuggestCitys([])
    }
  }
  const handleOptionCitys = (option) => {
    setCitys(option)
    setSuggestCitys([])
  }

  // Format price.
  const handlePrice = (e) => {
    setFormatPrice(e.target.value)
    if (e.target.value !== "") {
      let fortmat__ = e.target.value.replace(/[,]/g, "")
      if (!isFinite(fortmat__)) {
        alert("Bạn cần nhập kiểu số.")
      }
      let format_ = e.target.value.replace(/[^0-9,]/g, "")
      const formatPrice = handleFormatPriceInput(format_)
      setFormatPrice(formatPrice)
    }
  }

  const [checkNumber, setCheckNumber] = useState({
    duongngo: "",
    dtmb: '',
    mattien: ""
  })
  const handleCheckDuongNgo = (e) => {
    if (!isFinite(e.target.value)) {
      alert("Bạn cần nhập dạng số. Ví dụ: 3 hoặc 3.5")
      
    }
    setCheckNumber({
      duongngo: e.target.value.replace(/[^0-9.]/g, ""),
      dtmb: checkNumber.dtmb,
      mattien: checkNumber.mattien,
    })
  }
  const handleCheckDtmb = (e) => {
    if (!isFinite(e.target.value)) {
      alert("Bạn cần nhập dạng số. Ví dụ: 68 hoặc 68.5")
      
    }
    setCheckNumber({
      duongngo: checkNumber.duongngo,
      dtmb: e.target.value.replace(/[^0-9.]/g, ""),
      mattien: checkNumber.mattien,
    })
  }
  const handleCheckMattien = (e) => {
    if (!isFinite(e.target.value)) {
      alert("Bạn cần nhập dạng số. Ví dụ: 6 hoặc 6.5")
      
    }
    setCheckNumber({
      duongngo: checkNumber.duongngo,
      dtmb: checkNumber.dtmb,
      mattien: e.target.value.replace(/[^0-9.]/g, ""),
    })
  }

  // Check phone.
  const [checkPhone, setCheckPhone] = useState("");
  const handleCheckPhone = (e) => {
    let format_ = e.target.value.replace(/[^0-9.]/g, "")
    if (format_.length <= 12) {
      setCheckPhone(format_)
    }
  }

  // Submit
  const onSubmit = (data) => {
    data.userid = dataUser[0].id

    handleAddData(data, reset)
    result(true)
  }
  return (
    <div className="p-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row gy-3 pb-3">
          <div className="col-xl-6 col-md-12">
            <div className="form-floating">
              <select
                {...register("loaisanpham", { required: true })}
                className="form-control"
              >
                <option value="">Chọn danh mục</option>
                {optionCate.map((option) => {
                  return (
                    <option key={option.id} value={option.cate}>
                      {option.cate}
                    </option>
                  )
                })}
              </select>
              <label className="form-label mb-1">
                Danh mục <sup className="text-danger">*</sup>
              </label>
              {errors.loaisanpham && (
                <span className={cx("text-danger", "fs-12")}>
                  *Bạn chưa chọn danh mục
                </span>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <Controller
                name="tuyenduong"
                control={control}
                defaultValue=""
                render={({ field: { onChange } }) => (
                  <>
                    <input
                      value={street}
                      className="form-control"
                      placeholder="Trần nguyên hãn"
                      onChange={(e) => {
                        onChange(e)
                        handleSuggestStreet(e)
                      }}
                    />
                    <label>Tên đường hoặc dự án</label>
                    <div
                      className={cx(
                        "suggestList",
                        suggestStreet.length > 0 ? "active" : "",
                      )}
                    >
                      <ul>
                        {suggestStreet.map((item, index) => {
                          return (
                            <li
                              key={"streets" + index}
                              onClick={() => {
                                onChange(item.address)
                                handleOptionStreet(item.address)
                              }}
                            >
                              {item.address}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <Controller
                name="quanhuyen"
                control={control}
                defaultValue=""
                render={({ field: { onChange } }) => (
                  <>
                    <input
                      value={districts}
                      className="form-control"
                      placeholder="Lê Chân"
                      onChange={(e) => {
                        onChange(e)
                        handleSuggestDistricts(e)
                      }}
                    />
                    <label>Chọn quận/huyện</label>
                    <div
                      className={cx(
                        "suggestList",
                        suggestDistricts.length > 0 ? "active" : "",
                      )}
                    >
                      <ul>
                        {suggestDistricts.map((item, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() => {
                                onChange(item.districts)
                                handleOptionDistricts(item.districts)
                              }}
                            >
                              {item.districts}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <Controller
                name="thanhpho"
                control={control}
                defaultValue=""
                render={({ field: { onChange } }) => (
                  <>
                    <input
                      value={citys}
                      className="form-control"
                      placeholder="Hải phòng"
                      onChange={(e) => {
                        onChange(e)
                        handleSuggestCitys(e)
                      }}
                    />
                    <label>Chọn thành phố</label>
                    <div
                      className={cx(
                        "suggestList",
                        suggestCitys.length > 0 ? "active" : "",
                      )}
                    >
                      <ul>
                        {suggestCitys.map((item, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() => {
                                onChange(item.citys)
                                handleOptionCitys(item.citys)
                              }}
                            >
                              {item.citys}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <input
                value={checkNumber.duongngo}
                {...register("duongngo")}
                className="form-control"
                placeholder="Đường ngõ"
                onChange={handleCheckDuongNgo}
              />
              <label>
                Đường ngõ
              </label>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <input
                value={checkNumber.dtmb}
                {...register("dtmb")}
                className="form-control"
                placeholder="Diện tích mặt bằng"
                onChange={handleCheckDtmb}
              />
              <label>
                Diện tích mặt bằng
              </label>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <input
                value={checkNumber.mattien}
                {...register("mattien")}
                className="form-control"
                placeholder="Mặt tiền"
                onChange={handleCheckMattien}
              />
              <label>
                Mặt tiền
              </label>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <select {...register("huongnha")} className="form-control">
                <option value="">Chọn hướng</option>
                {optionHuong.map((option) => {
                  return (
                    <option key={option.id} value={option.title}>
                      {option.title}
                    </option>
                  )
                })}
              </select>
              <label>Hướng nhà</label>
            </div>
          </div>
          <div className="col-xl-12 col-md-12">
            <div className="form-floating mb-1">
              <input
                type="text"
                value={formatPrice}
                {...register("giasanpham", { required: true })}
                className="form-control"
                placeholder="Giá"
                onChange={handlePrice}
              />
              <label>
                Giá <sup className="text-danger">*</sup>
              </label>
              {errors.giasanpham && (
                <span className={cx("text-danger", "fs-12")}>
                  *Bạn chưa nhập giá
                </span>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <input
                type="text"
                {...register("tenlienhe", { required: true })}
                className="form-control"
                placeholder="Tên liên hệ"
              />
              <label>
                Tên liên hệ <sup className="text-danger">*</sup>
              </label>
              {errors.tenlienhe && (
                <span className={cx("text-danger", "fs-12")}>
                  *Bạn chưa tên liên hệ
                </span>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <input
                value={checkPhone}
                {...register("sodienthoai", { required: true })}
                className="form-control"
                placeholder="Số điện thoại"
                onChange={handleCheckPhone}
              />
              <label>
                Số điện thoại <sup className="text-danger">*</sup>
              </label>
              {errors.sodienthoai && (
                <span className={cx("text-danger", "fs-12")}>
                  *Bạn chưa Số điện thoại
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="px-4 d-flex py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
          <button
            className="btn btn-outline-dark m-1 px-4 fw-5"
            type="submit"
            id="submitProduct"
          >
            Thêm mới
          </button>
        </div>
      </form>
    </div>
  )
}

export default FormAddCustomer
