import { handleUploadFileImage } from "../../../../handle-reuses/reuses/UploadFile"
import { handleMethodEditData } from "../../../../handle-reuses/reuses/method"

async function handleEditInfoUser(object, file, handleStatusEditSuccess) {
  // Thêm ảnh
  await handleUploadFileImage(
    file,
    process.env.REACT_APP_APICreateRowMarketUploadFileUser,
  )

  // Edit thông tin user.
  await handleMethodEditData(
    object,
    () => {
      alert("Cập nhật dữ liệu thành công.")
      handleStatusEditSuccess(true)
    },
    process.env.REACT_APP_APIEditDataMarketUser,
  )
  // setTimeout(() => {
    
  // }, 200);
}

export { handleEditInfoUser }