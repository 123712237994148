import io from "socket.io-client"
import { useState, useEffect, useContext, useRef } from "react"

import HeaderDashboardPage from "./Header"
import classNames from "classnames/bind"
import styles from "./Dashboard.module.scss"
import Sanpham from "./Component/Sanpham"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import { LoadingSpinner } from "../../../components/Loading"
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import PageProductMobile from "../../mobile/PageProduct"

const cx = classNames.bind(styles)

function SanphamPage() {
  const {dataUser, socketio} = useContext(AppContext)
  const [dataEstates, setDataEstates] = useState([])
  const [dataBooking, setDataBooking] = useState([])
  const [loading, setLoading] = useState(true)
  const limit = 12;
  const page = useRef(1);
  const checkOverData = useRef(false)
  const checkpage = useRef(true)
  const statusSearch = useRef("")

  // Kiểm tra trang thái search hay không search.
  const handleStatusSearch = (status_) => {
    statusSearch.current = status_
  }

  // xỬ lý get data simple.
  const handleGetDataMarketSimple = (limit_, page_) => {
    const params = {
      iden: process.env.REACT_APP_IDENTIFICATION,
      object: {
        userid: dataUser[0].id,
        quantity: limit_,
        offset: (page_ - 1) * limit_,
      },
    }
    socketio.emit("client-data-for-user-simple", params)
  }

  // Quản lý data market simple
  useEffect(() => {
    handleGetDataMarketSimple(limit, page.current)
    return () => {
      socketio.off("client-data-for-user-simple")
    }
  }, [])

  // Quản lý nhận data.
  useEffect(() => {
    socketio.on("server-data-for-user-simple", (data) => {
      setDataEstates((old) => [...old, ...data])
      // console.log(data)
      checkpage.current = true
      if (limit > data.length) {
        // Xác nhận đã lấy hết dữ liệu
        checkOverData.current = true
      }
      setLoading(false)
    })
    return () => {
      socketio.off("server-data-for-user-simple")
    }
  }, [page.current])

  const fetchData = async () => {
    // data booking
    const responseBooking = await fetch(
      process.env.REACT_APP_APIGetForUserBooking + `?only=${dataUser[0].id}`,
    )
    const dataResBooking = await responseBooking.json()
    setDataBooking(dataResBooking)
  }

  useEffect(() => {
    fetchData()
  }, [])

  // Nhận về sự thay đổi của người dùng để cập nhật lại dữ liệu.
  const handleResultStatus = (status_) => {
    if (status_) {
      page.current = 1
      const params = {
        iden: process.env.REACT_APP_IDENTIFICATION,
        object: {
          userid: dataUser[0].id,
          quantity: limit,
          offset: (page.current - 1) * limit,
        },
      }
      setLoading(true)
      setDataEstates([])
      setTimeout(() => {
        socketio.emit("client-data-for-user-simple", params)
      }, 500);
    }
  }

  // Sự kiện scroll.
  useEffect(() => {
    const handleScrollDataSimple = () => {
      const totalHeight = document.querySelector("#containerManagerProduct")
      if (totalHeight) {
        //console.log("totalHeight: " + totalHeight.scrollHeight)
        const totalWin = window.innerHeight; //console.log("totalWin: " +totalWin)
        const scrollY_ = window.scrollY; //console.log("scrollY: " +scrollY_)
        if ((scrollY_ + totalWin + 100 > totalHeight.scrollHeight) && !checkOverData.current && checkpage.current && statusSearch.current === "") {
          page.current = page.current + 1
          checkpage.current = false
          setTimeout(() => {
            // console.log(page.current)
            handleGetDataMarketSimple(limit, page.current)
          }, 500);
        }
      }
    }
    window.addEventListener("scroll", handleScrollDataSimple)
    return () => {
      window.removeEventListener("scroll", handleScrollDataSimple)
      socketio.off("client-data-for-user-simple")
    }
  }, [])
// console.log(dataEstates)
  return (
    <div id="containerManagerProduct">
      <CheckPCMobile>
        {(isMobile) =>
          isMobile ? (
            <PageProductMobile
              loading={loading}
              dataEstates={dataEstates}
              dataBooking={dataBooking}
              socketio={socketio}
              result={handleResultStatus}
              effectLoading={checkOverData.current}
              checkSearch={handleStatusSearch}
            />
          ) : (
            <ListProductPC
              loading={loading}
              dataEstates={dataEstates}
              dataBooking={dataBooking}
              socketio={socketio}
              result={handleResultStatus}
              effectLoading={checkOverData.current}
              checkSearch={handleStatusSearch}
            />
          )
        }
      </CheckPCMobile>
    </div>
  )
}

export default SanphamPage

// Danh sách sản phẩm được hiển thị trên máy tính
function ListProductPC({
  loading,
  dataEstates,
  dataBooking,
  socketio,
  result,
  effectLoading,
  checkSearch,
}) {
  return (
    <>
      <HeaderDashboardPage />
      <div className={cx("page justify-content-start", "bg-page")}>
        <div className={cx("mt-3")}>
          {loading ? (
            <div className="mt-6">
              <LoadingSpinner />
            </div>
          ) : (
            <Sanpham
              data={dataEstates}
              dataBooking={dataBooking}
              socketio={socketio}
              result={result}
              effectLoading={effectLoading}
              checkSearch={checkSearch}
            />
          )}
        </div>
      </div>
    </>
  )
}
