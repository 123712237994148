
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"; 
import 'bootstrap/dist/css/bootstrap.min.css'; // Import stylesheet
import { publicRoutes, privateRoutes, isAuthen, privateRoutesPersonnal, privateRoutesAdmin } from "./routers";
import "./assets/style/style.scss"
import "./assets/style/flaticon.scss"
import "./assets/style/styleScreen.scss"
import { useContext, useEffect } from "react";
import AppContext from './handle-reuses/Contexts/CreateContexts';

function App() {
  const { dataUser, socketio } = useContext(AppContext) // gọi dữ liệu bằng useContext
  const filterAdmin = dataUser.filter((item) => item.roleid <= 2)
  const filterPersonnal = dataUser.filter((item) => item.roleid <= 6)
 
  // Kiểm tra user connect.
  useEffect(() => {
    if (dataUser.length > 0) {
      socketio.emit("client-connect-app", dataUser[0].id)
    }
    return () => {
      socketio.off("server-connect-app")
    }
  }, [])

  return (
    <Router>
      <Routes>
        {/* public router */}
        {publicRoutes.map((value, index) => {
          const Page = value.component
          return <Route key={index} path={value.path} element={<Page />} />
        })}

        {/* private router */}
        {privateRoutes.map((value, index) => {
          const PriPage = value.component
          return (
            <Route
              key={index}
              path={value.path}
              element={isAuthen ? <PriPage /> : <Navigate to="/login" />}
            />
          )
        })}

        {/* Private router personnal */}
        {privateRoutesPersonnal.map((value, index) => {
          const PriPerPage = value.component
          return (
            <Route
              key={index}
              path={value.path}
              element={
                isAuthen && filterPersonnal.length > 0 ? (
                  <PriPerPage />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
          )
        })}

        {/* Private router admin */}
        {privateRoutesAdmin.map((value, index) => {
          const PreAdPage = value.component
          return (
            <Route
              key={index}
              path={value.path}
              element={
                isAuthen && filterAdmin.length > 0 ? (
                  <PreAdPage />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
          )
        })}
      </Routes>
    </Router>
  )
}

export default App;
