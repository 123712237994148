import { LoadingSpinner } from "../../../components/Loading"
import classNames from "classnames/bind"
import styles from "../Mobile.module.scss"
import ListProducts from "./ListProducts";
import MenuFooter from "../MenuFooter/MenuFooter";
const cx = classNames.bind(styles);

function PageProduct({ loading, dataEstates, dataBooking, socketio, result, effectLoading, checkSearch }) {
    return (
      <div className={cx("wrapper_dash", "p-3 py-0")}>
        {loading ? (
          <div className="mt-5">
            <LoadingSpinner />
          </div>
        ) : (
          <ListProducts
            data={dataEstates}
            dataBooking={dataBooking}
            socketio={socketio}
            result={result}
            effectLoading={effectLoading}
            checkSearch={checkSearch}
          />
        )}
        {/* MenuFooter */}
        <MenuFooter />
      </div>
    )
}

export default PageProduct