import {
  handleMethodCreateData,
  handleMethodUploadFile,
  handleMethodEditData,
} from "./method"
import imageCompression from "browser-image-compression"


// Get tên file.
function handleSetNameFileImages(nameFile,folder) {
  // Lấy tên ảnh để thêm vào database
  let dateCurrent = new Date()
  let days_ = dateCurrent.getDate()
  let months_ = dateCurrent.getMonth() + 1
  let year_ = dateCurrent.getFullYear()
  let hours_ = dateCurrent.getHours()
  let tenphu = days_ + "-" + months_ + "-" + year_ + "-" + hours_

  let name_avatar = tenphu + "-" + nameFile
  // link avatar.
  let pathImage = `${folder}/${year_}/month-${months_}/days-${days_}/${name_avatar}`

  return pathImage
}



// handle add path image.
async function handleAddPathImages(objectFile, valueInput, folder, api) {
  let newImageFile = Array.from(objectFile)
  // Lấy tên ảnh để thêm vào database
  let dateCurrent = new Date()
  let days_ = dateCurrent.getDate()
  let months_ = dateCurrent.getMonth() + 1
  let year_ = dateCurrent.getFullYear()
  let hours_ = dateCurrent.getHours();
  let minutes_ = dateCurrent.getMinutes()
  let tenphu = days_ + "-" + months_ + "-" + year_ + "-" + hours_ + "-" + minutes_

  // Thêm đường dẫn image rows
  let i = 0
  let max_ = Number(newImageFile.length)
  function sortUploadImages() {
    // Điều kiện sort list images
    if (i < max_) {
      let name_avatar
      if (newImageFile !== "") {
        name_avatar = tenphu + "-" + newImageFile[i].name
      } else {
        name_avatar = ""
      }

      // link avatar.
      let pathImage = `${folder}/${year_}/month-${months_}/days-${days_}/${name_avatar}`

      // Giá trị đầu vào (tùy chọn thay thế cho phù hợp)
      valueInput.path = pathImage
      valueInput.updatetime = Date.now()
      // handle add path image
      handleMethodCreateData(
        valueInput,
        function () {},
        api,
      )
    }
    i++

    setTimeout(sortUploadImages, 10)
  }
  sortUploadImages()
}
/** ======= *handle add images ====== */

/** ======= Upload file ảnh lên hosting ========*/
async function handleUploadFileImage(objectFile, api) {
  // if (!objectFile) {
  //   alert("Bạn chưa chọn thêm ảnh: " + objectFile)
  //   return
  // }

  // if (!newImageFile) {
  //     alert("Không tìm thấy tệp ảnh.");
  //     return;
  // }
  // Chuyển đổi FileList thành mảng
  

  // if (newImageFile.length === 0) {
  //     alert("Vui lòng chọn ít nhất một tệp ảnh.");
  //     return;
  // }

  if (objectFile) {
    const newImageFile = Array.from(objectFile)
    newImageFile.forEach((file) => {
      const formData = new FormData()
      formData.append("file", file, file.name) // Thêm file vào formData với tên file gốc
      // Set kích thước image
      const options = {
        maxSizeMB: 0.6, // Kích thước tối đa của file ảnh sau khi nén (1MB trong trường hợp này)
        maxWidthOrHeight: 3000, // Chiều rộng hoặc chiều cao tối đa của ảnh
        useWebWorker: true, // Sử dụng Web Worker để nén ảnh
      }

      // Thực hiện nén ảnh
      imageCompression(file, options).then((compressedFile) => {
        formData.set("file", compressedFile, file.name) //thêm file sau khi được nén vào FormData()

        handleMethodUploadFile(
          formData,
          () => {},
          api,
        )
      })
    })
  }
}
/** ======= Upload file ảnh lên hosting ========*/


/** ======= Xử lý sắp xếp file images (edit) ===========*/
/**
 * 
 * @param {*} id_ id sản phẩm được thêm ảnh mới
 * @param {*} dataFile đối tượng đầu vào của images
 * @param {*} listImage class list images edit.
 * @param {*} apiEditPath api edit images
 * @param {*} apiCreateImages api Upload file
 * @param {*} apiCreatePath api add path
 * @param {*} folder thư mục chứa images
 * @param {*} callback tạo hiệu ứng khi loading success
 */
async function handleUpdateListPathImages(id_, dataFile, listImage, apiEditPath , apiCreateImages, apiCreatePath, folder, callback) {
  // Dùng đệ quy để sắp xếp update theo thời gian
  let class__ = document.querySelectorAll("." + listImage + "")
  let i = 0;
  async function processNextItem() {
    if (i < class__.length) {
      let strid_ = class__[i].getAttribute("id");
      if (strid_) {
        let id__ = strid_.replace(/\D/g, "")
        let time_ = Date.now()
        let editTime = {
          id: Number(id__),
          updatetime: time_,
        }
        await handleMethodEditData(editTime, function () { }, apiEditPath)

      } else {
        // Xử lý add path database.
        let valueInput = {
          simpleid: id_,
        };
        let filterNameFile = class__[i].getAttribute("data-image-name")

        // Lấy tên ảnh để thêm vào database
        let dateCurrent = new Date()
        let days_ = dateCurrent.getDate()
        let months_ = dateCurrent.getMonth() + 1
        let year_ = dateCurrent.getFullYear()
        let hours_ = dateCurrent.getHours()
        let minutes_ = dateCurrent.getMinutes()
        let tenphu = days_ + "-" + months_ + "-" + year_ + "-" + hours_ + "-" + minutes_
        let name_avatar = tenphu + "-" + filterNameFile

        // link avatar.
        let pathImage = `${folder}/${year_}/month-${months_}/days-${days_}/${name_avatar}`

        // Giá trị đầu vào (tùy chọn thay thế cho phù hợp)
        valueInput.path = pathImage
        valueInput.updatetime = Date.now()
        // handle add path image
        handleMethodCreateData(valueInput, function () {}, apiCreatePath)
        // await handleAddPathImages(dataFile, valueInput, folder, apiCreatePath)
        
      }

      i++ // Chuyển sang xử lý phần tử tiếp theo
      setTimeout(processNextItem, 6)
    }
  }
  
  if (dataFile && dataFile.length > 0) {
    // Xử lý add images hosting
    await handleUploadFileImage(dataFile, apiCreateImages)
    await processNextItem()
  } else {
    // Sắp xếp lại ảnh
    await processNextItem()
  }
}

export {handleSetNameFileImages, handleAddPathImages, handleUploadFileImage, handleUpdateListPathImages }
