import { useEffect, useState, useContext } from "react"
import moment from "moment"
import "moment/locale/vi"
import { HiOutlineHomeModern } from "react-icons/hi2"
import { GiPriceTag } from "react-icons/gi"
import { MdOutlineElectricalServices } from "react-icons/md"
import { BsWater } from "react-icons/bs"
import { GoDot } from "react-icons/go"
import { LuDot } from "react-icons/lu"
import { IoEyeOffOutline, IoWarningOutline } from "react-icons/io5"
import { PiHandshakeLight, PiTimerBold } from "react-icons/pi"
import { BsCreditCard2Back } from "react-icons/bs"
import { TbAirConditioning } from "react-icons/tb"
import { MdHotTub } from "react-icons/md"

import {
  handleFormatPrice,
  handleLimitString,
} from "../../../../../handle-reuses/reuses"
import SliderImage from "../../../../../components/Slider"
import AppContext from "../../../../../handle-reuses/Contexts/CreateContexts"
import { images } from "../../../../../assets/images"

function DetailProduct({ data }) {
  const dataG = useContext(AppContext)
  const dataUser = dataG.dataUser

  const [showPhone, setShowPhone] = useState(false)
  const [buttonShow, setButtonShow] = useState(false)
  const [checkTime, setCheckTime] = useState("")
  const handleShowPhone = () => {
    setShowPhone(data[0].phone)
    setButtonShow(true)
  }
  useEffect(() => {
    // Giới hạn phone.
    const hidePhone = handleLimitString(data[0].phone, 3)
    setShowPhone(hidePhone)
    // Check time.
    const addtime = moment(data[0].addtime)
    const currentTime = moment()
    const diffDays = currentTime.diff(addtime, "days")
    const formatTime = moment(data[0].addtime).format("DD/MM/YYYY HH:mm")
    const formatTime_ = moment(data[0].addtime)
      .locale("vi")
      .startOf("hour")
      .fromNow()
    if (diffDays >= 15) {
      setCheckTime(formatTime)
    } else {
      setCheckTime(formatTime_)
    }
    // Hiển thị nội dung.
    document.getElementById("discriptionDetail").innerHTML = data[0].discription
  }, [data])

  // Xem ảnh hoặc view 360.
  const [viewMethods, setViewMethods] = useState("imagesView")
  const handleEventClickView = (tab) => {
    setViewMethods(tab)
  }
  useEffect(() => {
    if (viewMethods === "view360") {
      // Hiển thị view360.
      document.getElementById("iframeView360").innerHTML = `
        <iframe id="vt_iframe_${data[0].id}" allow="accelerometer; camera; display-capture; fullscreen; geolocation; gyroscope; magnetometer; microphone; midi; xr-spatial-tracking;" width="100%" height="283px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="${data[0].view360}"></iframe>
      `
    }
  }, [viewMethods])

  // Quản lý danh sách hình ảnh.
  const [listImagesPro, setListImagesPro] = useState([images.noImagePro])
  useEffect(() => {
    if (data[0].images) {
      let listImages = data[0].images.split(",")
      setListImagesPro(listImages)
    }
  }, [])
  
  return (
    <div>
      <div className="d-flex mb-2">
        <button
          className="border-0 rounded-2 btn-outline-dark me-2"
          onClick={() => handleEventClickView("imagesView")}
        >
          Hình ảnh
        </button>
        <button
          className="border-0 rounded-2 btn-outline-dark"
          onClick={() => handleEventClickView("view360")}
        >
          View 360
        </button>
      </div>
      {viewMethods === "imagesView" && <SliderImage data={listImagesPro} />}
      {viewMethods === "view360" && (
        <div
          style={{
            height: "283px",
            width: "100%",
            borderRadius: "19px",
            overflow: "hidden",
          }}
        >
          <div id="iframeView360">Chưa có view 360</div>
        </div>
      )}
      <div className="mt-3">
        <div>
          <span className="fs-18 fw-6">{data[0].title}</span>
          <div className="mt-2">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center fs-15">
                {/* <HiOutlineHomeModern className="me-1" /> */}
                <div className="fs-16">{data[0].cate}</div>
              </div>
              <div>
                <span className="fs-17">
                  {/* <GiPriceTag className="me-1" /> */}
                  <span className="me-1">Giá:</span>
                  <span className="fw-6 me-1 text-h text-danger">
                    {data[0].donvi !== "Thỏa thuận"
                      ? handleFormatPrice(Number(data[0].price))
                      : ""}
                  </span>
                  {data[0].donvi === "Thỏa thuận"
                    ? data[0].donvi
                    : data[0].donvi === ""
                    ? ""
                    : " / " + data[0].donvi}
                </span>
              </div>
            </div>
            <span className="fs-16">
              {data[0].address}, {data[0].districts}, {data[0].city}
            </span>
          </div>
        </div>
        <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
        <div className="fs-15">
          <div className="mb-2 fw-6">Thông tin liên hệ</div>
          <div className="d-flex align-items-center">
            <div className="me-2">
              <img
                src={dataUser[0].avatar ? dataUser[0].avatar : images.noImage}
                width={45}
                height={45}
                className="rounded-5"
              />
            </div>
            <div>
              <div className="fw-5">
                {data[0].hoten} <GoDot className="fs-9" />{" "}
                <span className="phoneNumber">{showPhone}</span>
                {buttonShow ? (
                  ""
                ) : (
                  <button
                    className="border-0"
                    style={{ background: "none" }}
                    onClick={handleShowPhone}
                  >
                    <IoEyeOffOutline />
                  </button>
                )}
              </div>
              <div className="text-muted">
                {checkTime} <LuDot />
                {data[0].transaction === 1 ? "Đã giao dịch" : "Chưa giao dịch"}
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
        {/* Thông tin diện tích */}
        <div className="d-flex justify-content-start mt-3 mb-3 gap-3">
          <div className="fs-15">
            Diện tích:
            <span className="fw-6 ms-1">
              {data[0].dtmb.length > 0 ? data[0].dtmb : "0"}m<sup>2</sup>
            </span>
          </div>
          <div className="fs-15">
            M.Tiền:
            <span className="fw-6 ms-1">
              {data[0].mattien.length > 0 ? data[0].mattien : "0"}m
            </span>
          </div>
          <div className="fs-15">
            Số tầng:
            <span className="fw-6 ms-1">{data[0].sotang}</span>
          </div>
        </div>
        <div className="d-flex justify-content-start mt-3 mb-3 gap-5">
          <div className="fs-15">
            Hướng:
            <span className="fw-6 ms-1">
              {data[0].direction.length > 0 ? data[0].direction : "Liên hệ"}
            </span>
          </div>
          <div className="fs-15">
            Pháp lý:
            <span className="fw-6 ms-1">
              {data[0].phaply.length > 0 ? data[0].phaply : "Liên hệ"}
            </span>
          </div>
        </div>
        {/* Thông tin cho thuê */}
        {data[0].cate.indexOf("Cho thuê") !== -1 ? (
          <InfoRentEstate data={data} />
        ) : (
          ""
        )}
        {/* Nội dung mô tả */}
        <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
        <div className="fs-16">
          <div className="fw-6">Nội dung mô tả</div>
          <div className="mt-2" id="discriptionDetail">
            {/* {data[0].discription.replace(/<[^>]*>?/gm, "")} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailProduct

// Thông tin nếu là cho thuê.
const InfoRentEstate = ({ data }) => {
  // tiến độ thanh toán.
  let paymentMethods = data[0].payment_methods
  if (paymentMethods === 1) {
    paymentMethods = "Đêm"
  } else if (paymentMethods === 2) {
    paymentMethods = "Ngày"
  } else if (paymentMethods === 3) {
    paymentMethods = "Tháng"
  } else if (paymentMethods === 4) {
    paymentMethods = "Năm"
  }
  return (
    <div className="fs-15">
      <div className="fw-6">Thông tin cho thuê</div>
      <div className="mt-2">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center me-5">
            <TbAirConditioning className="me-3 fs-21" />
            <div>
              <div>Điều hòa</div>
              <div className="text-muted fs-12">
                {data[0].dieuhoa === 1 ? "Có" : "Không"}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <MdHotTub className="fs-21 me-2 ms-3" />
            <div>
              <div>Nóng lạnh</div>
              <div className="text-muted fs-12">
                {data[0].binhnonglanh === 1 ? "Có" : "Không"}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center mt-3">
          <MdOutlineElectricalServices className="me-3 fs-21" />
          <div>
            <div className="">Giá điện sử dụng</div>
            <div className="text-muted mt-0">
              <span className="me-1">Giá tiêu thụ điện năng</span>
              {Number(data[0].electricity_money)
                .toLocaleString()
                .replace(/[.]/g, ",")}
              đ / số điện
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <BsWater className="me-3 fs-21" />
          <div>
            <div className="">Giá nước sử dụng</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Giá tiêu thụ nước sinh hoạt</span>
              {Number(data[0].water_money)
                .toLocaleString()
                .replace(/[.]/g, ",")}
              đ / khối nước
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <IoWarningOutline className="me-3 fs-21" />
          <div>
            <div className="">Dịch vụ khác</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Chi phí cho các dịch vụ khác</span>
              {Number(data[0].service_price)
                .toLocaleString()
                .replace(/[.]/g, ",")}
              đ / tháng
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <PiHandshakeLight className="me-3 fs-21" />
          <div>
            <div className="">Thời gian ký hợp đồng</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Thời gian khi ký hợp đồng là</span>
              {Number(data[0].contract_time)} tháng trở lên
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <BsCreditCard2Back className="me-3 fs-21" />
          <div>
            <div className="">Số tiền đặt cọc khi thuê</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Số tiền cần đặt cọc khi thuê</span>
              {Number(data[0].deposit).toLocaleString().replace(/[.]/g, ",")}đ
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <PiTimerBold className="me-3 fs-21" />
          <div>
            <div className="">Kỳ hạn thanh toán</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Tiến độ thanh toán được tính theo</span>(
              {paymentMethods})
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
